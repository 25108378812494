import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChannelReportDetails from "../../components/ChannelReportDetails";
import classNames from "classnames";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import avatcoin from "../../assets/svg/avatcoin.svg";
import ExportChannelInfo from "../../forms/ExportChannelInfo";
import {
  Button,
  CircularProgressLoader,
  Modal,
  PostCreated,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ChannelManagementBlocked,
  ChannelManagementDelete,
  Overlay,
  Accordion,
  ChannelManagementSendNotification,
  ChannelNotification,
  ToastBar,
} from "..";
import { getChannelInfo } from "../../actions";
import { ChannelInfoService } from "../../services";
import profileImg from "../../assets/svg/profilepic.svg";
import deleteImg from "../../assets/svg/delete.svg";
import blockImg from "../../assets/svg/block.svg";
import unblockImg from "../../assets/svg/unblock.svg";
import exportImg from "../../assets/svg/export.svg";
import { dateFormatting } from "../../utils/dateMethods";
import "../LayoutWrapperMain/LayoutWrapperMain.module.scss";
import "./channelinfo.module.scss";

interface Props {
  channelInfo: any;
  id: any;
  fetchChannelInfo: any;
  intl: any;
}

const ChannelInfoComponent: React.FC<Props> = (props: Props) => {
  const { channelInfo, id, fetchChannelInfo, intl } = props;

  React.useEffect(() => {
    fetchChannelInfo(id);
  }, [fetchChannelInfo, id]);
  const navigate = useNavigate();

  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [showExportModal, setShowExportModal] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: "",
  });

  const handleModalClose = () => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
    setSendNotification(false);
    setShowExportModal(false);
  };

  const deleteChannelService = async () => {
    setShowOverlay(true);
    const data = await ChannelInfoService.deleteChannelApi(id);
    setShowOverlay(false);
    if (data.data.statusCode === 202) {
      navigate("/channelmanagement");
    }
  };
  return (
    <>
      {showOverlay && <Overlay />}
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() =>
            setNotification({
              ...notification,
              show: false,
              message: "",
            })
          }
        />
      )}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        showOnClose={showBlock || showDelete ? false : true}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className="modal_container">
          {showBlock && (
            <ChannelManagementBlocked
              handleModalClose={handleModalClose}
              fetchChannelList={() => {
                fetchChannelInfo(id);
              }}
              id={id}
              status={
                channelInfo.data.status === "active" ||
                channelInfo.data.status === "inactive"
                  ? "blocked"
                  : "unblocked"
              }
              setOverlay={setShowOverlay}
            />
          )}
          {showDelete && (
            <ChannelManagementDelete
              handleModalClose={handleModalClose}
              deleteChannelService={deleteChannelService}
            />
          )}
        </div>
        {sendNotification && (
          <ChannelManagementSendNotification
            handleModalClose={handleModalClose}
            id={id}
            channelInfo={channelInfo}
            setShowOverlay={setShowOverlay}
          />
        )}
        {showExportModal && (
          <ExportChannelInfo
            handleFormSubmit={(formData: any) => {
              setShowOverlay(true);
              const exportType: any = [];
              Object.keys(formData).forEach((ele: any) => {
                if (formData[ele]) {
                  exportType.push(ele);
                }
              });
              ChannelInfoService.exportChannelInfo({
                channelId: id,
                optionCode: exportType,
                ...(exportType.includes("detail") && {
                  values: {
                    User: channelInfo.data.userId?.username,
                    "Link to the Website":
                      channelInfo.data.link === ""
                        ? "--"
                        : channelInfo.data.link,
                    Dome: channelInfo.data.categoryId?.name,
                    "Pricing Plan":
                      channelInfo.data.subscriptionType === "free"
                        ? "Free"
                        : "Paid",
                    "Subscription Amount": channelInfo.data?.subscriptionPlan[0]
                      ? channelInfo.data?.subscriptionPlan[0]?.price
                      : 0,
                    "No. of Subscribers": channelInfo.data.subscriberCount,
                  },
                }),
              })
                .then(() => {
                  setShowOverlay(false);
                  setNotification({
                    show: true,
                    message: "Details Exported Successfully",
                  });
                  handleModalClose();
                })
                .catch((err: any) => {
                  setShowOverlay(false);
                  setNotification({
                    show: true,
                    message: err.response.data.message,
                  });
                });
            }}
            handleCloseModal={handleModalClose}
          />
        )}
      </Modal>
      {channelInfo.data === null || channelInfo.loadingChannelInfoProgress ? (
        <CircularProgressLoader />
      ) : (
        <>
          <div className="cardBg containerBox p-20">
            <div className="headerActions">
              <div>
                <Button
                  className="button"
                  onClick={() => {
                    setShowModal(true);
                    setSendNotification(true);
                  }}
                >
                  {intl.formatMessage({
                    id: "ChannelInfo.label.SendNotification",
                  })}
                </Button>
              </div>
              <div className="iconWrapperbox">
                <AccessControl
                  permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                  accessType={PERMISSION_TYPES.EDIT}
                >
                  <span className="iconWrapper">
                    <img
                      src={exportImg}
                      role="presentation"
                      onClick={() => {
                        setShowModal(true);
                        setShowExportModal(true);
                      }}
                      alt="exportImg"
                      title="Export"
                    />
                  </span>
                </AccessControl>
                <AccessControl
                  permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                  accessType={PERMISSION_TYPES.DELETE}
                >
                  <span className="iconWrapper">
                    <img
                      src={deleteImg}
                      alt="deleteImg"
                      role="presentation"
                      title="Delete"
                      onClick={() => {
                        setShowModal(true);
                        setShowDelete(true);
                      }}
                    />
                  </span>
                </AccessControl>

                <AccessControl
                  permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                  accessType={PERMISSION_TYPES.EDIT}
                >
                  {channelInfo.data.status === "active" ||
                  channelInfo.data.status === "inactive" ? (
                    <span className="iconWrapper">
                      <img
                        src={blockImg}
                        alt="blockImg"
                        title="Block"
                        role="presentation"
                        onClick={() => {
                          setShowModal(true);
                          setShowBlock(true);
                        }}
                      />
                    </span>
                  ) : (
                    <span className="iconWrapper">
                      <img
                        src={unblockImg}
                        alt="unblockImg"
                        title="unblock"
                        role="presentation"
                        onClick={() => {
                          setShowModal(true);
                          setShowBlock(true);
                        }}
                      />
                    </span>
                  )}
                </AccessControl>
              </div>
            </div>
            <div className="userInformatinc">
              <div className="profileImgWrapper">
                {channelInfo.data.channelIconImage !== "" &&
                channelInfo.data.channelIconImage !== undefined ? (
                  <img
                    className="profileImg"
                    src={channelInfo.data.channelIconImage}
                    alt="profileImg"
                  />
                ) : (
                  <img src={profileImg} alt="profile" />
                )}
              </div>
              <div className="userInfoinner">
                <div>
                  <h2>{channelInfo.data.channelName}</h2>
                  <h3>
                    {intl.formatMessage({
                      id: "ChannelInfo.label.description",
                    })}
                  </h3>
                  <p>{channelInfo.data.description}</p>
                </div>
                <div className="right_container">
                  <h3>
                    {intl.formatMessage({ id: "ChannelInfo.label.createdOn" })}:{" "}
                    <span>{dateFormatting(channelInfo.data.createdAt)}</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="icons_wrap">
              {channelInfo.data.status === "inactive" ? (
                <div className="simple_text">Deactivated</div>
              ) : channelInfo.data.status === "blocked" ? (
                <div className="simple_text">Blocked</div>
              ) : (
                <div className="simple_text">Active</div>
              )}
            </div>
            <div className="postDetails">
              <div className="column">
                <div>
                  <p className="heading_styles">
                    {intl.formatMessage({ id: "ChannelInfo.label.user" })}
                  </p>
                  <p className="count_backround">
                    {channelInfo.data.userId?.username}
                  </p>
                </div>
                <div>
                  <p className="heading_styles">
                    {intl.formatMessage({ id: "ChannelInfo.label.link" })}
                  </p>
                  <p className="count_backround">
                    {channelInfo.data.link === ""
                      ? "--"
                      : channelInfo.data.link}
                  </p>
                </div>
                <div>
                  <p className="heading_styles">
                    {intl.formatMessage({ id: "ChannelInfo.label.dome" })}
                  </p>
                  <p className="count_backround">
                    {channelInfo.data.categoryId?.name}
                  </p>
                </div>
              </div>
              <div className="column">
                <div>
                  <p className="heading_styles">
                    {intl.formatMessage({
                      id: "ChannelInfo.label.pricingplan",
                    })}
                  </p>
                  <p className="count_backround">
                    {channelInfo.data.subscriptionType === "free"
                      ? "Free"
                      : "Paid"}
                  </p>
                </div>
                <div>
                  <p className="heading_styles">
                    {intl.formatMessage({
                      id: "ChannelInfo.label.subscriptionAmount",
                    })}
                  </p>
                  <div className="c_price">
                    <img src={avatcoin} alt="avatcoin" />
                    <p className="count_backround">
                      {channelInfo.data?.subscriptionPlan?.length > 0 &&
                      channelInfo.data?.subscriptionPlan[0]
                        ? channelInfo.data?.subscriptionPlan[0]?.price
                        : 0}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="heading_styles">
                    {intl.formatMessage({
                      id: "ChannelInfo.label.noofsubscribers",
                    })}
                  </p>
                  <p className="count_backround">
                    {channelInfo.data.subscriberCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Accordion heading="Notification Details">
            <ChannelNotification id={id} />
          </Accordion>
          <Accordion heading="Posts Created">
            <PostCreated id={id} />
          </Accordion>
          <Accordion
            heading={intl.formatMessage({ id: "ChannelReport.label.title" })}
          >
            <ChannelReportDetails id={id} />
          </Accordion>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { channelinfo } = state;
  return {
    channelInfo: channelinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchChannelInfo: (payload: any) => dispatch(getChannelInfo(payload)),
});

const ChannelInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ChannelInfoComponent);

export default ChannelInfo;

