import { Button } from '../../components';
import React from 'react';
import { UserInfoService } from '../../services';
import warning from '../../assets/svg/warning.svg';
import '../Modal/Modal.module.scss';

interface Props {
  userInfoStatus: string;
  handleModalClose: any;
  id: any;
  callId?: boolean,
  setShowOverlay: any;
  fetchUserInfo: any;
}

const BlockUserInfo: React.FC<Props> = (props: Props) => {
  const {
    userInfoStatus,
    handleModalClose,
    setShowOverlay,
    id,
    fetchUserInfo,
    callId,
  } = props;

  const [blocked, setBlocked] = React.useState(false);
  const blockUserService = () => {
    setShowOverlay(true);
    UserInfoService.blockUser({
      id,
      status: (userInfoStatus === 'blocked'
        ? 'unblocked' : 'blocked'),
    }).then(() => {
      setShowOverlay(false);
      setBlocked(true);
    });
  };

  return (
    <>
      {!blocked ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            {userInfoStatus === 'blocked' ? 'Unblock User' : 'Block User'}
          </h2>
          <p>
            Are you sure you want to
            {userInfoStatus === 'blocked' ? ' unblock ' : ' block '}
            this user?
          </p>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockUserService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            User
            {userInfoStatus === 'blocked' ? ' Unblocked ' : ' Blocked '}
            successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            if (callId) {
              fetchUserInfo();
            } else {
              fetchUserInfo(id);
            }
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};


export default BlockUserInfo;
