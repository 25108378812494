import React, { useState } from "react";
import { compose } from "redux";
import { injectIntl } from "../../utils/reactIntl";
import { connect } from "react-redux";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Button } from "../../components";
import { stateToHTML } from "draft-js-export-html";
import { Interweave } from "interweave";
import { ContentManagementService } from "../../services";
import { getContentManagement } from "../../actions";
import "../ContentManagement/contentmanagement.module.scss";
import edit from "../../assets/svg/edit.svg";

enum ButtonTypes {
  "button",
  "submit",
  "reset",
  undefined,
}

interface Props {
  contentmanagement: any;
  fetchContentManagement: any;
}

const PrivacyPolicyComponent: React.FC<Props> = (props: Props) => {
  const { contentmanagement, fetchContentManagement } = props;
  const [editable, setEditable] = useState(false);
  const blocksFromHTML = convertFromHTML(
    contentmanagement.data.filter(
      (ele: any) => ele.contentType === "privacy_policy",
    )[0].content,
  );
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      ),
    ),
  );

  return (
    <>
      <h2 className="contentHeading">
        Privacy Policy
        <button type="button" className="headingEdit">
          <img
            src={edit}
            alt="edit"
            role="presentation"
            onClick={() => setEditable(true)}
          />
        </button>
      </h2>
      {editable ? (
        <div>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => setEditorState(e)}
            toolbar={{
              options: ["inline", "textAlign", "link"],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ["bold", "italic", "underline"],
              },
              textAlign: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ["left", "center", "right", "justify"],
              },
              link: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                popupClassName: undefined,
                dropdownClassName: undefined,
                showOpenOptionOnHover: true,
                defaultTargetOption: "",
                options: ["link"],
                linkCallback: undefined,
              },
            }}
          />
          <div className="text-center">
            <Button
              buttonType={ButtonTypes.button}
              onClick={() => {
                ContentManagementService.updatePrivacyPolicy({
                  contentType: "privacy_policy",
                  content: stateToHTML(
                    editorState.getCurrentContent(),
                  ).toString(),
                }).then(() => {
                  fetchContentManagement();
                  setEditable(false);
                });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Interweave
            content={
              contentmanagement.data.filter(
                (ele: any) => ele.contentType === "privacy_policy",
              )[0].content
            }
          />
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  contentmanagement: state.contentmanagement,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchContentManagement: () => dispatch(getContentManagement()),
});

const PrivacyPolicy = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PrivacyPolicyComponent);

export default PrivacyPolicy;

