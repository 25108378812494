import { Button } from '../../components';
import React from 'react';
import { SubDomesManagementService } from '../../services';
import warning from '../../assets/svg/warning.svg';
import  '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchSubDomesList: any;
}

const SubDomesManagementDelete: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchSubDomesList,
  } = props;

  const [deleted, setDeleted] = React.useState(false);
  const deleteDomesService = () => {
    SubDomesManagementService.deleteDomes(id).then(() => {
      setDeleted(true);
    });
  };

  return (
    <>
      {!deleted ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            Delete Sub Dome
          </h2>
          <p>
            Are you sure you want to delete this sub dome?
          </p>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={deleteDomesService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Sub Dome deleted successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchSubDomesList();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default SubDomesManagementDelete;
