import React from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from '../../utils/reactIntl';
import {
  Button,
  FieldCheckbox,
} from '../../components';
import './exportchannelinfo.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  intl: any;
  handleFormSubmit: any,
  handleCloseModal: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const ExportchannelInfoForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    handleFormSubmit,
    handleCloseModal,
  } = props;
  const formProps = useForm();

  return (
    <form className="filterForm" onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <FieldCheckbox
        id="posts"
        name="posts"
        formProps={formProps}
        className="radioColumn"
        label="Post Created"
      />
      <FieldCheckbox
        id="channel-reported"
        name="channel-reported"
        formProps={formProps}
        className="radioColumn"
        label="Channel Reported"
      />
      <FieldCheckbox
        id="notification"
        name="notification"
        formProps={formProps}
        className="radioColumn"
        label="Channel Notification"
      />
      <FieldCheckbox
        id="detail"
        name="detail"
        formProps={formProps}
        className="radioColumn"
        label="Channel Details"
      />
      <div className="buttonWrapper">
        <Button
          onClick={handleCloseModal}
        >
          {intl.formatMessage({ id: 'ExportUserInfo.button.cancel' })}
        </Button>
        <Button
          buttonType={ButtonTypes.submit}
        >
          {intl.formatMessage({ id: 'ExportUserInfo.button.export' })}
        </Button>
      </div>
    </form>
  );
};

export default injectIntl(ExportchannelInfoForm);
