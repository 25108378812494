import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { authInfo, showAlert } from './actions';
import { store, persistor } from './store/index';
import { AdminApp } from './App';
import reportWebVitals from './reportWebVitals';
import { Reload } from './components';
import { register } from './serviceWorker';

const render = (appStore: any) => {
  const { authInfoLoaded } = appStore.getState().auth;
  if (!authInfoLoaded) {
    authInfo(appStore.dispatch);
  }

  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(
      <PersistGate persistor={persistor}>
        <AdminApp appStore={appStore} />
      </PersistGate>
    );
  }
};

declare global {
  interface Window {
    __PRELOADED_STATE__: any;
  }
}

if (typeof window !== 'undefined') {
  render(store);
}

reportWebVitals();

register({
  onUpdate: () => {
    store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }));
  },
});
