import React from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from '../../utils/reactIntl';
import {
  Button,
  FieldCheckbox,
} from '../../components';
import './exportuserinfo.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  intl: any;
  handleFormSubmit: any,
  handleCloseModal: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const ExportUserInfoForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    handleFormSubmit,
    handleCloseModal,
  } = props;
  const formProps = useForm();

  return (
    <form className="filterForm" onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <FieldCheckbox
        id="posts"
        name="posts"
        formProps={formProps}
        className="radioColumn"
        label="Post Created"
      />
      <FieldCheckbox
        id="channels"
        name="channels"
        formProps={formProps}
        className="radioColumn"
        label="Channel Created"
      />
      <FieldCheckbox
        id="service-created"
        name="service-created"
        formProps={formProps}
        className="radioColumn"
        label="Services Created (Under Development)"
      />
      <FieldCheckbox
        id="events"
        name="events"
        formProps={formProps}
        className="radioColumn"
        label="Event Created"
      />
      <FieldCheckbox
        id="services-purchased"
        name="services-purchased"
        formProps={formProps}
        className="radioColumn"
        label="Service Purchased (Under Development)"
      />
      <FieldCheckbox
        id="campaigns-created"
        name="campaigns-created"
        formProps={formProps}
        className="radioColumn"
        label="Campaigns Created (Under Development)'"
      />
      <FieldCheckbox
        id="campaigns-invested"
        name="campaigns-invested"
        formProps={formProps}
        className="radioColumn"
        label="Campaigns Invested In (Under Development)"
      />
      <FieldCheckbox
        id="purchase-history"
        name="purchase-history"
        formProps={formProps}
        className="radioColumn"
        label="Purchase History"
      />
      <FieldCheckbox
        id="followedDomes"
        name="followedDomes"
        formProps={formProps}
        className="radioColumn"
        label="Domes Followed"
      />
      <FieldCheckbox
        id="notification"
        name="notification"
        formProps={formProps}
        className="radioColumn"
        label="User Notifications"
      />
      <FieldCheckbox
        id="detail"
        name="detail"
        formProps={formProps}
        className="radioColumn"
        label="User Details"
      />
      <div className="buttonWrapper">
        <Button
          onClick={handleCloseModal}
        >
          {intl.formatMessage({ id: 'ExportUserInfo.button.cancel' })}
        </Button>
        <Button
          buttonType={ButtonTypes.submit}
        >
          {intl.formatMessage({ id: 'ExportUserInfo.button.export' })}
        </Button>
      </div>
    </form>
  );
};

export default injectIntl(ExportUserInfoForm);
