import { PERMISSION_KEYS, PERMISSION_TYPES } from "../components";
import React from "react";

const ReportUserInfo = React.lazy(() => import("../modules/ReportUserInfo"));
const AuthenticationPage = React.lazy(() => import("../modules/Auth"));
const ChannelInfo = React.lazy(() => import("../modules/ChannelInfo"));
const ChannelManagementPage = React.lazy(
  () => import("../modules/ChannelManagement"),
);
const ContentManagement = React.lazy(
  () => import("../modules/ContentManagement"),
);
const DashboardPage = React.lazy(() => import("../modules/DashboardPage"));
const DomesManagementComponentPage = React.lazy(
  () => import("../modules/DomesManagement"),
);
const Feedback = React.lazy(() => import("../modules/Feedback"));
const LiveStreamingComponentPage = React.lazy(
  () => import("../modules/LiveStreaming"),
);
const LiveStreamingInfoComponentPage = React.lazy(
  () => import("../modules/LiveStreamingInfo"),
);
const ManageDomes = React.lazy(() => import("../modules/ManageDomes"));
const ManageFAQs = React.lazy(() => import("../modules/ManageFAQs"));
const NotificationManagementPage = React.lazy(
  () => import("../modules/NotificationManagement"),
);
const ManageNotification = React.lazy(
  () => import("../modules/ManageNotification"),
);
const ManageRoles = React.lazy(() => import("../modules/ManageRoles"));
const ManageSubAdminComponent = React.lazy(
  () => import("../modules/ManageSubAdmin"),
);
const ManageSubDomes = React.lazy(() => import("../modules/ManageSubDomes"));
const ManageVersion = React.lazy(() => import("../modules/ManageVersion"));
const MasterReasonManagementPage = React.lazy(
  () => import("../modules/MasterReasonManagement"),
);
const MyProfileComponent = React.lazy(() => import("../modules/MyProfile"));
const NotFoundPage = React.lazy(() => import("../modules/NotFoundPage"));
const PostManagement = React.lazy(() => import("../modules/PostManagement"));
const ReportUserManagement = React.lazy(
  () => import("../modules/ReportUserManagement"),
);
const SubscribedUsersManagement = React.lazy(
  () => import("../modules/SubscribedUsers"),
);
const CommissionManagement = React.lazy(
  () => import("../modules/CommissionManagement"),
);
const RewardPointManagement = React.lazy(
  () => import("../modules/RewardPointManagement"),
);
const RoleManagement = React.lazy(() => import("../modules/RoleManagement"));
const SettingComponent = React.lazy(() => import("../modules/Settings"));
const SubAdminManagement = React.lazy(
  () => import("../modules/SubAdminManagement"),
);
const SubDomesManagement = React.lazy(
  () => import("../modules/SubDomesManagement"),
);
const UserInfo = React.lazy(() => import("../modules/UserInfo"));
const SubscribedUserInfo = React.lazy(
  () => import("../modules/SubscribedUserInfo"),
);
const UserManagementComponentPage = React.lazy(
  () => import("../modules/Usermanagement"),
);
const VersionManagement = React.lazy(
  () => import("../modules/VersionManagement"),
);
const SupportModule = React.lazy(() => import("../modules/SupportModule"));
const SupportInfo = React.lazy(() => import("../modules/SupportInfo"));
const PaymentManagement = React.lazy(
  () => import("../modules/PaymentManagement"),
);
const PaymentManagementInfo = React.lazy(
  () => import("../modules/PaymentManagementInfo"),
);
const CoinActivityManagement = React.lazy(
  () => import("../modules/CoinActivityManagement"),
);
const EcommerceAndPurchase = React.lazy(
  () => import("../modules/EcommercePurchaseManagement"),
);
const EcommerceInfo = React.lazy(() => import("../modules/EcommerceInfo"));
const Session = React.lazy(() => import("../modules/Session"));
const SessionInfo = React.lazy(() => import("../modules/SessionInfo"));
const SubscriptionManagement = React.lazy(
  () => import("../modules/SubscriptionManagement"),
);

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
// import { NamedRedirect } from './components';

// const pageDataLoadingAPI = getPageDataLoadingAPI();
// const draftId = '00000000-0000-0000-0000-000000000000';
// const draftSlug = 'draft';

const routeConfiguration = () => [
  {
    path: "/login",
    name: "LoginPage",
    component: AuthenticationPage,
    extraProps: { tab: "login", key: null },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: AuthenticationPage,
    extraProps: { tab: "forgotpassword", key: null },
  },
  {
    path: "/myProfile",
    name: "MyProfile",
    component: MyProfileComponent,
    extraProps: { tab: "myprofile", key: null },
    auth: true,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: AuthenticationPage,
    extraProps: { tab: "resetPassword", key: null },
  },
  {
    path: "/",
    name: "Dashboard",
    component: DashboardPage,
    extraProps: { tab: "dashboard", key: null },
    auth: true,
  },
  {
    path: "/usermanagement",
    name: "UserManagement",
    component: UserManagementComponentPage,
    extraProps: {
      tab: "usermanagement",
      key: PERMISSION_KEYS.USER_MANAGEMENT,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/usermanagement/userdetails/:id",
    name: "User Info",
    component: UserInfo,
    extraProps: {
      tab: "userinfo",
      key: PERMISSION_KEYS.USER_MANAGEMENT,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/subscribedusers/subscribeduserdetails/:id",
    name: "Subscribed User Info",
    component: SubscribedUserInfo,
    extraProps: {
      tab: "subscribeduserinfo",
      key: PERMISSION_KEYS.SUBSCRIBED_USERS,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/channelmanagement",
    name: "ChannelManagement",
    component: ChannelManagementPage,
    extraProps: {
      tab: "channelmanagement",
      key: PERMISSION_KEYS.CHANNEL_MANAGEMENT,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/channelmanagement/channeldetails/:id",
    name: "Channel Details",
    component: ChannelInfo,
    extraProps: {
      tab: "channeldetails",
      key: PERMISSION_KEYS.CHANNEL_MANAGEMENT,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/domesmanagement",
    name: "DomesManagement",
    component: DomesManagementComponentPage,
    extraProps: {
      tab: "domesmanagement",
      key: PERMISSION_KEYS.CATERGORIES,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/domesmanagement/addDomes",
    name: "AddDomes",
    component: ManageDomes,
    extraProps: {
      tab: "adddomes",
      key: PERMISSION_KEYS.CATERGORIES,
      per_type: PERMISSION_TYPES.ADD,
    },
    auth: true,
  },
  {
    path: "/domesmanagement/editDomes/:id",
    name: "EditDomes",
    component: ManageDomes,
    extraProps: {
      tab: "editdomes",
      key: PERMISSION_KEYS.CATERGORIES,
      per_type: PERMISSION_TYPES.EDIT,
    },
    auth: true,
  },
  {
    path: "/versionmanagement/editVersion/:id",
    name: "ManageManagement",
    component: ManageVersion,
    extraProps: {
      tab: "manageversion",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/subdomesmanagement",
    name: "SubDomesManagement",
    component: SubDomesManagement,
    extraProps: {
      tab: "subdomesmanagement",
      key: PERMISSION_KEYS.SUB_CATEGORIES,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/subdomesmanagement/addSubDomes",
    name: "AddSubDomes",
    component: ManageSubDomes,
    extraProps: {
      tab: "addsubdomes",
      key: PERMISSION_KEYS.SUB_CATEGORIES,
      per_type: PERMISSION_TYPES.ADD,
    },
    auth: true,
  },
  {
    path: "/subdomesmanagement/editSubDomes/:id",
    name: "EditSubDomes",
    component: ManageSubDomes,
    extraProps: {
      tab: "editsubdomes",
      key: PERMISSION_KEYS.SUB_CATEGORIES,
      per_type: PERMISSION_TYPES.EDIT,
    },
    auth: true,
  },
  {
    path: "/contentmanagement",
    name: "ContentManagement",
    component: ContentManagement,
    extraProps: {
      tab: "contentmanagement",
      key: PERMISSION_KEYS.CONTENT_MANAGEMENT,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/contentmanagement/addFAQS",
    name: "ManageContent",
    component: ManageFAQs,
    extraProps: {
      tab: "addcontentmanagement",
      key: PERMISSION_KEYS.CONTENT_MANAGEMENT,
      per_type: PERMISSION_TYPES.ADD,
    },
    auth: true,
  },
  {
    path: "/contentmanagement/editFAQs/:id",
    name: "ManageContent",
    component: ManageFAQs,
    extraProps: {
      tab: "editcontentmanagement",
      key: PERMISSION_KEYS.CONTENT_MANAGEMENT,
      per_type: PERMISSION_TYPES.EDIT,
    },
    auth: true,
  },
  {
    path: "/rolemanagement",
    name: "RoleManagement",
    component: RoleManagement,
    extraProps: {
      tab: "rolemanagement",
      key: PERMISSION_KEYS.ROLE_MANAGEMENT,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/rolemanagement/addRoles",
    name: "AddRoles",
    component: ManageRoles,
    extraProps: {
      tab: "addroles",
      key: PERMISSION_KEYS.ROLE_MANAGEMENT,
      per_type: PERMISSION_TYPES.ADD,
    },
    auth: true,
  },
  {
    path: "/rolemanagement/editRoles/:id",
    name: "EditRoles",
    component: ManageRoles,
    extraProps: {
      tab: "editroles",
      key: PERMISSION_KEYS.ROLE_MANAGEMENT,
      per_type: PERMISSION_TYPES.EDIT,
    },
    auth: true,
  },
  {
    path: "/subadminmanagement",
    name: "SubAdminManagement",
    component: SubAdminManagement,
    extraProps: {
      tab: "subadminmanagement",
      key: PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/rewardpoints",
    name: "RewardPointManagement",
    component: RewardPointManagement,
    extraProps: {
      tab: "rewardpoints",
      key: PERMISSION_KEYS.REWARD_POINTS,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/subadminmanagement/addSubAdmin",
    name: "AddSubAdmin",
    component: ManageSubAdminComponent,
    extraProps: {
      tab: "addsubadmin",
      key: PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT,
      per_type: PERMISSION_TYPES.ADD,
    },
    auth: true,
  },
  {
    path: "/subadminmanagement/editSubAdmin/:id",
    name: "EditSubAdmin",
    component: ManageSubAdminComponent,
    extraProps: {
      tab: "editsubadmin",
      key: PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT,
      per_type: PERMISSION_TYPES.EDIT,
    },
    auth: true,
  },
  {
    path: "/reportusermanagement",
    name: "ReportUserManagement",
    component: ReportUserManagement,
    extraProps: {
      tab: "reportusermanagement",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/reportusermanagement/reportuserinfo/:id",
    name: "ReportUserManagement",
    component: ReportUserInfo,
    extraProps: {
      tab: "reportusermanagement",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/reportusermanagement/reportuserinfo/:id/reportedpost/:postId",
    name: "PostManagement",
    component: PostManagement,
    extraProps: {
      tab: "postmanagement",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingComponent,
    extraProps: {
      tab: "settings",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/notification",
    name: "Notification",
    component: NotificationManagementPage,
    extraProps: {
      tab: "notification",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/notification/editNotification/:id",
    name: "EditNotification",
    component: ManageNotification,
    extraProps: {
      tab: "notification",
      key: PERMISSION_KEYS.NOTIFICATION_MANAGEMENT,
      per_type: PERMISSION_KEYS.EDIT,
    },
    auth: true,
  },
  {
    path: "/notification/addNotification",
    name: "AddNotification",
    component: ManageNotification,
    extraProps: {
      tab: "notification",
      key: PERMISSION_KEYS.NOTIFICATION_MANAGEMENT,
      per_type: PERMISSION_KEYS.ADD,
    },
    auth: true,
  },
  {
    path: "/masterreason",
    name: "MasterReason",
    component: MasterReasonManagementPage,
    extraProps: {
      tab: "masterreason",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/livestreaming",
    name: "LiveStreaming",
    component: LiveStreamingComponentPage,
    extraProps: {
      tab: "livestreaming",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/livestreaming/livestreaminfo/:id",
    name: "LiveStreamingInfo",
    component: LiveStreamingInfoComponentPage,
    extraProps: {
      tab: "livestreaming",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/versionmanagement",
    name: "VersionManagement",
    component: VersionManagement,
    extraProps: {
      tab: "versionmanagement",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/versionmanagement/addVersion",
    name: "ManageManagement",
    component: ManageVersion,
    extraProps: {
      tab: "manageversion",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/support",
    name: "SupportManagement",
    component: SupportModule,
    extraProps: {
      tab: "supportmodule",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    extraProps: {
      tab: "feedback",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/support/supportinfo/:id",
    name: "SupportInfo",
    component: SupportInfo,
    extraProps: {
      tab: "supportinfo",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/paymentmanagement",
    name: "PaymentManagement",
    component: PaymentManagement,
    extraProps: {
      tab: "payment",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/paymentmanagement/paymentinfo/:id",
    name: "PaymentManagement",
    component: PaymentManagementInfo,
    extraProps: {
      tab: "payment",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/coinactivity",
    name: "CoinActivity",
    component: CoinActivityManagement,
    extraProps: {
      tab: "coinactivity",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/ecommerce",
    name: "Ecommerce",
    component: EcommerceAndPurchase,
    extraProps: {
      tab: "ecommerce",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/ecommerce/ecommerceInfo/:id",
    name: "EcommerceInfo",
    component: EcommerceInfo,
    extraProps: {
      tab: "ecommerceInfo",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/sessionmanagement",
    name: "Session",
    component: Session,
    extraProps: {
      tab: "session",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/sessionmanagement/sessioninfo/:id",
    name: "Session",
    component: SessionInfo,
    extraProps: {
      tab: "session",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/subscriptionmanagement",
    name: "SubscriptionManagement",
    component: SubscriptionManagement,
    extraProps: {
      tab: "subscriptionmanagement",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/commissionmanagement",
    name: "CommssionManagement",
    component: CommissionManagement,
    extraProps: {
      tab: "commssionmanagement",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/subscribedusers",
    name: "SubscribedUsers",
    component: SubscribedUsersManagement,
    extraProps: {
      tab: "subscribedusernmanagement",
      key: null,
      per_type: null,
    },
    auth: true,
  },
  {
    path: "/notfound",
    name: "NotFoundPage",
    component: NotFoundPage,
    extraProps: { tab: "notfound", key: null, per_type: null },
  },
];

export default routeConfiguration;

