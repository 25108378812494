import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgressLoader,
  FieldTextInput,
} from '../../components';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefineds
}

interface Props {
  loader: any;
  values: any;
  handleSubmit: any;
  handleSaveAsDraft: any;
  error: string;
}

const AddDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    loader,
    handleSaveAsDraft,
    values,
    error,
  } = props;

  const editSubscription = yup.object().shape({
    title: yup.string()
      .required('Please enter the required field'),
    type: yup.string()
      .required('Please enter the required field'),
    amount: yup.string()
      .required('Please enter the required field'),
    discount: yup.string()
      .required('Please enter the required field'),
    discountprice: yup.string()
      .required('Please enter the required field'),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(editSubscription),
  });

  React.useEffect(() => {
    if (values) {
      formProps.reset({
        title: values.title,
        type: values.type,
        amount: values.membership,
        discount: values.discount,
        discountprice: values.discountedprice,
      });
    }
  }, [values, formProps]);

  return (
    <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
      <h2 className="sign_text">Edit Subscription</h2>
      <FieldTextInput
        id="title"
        name="title"
        formName="addDomes"
        className="textInput"
        type="text"
        disabled
        formProps={formProps}
        rootClassName="passwordInput"
        label="Title"
      />
      <FieldTextInput
        id="type"
        name="type"
        formName="addDomes"
        className="textInput"
        type="text"
        disabled
        formProps={formProps}
        rootClassName="passwordInput"
        label="Membership Type"
      />
      <FieldTextInput
        id="amount"
        name="amount"
        formName="addDomes"
        className="textInput"
        type="text"
        formProps={formProps}
        rootClassName="passwordInput"
        label="Membership Amount"
      />
      {loader
        ? (
          <div className="savingLoader">
            <CircularProgressLoader />
          </div>
        ) : (
          <>
            <div className="buttonWrapper">
              <Button
                buttonType={ButtonTypes.submit}
              >
                Done
              </Button>
            </div>
          </>
        )}
    </form>
  );
};

export default AddDomesForm;
