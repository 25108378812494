import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  ChannelInfo, LayoutWrapperMain, Page,
} from '../../components';
import config from '../../config';
import './channelinfo.module.scss';
import { useLocation } from 'react-router';

interface Props {
  params: any;
  intl: any;
  scrollingDisabled: any;
}

const ChannelInfoComponent: React.FC<Props> = (props: Props) => {
  const { intl, scrollingDisabled, params } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'Channelmanagement.Page.title' },
    { siteTitle },
  );
  const location = useLocation();
  return (
    <Page
      location={location}
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain className="layoutWrapperMain">
        <div className="content">
          <ChannelInfo id={params.id} />
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ChannelInfoComponentPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ChannelInfoComponent);

export default ChannelInfoComponentPage;
