import api from '../api';

export class MasterConditionManagement {
  static async getMasterCondition(payload: any) {
    return api.getApiCall('/api/v1/admins/settings', payload);
  }

  static async changeMasterCondition(payload: any) {
    return api.putApiCall('/api/v1/admins/settings', payload);
  }

  static async activateDeactivate(payload: any) {
    return api.putApiCall('/api/v1/admins/settings/activate-deactivate', payload);
  }
}
