import api from '../api';

export class EcomemrceInfoService {
  static async getEcommerceInfo(payload: any) {
    return api.getApiCall(`/api/v1/admins/services-booking/${payload}`);
  }

  static async exportInfo(payload: any) {
    return api.postApiCall('/api/v1/admins/services-product/export/detail', {
      type: 'xlsx',
      sessionId: payload.channelId,
      optionCode: payload.optionCode,
      ...(payload.values && { detailKeys: payload.values }),
    });
  }
}
