import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  Button,
  FieldTextInput,
  ValidationError,
  FieldRadioButton,
  FieldTimePicker,
  FieldDatePicker,
  FieldSelect,
  CircularProgressLoader,
} from "../../components";
import { injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import "./addnewversion.module.scss";
import "../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss";
import { VerionManagementDetail } from "../../services";

enum ButtonTypes {
  "button",
  "submit",
  "reset",
  undefined,
}

interface Props {
  intl: any;
  handleSubmit: any;
  error: any;
  id: any;
  loader: any;
}

const EditNewVersionForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit, error, intl, id, loader } = props;
  const navigate = useNavigate();
  const addSubDomesSchema = yup.object().shape(
    {
      // versionNumber: yup.string()
      //   .required(intl.formatMessage({ id: 'AddVersionManagement.error.versionNumber' })),
      // platform: yup.string()
      //   .required(intl.formatMessage({ id: 'AddVersionManagement.error.platform' })),
      // launch: yup.string()
      //   .required(intl.formatMessage({ id: 'AddVersionManagement.error.launch' })),
      // date: yup.string()
      //   .nullable(false)
      //   .required(intl.formatMessage({ id: 'AddVersionManagement.error.date' }))
      //   .nullable(),
      // time: yup.string()
      //   .required(intl.formatMessage({ id: 'AddVersionManagement.error.time' }))
      //   .nullable(),
      // description: yup.string()
      //   .max(250, intl.formatMessage({ id: 'AddVersionManagement.error.description.max' }))
      //   .required(intl.formatMessage({ id: 'AddVersionManagement.error.description.required' })),
    },
    []
  );

  const formProps = useForm({
    resolver: yupResolver(addSubDomesSchema),
  });
  React.useEffect(() => {
    VerionManagementDetail.getVersionDetails(id).then((data: any) => {
      formProps.reset({
        versionNo: data.data.data.versionNo,
        dateTime: data.data.data.dateTime,
        description: data.data.data.description,
        platform: data.data.data.platform,
        launchType: data.data.data.launchType,
        createdAt: data.data.data.createdAt,
      });
      // setInitialLoader(false);
    });
  }, [formProps, id]);
  // useEffect(() => {
  //   VerionManagementDetail.getVersionDetails(id)
  //     .then((data: any) => {
  //       formProps.reset({
  //         domeName: data.data.data.name,
  //         description: data.data.data.description,
  //         selectedIcon: data.data.data.selectedImage,
  //         unselectedIcon: data.data.data.nonSelectedImage,
  //         selectedIconSVG: data.data.data.selectedImageSvg,
  //         unselectedIconSVG: data.data.data.nonSelectedImageSvg,
  //       });
  //       // setInitialLoader(false);
  //     });
  // }, [formProps, id]);
  console.log(formProps.getValues(), "values");
  return (
    <>
      <div
        className="cardBg addDetailsPage"
      >
        <form
          className="form"
          onSubmit={formProps.handleSubmit(handleSubmit)}
        >
          <h2 className="sign_text">
            {intl.formatMessage({ id: "AddVersionManagement.label.details" })}
          </h2>
          <div className="flex_row">
            <div className="flex_col_sm_6">
              <FieldTextInput
                id="versionNo"
                name="versionNo"
                formName="subAdmin"
                className="textInput"
                type="text"
                formProps={formProps}
                rootClassName="passwordInput"
                label={intl.formatMessage({
                  id: "AddVersionManagement.label.versionNumber",
                })}
                pattern="^\d+(\.\d+)*$"
              />
            </div>
            <div className="flex_col_sm_6">
              <div className="userType">
                <FieldSelect
                  id="platform"
                  name="platform"
                  label={intl.formatMessage({
                    id: "AddVersionManagement.label.platform",
                  })}
                  variant={false}
                  formProps={formProps}
                  options={[
                    { key: "1", label: "Android" },
                    { key: "2", label: "IOS" },
                  ]}
                />
              </div>
            </div>
            <div className="flex_col_sm_12">
              <FieldRadioButton
                id="launchType"
                name="launchType"
                formProps={formProps}
                className="radioBtnWrapper"
                options={[
                  {
                    key: "hard",
                    value: intl.formatMessage({
                      id: "AddVersionManagement.label.hardLaunch",
                    }),
                  },
                  {
                    key: "soft",
                    value: intl.formatMessage({
                      id: "AddVersionManagement.label.softLaunch",
                    }),
                  },
                ]}
                defaultValue={formProps.getValues().launchType} // Pass the defaultValue
              />
            </div>
            <div className="flex_col_sm_6">
              <div className="datepickerField">
                <FieldDatePicker
                  id="dateTime"
                  name="dateTime"
                  className="dateOfBirthInput dateOfBirthInputLg"
                  label={intl.formatMessage({
                    id: "AddVersionManagement.label.date",
                  })}
                  formProps={formProps}
                  maxDate={
                    formProps.watch("endDate") !== null
                      ? formProps.watch("endDate")
                      : moment().subtract(0, "years").format("YYYY-MM-DD")
                  }
                />
              </div>
            </div>
            <div className="flex_col_sm_6">
              <div className="datepickerField">
                <FieldTimePicker
                  id="dateTime"
                  name="dateTime"
                  className="dateOfBirthInput dateOfBirthInputLg"
                  label={intl.formatMessage({
                    id: "AddVersionManagement.label.time",
                  })}
                  formProps={formProps}
                />
              </div>
            </div>
            <div className="flex_col_sm_12">
              <FieldTextInput
                id="description"
                name="description"
                formName="addDomes"
                className="textInput"
                type="textarea"
                formProps={formProps}
                rootClassName="passwordInput"
                label={intl.formatMessage({
                  id: "AddVersionManagement.label.Description",
                })}
              />
            </div>
          </div>
          {Object.keys(formProps.formState.errors).length > 0 && (
            <ValidationError
              className="validation_error"
              error={formProps.formState.errors[
                Object.keys(formProps.formState.errors)[0]
              ]?.message?.toString()}
            />
          )}
          {Object.keys(formProps.formState.errors).length === 0 && (
            <ValidationError error={error} />
          )}
          {/* <div className="buttonWrapper">
            <Button onClick={() => navigate('/versionmanagement')}>
              {intl.formatMessage({ id: 'AddVersionManagement.label.cancel' })}
            </Button>
            <Button buttonType={ButtonTypes.submit}>
              {intl.formatMessage({ id: 'AddVersionManagement.label.publish' })}
            </Button>
          </div> */}
          {loader ? (
            <div className="savingLoader">
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className="buttonWrapper">
                <Button
                  onClick={() => navigate("/versionmanagement")}
                // onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                >
                  {intl.formatMessage({
                    id: "AddVersionManagement.label.cancel",
                  })}
                </Button>
                <Button buttonType={ButtonTypes.submit}>
                  {intl.formatMessage({
                    id: "AddVersionManagement.label.publish",
                  })}
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default injectIntl(EditNewVersionForm);

