import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateFormatting } from "../../utils/dateMethods";
import {
  Button,
  SubDomesManagementBlocked,
  SubDomesManagementDelete,
  Modal,
  ToastBar,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  hasAccess,
} from "../../components";
import { SubDomesManagementService } from "../../services";
import { subdomesManagement } from "../../actions";
import { SubDomesManagementFilter } from "../../forms";
import { exportToCSV, exportToExcel } from "../../utils/exportToExcel";
import DataTable from "../Datatable";
import "../LayoutWrapperMain/LayoutWrapperMain.module.scss";
// import blockIcon from '../../assets/svg/block.svg';
// import unblock from '../../assets/svg/unblock.svg';
import editIcon from "../../assets/svg/edit.svg";
// import deleteIcon from '../../assets/svg/delete.svg';
// re-run
interface Props {
  subdomeslist: any;
  fetchSubDomesList: any;
}

const SubDomesManagement: React.FC<Props> = (props: Props) => {
  const { subdomeslist, fetchSubDomesList } = props;
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    status: "",
    search: "",
    isSortByCategoryName: "",
    isSortByParentCategoryName: "",
    isSortByCreated: "",
  });
  const [error, setError] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edited, setEdited] = React.useState<any>({
    show: false,
    id: null,
  });
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: "",
  });
  React.useEffect(() => {
    fetchSubDomesList(filter);
  }, [fetchSubDomesList, filter]);

  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case "blocked":
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case "unblocked":
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case "delete":
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      case "edit":
        navigate(`/subdomesmanagement/editSubDomes/${blockedid}`);
        break;
      default:
        break;
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = subdomeslist.data ? subdomeslist.data["Pagination-Count"] : 0;
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilter({
      ...filter,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };
  const hideActions = () =>
    hasAccess(PERMISSION_KEYS.SUB_CATEGORIES, PERMISSION_TYPES.ADD) ||
    hasAccess(PERMISSION_KEYS.SUB_CATEGORIES, PERMISSION_TYPES.EDIT) ||
    hasAccess(PERMISSION_KEYS.SUB_CATEGORIES, PERMISSION_TYPES.DELETE);

  const handleSubmitFilter = (data: any) => {
    setError(null);
    if (data.search.label !== undefined || data.userType !== "") {
      setFilter({
        ...filter,
        search: data.search.label,
        status: data.userType,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setEdited({ ...edited, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  const actionButtons = (action: any, idS: any) => (
    <>
      <div className="actionBtnsWrapper">
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div className="actionBtnCover">
            <img
              src={editIcon}
              role="presentation"
              alt="edit"
              width="35"
              title="Edit"
              height="35"
              onClick={() => handleBlockUnblock("edit", idS)}
            />
          </div>
        </AccessControl>
        {/* <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
          accessType={PERMISSION_TYPES.DELETE}
        >
          <div className={commonStyles.actionBtnCover}>
            <img
              src={deleteIcon}
              role="presentation"
              alt="delete"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleBlockUnblock('delete', idS)}
            />
          </div>
        </AccessControl>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div className={commonStyles.actionBtnCover}>
            {action === 'pending' ? null : (action === 'unblocked' ? (
              <img
                src={blockIcon}
                role="presentation"
                alt="block"
                width="35"
                title="Block"
                height="35"
                onClick={() => handleBlockUnblock('blocked', idS)}
              />
            ) : (
              <img
                src={unblock}
                role="presentation"
                alt="unblock"
                width="35"
                title="Unblock"
                height="35"
                onClick={() => handleBlockUnblock('unblocked', idS)}
              />
            ))}
          </div>
        </AccessControl> */}
      </div>
    </>
  );

  function getStatus(status: string) {
    switch (status) {
      case "unblocked":
        return "Active";
      case "pending":
        return "Draft";
      default:
        return "Blocked";
    }
  }

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      domes: data.category.name,
      name: data.name,
      description: (
        <div title={data.description}>
          {data.description.length > 20
            ? `${data.description.substring(0, 20)}...`
            : data.description}
        </div>
      ),
      createdAt: dateFormatting(data.createdAt),
      status: getStatus(data.status),
      ...(hideActions() && {
        action: actionButtons(data.status, idS),
      }),
    };
  }

  const rows: any = [];
  if (subdomeslist.data !== null && subdomeslist.data) {
    subdomeslist.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: "sno",
      label: "S.No",
      sort: false,
    },
    {
      id: "domes",
      label: "Domes",
      sort: true,
    },
    {
      id: "name",
      label: "Sub-Domes Name",
      sort: true,
    },
    {
      id: "description",
      label: "Description",
      sort: false,
    },
    {
      id: "createdAt",
      label: "Added On",
      sort: true,
    },
    {
      id: "status",
      label: "Status",
      sort: false,
    },
    ...(hideActions()
      ? [
          {
            id: "action",
            label: "Action",
          },
        ]
      : []),
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === "domes") {
      setFilter({
        ...filter,
        page: 0,
        isSortByParentCategoryName: order === "asc" ? "1" : "-1",
      });
    }
    if (orderBy === "name") {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === "asc" ? "1" : "-1",
      });
    }
    if (orderBy === "createdAt") {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === "asc" ? "1" : "-1",
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === "input") {
      handleSelectedClick(event, name);
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      status: "",
      search: "",
      isSortByCategoryName: "",
      isSortByParentCategoryName: "",
      isSortByCreated: "",
    });
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === "XLSX") {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Domes: ele.domes,
            SubDomeName: ele.name,
            // Image: ele.icon.props.src,
            description: ele.description.props.title,
            AddedOn: ele.createdAt,
            Status: ele.status,
          });
        });
        exportToExcel(selectedRowDataModified, "SubDomesManagementData");
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: "Excel successfully downloaded.",
        });
      } else {
        SubDomesManagementService.exportUserListToMail({
          ...filter,
          type: "xlsx",
        })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: "Excel has been sent on mail.",
            });
          })
          .catch((err: any) => {
            setLoaderExport(false);
            setNotification({
              show: true,
              message: err.response.data.message,
            });
          });
      }
    } else if (exportType === "CSV") {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Domes: ele.domes,
            SubDomeName: ele.name,
            // Image: ele.icon.props.src,
            description: ele.description.props.title,
            AddedOn: ele.createdAt,
            Status: ele.status,
          });
        });
        exportToCSV(selectedRowDataModified, "SubDomesManagementData");
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: "CSV successfully downloaded.",
        });
      } else {
        SubDomesManagementService.exportUserListToMail({
          ...filter,
          type: "csv",
        })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: "CSV has been sent on mail.",
            });
          })
          .catch((err: any) => {
            setLoaderExport(false);
            setNotification({
              show: true,
              message: err.response.data.message,
            });
          });
      }
    }
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {deleted.show && (
          <SubDomesManagementDelete
            handleModalClose={handleModalClose}
            fetchSubDomesList={() => {
              setFilter({ ...filter, page: 0 });
            }}
            id={deleted.id}
          />
        )}
        {blocked.show && (
          <SubDomesManagementBlocked
            handleModalClose={handleModalClose}
            fetchSubDomesList={() => {
              setFilter({ ...filter, page: 0 });
            }}
            id={blocked.id}
            status="blocked"
          />
        )}
        {unblocked.show && (
          <SubDomesManagementBlocked
            handleModalClose={handleModalClose}
            fetchSubDomesList={() => {
              setFilter({ ...filter, page: 0 });
            }}
            id={unblocked.id}
            status="unblocked"
          />
        )}
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() =>
            setNotification({
              ...notification,
              show: false,
              message: "",
            })
          }
        />
      )}
      <div>
        <div className="topButton">
          <AccessControl
            permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
            accessType={PERMISSION_TYPES.ADD}
          >
            <Button onClick={() => navigate("/subdomesmanagement/addSubDomes")}>
              Add New Sub Dome
            </Button>
          </AccessControl>
        </div>
        <SubDomesManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className="dataTableCover">
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={subdomeslist.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { subdomesmanagement } = state;
  return {
    subdomeslist: subdomesmanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSubDomesList: (payload: any) => dispatch(subdomesManagement(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubDomesManagement);

