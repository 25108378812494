import api from '../api';

export class ReportedUserManagementService {
  static async getReportedUserList(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      startDate,
      endDate,
      isSortByCreated,
      isSortByUsername,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByUsername !== undefined && isSortByUsername !== '' ? `&isSortByUserName=${isSortByUsername}` : ''}${startDate !== undefined && startDate !== '' ? `&startDate=${startDate}` : ''}${endDate !== undefined && endDate !== '' ? `&endDate=${endDate}` : ''}`;
    return api.getApiCall(`/api/v1/admins/reported-users/reported?${apiEndPoint}`);
  }

  static async exportListToMail(payload: any) {
    const {
      startDate,
      endDate,
      status,
      search,
      isSortByCreated,
      isSortByUsername,
      type,
    } = payload;
    const apiEndPoint = `type=${type}&${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByUsername !== undefined && isSortByUsername !== '' ? `&isSortByUsername=${isSortByUsername}` : ''}${startDate !== undefined && startDate !== '' ? `&startDate=${startDate}` : ''}${endDate !== undefined && endDate !== '' ? `&endDate=${endDate}` : ''}`;
    return api.getApiCall(`/api/v1/admins/reported-users/exports?${apiEndPoint}`);
  }

  static async addNote(payload: any) {
    return api.postApiCall('/api/v1/admins/notes', payload);
  }

  static async exports(payload: any) {
    return api.postApiCall('/api/v1/admins/reported-users/exports/reported-details', {
      type: 'xlsx',
      userId: payload.userId,
      optionCode: payload.optionCode,
    });
  }

  static async sendEmail(payload: any) {
    const { userId, subject, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-user-notifications', {
      userId,
      subject,
      message,
      type: 'admin-reported-user-notification',
    });
    return data;
  }

  static async sendPushNotification(payload: any) {
    const { userId, title, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-user-notifications', {
      userId,
      title,
      message,
      type: 'admin-reported-user-notification',
    });
    return data;
  }

  static async sendSMS(payload: any) {
    const { userId, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-user-notifications', {
      userId,
      message,
      type: 'admin-reported-user-notification',
    });
    return data;
  }
}
