import roles from '../../components/AdminRoles/roles.enum';
import React from 'react';

interface Props {
  children: React.ReactNode;
  permissionKey: string;
  accessType: string;
}

const AccessControl: React.FC<Props> = ({ children, permissionKey, accessType }: Props) => {
  const permissions: string | null = localStorage.getItem('permissions');

  if (localStorage.getItem('adminType') === roles.superadmin || !permissions) {
    return <>{children}</>;
  }

  const parsedPermissions = JSON.parse(permissions);

  const hasAccess = parsedPermissions?.find((ele: any) => ele.key === permissionKey)?.[accessType] ?? false;

  return hasAccess ? <>{children}</> : null;
};

export default AccessControl;
