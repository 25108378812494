import { Button } from '../../components';
import React from 'react';
import { MasterReasonManagement } from '../../services';
import warning from '../../assets/svg/warning.svg';
import  '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  fetchReasonList: any;
  title: string;
  description: string;
  reasons: any;
  masterReasonId: any;
}
const MasterReasonManagementSave: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    fetchReasonList,
    title,
    description,
    reasons,
    masterReasonId,
  } = props;

  const [save, setSave] = React.useState(false);
  const blockService = () => {
    MasterReasonManagement.editMasterReason({
      masterReasonId,
      reason: title,
      ...(description !== null && { description }),
      reportModuleCodes: reasons,
    }).then(() => {
      setSave(true);
    });
  };

  return (
    <>
      {!save ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            Edit
          </h2>
          <p>
            Are you sure you want to save the entered value?
          </p>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Saved successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchReasonList();
          }}
          >
            Ok
          </Button>
        </>
      )}
    </>
  );
};

export default MasterReasonManagementSave;
