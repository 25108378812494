import React, { useEffect, useRef, useState } from 'react';
import { onMessageListener } from '../../utils/firebase.js';
import { Notification } from '../../services';
import NotificationsIcon from '@material-ui/icons/Notifications';
import classNames from 'classnames';
import InfinitScroll from 'react-infinite-scroll-component';
import { CircularProgressLoader } from '../../components';
import userImg from '../../assets/svg/userbig.svg';
import { compose } from 'redux';
import { injectIntl } from '../../utils/reactIntl';
import { getDateAndTime } from '../../utils/dateMethods';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../constants/index';
import './notification.scss';

const Notifications = () => {
  const wrapperRef = useRef(null);
  const [newNotification, setNewNotification] = useState(false);
  const [count, setCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [scrollableData, setScrollableData] = React.useState<any>();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    setScrollableData({
      data: [],
      count: 0,
      msg: '',
    });
    Notification.getAppNotification({
      page: 0,
      limit: 10,
    }).then((res: any) => {
      setScrollableData({
        data: [...res.data.data.data],
        count: res.data.data['Total-Page'],
      })
      setCount((prevState: any) => prevState + 1);
    }).catch((err: any) => {
      if (err.response.data.statusCode === 403) {
        dispatch({ type: ActionTypes.LOGOUT_WITH_ERROR_REQUEST });
      }
    });
  }, [newNotification]);

  const fetchNextNotification = () => {
    setCount((prevState: any) => prevState + 1);
    Notification.getAppNotification({
      page: count,
      limit: 10,
    }).then((res: any) => {
      setScrollableData({
        ...scrollableData,
        data: [...scrollableData.data, ...res.data.data.data],
        count: res.data.data['Total-Page'],
      });
    });
  };

  onMessageListener()
    .then(() => {
      setNewNotification(true);
    });

  const handleRedirection = (notificationData: any) => {
    if (notificationData.type === 'faq') {
      navigate('/contentmanagement?active=FAQ&ques=' + notificationData.question);
    }
    if (notificationData.type === 'contact-us') {
      navigate('/support/supportinfo/' + notificationData.contactUsId);
    }
    if (notificationData.type === "repoted-user") {
      navigate('/reportusermanagement/reportuserinfo/' + notificationData.userId);
    }
  }

  const handleReadNotification = (notificationData: any) => {
    handleRedirection(notificationData);
    const notificationId = notificationData._id;
    setShowNotification((prevState: any) => !prevState);
    setShowLoader(true);
    Notification.markReadNotiifcation({
      isReaded: 1,
      isCleared: 0,
      noitficationIds: new Array(notificationId),
    }).then(() => {
      Notification.getAppNotification({
        page: 0,
        limit: 10,
      }).then((res: any) => {
        setScrollableData({
          data: [...res.data.data.data],
          count: res.data.data['Total-Page'],
        });
        setCount(0);
        setShowLoader(false);
      });
    });
  };

  // const handleClearNotification = (notificationId: any) => {
  //   Notification.markReadNotiifcation({
  //     isReaded: 0,
  //     isCleared: 1,
  //     noitficationIds: new Array(notificationId),
  //   }).then(() => {
  //     setScrollableData({
  //       data: [],
  //       count: 0,
  //       msg: '',
  //     });
  //   });
  // };
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowNotification(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);
  return (
    <>
      <div ref={wrapperRef} style={{ position: 'relative' }}>
        <>
          <div className={classNames('notification_icon', newNotification ? 'notification_dot' : '')}>
            <NotificationsIcon onClick={() => setShowNotification((prevState: any) => !prevState)} />
          </div>
          {showNotification && (
            <>
              <div className="notification_menu">
                {showLoader && <CircularProgressLoader />}
                <div className="notification_topbar">Notifications</div>
                {!showLoader && (
                  <div id="scrollableDiv" className="notification_menu_wrap">
                    <InfinitScroll
                      dataLength={scrollableData.data.length}
                      next={fetchNextNotification}
                      hasMore={count <= scrollableData.count}
                      loader={<CircularProgressLoader />}
                      scrollableTarget="scrollableDiv"
                    >
                      {scrollableData.data.map((ele: any) => (
                        <div
                          role="presentation"
                          className={ele.isReaded ? 'notification_menu_item' : 'notification_menu_item messageNotRead'}
                          onClick={() => handleReadNotification(ele)}
                        >
                          <div className="d_flex">
                            <figure className="user_img">
                              {ele.image === null || ele.image === undefined
                                ? <img src={userImg} alt="" />
                                : <img src={ele.image} alt="" />}
                            </figure>
                            <div className="notification_item_content">
                              <p>
                                <strong>{ele.title}</strong>
                              </p>
                              <div>
                                <p>{ele.message}</p>
                              </div>
                              <span className="time">{getDateAndTime(ele.createdAt)}</span>
                            </div>
                            {/* <button type="button" className="clear_btn">
                              <ClearIcon onClick={() => handleClearNotification(ele._id)} />
                            </button> */}
                          </div>
                        </div>
                      ))}
                    </InfinitScroll>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </div>

    </>
  );
};

const NotificationComponent = compose<React.ElementType>(
  injectIntl,
)(Notifications);
export default NotificationComponent;
