/* eslint-disable max-classes-per-file */

class AuthDataModal {
    isAuthenticated = false;

authScopes: any = [];

// auth info

    authInfoLoaded = false;

    // login

    loginError = null;

    loginInProgress = false;

    // logout

    logoutError = null;

    logoutInProgress = false;

    loginInfoData = null;

    // forgotpass

    forgotpassInprogress = false;

    forgotpassError = null;

    forgotpassSucess = false;
}

class RoutingModal {
    currentLocation = null;

    currentCanonicalUrl = null;
}

class AvatarModal {
    data: any = null;

    avatarError: any = null;

    loadingAvatar = false;
}

class Usermanagement {
    data: any= null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ChannelManagement {
    data: any=null;

    loadingInProgress: any = null;

    loadingSucess: any = null;

    loadingError: any = null;
}

class SubscribedUserManagement {
    data: any=null;

    loadingInProgress: any = null;

    loadingSucess: any = null;

    loadingError: any = null;
}

class CommissionManagement {
    data: any=null;

    loadingInProgress: any = null;

    loadingSucess: any = null;

    loadingError: any = null;
}
class CommissionDetails {
    data: any=null;

    loadingInProgress: any = null;

    loadingSucess: any = null;

    loadingError: any = null;
}
class CommissionRulesManagement {
    data: any=null;

    loadingInProgress: any = null;

    loadingSucess: any = null;

    loadingError: any = null;
}

class CommissionRulesManagementUpdate {
    data: any=null;

    loadingInProgress: any = null;

    loadingSucess: any = null;

    loadingError: any = null;
}
class ChannelInfo {
    data: any = null;

    loadingChannelInfoProgress: any = false;

    loadingChannelInfoSucess: any = false;

    loadingChannelInfoError: any = false;
}

class UserInfo {
    data: any= null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class UserProfileInfo {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class DomesManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SubDomesManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ContentManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}
class FAQSManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class RoleManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PostCreated {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class LoginDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SubAdminManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class CreditActivityDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class DebitActivityDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ReportedUserManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class NotesDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class UserReportedUser {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PostReportedUser {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ChannelReported {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class NotificationDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PriceManagement {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PermissionDispatch {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class Notification {
    data: any = [];

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class NotificationManagement {
    data: any = [];

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}
class MasterReasonManagement {
    data: any = [];

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class MasterConditionManagement {
    data: any = [];

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class FeedbackManagement {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ManageVersion {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class VersionManage {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}
class LiveStream {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class LiveStreamInfo {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ParticipantDetail {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class EventDetail {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class NotificationLive {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PostDetail {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PostDetailReportedUserInfo {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SupportManagement {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SupportInfo {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PaymentManagement {
    data: any = [];

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PaymentInfo {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class UserNotification {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SubscriptionDetails {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ChannelNotificationDetail {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class CoinActivity {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class AdminNotification {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class TransactionDetails {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class DashboardData {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class NotificationEdit {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class EcommerceManagement {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class EcommerceInfo {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class EcommerceNotification {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SessionManagement {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SubscriptionPlan {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SessionInfo {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class Tutorials {
    data: any = null;
    loadingInProgress: any = true;
    loadingSuccess: any = null;
    loadingError: any = null;
    loadingInProgressTutorial: any = false;
    loadingSuccessTutorial: any = false;
    loadingErrorTutorial: any = false;
    loadingRequestDelete: any = false;
    loadingSuccessDelete: any = false;
    loadingErrorDelete: any = false;
}

export {
  AvatarModal,
  AuthDataModal,
  RoutingModal,
  Usermanagement,
  UserInfo,
  ChannelManagement,
  SubscribedUserManagement,
  ChannelInfo,
  UserProfileInfo,
  DomesManagement,
  SubDomesManagement,
  ContentManagement,
  FAQSManagement,
  RoleManagement,
  PostCreated,
  LoginDetails,
  SubAdminManagement,
  CreditActivityDetails,
  DebitActivityDetails,
  ReportedUserManagement,
  NotesDetails,
  UserReportedUser,
  PostReportedUser,
  ChannelReported,
  NotificationDetails,
  PriceManagement,
  PermissionDispatch,
  Notification,
  NotificationManagement,
  MasterReasonManagement,
  MasterConditionManagement,
  FeedbackManagement,
  ManageVersion,
  LiveStream,
  LiveStreamInfo,
  ParticipantDetail,
  EventDetail,
  NotificationLive,
  PostDetail,
  SupportManagement,
  PostDetailReportedUserInfo,
  SupportInfo,
  PaymentManagement,
  PaymentInfo,
  UserNotification,
  ChannelNotificationDetail,
  CoinActivity,
  AdminNotification,
  TransactionDetails,
  DashboardData,
  NotificationEdit,
  EcommerceManagement,
  EcommerceInfo,
  EcommerceNotification,
  SessionManagement,
  SubscriptionPlan,
  SessionInfo,
  Tutorials,
  SubscriptionDetails,
  CommissionManagement,
  CommissionDetails,
  CommissionRulesManagement,
  CommissionRulesManagementUpdate,
  VersionManage
};
