import { Button, ValidationError, Overlay } from '../../components';
import {
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentManagementService } from '../../services';
import { useNavigate } from 'react-router-dom';
import {
  FormControl, MenuItem, Select,
} from '@material-ui/core';
import './editfaq.module.scss';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  id: string,
  categoryId: any,
  answer: any;
  question: any;
}

const EditFAQs: React.FC<Props> = (props: Props) => {
  const {
    answer, question, id, categoryId,
  } = props;
  let blocksFromHTML = convertFromHTML(answer);
  const [overlay, setOverlay] = React.useState(true);
  const [answerState, setAnswerState] = React.useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )),
  );
  blocksFromHTML = convertFromHTML(question);
  const [questionState, setQuestionState] = React.useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )),
  );
  const [categories, setCategories] = React.useState([]);
  useEffect(() => {
    ContentManagementService.getFAQCategory().then((res: any) => {
      setCategories(res.data.data.data);
      setOverlay(false);
    });
  }, []);
  const [error, setError] = React.useState('');
  const navigate = useNavigate();
  const [id_, setId] = React.useState<any>({ id: categoryId });

  return (
    <>
      {overlay && <Overlay />}
      <h2>Edit FAQs</h2>
      <div>
        <h2>Category</h2>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="dropdown_b"
            value={id_.id}
            label="Category"
            onChange={(val: any) => {
              const name: any = categories.filter((ele: any) => ele._id === val.target.value);
              setId({ id: name[0]._id, name: name[0].name });
            }}
          >
            {categories.map((ele: any) => <MenuItem value={ele._id}>{ele.name}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      <h2>Question</h2>
      <Editor
        editorState={questionState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(e: any) => {
          setQuestionState(e);
        }}
        toolbar={{
          options: ['inline', 'textAlign', 'link'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link'],
            linkCallback: undefined,
          },
        }}
      />
      <h2>Answer</h2>
      <Editor
        editorState={answerState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(e) => setAnswerState(e)}
        toolbar={{
          options: ['inline', 'textAlign', 'link'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            defaultTargetOption: '_blank',
            options: ['link'],
            linkCallback: undefined,
          },
        }}
      />
      {error.length > 0 && <ValidationError error={error} />}
      <div className="buttonWrapper">
        <Button
          buttonType={ButtonTypes.button}
          onClick={() => {
            if (answerState.getCurrentContent().getPlainText().length === 0
              || questionState.getCurrentContent().getPlainText().length === 0) {
              setError('Please fill all fields.');
            } else {
              ContentManagementService.editFAQs(
                {
                  faqId: id,
                  categoryId: id_.id,
                  categoryName: id_.name,
                  status: 'draft',
                  question: stateToHTML(questionState.getCurrentContent()),
                  answer: stateToHTML(answerState.getCurrentContent()),
                },
              ).then(() => {
                setError('');
                navigate('/contentmanagement?active=FAQ');
              });
            }
          }}
        >
          Save as Draft
        </Button>
        <Button
          buttonType={ButtonTypes.button}
          onClick={() => {
            if (answerState.getCurrentContent().getPlainText().length === 0
              || questionState.getCurrentContent().getPlainText().length === 0) {
              setError('Please fill all fields.');
            } else {
              const question_ = stateToHTML(questionState.getCurrentContent());
              // if (question.includes('<a ')) {
              //   question_ = `${question_.split('<a ')[0]} <a target = '_blank' ${question_.split('<a ')[1]}`;
              // }
              const answer_ = stateToHTML(answerState.getCurrentContent());
              // if (answer_.includes('<a ')) {
              //   answer_ = `${answer_.split('<a ')[0]} <a target = '_blank' ${answer_.split('<a ')[1]}`;
              // }
              ContentManagementService.editFAQs({
                question: question_,
                answer: answer_,
                faqId: id,
                categoryId: id_.id,
                categoryName: id_.name,
                status: 'unblocked',
              }).then(() => {
                setError('');
                navigate('/contentmanagement?active=FAQ');
              });
            }
          }}
        >
          Publish
        </Button>
      </div>
    </>
  );
};

export default EditFAQs;
