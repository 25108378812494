import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldCheckbox,
  FieldTextInput,
  ValidationError,
  CircularProgressLoader,
} from '../../components';
import classNames from 'classnames';
import './addnewroles.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  handleSubmit: any;
  handleSaveAsDraft: any;
  loader: any;
}

const AddNewRolesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    loader,
    handleSaveAsDraft,
  } = props;

  const formProps = useForm({
    // resolver: yupResolver(),
  });

  return (
    <>
      <div className="cardBg addDetailsPage">
        <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
          <h2 className="sign_text_s">New Roles</h2>
          <div className="flex_row">
            <div className="flex_col_sm_4">
              <FieldTextInput
                id="roleName"
                name="roleName"
                formName="newRole"
                className="textInput"
                type="text"
                formProps={formProps}
                label="*Role Name"
              />
            </div>
          </div>
          <h3 className="sign_text_s">Permissions</h3>
          <table className="dataTableCover permissionsTable">
            <thead>
              <tr>
                <th>
                  Modules
                </th>
                <th>
                  Add
                </th>
                <th>
                  Edit
                </th>
                <th>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>User Management</td>
                <td>
                  <FieldCheckbox
                    id="usermgmt_add"
                    name="usermgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="usermgmt_edit"
                    name="usermgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="usermgmt_delete"
                    name="usermgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Channel Management</td>
                <td>
                  <FieldCheckbox
                    id="channelmgmt_add"
                    name="channelmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="channelmgmt_edit"
                    name="channelmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="channelmgmt_delete"
                    name="channelmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Subscription Plan Management</td>
                <td>
                  <FieldCheckbox
                    id="subscriptionplanmgmt_add"
                    name="subscriptionplanmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subscriptionplanmgmt_edit"
                    name="subscriptionplanmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subscriptionplanmgmt_delete"
                    name="subscriptionplanmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Subscribed Users</td>
                <td>
                  <FieldCheckbox
                    id="subscribedusers_add"
                    name="subscribedusers_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subscribedusers_edit"
                    name="subscribedusers_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subscribedusers_delete"
                    name="subscribedusers_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Service Provider Management</td>
                <td>
                  <FieldCheckbox
                    id="serviceprovidemgmt_add"
                    name="serviceprovidemgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="serviceprovidemgmt_edit"
                    name="serviceprovidemgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="serviceprovidemgmt_delete"
                    name="serviceprovidemgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Purchase/E-commerce</td>
                <td>
                  <FieldCheckbox
                    id="purchaseecommerce_add"
                    name="purchaseecommerce_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="purchaseecommerce_edit"
                    name="purchaseecommerce_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="purchaseecommerce_delete"
                    name="purchaseecommerce_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Campaign Management</td>
                <td>
                  <FieldCheckbox
                    id="campaignmgmt_add"
                    name="campaignmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="campaignmgmt_edit"
                    name="campaignmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="campaignmgmt_delete"
                    name="campaignmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Promotions & Advertising</td>
                <td>
                  <FieldCheckbox
                    id="promotionsadver_add"
                    name="promotionsadver_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="promotionsadver_edit"
                    name="promotionsadver_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="promotionsadver_delete"
                    name="promotionsadver_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Seller/Brand Management</td>
                <td>
                  <FieldCheckbox
                    id="sellerbrandmgmt_add"
                    name="sellerbrandmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="sellerbrandmgmt_edit"
                    name="sellerbrandmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="sellerbrandmgmt_delete"
                    name="sellerbrandmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Event Management</td>
                <td>
                  <FieldCheckbox
                    id="livestreaming_add"
                    name="livestreaming_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="livestreaming_edit"
                    name="livestreaming_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="livestreaming_delete"
                    name="livestreaming_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Reported User Management</td>
                <td>
                  <FieldCheckbox
                    id="reportedusermgmt_add"
                    name="reportedusermgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="reportedusermgmt_edit"
                    name="reportedusermgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="reportedusermgmt_delete"
                    name="reportedusermgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Role Management</td>
                <td>
                  <FieldCheckbox
                    id="rolemgmt_add"
                    name="rolemgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="rolemgmt_edit"
                    name="rolemgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="rolemgmt_delete"
                    name="rolemgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Sub-Admin Management</td>
                <td>
                  <FieldCheckbox
                    id="subadminmgmt_add"
                    name="subadminmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subadminmgmt_edit"
                    name="subadminmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subadminmgmt_delete"
                    name="subadminmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Event Management</td>
                <td>
                  <FieldCheckbox
                    id="eventmgmt_add"
                    name="eventmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="eventmgmt_edit"
                    name="eventmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="eventmgmt_delete"
                    name="eventmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Payment Management</td>
                <td>
                  <FieldCheckbox
                    id="paymentmgmt_add"
                    name="paymentmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="paymentmgmt_edit"
                    name="paymentmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="paymentmgmt_delete"
                    name="paymentmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Coin Activity Management</td>
                <td>
                  <FieldCheckbox
                    id="coinactivitymgmt_add"
                    name="coinactivitymgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="coinactivitymgmt_edit"
                    name="coinactivitymgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="coinactivitymgmt_delete"
                    name="coinactivitymgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Commission Management</td>
                <td>
                  <FieldCheckbox
                    id="commissionmgmt_add"
                    name="commissionmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="commissionmgmt_edit"
                    name="commissionmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="commissionmgmt_delete"
                    name="commissionmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Reward Points</td>
                <td>
                  <FieldCheckbox
                    id="rewardpoints_add"
                    name="rewardpoints_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="rewardpoints_edit"
                    name="rewardpoints_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="rewardpoints_delete"
                    name="rewardpoints_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Domes Management</td>
                <td>
                  <FieldCheckbox
                    id="categories_add"
                    name="categories_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="categories_edit"
                    name="categories_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="categories_delete"
                    name="categories_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Sub Domes Management</td>
                <td>
                  <FieldCheckbox
                    id="subcategorymgmt_add"
                    name="subcategorymgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subcategorymgmt_edit"
                    name="subcategorymgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="subcategorymgmt_delete"
                    name="subcategorymgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Issues Reported</td>
                <td>
                  <FieldCheckbox
                    id="issuesreported_add"
                    name="issuesreported_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="issuesreported_edit"
                    name="issuesreported_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="issuesreported_delete"
                    name="issuesreported_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Version Management</td>
                <td>
                  <FieldCheckbox
                    id="versionmanagement_add"
                    name="versionmanagement_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="versionmanagement_edit"
                    name="versionmanagement_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="versionmanagement_delete"
                    name="versionmanagement_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Reminder Emails</td>
                <td>
                  <FieldCheckbox
                    id="reminderemails_add"
                    name="reminderemails_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="reminderemails_edit"
                    name="reminderemails_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="reminderemails_delete"
                    name="reminderemails_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Content Management</td>
                <td>
                  <FieldCheckbox
                    id="contentmgmt_add"
                    name="contentmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="contentmgmt_edit"
                    name="contentmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="contentmgmt_delete"
                    name="contentmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Notification Management</td>
                <td>
                  <FieldCheckbox
                    id="notificationmmt_add"
                    name="notificationmmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="notificationmmt_edit"
                    name="notificationmmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="notificationmmt_delete"
                    name="notificationmmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Support</td>
                <td>
                  <FieldCheckbox
                    id="support_add"
                    name="support_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="support_edit"
                    name="support_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="support_delete"
                    name="support_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Feedback </td>
                <td>
                  <FieldCheckbox
                    id="feedback_add"
                    name="feedback_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="feedback_edit"
                    name="feedback_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="feedback_delete"
                    name="feedback_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
              <tr>
                <td>Report Management </td>
                <td>
                  <FieldCheckbox
                    id="reportmgmt_add"
                    name="reportmgmt_add"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="reportmgmt_edit"
                    name="reportmgmt_edit"
                    formProps={formProps}
                  />
                </td>
                <td>
                  <FieldCheckbox
                    id="reportmgmt_delete"
                    name="reportmgmt_delete"
                    formProps={formProps}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {Object.keys(formProps.formState.errors).length > 0
            && (
              <ValidationError
                className="validation_error"
                error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
              />
            )}
          {loader ? <CircularProgressLoader /> : (
            <>
              <div className="buttonWrapper">
                <Button onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}>
                  Save as Draft
                </Button>
                <Button buttonType={ButtonTypes.submit}>
                  Create Role
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default AddNewRolesForm;
