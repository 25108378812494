import classNames from 'classnames';
import { PriceManagement, SuperAdminAccessControl, MasterConditionManagement } from '../../components';
import roles from '../../components/AdminRoles/roles.enum';
import React from 'react';
import { connect } from 'react-redux';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

const Settings: React.FC = () => (
  <>
    <div className="cardBg">
      <SuperAdminAccessControl rules={[roles.superadmin]}>
        <PriceManagement />
      </SuperAdminAccessControl>
      <MasterConditionManagement />
    </div>
  </>
);

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
