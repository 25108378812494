import React from 'react';
import errorImg from '../../assets/images/error.png';
import {
  LayoutWrapperMain,
} from '..';
import './LoadableComponentErrorBoundary.scss';

interface Props {
  error: string;
}

export const LoadableComponentErrorBoundaryPage = (props: Props) => {
  const { error } = props;

  return (
    <div>
      <LayoutWrapperMain>
        <div className="error_root">
          <div className="content">
            <div className="img_wrap">
              <img src={errorImg} alt="" role="presentation" />
            </div>
            <h1>
              OOPS! Something went wrong.
            </h1>
            <p>
              {error}
            </p>
          </div>
        </div>
      </LayoutWrapperMain>
    </div>
  );
};
