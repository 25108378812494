import api from '../api';

export class CommissionManagement {
  static async getTopCardsList(payload: any) {
    const {
      startDate,
      endDate,
    } = payload;
    const apiEndPoint=`${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}`
    return api.getApiCall(`/api/v1/admins/commissions/commissionAmount-Details?${apiEndPoint}`);
  }

}
