import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  ContentManagement,
  LayoutWrapperMain,
  Page,
} from '../../components';
import config from '../../config';
import './contentmanagement.module.scss';

interface Props {
  scrollingDisabled: boolean,
  intl: any,
}

const ContentManagementComponent: React.FC<Props> = (props: Props) => {
  const {
    intl,
    scrollingDisabled,
  } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'ContentManagement.page.title' },
    { siteTitle },
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain className="layoutWrapperMain">
        <ContentManagement />
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ContentManagementComponentPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ContentManagementComponent);

export default ContentManagementComponentPage;
