import api from '../api';

export class LiveStreamingManagementInfo {
  static async getLiveStreamIfo(payload: any) {
    return api.getApiCall(`/api/v1/admins/events/${payload}`);
  }

  static async getDomes() {
    return api.getApiCall('/api/v1/admins/categories/names');
  }

  static async exportEventInfo(payload: any) {
    return api.postApiCall('/api/v1/admins/events/exports/details', {
      type: 'xlsx',
      eventId: payload.eventId,
      optionCode: payload.optionCode,
      ...(payload.values && { detailKeys: payload.values }),
    });
  }

  static async sendEmail(payload: any) {
    const {
      userId, eventId, subject, message,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-event-notifications', {
      eventId,
      subject,
      message,
      userId,
    });
    return data;
  }

  static async sendPushNotification(payload: any) {
    const {
      userId, eventId, title, message,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-event-notifications', {
      eventId,
      title,
      message,
      userId,
    });
    return data;
  }

  static async sendSMS(payload: any) {
    const { userId, eventId, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-event-notifications', {
      eventId,
      message,
      userId,
    });
    return data;
  }
}
// https://avatusdevapi.appskeeper.in/dev/api/v1/admins/reported-users/exports/reported-details
