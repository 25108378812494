import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import classNames from 'classnames';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Notification } from '../../services';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import information from '../../assets/svg/info.svg';
import { AddNotificationEmailForm, AddNotificationSMSForm, AddNotificationPushForm } from '../../forms';
import { injectIntl } from 'react-intl';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import { Button, Modal, Overlay, ToastBar } from '../../components';
import './AddNotification.module.scss';
import edit from '../../assets/svg/edit.svg';


interface Props {
  intl: any;
}

const AddNotification = (props: Props) => {
  const { intl } = props;
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState("#");
  const [notification, setNotification] = useState({
    email: false,
    sms: false,
    push: false,
  });
  const [fileData, setFileData] = React.useState<any>(false);
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const imageRef = useRef<any>(null);
  const [cropper, setCropper] = useState<Cropper>();
  const [overlay, setOverlay] = React.useState(false);
  const [notificationError, setNotificationError] = React.useState('');

  const navigate = useNavigate();
  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (event.target.value === 'email') {
      setNotification({ email: true, sms: false, push: false });
    }
    if (event.target.value === 'sms') {
      setNotification({ email: false, sms: true, push: false });
    }
    if (event.target.value === 'push') {
      setNotification({ email: false, sms: false, push: true });
    }
  };
  const onChange = (e: any) => {
    e.preventDefault();
    setShowModal(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    handleModalClose();
    setFileData(true);
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  function dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const handleSaveAsDraft = (e: any, data: any) => {
    e.preventDefault();
    if (data.title === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.description === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.android !== true && data.ios !== true && data.web !== true) {
      setNotificationError('Please select a platform');
    }
    else {
      setNotificationError('');
      const platform: any = [];
      if (data.web) {
        platform.push('3');
      }
      if (data.android) {
        platform.push('1');
      }
      if (data.ios) {
        platform.push('2');
      }
      setOverlay(true);
      if (fileData) {
        const form1 = new FormData();
        const blob_data = dataURItoBlob(cropData);
        form1.append('file', blob_data);
        if (blob_data.size > 1024 * 1024 * 2) {
          setNotificationError("Image size must be less than 2 mb");
          setOverlay(false);
        }
        else {
          Notification.uploadpicture(form1).then((res: any) => {
            Notification.addNotification({
              title: data.title,
              message: data.description,
              image: res.data.data.url,
              sendBy: 'push',
              status: 'draft',
              platforms: platform,
            }).then(() => {
              navigate('/notification');
              setOverlay(false);
            }).catch((err) => {
              setOverlay(false);
              setError(err.response.data.message);
            });
          });
        }
      } else {
        Notification.addNotification({
          title: data.title,
          message: data.description,
          image: '',
          sendBy: 'push',
          status: 'draft',
          platforms: platform,
        }).then(() => {
          navigate('/notification');
          setOverlay(false);
        }).catch((err) => {
          setOverlay(false);
          setError(err.response.data.message);
        });
      }
    }
  };
  const handleSubmit = (data: any) => {
    const platform: any = [];
    if (data.web) {
      platform.push('3');
    }
    if (data.android) {
      platform.push('1');
    }
    if (data.ios) {
      platform.push('2');
    }
    if (data.title === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.description === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.android !== true && data.ios !== true && data.web !== true) {
      setNotificationError('Please select a platform');
    } else {
      setOverlay(true);
      if (fileData) {
        const form1 = new FormData();
        const blob_data = dataURItoBlob(cropData);
        form1.append('file', blob_data);
        if (blob_data.size > 1024 * 1024 * 2) {
          setNotificationError("Image size must be less than 2 mb");
          setOverlay(false);
        }
        else {
          Notification.uploadpicture(form1).then((res: any) => {
            Notification.addNotification({
              title: data.title,
              message: data.description,
              image: res.data.data.url,
              sendBy: 'push',
              status: 'sent',
              platforms: platform,
            }).then(() => {
              setOverlay(false);
              navigate('/notification');
            }).catch((err) => {
              setOverlay(false);
              setError(err.response.data.message);
            });
          });
        }
      } else {
        Notification.addNotification({
          title: data.title,
          message: data.description,
          image: '',
          sendBy: 'push',
          status: 'sent',
          platforms: platform,
        }).then(() => {
          setOverlay(false);
          navigate('/notification');
        }).catch((err) => {
          setOverlay(false);
          setError(err.response.data.message);
        });
      }
    }
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        showOnClose={false}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <Cropper
          style={{ height: 400, width: "100%" }}
          aspectRatio={1}
          preview=".img-preview"
          src={image}
          ref={imageRef}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={false}
          checkOrientation={false}
          onInitialized={(instance: any) => {
            setCropper(instance);
          }}
        />
        <div className="buttonWrapper">
          <Button
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            onClick={getCropData}
          >
            Save
          </Button>
        </div>
      </Modal>
      {error.length > 0 && (
        <ToastBar
          message={error}
          handleCloseMethod={() => setError('')}
        />
      )}
      {overlay && <Overlay />}
      <div className="cardBg">
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <h2 className="heading">Add Details</h2>
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            style={{ flexDirection: 'row' }}
          >
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="sms" control={<Radio />} label="SMS" />
            <FormControlLabel value="push" control={<Radio />} label="Push" />
          </RadioGroup>
        </FormControl>
        {notification.email && (
          <AddNotificationEmailForm
            loader={false}
            error=""
            setError={setError}
            setOverlay={setOverlay}
          />
        )}
        {notification.sms && (
          <AddNotificationSMSForm
            loader={false}
            error=""
            setError={setError}
            setOverlay={setOverlay}
          />
        )}
        {notification.push && (
          <>
            <h2 className="sign_text">Upload Image</h2>
            <div className="file">
              <div className="fileiconU">
                <input
                  type="file"
                  onChange={onChange}
                  title=""
                />
                <img src={edit} alt="userprofile" />
              </div>
              <span className="fileimage">
                {fileData && <img src={cropData} alt="upload" />}
              </span>
            </div>
            <p className="informationUpload">
              <img src={information} alt="information" />
              {intl.formatMessage({ id: 'AddNotificationPush.label.info' })}
            </p>
            <AddNotificationPushForm
              loader={false}
              error={notificationError}
              setOverlay={setOverlay}
              handleSaveAsDraft={handleSaveAsDraft}
              handleSubmit={handleSubmit} />
          </>
        )}
      </div>
    </>
  );
};

export default injectIntl(AddNotification);
