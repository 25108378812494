import React from 'react';
import { connect } from 'react-redux';
import { getParticipantDetails } from '../../actions';
import classNames from 'classnames';
import DataTable from '../Datatable';
import avatus from '../../assets/svg/image-placeholder.png';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  id: any;
  participantdetails: any;
  fetchParticipantList: any;
}

const ParticipantDetails: React.FC<Props> = (props: Props) => {
  const {
    participantdetails,
    fetchParticipantList,
    id,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    id,
    isSortByUsername: 0,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  React.useEffect(() => {
    fetchParticipantList(filter);
  }, [fetchParticipantList, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = participantdetails.data ? participantdetails.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      username: data.username,
      avatar: <img src={avatus} alt="avatus" />,
      bio: data.bio.length > 20 ? (
        <div title={data.bio}>
          {data.bio.substring(0, 20)}
          ...
        </div>
      ) : data.bio,
      virtualgifts: '',
      amount: '',
    };
  }

  const rows: any = [];
  if (participantdetails.data !== null && participantdetails.data) {
    participantdetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCellsPostsCreated: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'avatar', label: 'Avatar', sort: false,
    },
    {
      id: 'bio', label: 'Bio', sort: false,
    },
    {
      id: 'virtualgifts', label: 'Virtual Gifts', sort: false,
    },
    {
      id: 'amounts', label: 'Amount of virtual gifts', sort: false,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'username') {
      setFilter({
        ...filter,
        page: 0,
        isSortByUsername: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headCellsPostsCreated}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={participantdetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { participantdetails } = state;
  return {
    participantdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchParticipantList: (payload: any) => dispatch(getParticipantDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantDetails);
