import api from '../api';

export class NotificationDetail {
  static async getNotificationDetailsService(payload: any) {
    const {
      id,
      page,
      rowsPerPage,
    } = payload;
    const apiEndPoint = `type=${'admin-reported-user-notification'}&limit=${rowsPerPage}&pageNo=${page}&userId=${id}`;
    return api.getApiCall(`/api/v1/admins/notifications?${apiEndPoint}`);
  }

  static async getNotificationTemplate() {
    return api.getApiCall('/api/v1/admins/notifications/template');
  }
}
