import React, { useEffect } from 'react';
import { ContentManagementService } from '../../services';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { AddFAQ, CircularProgressLoader, EditFAQ } from '../../components';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface ParamTypes {
  id: any;
}

const ManageFAQsPageComponent: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [QAs, setQAs] = React.useState<any>({
    question: '',
    answer: '',
  });
  const [loading, setLoading] = React.useState(id !== undefined);
  useEffect(() => {
    if (id !== undefined) {
      ContentManagementService.detailsFAQ(id).then((res: any) => {
        setQAs(res.data.data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? <CircularProgressLoader /> : (
        <div className="cardBg">
          {id === undefined
            ? <AddFAQ />
            : (
              <EditFAQ
                categoryId={QAs.categoryId}
                question={QAs.question}
                answer={QAs.answer}
                id={id}
              />
            )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = () => ({
});
const mapDispatchToProps = () => ({
});

const ManageFAQS = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageFAQsPageComponent);

export default ManageFAQS;
