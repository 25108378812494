import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../utils/reactIntl';
import reminderemails from '../../assets/svg/reminderemails.svg';
import contentmanagement from '../../assets/svg/contentmanagement.svg';
import notificationmanagement from '../../assets/svg/notificationmanagement.svg';
import support from '../../assets/svg/support.svg';
import feedback from '../../assets/svg/feedback.svg';
import avatus from '../../assets/svg/avatus_logoo.svg';
import rolemanagement from '../../assets/svg/rolemanagement.svg';
import subadmin from '../../assets/svg/subadminmanagement.svg';
import { withViewport } from '../../utils/contextHelpers.jsx';
import dashboard from '../../assets/svg/dashboard.svg';
import usermanagement from '../../assets/svg/usermanagement.svg';
import channelmanagement from '../../assets/svg/channelmanagement.svg';
import subscription from '../../assets/svg/subscription.svg';
import subscribedusers from '../../assets/svg/subscribedusers.svg';
import service from '../../assets/svg/service.svg';
import purchase from '../../assets/svg/purchase.svg';
import campaign from '../../assets/svg/campaign.svg';
import promotionsandadvertising from '../../assets/svg/promotionsandadvertising.svg';
import sellerbrandmanagement from '../../assets/svg/sellerbrandmanagement.svg';
import livestreaming from '../../assets/svg/livestreaming.svg';
import payementmanagement from '../../assets/svg/payementmanagement.svg';
import coinactivitymanagement from '../../assets/svg/coinactivitymanagement.svg';
import rewardmanagement from '../../assets/svg/rewardmanagement.svg';
import categories from '../../assets/svg/categories.svg';
import subcategories from '../../assets/svg/subcategories.svg';
import issuesreported from '../../assets/svg/issuesreported.svg';
import versionmanagement from '../../assets/svg/versionmanagement.svg';
import reportedusermanagement from '../../assets/svg/reportedusericon.svg';
import masterreason from '../../assets/svg/masterreason.svg';
import {
  hasAccess, PERMISSION_KEYS, PERMISSION_TYPES, ToastBar,
} from '../../components';
import './sidebar.module.scss';

interface Props {
  icon: any;
  content: string;
  route: string;
  setNotification: any
}

const SidebarElements = (props: Props) => {
  const {
    icon, content, route, setNotification,
  } = props;
  return (
    <div className="sidebarelement">
      <img src={icon} alt="img" />
      {route === '/usermanagement'
        || route === '/channelmanagement'
        || route === '/domesmanagement'
        || route === '/subdomesmanagement'
        || route === '/contentmanagement'
        || route === '/rolemanagement'
        || route === '/subadminmanagement'
        || route === '/rewardpoints'
        || route === '/reportusermanagement'
        || route === '/notification'
        || route === '/masterreason'
        || route === '/livestreaming'
        || route === '/feedback'
        || route === '/versionmanagement'
        || route === '/support'
        || route === '/paymentmanagement'
        || route === '/coinactivity'
        || route === '/ecommerce'
        || route === '/sessionmanagement'
        || route === '/subscriptionmanagement'
        || route === '/'
        || route === '/subscribedusers'
        || route === '/commissionmanagement'
        ? (
          <Link to={route} className="p_styles">
            {content}
          </Link>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to="#"
            onClick={() => setNotification({
              show: true,
              message: 'Under Development',
            })}
          >
            {content}
          </Link>

        )}
    </div>
  );
};

const Sidebar: React.FC = (props) => {
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const sidebarContent = [
    {
      icon: dashboard,
      content: 'Dashboard',
      route: '/',
      access: true,
    },
    {
      icon: usermanagement,
      content: 'User Management',
      route: '/usermanagement',
      access: hasAccess(
        PERMISSION_KEYS.USER_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: channelmanagement,
      content: 'Channel Management',
      route: '/channelmanagement',
      access: hasAccess(
        PERMISSION_KEYS.CHANNEL_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: subscription,
      content: 'Subscription Plan Management',
      route: '/subscriptionmanagement',
      access: hasAccess(
        PERMISSION_KEYS.SUBSCRIPTION_PLAN_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: subscribedusers,
      content: 'Subscribed Users',
      route: '/subscribedusers',
      access: hasAccess(
        PERMISSION_KEYS.SUBSCRIBED_USERS,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: reportedusermanagement,
      content: 'Commission Management',
      route: '/commissionmanagement',
      access: hasAccess(
        PERMISSION_KEYS.COMMISION_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: service,
      content: 'Service Provider Management',
      route: '/service',
      access: hasAccess(
        PERMISSION_KEYS.SERVICE_PROVIDER_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: purchase,
      content: 'Purchase & Ecommerce',
      route: '/ecommerce',
      access: hasAccess(
        PERMISSION_KEYS.PURCHASE_E_COMMERCE,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: campaign,
      content: 'Campaign Management',
      route: '/campaignmanagement',
      access: hasAccess(
        PERMISSION_KEYS.CAMPAIGN_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: promotionsandadvertising,
      content: 'Promotion/Advertising',
      route: '/promotionadvertising',
      access: hasAccess(
        PERMISSION_KEYS.PROMOTIONS_AND_ADVERTISING,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: sellerbrandmanagement,
      content: 'Seller/Brand Management',
      route: '/sellerbrandmanagement',
      access: hasAccess(
        PERMISSION_KEYS.SELLER_BRAND_MANGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: livestreaming,
      content: 'Event Management',
      route: '/livestreaming',
      access: hasAccess(
        PERMISSION_KEYS.LIVE_STREAMING,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: reportedusermanagement,
      content: 'Report User Management',
      route: '/reportusermanagement',
      access: true,
    },
    {
      icon: rolemanagement,
      content: 'Role Management',
      route: '/rolemanagement',
      access: hasAccess(
        PERMISSION_KEYS.ROLE_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: subadmin,
      content: 'Sub Admin Management',
      route: '/subadminmanagement',
      access: hasAccess(
        PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    // {
    //   icon: postmanagement,
    //   content: 'Post Management',
    //   route: '/postmanagement',
    //   access: hasAccess(
    //     PERMISSION_KEYS.POS,
    //     PERMISSION_TYPES.VIEW,
    //   ),
    // },
    // {
    //   icon: eventmanagement,
    //   content: 'Event Management',
    //   route: '/eventmanagement',
    //   access: hasAccess(
    //     PERMISSION_KEYS.EVENT_MANAGEMENT,
    //     PERMISSION_TYPES.VIEW,
    //   ),
    // },
    {
      icon: payementmanagement,
      content: 'Payment Management',
      route: '/paymentmanagement',
      access: hasAccess(
        PERMISSION_KEYS.PAYMENT_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: coinactivitymanagement,
      content: 'Avatcoin Activity Management',
      route: '/coinactivity',
      access: hasAccess(
        PERMISSION_KEYS.COIN_ACTIVITY_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: masterreason,
      content: 'Master Reason Management',
      route: '/masterreason',
      access: true,
    },
    {
      icon: rewardmanagement,
      content: 'Reward Points',
      route: '/rewardpoints',
      access: hasAccess(
        PERMISSION_KEYS.REWARD_POINTS,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: categories,
      content: 'Domes Management',
      route: '/domesmanagement',
      access: hasAccess(
        PERMISSION_KEYS.CATERGORIES,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: subcategories,
      content: 'Sub Domes Management',
      route: '/subdomesmanagement',
      access: hasAccess(
        PERMISSION_KEYS.SUB_CATEGORIES,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: issuesreported,
      content: 'Issues Reported',
      route: '/issuesreported',
      access: hasAccess(
        PERMISSION_KEYS.ISSUES_REPORTED,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: versionmanagement,
      content: 'Version Management',
      route: '/versionmanagement',
      access: hasAccess(
        PERMISSION_KEYS.VERSION_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: reminderemails,
      content: 'Reminder Emails',
      route: '/remideremails',
      access: hasAccess(
        PERMISSION_KEYS.REMINDER_EMAILS,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: contentmanagement,
      content: 'Content Management',
      route: '/contentmanagement',
      access: true,
    },
    {
      icon: notificationmanagement,
      content: 'Notification Management',
      route: '/notification',
      access: hasAccess(
        PERMISSION_KEYS.NOTIFICATION_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: support,
      content: 'Support',
      route: '/support',
      access: hasAccess(
        PERMISSION_KEYS.SUPPORT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: feedback,
      content: 'Feedback',
      route: '/feedback',
      access: hasAccess(
        PERMISSION_KEYS.FEEDBACK,
        PERMISSION_TYPES.VIEW,
      ),
    },
    {
      icon: feedback,
      content: 'Session',
      route: '/sessionmanagement',
      access: hasAccess(
        PERMISSION_KEYS.FEEDBACK,
        PERMISSION_TYPES.VIEW,
      ),
    },
  ];
  const location = useLocation();

  return (
    <>
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={() => setNotification({
              ...notification,
              show: false,
              message: '',
            })}
          />
        )
      }
      <div className="sideBar">
        <div>
          <SidebarElements
            key="avatusicon"
            icon={avatus}
            content=""
            route=""
            setNotification={setNotification}
          />
        </div>
        <ul>
          {sidebarContent.map((ele: any, index: any) => (
            <>
              {ele.access && (
                <li
                  key={index}
                  className={ele.route !== '/'
                    ? (
                      location.pathname.includes(ele.route) ? 'active-bar' : '')
                    : location.pathname === '/' ? 'active-bar' : ''}
                >
                  <SidebarElements
                    key={index}
                    icon={ele.icon}
                    content={ele.content}
                    route={ele.route}
                    setNotification={setNotification}
                  />
                </li>
              )}
            </>

          ))}
        </ul>
      </div>
    </>
  );
};
const mapStateToProps = () => ({
});

const SidebarComponent = compose(
  withViewport,
  injectIntl,
  connect(mapStateToProps, null)
)(Sidebar);

export default SidebarComponent;
