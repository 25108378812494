import React from 'react';
import classNames from 'classnames';
import {
  Checkbox, FormControlLabel,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import './FieldCheckbox.module.scss';

interface Props {
  rootClassName?: string,
  className?: string,
  inputRootClass?: string,
  children?: any,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  name: string,
  valid?: boolean,
  formProps: any,
  disableRipple?: boolean,
  label?: string,
  defaultValue?: boolean,
}

const FieldCheckbox = (props: Props) => {
  /* eslint-disable no-unused-vars */
  const {
    id,
    name,
    formProps: { control },
    rootClassName = null,
    className = null,
    inputRootClass = null,
    children = null,
    valid = null,
    disableRipple = false,
    label = '',
    defaultValue = false,

  } = props;

  const inputClasses = classNames(inputRootClass, 'checkbox', {
    ['inputSuccess']: valid,
  });

  const classes = classNames(rootClassName || 'fieldcheckbox_root', className);
  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
          field,
        }) => (
          <FormControlLabel
            control={(
              <Checkbox
                checked={field.value ?? false}
                {...field}
                id={id}
                className={inputClasses}
                disableRipple={disableRipple}
              />
            )}
            label={label}
          />
        )}
      />
      {children}
    </div>
  );
};


export default FieldCheckbox;
