import { ActionTypes } from '../constants/index';

export const fetchSubscribedUserList = (payload: any) => ({
  type: ActionTypes.SUBSCRIBED_USER_MGMT_REQUEST,
  payload: {
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
    startDate: payload.startDate,
    endDate: payload.endDate,
    planStatus: payload.planStatus,
    search: payload.search,
    planType: payload.planType,
    isSortByUserName: payload.isSortByUserName,
    isSortByPlanActivationDate: payload.isSortByPlanActivationDate,
    isSortByPlanUpgradationDate:payload.isSortByPlanUpgradationDate
  },
});
