import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { injectIntl } from "react-intl";
import {
  FieldTextInput,
  Button,
  FieldCheckbox,
  ValidationError,
} from
  "../../components";
import "./loginform.module.scss";
import "../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss";

interface Props {
  intl: any;
  handleFormSubmit: any;
  loginError: any;
  changeSuccessPass: any;
}

enum ButtonTypes {
  "button",
  "submit",
  "reset",
  undefined,
}

const LoginForm: React.FC<Props> = (props: Props) => {
  const { handleFormSubmit, loginError, changeSuccessPass, intl } = props;
  const [passwordType, setPasswordType] = useState("password");
  const loginSchema = yup.object().shape(
    {
      email: yup
        .string()
        .required(intl.formatMessage({ id: "LoginForm.error.email.required" }))
        .matches(
          /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/,
          intl.formatMessage({ id: "LoginForm.error.email.match" })
        ),
      password: yup
        .string()
        .required(
          intl.formatMessage({ id: "LoginForm.error.password.required" })
        ),
    },
    []
  );
  const formProps = useForm({
    defaultValues: {
      email:
        Cookies.get("email_authInfo") !== undefined
          ? Cookies.get("email_authInfo")
          : "",
      password:
        Cookies.get("password_authInfo") !== undefined
          ? Cookies.get("password_authInfo")
          : "",
    },
    resolver: yupResolver(loginSchema),
  });
  const navigate = useNavigate();
  const passwordTypeHandler = () => {
    setPasswordType(passwordType === "text" ? "password" : "text");
  };
  // console.log(commonStyles);
  return (
    <form
      className="form"
      onSubmit={formProps.handleSubmit(handleFormSubmit)}
    >
      <h2 className="sign_text">
        {intl.formatMessage({ id: "LoginForm.page.title" })}
      </h2>
      <p className="details_text">
        {intl.formatMessage({ id: "LoginForm.label.details" })}
      </p>
      <FieldTextInput
        id="email"
        name="email"
        formName="login"
        className="textInput"
        type="text"
        formProps={formProps}
        label={intl.formatMessage({ id: "LoginForm.label.email" })}
      />
      <FieldTextInput
        id="password"
        name="password"
        formName="signup"
        className="textInput"
        type={passwordType}
        passwordTypeHandler={passwordTypeHandler}
        formProps={formProps}
        showVisibilityIcon
        rootClassName="passwordInput"
        label={intl.formatMessage({ id: "LoginForm.label.password" })}
      />
      <div className="rememberMeWrapper">
        <div className="remember">
          <FieldCheckbox
            id="remember"
            name="remember"
            formProps={formProps}
            disableRipple
          />
          Remember me
        </div>

        <small
          role="presentation"
          className="forgotpassword_text"
          onClick={() => {
            changeSuccessPass();
            navigate("/forgotpassword");
          }}
        >
          Forgot Password?
        </small>
      </div>
      {Object.keys(formProps.formState.errors).length > 0 && (
        <ValidationError
          className="validation_error"
          error={formProps.formState.errors[
            Object.keys(formProps.formState.errors)[0]
          ]?.message?.toString()}
        />
      )}
      {Object.keys(formProps.formState.errors).length === 0 && loginError && (
        <ValidationError className="validation_error" error={loginError} />
      )}
      <Button buttonType={ButtonTypes.submit}>Login</Button>
    </form>
  );
};

export default injectIntl(LoginForm);
