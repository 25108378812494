import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  ManageFAQs,
  LayoutWrapperMain,
  Page,
} from '../../components';
import config from '../../config';
import './managefaqs.module.scss';

interface Props {
  scrollingDisabled: boolean,
  intl: any,
}

const ManageFAQsPageComponent:
  React.FC<Props> = (props: Props) => {
    const {
      intl,
      scrollingDisabled,
    } = props;
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage(
      { id: 'ContentManagement.page.title' },
      { siteTitle },
    );
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutWrapperMain className="layoutWrapperMain">
          <div className="content">
            <ManageFAQs />
          </div>
        </LayoutWrapperMain>
      </Page>
    );
  };

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ManageFAQS = compose(

  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageFAQsPageComponent);

export default ManageFAQS;
