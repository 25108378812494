import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import { getNotificationById } from '../../actions';
import { Notification } from '../../services';
import Cropper from "react-cropper";
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import edit from '../../assets/svg/edit.svg';
import information from '../../assets/svg/info.svg';
import { injectIntl } from 'react-intl';
import React, { useEffect } from 'react';
import { AddNotificationEmailForm, AddNotificationSMSForm, AddNotificationPushForm } from '../../forms';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import { Button, Modal, Overlay, ToastBar } from '../../components';
import { connect } from 'react-redux';
import './EditNotification.module.scss';

interface Props {
  id: string;
  fetchNotificationById: any;
  notificationedit: any;
  intl: any;
}

const EditNotification = (props: Props) => {
  const navigate = useNavigate();
  const { id, fetchNotificationById, notificationedit, intl } = props;
  const [cropData, setCropData] = React.useState("#");
  const [cropper, setCropper] = React.useState<Cropper>();
  const [image, setImage] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const imageRef = React.useRef<any>(null);
  const [error, setError] = React.useState('');
  const [overlay, setOverlay] = React.useState(false);
  useEffect(() => {
    fetchNotificationById(id);
  }, [fetchNotificationById, id]);
  const [notificationError, setNotificationError] = React.useState('');
  const [fileData, setFileData] = React.useState<any>(false);

  function dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const handleSaveAsDraft = (e: any, data: any) => {
    e.preventDefault();
    if (data.title === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.description === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.android !== true && data.ios !== true && data.web !== true) {
      setNotificationError('Please select a platform');
    } else {
      setNotificationError('');
      const platform: any = [];
      if (data.web) {
        platform.push('3');
      }
      if (data.android) {
        platform.push('1');
      }
      if (data.ios) {
        platform.push('2');
      }
      setOverlay(true);
      if (fileData) {
        const form1 = new FormData();
        const blob_data = dataURItoBlob(cropData);
        form1.append('file', blob_data);
        if (blob_data.size > 1024 * 1024 * 2) {
          setNotificationError("Image size must be less than 2 mb");
          setOverlay(false);
        }
        else {
          Notification.uploadpicture(form1).then((res: any) => {
            Notification.addNotification({
              title: data.title,
              message: data.description,
              image: res.data.data.url,
              sendBy: 'push',
              status: 'sent',
              platforms: platform,
            }).then(() => {
              setOverlay(false);
              navigate('/notification');
            }).catch((err) => {
              setOverlay(false);
              setError(err.response.data.message);
            });
          });
        }
      } else {
        Notification.addNotification({
          title: data.title,
          message: data.description,
          image: notificationedit.data.image ? notificationedit.data.image : '',
          sendBy: 'push',
          status: 'draft',
          platforms: platform,
        }).then(() => {
          navigate('/notification');
          setOverlay(false);
        }).catch((err) => {
          setOverlay(false);
          setError(err.response.data.message);
        });
      }
    }
  };
  const handleSubmit = (data: any) => {
    const platform: any = [];
    if (data.web) {
      platform.push('3');
    }
    if (data.android) {
      platform.push('1');
    }
    if (data.ios) {
      platform.push('2');
    }
    if (data.title === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.description === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.android !== true && data.ios !== true && data.web !== true) {
      setNotificationError('Please select a platform');
    } else {
      setOverlay(true);
      if (fileData) {
        const form1 = new FormData();
        const blob_data = dataURItoBlob(cropData);
        form1.append('file', blob_data);
        if (blob_data.size > 1024 * 1024 * 2) {
          setNotificationError("Image size must be less than 2 mb");
          setOverlay(false);
        }
        else {
          Notification.uploadpicture(form1).then((res: any) => {
            Notification.addNotification({
              title: data.title,
              message: data.description,
              image: res.data.data.url,
              sendBy: 'push',
              status: 'sent',
              platforms: platform,
            }).then(() => {
              setOverlay(false);
              navigate('/notification');
            }).catch((err) => {
              setOverlay(false);
              setError(err.response.data.message);
            });
          });
        }
      } else {
        Notification.addNotification({
          title: data.title,
          message: data.description,
          image: notificationedit.data.image ? notificationedit.data.image : '',
          sendBy: 'push',
          status: 'sent',
          platforms: platform,
        }).then(() => {
          setOverlay(false);
          navigate('/notification');
        }).catch((err) => {
          setOverlay(false);
          setError(err.response.data.message);
        });
      }
    }
  };
  const onChange = (e: any) => {
    e.preventDefault();
    setShowModal(true);
    setFileData(false);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const getCropData = () => {
    handleModalClose();
    setFileData(true);
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };


  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        showOnClose={false}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <Cropper
          style={{ height: 400, width: "100%" }}
          aspectRatio={1}
          preview=".img-preview"
          src={image}
          ref={imageRef}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={false}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance: any) => {
            setCropper(instance);
          }}
        />
        <div className="buttonWrapper">
          <Button
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            onClick={getCropData}
          >
            Save
          </Button>
        </div>
      </Modal>
      {error.length > 0 && (
        <ToastBar
          message={error}
          handleCloseMethod={() => setError('')}
        />
      )}
      {(notificationedit.loadingInProgress || overlay) ? <Overlay /> : (
        <div className="cardBg">
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <h2 className="heading_s">Edit Details</h2>
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={notificationedit.data.sendBy}
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel
                disabled={notificationedit.data.sendBy !== 'email'}
                value="email"
                control={<Radio />}
                label="Email"
              />
              <FormControlLabel
                disabled={notificationedit.data.sendBy !== 'sms'}
                value="sms"
                control={<Radio />}
                label="SMS"
              />
              <FormControlLabel
                disabled={notificationedit.data.sendBy !== 'push'}
                value="push"
                control={<Radio />}
                label="Push"
              />
            </RadioGroup>
          </FormControl>
          {notificationedit.data.sendBy === 'email' && (
            <AddNotificationEmailForm
              loader={false}
              error=""
              dataForm={{ title: notificationedit.data.subject, message: notificationedit.data.message }}
              setError={setError}
              setOverlay={setOverlay}
            />
          )}
          {notificationedit.data.sendBy === 'sms' && (
            <AddNotificationSMSForm
              loader={false}
              error=""
              dataForm={{ description: notificationedit.data.message, device: notificationedit.data.platforms }}
              setError={setError}
              setOverlay={setOverlay}
            />
          )}
          {notificationedit.data.sendBy === 'push' && (
            <>
              <h2 className="sign_text">Upload Image</h2>
              <div className="file_s">
                <div className="fileiconU">
                  <input
                    type="file"
                    onChange={onChange}
                  />
                  <img src={edit} alt="userprofile" />
                </div>
                <span className="fileimage">
                  {!fileData ?
                    (notificationedit.data.image.length > 0 ?
                      <img src={notificationedit.data.image} alt="upload" /> :
                      null)
                    : <img src={cropData} alt="upload" />}
                </span>
              </div>
              <p className="informationUpload">
                <img src={information} alt="information" />
                {intl.formatMessage({ id: 'AddNotificationPush.label.info' })}
              </p>
              <AddNotificationPushForm
                loader={false}
                error={notificationError}
                dataForm={{
                  title: notificationedit.data.title,
                  description: notificationedit.data.message,
                  platforms: notificationedit.data.platforms,
                }}
                setOverlay={setOverlay}
                handleSaveAsDraft={handleSaveAsDraft}
                handleSubmit={handleSubmit} />
            </>
          )}
        </div>
      )}

    </>
  );
};

const mapStateToProps = (state: any) => ({ notificationedit: state.notificationedit });

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotificationById: (payload: any) => dispatch(getNotificationById(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditNotification));
