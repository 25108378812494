import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import routeConfiguration from '../../routes/routeConfiguration.js';
import { pathByRouteName, findRouteByRouteName } from '../../utils/routes';

interface Props {
  name: string,
  params?: any,
  title?: string,
  children: any,
  to?: any,
  className?: string,
  activeClassName?: any,
  style?: any,
}

export const NamedLinkComponent: React.FC<Props> = (props: Props) => {
  const {
    name,
    to = {},
    className = '',
    style = {},
    activeClassName = 'NamedLink_active',
    title = ''
  } = props;
  const navigate = useNavigate();
  const routerParams = useParams()
  const params = {
    ...routerParams,
    slug: routerParams.slug || '',
    url: routerParams.url || ''
  };
  const routes = routeConfiguration();
  const onOver = () => {
    const { component: Page } = findRouteByRouteName(name, routes);
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  // Link props
  const { children } = props;
  const pathname = pathByRouteName(name, routes, params);
  const active = params.url && params.url === pathname;

  // <a> element props
  const aElemProps = {
    className: classNames(className, { [activeClassName]: active }),
    style,
    title,
  };

  return (
    <Link onMouseOver={onOver} onTouchStart={onOver} to={{ pathname, ...to }} {...aElemProps}>
      {children}
    </Link>
  );
};


// This ensures a nice display name in snapshots etc.
NamedLinkComponent.displayName = 'NamedLink';

const NamedLink = NamedLinkComponent;
NamedLink.displayName = 'NamedLink';

export default NamedLink;
