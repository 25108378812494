import api from '../api';

export class SessionService {
  static async getService(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      startDate,
      endDate,
      search,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}`;
    return api.getApiCall(`/api/v1/admins/services-product?${apiEndPoint}`);
  }

  static async sendEmail(payload: any) {
    const {
      sessionId,
      subject,
      message,
      userId,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-session-notification', {
      sessionId,
      subject,
      message,
      userId,
    });
    return data;
  }

  static async sendPushNotification(payload: any) {
    const {
      sessionId,
      title,
      message,
      userId,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-session-notification', {
      sessionId,
      title,
      message,
      userId,
    });
    return data;
  }

  static async sendSMS(payload: any) {
    const {
      sessionId,
      message,
      userId,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-session-notification', {
      sessionId,
      message,
      userId,
    });
    return data;
  }

  static async export(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      isSortByCreated,
      isSortByCategoryName,
      type,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${isSortByCategoryName}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/services-product/export?${apiEndPoint}`);
  }

  static async getDomes() {
    return api.getApiCall('/api/v1/admins/categories/names');
  }

  static block(id: any, status: any) {
    return api.putApiCall('/api/v1/admins/services-product', {
      sessionId: id,
      status,
    });
  }

  static async deleteApi(payload: any) {
    const data = await api.deleteApiCall('/api/v1/admins/services-product', { sessionId: payload });
    return data;
  }
}
