import React from "react";
import classNames from "classnames";
import { TextField, makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import Country from "../../utils/country.json";
import { FieldSelect } from "../../components";
import "./FieldTextInput.module.scss";
import eyeOff from "../../assets/svg/ic_password_hide.svg";
import eyeOn from "../../assets/svg/ic_password_show.svg";
import unselectedBorder from "../../assets/svg/input-border-unselected.svg";
import selectedBorder from "../../assets/svg/input-border-selected.svg";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f00",
    },
    "& .MuiFormLabel-root": {
      color: "#8A8A8A",
      letterSpacing: "0.12px",
      "&.MuiInputLabel-shrink": {
        margin: "0",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderImageSlice: "1",
      borderWidth: "1px",
      borderImage: `url(${selectedBorder})`,
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
      borderImage:
        "linear-gradient( to right, rgb(255 0 0 / 65%) 100%, rgb(203 32 32) 50%, rgb(255 0 0 / 52%) -1% )",
      borderWidth: "1px",
      borderImageSlice: "1",
    },
  },
  notchedOutline: {
    borderImageSlice: "1",
    borderWidth: "1px",
    borderImage: `url(${unselectedBorder})`,
  },
  focused: {
    // borderColor: '#ffffff',
  },
}));

interface Props {
  rootClassName?: string;
  className?: string;
  inputRootClass?: string;
  formName: string;
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  type: string;
  valid?: boolean;
  showError?: boolean;
  passwordTypeHandler?: any;
  passwordTipsHandler?: any;
  defaultValue?: string;
  defaultValueCc?: string;
  setTextareaLength?: any;
  // eslint-disable-next-line react/require-default-props
  formProps?: any;
  fullWidth?: boolean;
  handleMainInputChange?: (data: any) => null;
  showVisibilityIcon?: boolean;
  disabled?: boolean;
  pattern?: string;
}

const FieldTextInput = (props: Props) => {
  const muiClasses = useStyles();
  /* eslint-disable no-unused-vars */
  const {
    id,
    name,
    type,
    formProps: {
      formState: { errors },
      control,
    },
    pattern,
    rootClassName = null,
    className = null,
    inputRootClass = null,
    label = null,
    placeholder = null,
    defaultValue = "",
    defaultValueCc = "",
    handleMainInputChange = () => null,
    passwordTypeHandler = () => null,
    passwordTipsHandler = null,
    setTextareaLength = null,
    showVisibilityIcon = null,
    valid = null,
    showError = true,
    disabled = false,
    fullWidth = false,
  } = props;
  const countryCodeOptions: { key: string; label: string }[] = [];
  Country.map((countryObj: any) =>
    countryCodeOptions.push({
      key: countryObj.dial_code,
      label: countryObj.dial_code,
    }),
  );
  const isTextarea = type === "textarea";

  const inputClasses = classNames(inputRootClass, muiClasses.root, "input", {
    ["inputSuccess"]: valid,
  });
  const textareaProps = isTextarea ? { multiline: true, rows: 4 } : {};
  const classes = classNames(
    rootClassName || "text_root",
    className ? [className] : null,
  );
  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <>
            {id === "phoneNo" ? (
              <div className="text_field">
                <FieldSelect
                  id="countryCode"
                  name="countryCode"
                  className="countryCode"
                  label="*Country Code"
                  variant={false}
                  showError={!!errors[id]}
                  search
                  defaultValue={
                    defaultValueCc
                      ? !defaultValueCc.includes("+")
                        ? `+${defaultValueCc}`
                        : defaultValueCc
                      : ""
                  }
                  formProps={{ formState: { errors }, control }}
                  options={countryCodeOptions}
                />
              </div>
            ) : null}
            <div className="text_field">
              <TextField
                onChange={(e) => {
                  onChange(e);
                  if (setTextareaLength) {
                    setTextareaLength(e?.target?.value?.length);
                  }
                  handleMainInputChange?.(e);
                  passwordTipsHandler?.(e?.target?.value);
                }}
                onBlur={onBlur}
                value={value}
                ref={ref}
                className={inputClasses}
                InputProps={{
                  className: classNames("inputMainClass", {
                    ["phoneNumberPadding"]: id === "phoneNo",
                  }),
                  classes: {
                    notchedOutline: muiClasses.notchedOutline,
                    focused: muiClasses.focused,
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: isTextarea ? 2000 : 100,
                  step: "any",
                  ...(pattern && { pattern }),
                }}
                autoComplete="off"
                id={id}
                label={label}
                name={name}
                fullWidth={fullWidth}
                placeholder={placeholder}
                type={type}
                variant="outlined"
                error={!!errors[id]}
                {...textareaProps}
                disabled={disabled}
              />

              {showVisibilityIcon && passwordTypeHandler ? (
                type === "password" ? (
                  <button
                    type="button"
                    onClick={() => passwordTypeHandler(id)}
                    className="detect_icon"
                  >
                    <img src={eyeOff} alt="hide" />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => passwordTypeHandler(id)}
                    className="detect_icon"
                  >
                    <img src={eyeOn} alt="show" />
                  </button>
                )
              ) : null}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default FieldTextInput;

