import React, { useState } from "react";
import { Button } from "../../components";
import "../Modal/Modal.module.scss";

interface Props {
  handleModalClose: () => void;
  updatedPercentage: string;
  commissionTypeToUpdate: string;
  handleModalCloseonNo: () => void;
}

const CommissionRulesUpdate: React.FC<Props> = (props: Props) => {
  const { handleModalClose, updatedPercentage, commissionTypeToUpdate, handleModalCloseonNo } = props;
  const [updateConfirmed, setUpdateConfirmed] = useState(false);

  const handleUpdateConfirmation = () => {
    setUpdateConfirmed(true);
  };

  const handleOkButtonClick = () => {
    setUpdateConfirmed(false); // Reset confirmation state
    handleModalClose(); // Close modal
  };

  return (
    <>
      {!updateConfirmed ? (
        <>
          <p>Are you sure you want to Update {commissionTypeToUpdate}?</p>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalCloseonNo}>No</Button>
            <Button onClick={handleUpdateConfirmation}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <p>Updated Successfully!</p>

          <Button onClick={handleOkButtonClick}>OK</Button>

        </>
      )}
    </>
  );
};

export default CommissionRulesUpdate;

