import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import routeConfiguration from '../../routes/routeConfiguration.js';
import { findRouteByRouteName } from '../../utils/routes';
import { IconSpinner, IconCheckmark } from '..';
import './Button.module.scss';

interface Props {
  className?: string,
  rootClassName?: string,
  spinnerClassName?: string,
  checkmarkClassName?: string,
  onClick?: any,
  inProgress?: [string, boolean],
  buttonType?: any,
  ready?: [string, boolean],
  title?: string,
  disabled?: boolean,
  enforcePagePreloadFor?: [string, boolean],
  children: ReactNode
}

interface State {
  mounted: boolean
}

class Button extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  render() {
    const {
      children,
      className,
      rootClassName,
      spinnerClassName,
      checkmarkClassName,
      inProgress,
      ready,
      disabled,
      buttonType,
      enforcePagePreloadFor,
      ...rest
    } = this.props;

    const rootClass = rootClassName || 'btn_root';
    const { mounted } = this.state;
    const classes = classNames(rootClass, className, {
      ready,
      inProgress,
    });

    let content;

    if (inProgress) {
      content = <IconSpinner rootClassName={spinnerClassName || 'spinner'} />;
    } else if (ready) {
      content = <IconCheckmark rootClassName={checkmarkClassName || 'checkmark'} />;
    } else {
      content = children;
    }

    const onOverButtonFn = (enforcePreloadOfPage: any) => () => {
      // Enforce preloading of given page (loadable component)
      const { component: Page } = findRouteByRouteName(enforcePreloadOfPage, routeConfiguration());
      // Loadable Component has a "preload" function.
      if (Page.preload) {
        Page.preload();
      }
    };

    const onOverButton = enforcePagePreloadFor ? onOverButtonFn(enforcePagePreloadFor) : null;
    const onOverButtonMaybe = onOverButton
      ? {
        onMouseOver: onOverButton,
        onTouchStart: onOverButton,
      }
      : {};

    // All buttons are disabled until the component is mounted. This
    // prevents e.g. being able to submit forms to the backend before
    // the client side is handling the submit.
    const buttonDisabled = mounted ? disabled : true;

    return (
      // eslint-disable-next-line react/button-has-type
      <button type={buttonType} className={`bttn ${classes}`} {...onOverButtonMaybe} {...rest} disabled={buttonDisabled}>
        {content}
      </button>
    );
  }
}

export default Button;

export const PrimaryButton = (props: any) => {
  const { rootClassName } = props;
  const classes = classNames(rootClassName || 'primaryButtonRoot', 'primaryButton');
  return <Button {...props} rootClassName={classes} />;
};
PrimaryButton.displayName = 'PrimaryButton';

export const SecondaryButton = (props: any) => {
  const { rootClassName } = props;
  const classes = classNames(rootClassName || 'secondaryButtonRoot', 'secondaryButton');
  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const InlineTextButton = (props: any) => {
  const { rootClassName } = props;
  const classes = classNames(rootClassName || 'inlineTextButtonRoot', 'inlineTextButton');
  return <Button {...props} rootClassName={classes} />;
};
InlineTextButton.displayName = 'InlineTextButton';

export const SocialLoginButton = (props: any) => {
  const { rootClassName } = props;
  const classes = classNames(rootClassName || 'socialButtonRoot', 'socialButton');
  return <Button {...props} rootClassName={classes} />;
};

SocialLoginButton.displayName = 'SocialLoginButton';
