import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import {
  CircularProgressLoader,
  Modal,
  Overlay,
  ToastBar,
  EcommerceSendNotification,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  SessionManagementDelete,
  SessionManagementBlocked,
} from '..';
import { getSessionInfo } from '../../actions';
import { SessionService } from '../../services';
import exportImg from '../../assets/svg/export.svg';
import { dateFormatting } from '../../utils/dateMethods';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import './sessioninfo.module.scss';
import userprofile from '../../assets/svg/image-placeholder.png';
import deleteImg from '../../assets/svg/delete.svg';
import blockImg from '../../assets/svg/block.svg';
import unblockImg from '../../assets/svg/unblock.svg';

interface Props {
  sessioninfo: any;
  id: any;
  fetchSessionInfo: any;
  intl: any;
}

const SessionInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    sessioninfo,
    id,
    fetchSessionInfo,
    intl,
  } = props;

  React.useEffect(() => {
    fetchSessionInfo(id);
  }, [fetchSessionInfo, id]);

  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [showExportModal, setShowExportModal] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  const handleModalClose = () => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
    setSendNotification(false);
    setShowExportModal(false);
  };

  return (
    <>
      {showOverlay && <Overlay />}
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className="modal_container">
          {
            showBlock && (
              <SessionManagementBlocked
                setOverlay={setShowOverlay}
                handleModalClose={handleModalClose}
                fetchChannelList={() => { fetchSessionInfo(id); }}
                id={id}
                status={sessioninfo.data.status === 'active' ? 'blocked' : 'unblocked'}
              />
            )
          }
          {showDelete && (
            <SessionManagementDelete
              handleModalClose={handleModalClose}
              setShowOverlay={setShowOverlay}
              deleteService={() => {
                SessionService
                  .deleteApi(id).then(() => {
                    handleModalClose();
                    setShowOverlay(false);
                    navigate('/sessionmanagement');
                  });
              }}
            />
          )}
        </div>
        {
          sendNotification
          && (
            <EcommerceSendNotification
              handleModalClose={handleModalClose}
              id={id}
              serviceInfo={sessioninfo}
              setShowOverlay={setShowOverlay}
            />
          )
        }
      </Modal>
      {
        sessioninfo.data === null || sessioninfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className="cardBg containerBox p-20">
                <div className="headerActions">
                  <div />
                  <div className="iconWrapperbox">
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className="iconWrapper">
                        <img
                          src={exportImg}
                          role="presentation"
                          onClick={() => {
                            setShowModal(true);
                            setShowExportModal(true);
                          }}
                          alt="exportImg"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.DELETE}
                    >
                      <span className="iconWrapper">
                        <img
                          src={deleteImg}
                          alt="deleteImg"
                          role="presentation"
                          title="Delete"
                          onClick={() => {
                            setShowModal(true);
                            setShowDelete(true);
                          }}
                        />
                      </span>
                    </AccessControl>

                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      {sessioninfo.data.status === 'active' || sessioninfo.data.status === 'inactive' ? (
                        <span className="iconWrapper">
                          <img
                            src={blockImg}
                            alt="blockImg"
                            title="Block"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      ) : (
                        <span className="iconWrapper">
                          <img
                            src={unblockImg}
                            alt="unblockImg"
                            title="unblock"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      )}
                    </AccessControl>
                  </div>
                </div>
                <div className="userInformatinc">
                  <div className="profileImgWrapper">
                    {/* {channelInfo.data.channelIconImage !== ''
                      && channelInfo.data.channelIconImage !== undefined
                      ? (
                        <img
                          className={commonStyles.profileImg}
                          src={channelInfo.data.channelIconImage}
                          alt="profileImg"
                        />
                      )
                      : <img src={profileImg} alt="profile" />} */}
                    <img src={userprofile} alt="userprofile" />
                  </div>
                  <div className="userInfoinner">
                    <div>
                      <h2> Service Title:</h2>
                      <p>
                        {sessioninfo.data.description}
                      </p>
                      <h3>{intl.formatMessage({ id: 'ChannelInfo.label.description' })}</h3>
                      <p>
                        {sessioninfo.data.description}
                      </p>
                    </div>
                    <div className="right_container">
                      <h3>
                        {intl.formatMessage({ id: 'ChannelInfo.label.createdOn' })}
                        :
                        {' '}
                        <span>
                          {dateFormatting(sessioninfo.data.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="icons">
                  {
                    sessioninfo.data.status === 'inactive'
                      ? <div className="simple_text">Deactivated</div>
                      : sessioninfo.data.status === 'blocked'
                        ? <div className="simple_text">Blocked</div>
                        : <div className="simple_text">Active</div>
                  }
                </div>
                <div className="postDetails">
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Username
                      </p>
                      <p className="count_backround">
                        {sessioninfo.data.userDetails.username}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Domes
                      </p>
                      <p className="count_backround">
                        {sessioninfo.data.category.name}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Sub Domes
                      </p>
                      <p className="count_backround">
                        {sessioninfo.data.subCategory.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Accordion heading="Notification Details">
                <NotificationEcommerce id={id} />
              </Accordion> */}
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({ sessioninfo: state.sessionInfo });

const mapDispatchToProps = (dispatch: any) => ({
  fetchSessionInfo: (payload: any) => dispatch(getSessionInfo(payload)),
});

const SessionInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SessionInfoComponent);

export default SessionInfo;
