/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { LoadableComponentErrorBoundaryPage } from './LoadableComponentErrorBoundaryPage';

interface State {
  error: any
}

class LoadableComponentErrorBoundary extends React.Component<unknown, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    if (this.state.error) {
      return <LoadableComponentErrorBoundaryPage error={this.state.error.toString()} />;
    }

    return this.props.children;
  }
}

export default LoadableComponentErrorBoundary;
