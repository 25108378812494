import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {

} from 'react-router-dom';
import { compose } from 'redux';
import { LayoutWrapperMain, Page, Usermanagement } from '../../components';
import config from '../../config';
import './usermanagement.module.scss';

interface Props {
  scrollingDisabled: boolean,
  // from  
  // from injectIntl
  intl: any,
}

const UserManagementComponent: React.FC<Props> = (props: Props) => {
  const {
    intl,
    scrollingDisabled,
  } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'Usermanagement.Page.title' },
    { siteTitle },
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain className="layoutWrapperMain">
        <div className="content">
          <Usermanagement />
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const UserManagementComponentPage = compose(

  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(UserManagementComponent);

export default UserManagementComponentPage;
