import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Button } from '../../components';
import { ResetPasswordForm } from '../../forms';
import './resetpassword.module.scss';

interface Props {
  handleResetPassword: any
  resetPassword: any;
}

const ResetPassword: React.FC<Props> = (props: Props) => {
  const { handleResetPassword, resetPassword } = props;
  const { search } = useLocation();
  const resetToken = queryString.parse(search);

  const handleFormSubmit = (data: any) => {
    handleResetPassword(data, resetToken);
  };

  const navigate = useNavigate();

  return (
    <section>
      <div className="form_wrap">
        {resetPassword ? (
          <>
            <h2 className="sign_text">Password Reset</h2>
            <p className="details_textSuccess">
              <span>
                Your Password has been set successfully!
              </span>
            </p>
            <Button onClick={() => navigate('/')}>
              Back to Login
            </Button>
          </>
        ) : (
          <ResetPasswordForm handleFormSubmit={handleFormSubmit} />
        )}
      </div>
    </section>
  );
};

export default ResetPassword;
