import { put, call, takeEvery } from "redux-saga/effects";
import { MyProfileService } from "../services";
import { ActionTypes } from "../constants";

async function fetchAsync(func: any, arg: any) {
  const response = arg !== null ? await func(arg) : await func();
  return response;
}

function* watchUserInfo(): Generator {
  try {
    const data: any = yield call(
      fetchAsync,
      MyProfileService.getMyProfileData,
      null
    );
    yield put({
      type: ActionTypes.USER_PROFILE_SUCCESS,
      payload: data.data.data,
    });
  } catch (e: any) {
    yield put({ type: ActionTypes.USER_PROFILE_ERROR, error: e.data });
  }
}

export function* watchUserProfile() {
  yield takeEvery(ActionTypes.USER_PROFILE_REQUEST, watchUserInfo);
}
export default watchUserProfile;
