import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { injectIntl } from '../../utils/reactIntl';
import {
  Button,
  CircularProgressLoader,
  FieldFileInput,
  FieldTextInput,
  ValidationError,
} from '../../components';
import { DomesManagementService } from '../../services';
import classNames from 'classnames';
import information from '../../assets/svg/info.svg';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  loader: any;
  handleSubmit: any;
  handleSaveAsDraft: any,
  error: string;
  id: string;
}

const EditDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    intl,
    loader,
    error,
    id,
    handleSaveAsDraft,
  } = props;
  const [initialLoader, setInitialLoader] = React.useState(true);
  const FILE_SIZE = 50 * 1024 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
  ];
  const editDomesSchema = yup.object().shape({
    domeName: yup.string()
      .required(intl.formatMessage({ id: 'EditDomeForm.error.domeName' })),
    description: yup.string()
      .max(250, intl.formatMessage({ id: 'EditDomeForm.error.description' })),
    selectedIcon: yup
      .mixed()
      .required(intl.formatMessage({ id: 'AddDomes.error.selectedIcon.required' }))
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || SUPPORTED_FORMATS.includes(value[0].type)),
      ),
    unselectedIcon: yup.mixed()
      .required('Please fill all required fields')
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIcon.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || SUPPORTED_FORMATS.includes(value[0].type)),
      ),
    selectedIconSVG: yup
      .mixed()
      .required(intl.formatMessage({ id: 'AddDomes.error.selectedSVG.required' }))
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.selectedIconSVG.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || value[0].type === 'image/svg+xml'),
      ),
    unselectedIconSVG: yup
      .mixed()
      .required(intl.formatMessage({ id: 'AddDomes.error.unselectedsvg.required' }))
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIconSVG.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || value[0].type === 'image/svg+xml'),
      ),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(editDomesSchema),
  });
  useEffect(() => {
    DomesManagementService
      .getDetails(id)
      .then((data: any) => {
        formProps.reset({
          domeName: data.data.data.name,
          description: data.data.data.description,
          selectedIcon: data.data.data.selectedImage,
          unselectedIcon: data.data.data.nonSelectedImage,
          selectedIconSVG: data.data.data.selectedImageSvg,
          unselectedIconSVG: data.data.data.nonSelectedImageSvg,
        });
        setInitialLoader(false);
      });
  }, [formProps, id]);

  return (
    <>
      {initialLoader ? (
        <>
          <CircularProgressLoader />
        </>
      ) : (
        <div className="cardBg addDetailsPage">
          <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
            <h2 className="sign_text">
              {intl.formatMessage({ id: 'EditDomeForm.label.name' })}
            </h2>
            <FieldTextInput
              id="domeName"
              name="domeName"
              formName="editDomes"
              className="textInput"
              type="text"
              formProps={formProps}
              label={intl.formatMessage({ id: 'EditDomeForm.label.domename' })}
            />
            <div>
              <FieldTextInput
                id="description"
                name="description"
                formName="editDomes"
                className="textInput"
                type="textarea"
                formProps={formProps}
                label={intl.formatMessage({ id: 'EditDomeForm.label.descritpion' })}
              />
            </div>
            <h2 className="sign_text">
              {intl.formatMessage({ id: 'EditDomeForm.label.uploadLabel' })}
            </h2>
            <div className="profileWrapperimg">
              <div className="selection_box">
                <h4>
                  {intl.formatMessage({ id: 'EditDomeForm.label.selectedIcon' })}
                </h4>
                <FieldFileInput
                  id="selectedIcon"
                  name="selectedIcon"
                  className="textInput"
                  formProps={formProps}
                  rootClassName="input_upload"
                />
              </div>
              <div className="selection_box">
                <h4>
                  {intl.formatMessage({ id: 'EditDomeForm.label.unselectedIcon' })}
                </h4>
                <FieldFileInput
                  id="unselectedIcon"
                  name="unselectedIcon"
                  className="textInput"
                  formProps={formProps}
                  rootClassName="input_upload"
                />
              </div>
              <div className="selection_box">
                <h4>
                  {intl.formatMessage({ id: 'EditDomeForm.label.selectedIconSvg' })}
                  {' '}
                </h4>
                <FieldFileInput
                  id="selectedIconSVG"
                  name="selectedIconSVG"
                  className="textInput"
                  formProps={formProps}
                  rootClassName="input_upload"
                />
              </div>
              <div className="selection_box">
                <h4>
                  {intl.formatMessage({ id: 'EditDomeForm.label.unselectedIconSvg' })}
                </h4>
                <FieldFileInput
                  id="unselectedIconSVG"
                  name="unselectedIconSVG"
                  className="textInput"
                  formProps={formProps}
                  rootClassName="input_upload"
                />
              </div>
            </div>
            <p className="informationUpload">
              <img src={information} alt="information" />
              {intl.formatMessage({ id: 'EditDomeForm.label.size' })}
            </p>
            {Object.keys(formProps.formState.errors).length > 0
              && (
                <ValidationError
                  className="validation_error"
                  error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
                />
              )}
            {Object.keys(formProps.formState.errors).length === 0
              && <ValidationError error={error} />}
            {loader
              ? (
                <div className="savingLoader">
                  <CircularProgressLoader />
                </div>
              ) : (
                <>
                  <div className="buttonWrapper">
                    <Button
                      onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                    >
                      {intl.formatMessage({ id: 'EditDomeForm.label.saveAsDraft' })}
                    </Button>
                    <Button buttonType={ButtonTypes.submit}>
                      {intl.formatMessage({ id: 'EditDomeForm.label.publish' })}
                    </Button>
                  </div>
                </>
              )}
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(EditDomesForm);
