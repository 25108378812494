import roles from '../components/AdminRoles/roles.enum';
import api from '../api';

export class DashboardService {
  static async getDashboardDetails(payload: any) {
    if (localStorage.getItem('adminType') === roles.superadmin) {
      return Promise.all(
        [
          api.getApiCall('/api/v1/admins/dashboard/trending-users'),
          api.getApiCall(`/api/v1/admins/dashboard/top-channels?subscriptionType=${payload}`),
          api.getApiCall('/api/v1/admins/dashboard/important-stastics'),
          api.getApiCall('/api/v1/admins/dashboard/events'),
          api.getApiCall('/api/v1/admins/dashboard/subscription-plans'),
          api.getApiCall('/api/v1/admins/dashboard/memberships'),
          api.getApiCall('/api/v1/admins/dashboard/transactions'),
        ],
      );
    }

    return Promise.all(
      [
        api.getApiCall('/api/v1/admins/dashboard/trending-users'),
        // api.getApiCall('/api/v1/admins/dashboard/transactions'),
        api.getApiCall(`/api/v1/admins/dashboard/top-channels?subscriptionType=${payload}`),
        api.getApiCall('/api/v1/admins/dashboard/important-stastics'),
        api.getApiCall('/api/v1/admins/dashboard/events'),
        api.getApiCall('/api/v1/admins/dashboard/subscription-plans'),
        api.getApiCall('/api/v1/admins/dashboard/memberships'),
      ],
    );
  }
}
