import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  ValidationError,
  CircularProgressLoader,
} from '../../components';
import { ChannelManagementService } from '../../services';
import { FormattedMessage } from '../../utils/reactIntl';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import './AddSubDomes.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  handleSaveAsDraft: any;
  error: any;
}

const AddSubDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit, handleSaveAsDraft, error, intl,
  } = props;
  const [domes, setDomes] = React.useState([]);
  const addSubDomesSchema = yup.object().shape({
    domes: yup.string().required(intl.formatMessage({ id: 'AddSubDomes.error.domes.required' })),
    domeName: yup.string()
      .required(intl.formatMessage({ id: 'AddSubDomes.error.domeName.required' })),
    description: yup.string()
      .max(250, intl.formatMessage({ id: 'AddSubDomes.error.description.max' }))
      .required(intl.formatMessage({ id: 'AddSubDomes.error.description.required' })),
  }, []);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    ChannelManagementService.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
      setLoading(false);
    });
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addSubDomesSchema),
  });

  return (
    <>
      {loading ? (
        <div>
          <CircularProgressLoader />
        </div>
      ) : (
        <div className="cardBg addDetailsPage">
          <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
            <h2 className="sign_text">
              <FormattedMessage id="AddSubDomes.label.details" />
            </h2>
            <div className="flex_row">
              <div className="flex_col_sm_6">
                <div className="userType">
                  <FieldSelect
                    id="domes"
                    name="domes"
                    label={intl.formatMessage({ id: 'AddSubDomes.label.domes' })}
                    variant={false}
                    formProps={formProps}
                    options={domes}
                  />
                </div>
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="domeName"
                  name="domeName"
                  formName="addDomes"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName="passwordInput"
                  label={intl.formatMessage({ id: 'AddSubDomes.label.subDomes' })}
                />
              </div>
              <div className="flex_col_sm_12">
                <FieldTextInput
                  id="description"
                  name="description"
                  formName="addDomes"
                  className="textInput"
                  type="textarea"
                  formProps={formProps}
                  rootClassName="passwordInput"
                  label={intl.formatMessage({ id: 'AddSubDomes.label.description' })}
                />
              </div>
            </div>
            {/* <h2 className="sign_text">
              <FormattedMessage id="AddSubDomes.label.uploadIcons" />
            </h2> */}
            {Object.keys(formProps.formState.errors).length > 0
              && (
                <ValidationError
                  className="validation_error"
                  error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
                />
              )}
            {Object.keys(formProps.formState.errors).length === 0
              && <ValidationError error={error} />}
            <div className="buttonWrapper">
              <Button onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}>
                <FormattedMessage id="AddSubDomes.label.save.as.draft" />
              </Button>
              <Button buttonType={ButtonTypes.submit}>
                <FormattedMessage id="AddSubDomes.label.publish" />
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(AddSubDomesForm);
