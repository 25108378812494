import React from "react";
import "../Modal/Modal.module.scss";
import { Button } from "../../components";

interface Props {
  handleModalUpdate: () => void;
  handleModalCloseReset: () => void;
  updatedPercentage: string;
  commissionTypeToUpdate: string;
}

const CommissionRulesReset: React.FC<Props> = (props: Props) => {
  const {
    handleModalCloseReset,
    updatedPercentage,
    commissionTypeToUpdate,
    handleModalUpdate,
  } = props;

  return (
    <>
      <p>Are you sure you want to Reset {commissionTypeToUpdate} ? </p>
      <div className="modalButtonWrapper">
        <Button
          onClick={() => {
            handleModalUpdate();
            handleModalCloseReset(); // Close modal after updating values
          }}
        >
          Yes
        </Button>
        <Button onClick={handleModalCloseReset}>No</Button>
      </div>
    </>
  );
};

export default CommissionRulesReset;
