/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import avatcoin from '../../assets/svg/avatcoin.svg';
import avatcoinsilver from '../../assets/images/silvercoin.png';
import { ExportPaymentInfo } from '../../forms';
import { PaymentinfoManagementService } from '../../services';
import {
  Button,
  CircularProgressLoader,
  Modal,
  // RefundTable,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  // Accordion,
  PayementNotification,
  Overlay,
  ToastBar,
} from '..';
import { getPaymentInfo } from '../../actions';
import exportImg from '../../assets/svg/export.svg';
import { getDateAndTime } from '../../utils/dateMethods';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  paymentinfo: any;
  id: any;
  fetchPaymentInfo: any;
  intl: any;
}

const PaymentInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    paymentinfo,
    id,
    fetchPaymentInfo,
    intl,
  } = props;

  React.useEffect(() => {
    fetchPaymentInfo(id);
  }, [fetchPaymentInfo, id]);

  const [showModal, setShowModal] = React.useState(false);
  const [overlay, setOverlay] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const [paymentNotification, setPaymentNotification] = React.useState(false);
  const [showPaymentExport, setShowPaymentExport] = React.useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    setShowPaymentExport(false);
    setPaymentNotification(false);
  };
  const handleStatus = (status: any) => {
    switch (status) {
      case 'Success':
        return 'Successful';
      case 'pending':
        return 'Pending';
      case 'refund':
        return 'Refunded';
      default:
        return '';
    }
  };

  return (
    <>
      {overlay && <Overlay />}
      {notification.message.length > 0 && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {paymentNotification && (
          <div className="modal_container">
            <PayementNotification
              transactionId={id}
              id={paymentinfo.data === null ? '' : paymentinfo.data.receiverUserId._id}
              handleModalClose={handleModalClose}
              setShowOverlay={setOverlay}
              setNotification={setNotification}
            />
          </div>
        )}
        {showPaymentExport && (
          <div className="modal_container">
            <ExportPaymentInfo
              handleFormSubmit={(formdata: any) => {
                setOverlay(true);
                const exportTypes: any = [];
                Object.keys(formdata).forEach((ele: any) => {
                  if (formdata[ele]) {
                    exportTypes.push(ele);
                  }
                });
                PaymentinfoManagementService.exportPaymentInfo({
                  transactionId: id,
                  optionCode: exportTypes,
                  ...(
                    exportTypes.includes('detail') && {
                      values: {
                        'Order ID': paymentinfo.data.orderId,
                        'Total Price': paymentinfo.data.purchaseIn !== 'USD' ? `Gold(${paymentinfo.data.goldPrice})` : `$${paymentinfo.data.amount}`,
                        'Purchased Item': paymentinfo.data.title,
                        'Item type': paymentinfo.data.itemType,
                        'Date & Time of payment': getDateAndTime(paymentinfo.data.createdAt),
                        'Transaction status': handleStatus(paymentinfo.data.sourceUserPaymentStatus),
                        'Discounted price':
                          paymentinfo.data.discountedPrice.gold !== 0 && paymentinfo.data.discountedPrice.silver !== 0 ? (paymentinfo.data.isViaDiscountPrice ? (paymentinfo.data.discountedPrice ? (
                            `Gold ${paymentinfo.data.discountedPrice.gold} + Silver ${paymentinfo.data.discountedPrice.silver}`) : 0) : (
                            `Gold ${paymentinfo.data.discountedPrice.gold} + Silver ${paymentinfo.data.discountedPrice.silver}`)) : '--',
                        'Amount paid':
                          paymentinfo.data.isViaDiscountPrice ? (
                            `Gold ${paymentinfo.data.discountedPrice.gold} + Silver ${paymentinfo.data.discountedPrice.silver}`
                          ) : paymentinfo.data.purchaseIn !== 'USD' ? `Gold (${paymentinfo.data.goldCoin})` : `$${paymentinfo.data.amount}`,
                        'Receiver username': paymentinfo.data.receiverUserId.username,
                      },
                    }
                  ),
                }).then(() => {
                  setOverlay(false);
                  setNotification({
                    show: true,
                    message: 'Details Exported Successfully',
                  });
                  handleModalClose();
                }).catch((err: any) => {
                  setOverlay(false);
                  setNotification({
                    show: true,
                    message: err.response.data.message,
                  });
                });
              }}
              handleCloseModal={handleModalClose}
            />
          </div>
        )}
      </Modal>
      {
        paymentinfo.data === null || paymentinfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className="cardBg containerPayment">
                <div className="headerActions">
                  <div>
                    <Button
                      className="button"
                      onClick={() => {
                        setShowModal(true);
                        setPaymentNotification(true);
                      }}
                    >
                      {intl.formatMessage({ id: 'ChannelInfo.label.SendNotification' })}
                    </Button>
                  </div>
                  <div className="iconWrapperbox">
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className="iconWrapper">
                        <img
                          role="presentation"
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                          onClick={() => {
                            setShowModal(true);
                            setShowPaymentExport(true);
                          }}
                        />
                      </span>
                    </AccessControl>
                  </div>
                </div>
                <div className="userInformatinc">
                  <div className="userInfoinner">
                    <div>
                      <h2>{paymentinfo.data.channelName}</h2>
                      <h3>
                        Transaction ID:
                        {` ${paymentinfo.data.transactionId}`}
                      </h3>
                      {paymentinfo.data?.sourceUserId === undefined || paymentinfo.data?.sourceUserId === null ? ''
                        : (
                          <h3>
                            Username:
                            {' '}
                            {paymentinfo.data?.sourceUserId.username}
                          </h3>
                        )}
                    </div>
                  </div>
                </div>

                <div className="postDetails">
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Order ID
                      </p>
                      <p className="count_backround">
                        {paymentinfo.data.orderId}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Total Price
                      </p>
                      <p className="count_backround">
                        {paymentinfo.data.purchaseIn !== 'USD' ? (
                          <div className="c_price">
                            <img src={avatcoin} alt="avatcoin" />
                            <span>{paymentinfo.data.goldPrice}</span>
                          </div>
                        ) : `$${paymentinfo.data.amount}`}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Purchased Item
                      </p>
                      <p className="count_backround">
                        {paymentinfo.data.title}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Item type
                      </p>
                      <p className="count_backround">
                        {paymentinfo.data.itemType}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Date & Time of payment
                      </p>
                      <p className="count_backround">
                        {getDateAndTime(paymentinfo.data.createdAt)}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Transaction status
                      </p>
                      <p className="count_backround">
                        {handleStatus(paymentinfo.data.sourceUserPaymentStatus)}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Discounted price
                      </p>
                      <>
                        {
                          paymentinfo.data.discountedPrice.gold !== 0
                            && paymentinfo.data.discountedPrice.silver !== 0
                            ? (paymentinfo.data.isViaDiscountPrice ? (paymentinfo.data.discountedPrice ? (
                              <div className="c_price">
                                <img src={avatcoin} alt="avatcoin" />
                                <span>{paymentinfo.data.discountedPrice.gold}</span>
                                <span className="separator">+</span>
                                <img src={avatcoinsilver} alt="avatcoin" />
                                <span>{paymentinfo.data.discountedPrice.silver}</span>
                              </div>
                            ) : 0) : (
                              <div className="c_price">
                                <img src={avatcoin} alt="avatcoin" />
                                <span>{paymentinfo.data.discountedPrice.gold}</span>
                                <span className="separator">+</span>
                                <img src={avatcoinsilver} alt="avatcoin" />
                                <span>{paymentinfo.data.discountedPrice.silver}</span>
                              </div>
                            )) : '--'
                        }
                      </>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Amount paid
                      </p>
                      <p className="count_backround">
                        {paymentinfo.data.isViaDiscountPrice ? (
                          <div className="c_price">
                            <img src={avatcoin} alt="avatcoin" />
                            <span>{paymentinfo.data.discountedPrice.gold}</span>
                            <span className="separator">+</span>
                            <img src={avatcoinsilver} alt="avatcoin" />
                            <span>{paymentinfo.data.discountedPrice.silver}</span>
                          </div>
                        ) : paymentinfo.data.purchaseIn !== 'USD' ? (
                          <div className="c_price">
                            <img src={avatcoin} alt="avatcoin" />
                            <span>{paymentinfo.data.goldCoin}</span>
                          </div>
                        ) : `$${paymentinfo.data.amount}`}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Receiver username
                      </p>
                      <p className="count_backround">
                        {paymentinfo.data.receiverUserId.username}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Accordion heading="Refund Details">
                <RefundTable id={undefined} />
              </Accordion> */}
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { paymentinfo } = state;
  return {
    paymentinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPaymentInfo: (payload: any) => dispatch(getPaymentInfo(payload)),
});

const PaymentInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PaymentInfoComponent);

export default PaymentInfo;
