import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { DashboardData } from '../modal';

export default function reducer(
  state: DashboardData = new DashboardData(),
  action: AnyAction,
) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.DASHBOARD_DATA_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    case ActionTypes.DASHBOARD_DATA_REMOVE:
      return {
        ...state,
        loadingInProgress: true,
        loadingError: null,
      };
    default:
      return state;
  }
}
