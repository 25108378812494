import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dateFormatting } from '../../utils/dateMethods';
import {
  Modal,
  PostCreatedBlocked,
  PostCreatedDelete,
} from '../../components';
import { fetchPostCreated } from '../../actions';
import classNames from 'classnames';
import DataTable from '../Datatable';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  id: any;
  postcreated: any;
  roleList: any;
}

const PostCreated: React.FC<Props> = (props: Props) => {
  const {
    postcreated,
    roleList,
    id,
  } = props;
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    id,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edited, setEdited] = React.useState<any>({
    show: false,
    id: null,
  });

  React.useEffect(() => {
    roleList(filter);
  }, [roleList, filter]);

  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      case 'edit':
        navigate(`/subdomesmanagement/editSubDomes/${blockedid}`);
        break;
      default:
        break;
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = postcreated.data ? postcreated.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setEdited({ ...edited, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  const actionButtons = (action: any, idS: any) => (
    <>
      <img
        src={deleteIcon}
        role="presentation"
        alt="block"
        width="35"
        title="Delete"
        height="35"
        onClick={() => handleBlockUnblock('delete', idS)}
      />
      {action === 'publish' || action === 'unblocked' ? (
        <img
          src={blockIcon}
          role="presentation"
          alt="block"
          width="35"
          title="Block"
          height="35"
          onClick={() => handleBlockUnblock('blocked', idS)}
        />
      ) : (
        <img
          src={unblock}
          role="presentation"
          alt="unblock"
          width="35"
          title="Unblock"
          height="35"
          onClick={() => handleBlockUnblock('unblocked', idS)}
        />
      )}
    </>
  );

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      description: data.description !== null && <div title={data.description}>{data.description.length > 20 ? `${data.description.substring(0, 20)}...` : data.description}</div>,
      createdAt: dateFormatting(data.createdAt),
      domes: data.categoryId.name,
      action: actionButtons(data.status, idS),
    };
  }

  const rows: any = [];
  if (postcreated.data !== null && postcreated.data) {
    postcreated.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCellsPostsCreated: any = [
    {
      id: 'sno', numeric: true, disablePadding: true, label: 'S.No',
    },
    {
      id: 'description', numeric: false, disablePadding: false, label: 'Description',
    },
    {
      id: 'postedon', numeric: false, disablePadding: false, label: 'Posted On',
    },
    {
      id: 'domes', numeric: false, disablePadding: false, label: 'Domes',
    },
    {
      id: 'action', numeric: false, disablePadding: false, label: 'Action',
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByParentCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        showOnClose={false}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          deleted.show && (
            <PostCreatedDelete
              handleModalClose={handleModalClose}
              fetchPostList={() => { setFilter({ ...filter, page: 0 }); }}
              id={deleted.id}
            />
          )
        }
        {
          blocked.show && (
            <PostCreatedBlocked
              handleModalClose={handleModalClose}
              fetchPostList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <PostCreatedBlocked
              handleModalClose={handleModalClose}
              fetchPostList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
      </Modal>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headCellsPostsCreated}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={postcreated.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { postcreated } = state;
  return {
    postcreated,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  roleList: (payload: any) => dispatch(fetchPostCreated(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCreated);
