import React from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import './FieldInputFile.module.scss';
import edit from '../../assets/svg/edit.svg';

interface Props {
  rootClassName?: string,
  className?: string,
  id: any,
  name: string,
  formProps: any,
  disabled?: boolean,
}

const FieldFileInput = (props: Props) => {
  const {
    rootClassName,
    className,
    id,
    name,
    formProps: { control },
    disabled,
  } = props;

  const classes = classNames(rootClassName || 'root', className ? [className] : null);
  const [_value, _setValue] = React.useState<any>('');
  const [file, setFile] = React.useState<any>('');
  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        render={({
          field: {
            ref, onChange, value,
          },
        }) => (
          <>
            <div className='file_s'>
              <div className='fileiconU'>
                <input
                  type="file"
                  id={id}
                  ref={ref}
                  onChange={(e: any) => {
                    if (e.target.files.length > 0) {
                      _setValue(e.target.value);
                      setFile(e.target.files[0]);
                      onChange(e.target.files);
                    }
                  }}
                  value={_value}
                  disabled={disabled}
                  title=""
                />
                <img src={edit} alt="userprofile" />
              </div>
            </div>
            <span className='fileimage'>
              {value !== null && value !== undefined && typeof (value) === 'string' && value !== '' && <img src={value} alt="upload" />}
              {value !== null && value !== undefined && typeof (value) === 'object' && <img src={URL.createObjectURL(file)} alt="upload" />}
            </span>
          </>
        )}
      />
    </div>
  );
};

export default FieldFileInput;
