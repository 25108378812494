import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgressLoader,
  FieldCheckbox,
  FieldFileInput,
  FieldTextInput,
  ValidationError,
} from '../../components';
import { Notification } from '../../services';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import information from '../../assets/svg/info.svg';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  loader: any;
  error: string;
  handleSaveAsDraft: any;
  handleSubmit: any;
  dataForm?: any;
  setOverlay: any;
}

const AddNotificationPushForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    loader,
    error,
    handleSaveAsDraft,
    handleSubmit,
    dataForm,
  } = props;
  const FILE_SIZE = 5 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'jpg',
    'jpeg',
    'png',
  ];



  const addNotificationSchema = yup.object().shape({
    title: yup.string()
      .max(500, 'Please enter title less than 500 characters for description')
      .required('Please fill all required fields'),
    description: yup.string()
      .max(500, 'Please enter characters less than 500 characters for description')
      .required('Please fill all required fields'),
    android: yup
      .boolean()
      .default(false),
    ios: yup
      .boolean()
      .default(false),
    web: yup
      .boolean()
      .default(false),
    selectedIcon: yup.lazy((value) => {
      switch (typeof value) {
        case 'undefined':
          return yup.string();
        case 'object':
          return yup
            .mixed()
            .test(
              'fileSize',
              'Selected Icon File too large',
              () => value === '' || value[0].size <= FILE_SIZE,
            )
            .test(
              'fileFormat',
              'Unsupported Format for Selected icon.(jpeg/png is required)',
              () => value === '' || SUPPORTED_FORMATS.includes(value[0].type),
            );
        default:
          throw new yup.ValidationError('Value must be a string or `undefined`');
      }
    }),
  }, [['android', 'ios']]);

  const formProps = useForm({
    resolver: yupResolver(addNotificationSchema),
  });
  React.useEffect(() => {
    if (dataForm) {
      formProps.reset({
        title: dataForm.title,
        description: dataForm.description,
        android: dataForm.platforms.includes('1'),
        ios: dataForm.platforms.includes('2'),
        web: dataForm.platforms.includes('3'),
        selectedIcon: dataForm.image,
      });
    }
  }, []);

  return (
    <div className="cardBg addDetailsPage">
      <form className="form" onSubmit={formProps.handleSubmit(() => handleSubmit(formProps.getValues()))}>
        <FieldTextInput
          id="title"
          name="title"
          formName="addNotification"
          className="textInput"
          type="text"
          formProps={formProps}
          rootClassName="passwordInput"
          label={intl.formatMessage({ id: 'AddNotificationPush.label.title' })}
        />
        <div>
          <FieldTextInput
            id="description"
            name="description"
            formName="addDomes"
            className="textInput"
            type="textarea"
            formProps={formProps}
            rootClassName="passwordInput"
            label={intl.formatMessage({ id: 'AddNotificationPush.label.description' })}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FieldCheckbox
            id="android"
            name="android"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationPush.label.android' })}
            defaultValue={false}
          />
          <FieldCheckbox
            id="ios"
            name="ios"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationPush.label.ios' })}
            defaultValue={false}
          />
          <FieldCheckbox
            id="web"
            name="web"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationPush.label.web' })}
            defaultValue={false}
          />
        </div>
        {Object.keys(formProps.formState.errors).length > 0
          && (
            <ValidationError
              className="validation_error"
              error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
            />
          )}
        {Object.keys(formProps.formState.errors).length === 0
          && error.length > 0
          && <ValidationError error={error} />}
        {loader
          ? (
            <div className="savingLoader">
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className="buttonWrapper">
                <Button
                  buttonType={ButtonTypes.button}
                  onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                >
                  {intl.formatMessage({ id: 'AddNotificationPush.label.Save.as.draft' })}
                </Button>
                <Button
                  buttonType={ButtonTypes.submit}
                >
                  {intl.formatMessage({ id: 'AddNotificationPush.label.Submit' })}
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
};

export default injectIntl(AddNotificationPushForm);
