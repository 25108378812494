import api from '../api';

export class VerionManagementDetail {
    static getVersionMangementDetail(payload: any) {
        return api.putApiCall('/api/v1/admins/app-versions?limit=10&pageNo=1', {
          versionId: payload.versionId,
          versionNo: payload.versionNo,
          platform: payload.platform,
          launchType: payload.launchType,
          description: payload.description,
          dateTime: payload.dateTime,
          status: payload.status,
        });
      }

      static async getVersionDetails(payload: any) {
        return api.getApiCall(`/api/v1/admins/app-versions/${payload}`, payload);
      }

      static async deleteVersionDetails(payload: any) {
        return api.deleteApiCall(`/api/v1/admins/app-versions`,{
          versionId: payload.versionId,
        });
      }
}