import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { injectIntl } from 'react-intl';
import {
  FieldTextInput, Button, ValidationError,
} from '../../components';
import './resetpassword.module.scss';

interface Props {
  handleFormSubmit: any
  intl: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const ResetPasswordForm: React.FC<Props> = (props: Props) => {
  const { handleFormSubmit, intl } = props;
  const [passwordType, setPasswordType] = useState('password');
  const resetPassSchema = yup.object().shape({
    password: yup.string()
      .required(intl.formatMessage({ id: 'ResetPass.error.required' }))
      .matches(/((?=.*\d)(?=.*[A-Z])(?=.*\W).{8})/, intl.formatMessage({ id: 'ResetPass.error.matches' }))
      .max(30, intl.formatMessage({ id: ' ResetPass.error.max' })),
  }, []);
  const formProps = useForm({
    resolver: yupResolver(resetPassSchema),
  });
  const passwordTypeHandler = () => {
    setPasswordType(passwordType === 'text' ? 'password' : 'text');
  };
  return (
    <form onSubmit={formProps.handleSubmit(handleFormSubmit)} className="form">
      <h2 className="sign_text">
        {intl.formatMessage({ id: 'ResetPassword.label.title' })}
      </h2>
      <p className="details_text">
        {intl.formatMessage({ id: 'ResetPassword.label.detail_text' })}
      </p>
      <FieldTextInput
        id="password"
        name="password"
        formName="password"
        className="textInput password"
        type={passwordType}
        showVisibilityIcon
        passwordTypeHandler={passwordTypeHandler}
        formProps={formProps}
        label={intl.formatMessage({ id: 'ResetPassword.label.password' })}
      />
      {Object.keys(formProps.formState.errors).length > 0
        && (
          <ValidationError
            className="validation_error"
            error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
          />
        )}
      <Button buttonType={ButtonTypes.submit}>
        {intl.formatMessage({ id: 'ResetPassword.label.setNewPassword' })}
      </Button>
    </form>
  );
};

export default injectIntl(ResetPasswordForm);
