import { ActionTypes } from '../constants/index';

export const getTutorials = () => ({
  type: ActionTypes.GET_TUTORIALS_MGMT_REQUEST,
});

export const addTutorial = (payload: any) => ({
  type: ActionTypes.CREATE_TUTORIALS_MGMT_REQUEST,
  payload,
})

export const deleteTutorial = (payload: string) => ({
  type: ActionTypes.DELETE_TUTORIALS_MGMT_REQUEST,
  payload,
})
