import routing from './routing';
import auth from './auth';
import usermanagement from './usermanagement';
import userinfo from './userinfo';
import channelmanagement from './channelmanagement';
import userProfile from './userProfile';
import domes from './domesmanagement';
import subdomesmanagement from './subdomesmanagement';
import contentmanagement from './contentmanagement';
import faqs from './faqs';
import rolesmanagement from './rolesmanagement';
import postcreated from './postcreated';
import logindetails from './logindetails';
import subadminmanagement from './subadminmanagement';
import creditactivitydetails from './creditactivitydetails';
import debitactivitydetails from './debitactivitydetails';
import reportedusermanagement from './reportedusermanagement';
import notesdetails from './notesdetails';
import userreportdetail from './userreportdetail';
import postreportdetail from './postreportdetail';
import channelreportdetail from './channelreportdetail';
import notificationdetails from './notificationdetails';
import pricemanagement from './pricemanagement';
import permissions from './permissions';
import notification from './notification';
import notificationmanagement from './notificationmanagement';
import masterreasonmanagement from './masterreasonmanagement';
import masterconditionmanagement from './masterconditionmanagement';
import feedbackmanagement from './feedbackmanagement';
import manageversion from './manageversion';
import livestreaming from './livestreaming';
import livestreaminfo from './livestreaminfo';
import participantdetails from './participantdetails';
import eventdetails from './eventdetails';
import notificationmanagementlivestream from './notificationlivestreaming';
import postdetails from './postdetails';
import postdetailreporteduserinfo from './postdetailreporteduserinfo';
import support from './support';
import supportInfo from './supportInfo';
import paymentManagement from './paymentManagement';
import paymentinfo from './paymentinfo';
import usernotificationdetail from './usernotificationdetail';
import channelnotifcationdetail from './channelnotifcationdetail';
import coinactivity from './coinactivity';
import eventreportdetail from './eventreportdetail';
import adminnotification from './adminnotification';
import transactionDetails from './transactionDetails';
import dashboard from './dashboard';
import editnotification from './editnotification';
import ecommerce from './ecommerce';
import ecommerceinfo from './ecommerceinfo';
import ecommercenotification from './ecommercenotification';
import session from './session';
import subscriptionplan from './subscriptionplan';
import sessioninfo from './sessioninfo';
import tutorials from './tutorials';
import subscribedusers from './subscribedusermanagement';
import subscriptiondetails from './subscriptiondetails';
import commissionmanagement from './commissionmanagement';
import commissiondetails from './commissiondetails';
import commissionrules from './commissionrules';
import commisssionrulesupdate from './commissionrulesupdate';
import versionmanage from './versionmanage';

export const ActionTypes = {
  SWITCH_MENU: 'APP/Alerts/SWITCH_MENU',
  EXCEPTION: 'APP/Alerts/EXCEPTION',

  SHOW_ALERT: 'APP/Alerts/SHOW_ALERT',
  HIDE_ALERT: 'APP/Alerts/HIDE_ALERT',
  ...routing,
  ...auth,
  ...usermanagement,
  ...userinfo,
  ...channelmanagement,
  ...userProfile,
  ...domes,
  ...subdomesmanagement,
  ...contentmanagement,
  ...faqs,
  ...rolesmanagement,
  ...postcreated,
  ...logindetails,
  ...subadminmanagement,
  ...creditactivitydetails,
  ...debitactivitydetails,
  ...reportedusermanagement,
  ...notesdetails,
  ...userreportdetail,
  ...postreportdetail,
  ...channelreportdetail,
  ...notificationdetails,
  ...pricemanagement,
  ...permissions,
  ...notification,
  ...notificationmanagement,
  ...masterreasonmanagement,
  ...masterconditionmanagement,
  ...feedbackmanagement,
  ...manageversion,
  ...livestreaming,
  ...livestreaminfo,
  ...participantdetails,
  ...eventdetails,
  ...notificationmanagementlivestream,
  ...postdetails,
  ...postdetailreporteduserinfo,
  ...support,
  ...supportInfo,
  ...paymentManagement,
  ...paymentinfo,
  ...usernotificationdetail,
  ...channelnotifcationdetail,
  ...coinactivity,
  ...eventreportdetail,
  ...adminnotification,
  ...transactionDetails,
  ...dashboard,
  ...editnotification,
  ...ecommerce,
  ...ecommerceinfo,
  ...ecommercenotification,
  ...session,
  ...subscriptionplan,
  ...sessioninfo,
  ...tutorials,
  ...subscribedusers,
  ...subscriptiondetails,
  ...commissionmanagement,
  ...commissiondetails,
  ...commissionrules,
  ...commisssionrulesupdate,
  ...versionmanage
};
