import { UserProfileInfo } from '../modal';
import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';

export default function reducer(state: UserProfileInfo = new UserProfileInfo(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.USER_PROFILE_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.USER_PROFILE_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.USER_PROFILE_ERROR:
      return {
        ...state,
        loadingError: true,
        data: payload,
      };
    default:
      return state;
  }
}
