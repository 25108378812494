/**
 * This is a wrapper component for different Layouts. Footer content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LayoutWrapperFooter.module.scss';

const LayoutWrapperFooter = (props: any) => {
  const { className, rootClassName, children } = props;
  const classes = classNames(rootClassName || 'layout_root', className);

  return <div className={classes}>{children}</div>;
};

const { node, string } = PropTypes;

LayoutWrapperFooter.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
};

export default LayoutWrapperFooter;
