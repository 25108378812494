import { getNotesDetails } from '../../actions';
import classNames from 'classnames';
import {
  DataTable, Modal, ShowTable,
} from '../../components';
import React from 'react';
import { connect } from 'react-redux';
import { dateFormatting } from '../../utils/dateMethods';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  notesdetails: any;
  fetchNotesDetails: any;
  id: any;
}

const NotestDetail = (props: Props) => {
  const { fetchNotesDetails, notesdetails, id } = props;
  const [selected, setSelected] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showRows, setShowRows] = React.useState({
    show: false,
    data: [],
  });

  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  React.useEffect(() => {
    fetchNotesDetails(filter);
  }, [fetchNotesDetails, filter]);

  const rows: any = [];

  function createData(data: any, index: number): any {
    const id_ = data._id;
    const desc = (
      <div title={data.description}>
        {data.description.length > 20 ? `${data.description.substring(0, 20)}...` : data.description}
      </div>
    );
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      description: desc,
      addedby: data.admin.name,
      createdOn: dateFormatting(data.createdAt),
    };
  }
  if (notesdetails.data !== null && notesdetails.data) {
    notesdetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headerCells: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'description', label: 'Notes Description',
    },
    {
      id: 'addedby', label: 'Added By',
    },
    {
      id: 'createdOn', label: 'Added on',
    },
  ];
  const count = notesdetails.data ? notesdetails.data['Pagination-Count'] : 0;

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const handleShowInfo = (row: any) => {
    if (notesdetails.data !== null && notesdetails.data) {
      setShowModal(true);
      const headerRows: any = [
        row.sno,
        notesdetails.data.data[row.sno - 1].description,
        notesdetails.data.data[row.sno - 1].admin.name,
        dateFormatting(notesdetails.data.data[row.sno - 1].createdAt),
      ];
      const data: any = [];
      headerCells.forEach((values: any, index: number) => {
        if (values.label !== 'Action') {
          data.push({
            key: values.label,
            values: headerRows[index],
          });
        }
      });
      setShowRows({
        show: true,
        data,
      });
    }
  };
  const handleModalClose = () => {
    setShowModal(false);
    setShowRows({
      show: false,
      data: [],
    });
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {showRows.show && (
          <ShowTable headerRows={showRows.data} />
        )}
      </Modal>
      <div className="dataTableCover accordionTable">
        <DataTable
          handleShowRowInfo={(row: any) => handleShowInfo(row)}
          headCells_={headerCells}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={notesdetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { notesdetails } = state;
  return {
    notesdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotesDetails: (payload: any) => dispatch(getNotesDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotestDetail);
