import api from '../api';

export class CoinActivityService {
  static async getCoinActivity(payload: any) {
    const {
      page,
      rowsPerPage,
      startDate,
      endDate,
      search,
      avatcoinActivity,
      transactionStatus,
      isSortByCreatedAt,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${startDate !== '' && startDate !== undefined && startDate !== null ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined && endDate !== null ? `&endDate=${endDate}` : ''}${avatcoinActivity !== 'Select' && avatcoinActivity !== '' && avatcoinActivity !== undefined ? `&transactionType=${avatcoinActivity}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${transactionStatus !== 'Select' && transactionStatus !== '' && transactionStatus !== undefined ? `&transactionStatus=${transactionStatus}` : ''}${isSortByCreatedAt !== '' ? `&isSortByCreated=${isSortByCreatedAt}` : ''}`;
    return api.getApiCall(`/api/v1/admins/transactions/avat-coins?${apiEndPoint}`);
  }

  static async exportToMail(payload: any) {
    const {
      page,
      rowsPerPage,
      search,
      startDate,
      endDate,
      avatcoinActivity,
      transactionStatus,
      isSortByCreatedAt,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${startDate !== '' && startDate !== undefined && startDate !== null ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined && endDate !== null ? `&endDate=${endDate}` : ''}${avatcoinActivity !== 'Select' && avatcoinActivity !== '' && avatcoinActivity !== undefined ? `&transactionType=${avatcoinActivity}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${transactionStatus !== 'Select' && transactionStatus !== '' && transactionStatus !== undefined ? `&transactionStatus=${transactionStatus}` : ''}${isSortByCreatedAt !== '' ? `&isSortByCreated=${isSortByCreatedAt}` : ''}&type=${payload.type}`;
    return api.getApiCall(`/api/v1/admins/transactions/avat-coins/exports?${apiEndPoint}`);
  }
}
