import api from '../api';

export class UserInfoService {
  static async getUserInfo(payload: any) {
    return api.getApiCall(`/api/v1/admins/users/${payload.data.id}`);
  }

  static async deleteUserApi(payload: any) {
    const data = await api.deleteApiCall('/api/v1/admins/users', { userId: payload });
    return data;
  }

  static blockUser(payload: any) {
    return api.putApiCall('/api/v1/admins/users/activate-deactivate', {
      userId: payload.id,
      status: payload.status,
    });
  }

  static async sendOTP() {
    const data = api.putApiCall('/api/v1/admins/users/send-otp', {});
    return data;
  }

  static async checkOTP(otp_: any, userId_: string) {
    const data = await api.getApiCall('/api/v1/admins/users/personal-details',
      { otp: otp_, userId: userId_ });
    return data;
  }

  static async getUserLoginInfo(payload: any) {
    const {
      page,
      rowsPerPage,
      id,
    } = payload;
    const data = await api.getApiCall(`/api/v1/admins/user-sessions?limit=${rowsPerPage + 1}&pageNo=${page}&userId=${id}`);
    return data;
  }

  static async sendEmail(payload: any) {
    const { userId, subject, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-user-notifications', {
      userId,
      subject,
      message,
    });
    return data;
  }

  static async sendPushNotification(payload: any) {
    const { userId, title, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-user-notifications', {
      userId,
      title,
      message,
    });
    return data;
  }

  static async sendSMS(payload: any) {
    const { userId, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-user-notifications', {
      userId,
      message,
    });
    return data;
  }

  static async userReportDetail(payload: any) {
    const { userId, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-user-notifications', {
      userId,
      message,
    });
    return data;
  }

  static async userInfoExports(payload: any) {
    return api.postApiCall('/api/v1/admins/users/exports/details', {
      type: 'xlsx',
      userId: payload.userId,
      optionCode: payload.optionCode,
      ...(payload.values && { detailKeys: payload.values }),
    });
  }
}
