import { Button } from '../../components';
import React from 'react';
import { ChannelInfoService } from '../../services';
import warning from '../../assets/svg/warning.svg';
import  '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchPostList: any;
  status: any;
}
const SubDomesManagementBlocked: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchPostList,
    status,
  } = props;

  const [blocked, setBlocked] = React.useState(false);
  const blockService = () => {
    ChannelInfoService.blockPost({ id, status }).then(() => {
      setBlocked(true);
    });
  };

  return (
    <>
      {!blocked ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            {status === 'blocked' ? 'Block' : 'Unblock'}
            {' '}
            Post
          </h2>
          <p>
            Are you sure you want to
            {' '}
            {status === 'blocked' ? 'block' : 'unblock'}
            {' '}
            this post?
          </p>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Post
            {' '}
            {status === 'blocked' ? 'blocked' : 'unblocked'}
            {' '}
            successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchPostList();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default SubDomesManagementBlocked;
