import { Button } from '../../components';
import React from 'react';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import  '../Modal/Modal.module.scss';
import warning from '../../assets/svg/warning.svg';

interface Props {
  handleModalClose: any,
  deleteService: any;
  setShowOverlay: any;
}

const SessionManagementDelete: React.FC<Props> = (props: Props) => {
  const { handleModalClose, deleteService, setShowOverlay } = props;
  return (
    <div className="delete_modalContainer">
      <img src={warning} alt="warning" />
      <h2>Delete Session </h2>
      <p>Are you sure you want to delete this Session? </p>
      <div className="modalButtonWrapper">
        <Button
          onClick={handleModalClose}
        >
          No
        </Button>
        <Button
          onClick={() => {
            setShowOverlay(true);
            deleteService();
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
export default SessionManagementDelete;
