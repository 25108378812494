import api from '../api';

export class ChannelInfoService {
  static async deleteChannelApi(payload: any) {
    return api.deleteApiCall('/api/v1/admins/channels', { channelId: payload });
  }

  static async deletePostApi(payload: string) {
    return api.deleteApiCall('/api/v1/admins/posts', { postId: payload });
  }

  static async blockChannelApi(id: string, status: string) {
    return api.putApiCall('api/v1/admins/channels/activate-deactivate', {
      channelId: id,
      status,
    });
  }

  static async getPostOfChannel(payload: any) {
    const { id, page, rowsPerPage } = payload;
    return api.getApiCall(`api/v1/admins/posts/${id}?limit=${rowsPerPage}&pageNo=${page}`);
  }

  static async getChannelReport(payload: any) {
    const {
      channelId, pageNumber, limit, isSortByUserName,
    } = payload;
    return api.getApiCall(`api/v1/admins/channels/reported?channelId=${channelId}&limit=${limit}&pageNo=${pageNumber}${isSortByUserName !== '' ? `&isSortByUserName=${isSortByUserName}` : ''}`);
  }

  static async sendEmail(payload: any) {
    const {
      channelId,
      subject,
      message,
      userId,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-channel-notifications', {
      channelId,
      subject,
      message,
      userId,
    });
    return data;
  }

  static async sendPushNotification(payload: any) {
    const {
      channelId,
      title,
      message,
      userId,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-channel-notifications', {
      channelId,
      title,
      message,
      userId,
    });
    return data;
  }

  static async sendSMS(payload: any) {
    const {
      channelId,
      message,
      userId,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-channel-notifications', {
      channelId,
      message,
      userId,
    });
    return data;
  }

  static async blockPost(payload: any) {
    return api.putApiCall('api/v1/admins/posts/activate-deactivate', {
      postId: payload.id,
      status: payload.status,
    });
  }

  static async exportChannelInfo(payload: any) {
    return api.postApiCall('/api/v1/admins/channels/details/exports', {
      type: 'xlsx',
      channelId: payload.channelId,
      optionCode: payload.optionCode,
      ...(payload.values && { detailKeys: payload.values }),
    });
  }
}
