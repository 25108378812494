import { Button } from '../../components';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { PriceManagementService } from '../../services';
import warning from '../../assets/svg/warning.svg';
import  '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchPriceList: any;
}

const PriceManagementDelete: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchPriceList,
  } = props;

  const [deleted, setDeleted] = React.useState(false);
  const deleteDomesService = () => {
    PriceManagementService
      .deletePriceEntry(id)
      .then(() => {
        setDeleted(true);
      });
  };

  return (
    <>
      {!deleted ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            Delete
          </h2>
          <p>
            Are you sure you want to delete this entry?
          </p>
          <Alert severity="warning" className="warningMsg">
            By deleting this range you’ll not get any commission whenever
            the users make transaction on this range.
          </Alert>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={deleteDomesService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Deleted successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchPriceList();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default PriceManagementDelete;
