import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { ExportPostInfoForm } from '../../forms';
import { PostDetailReportedUserInfoService } from '../../services';
import {
  CircularProgressLoader,
  Modal,
  PostDetailReportedUserInfo,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  PostCreatedBlocked,
  PostCreatedDelete,
  Accordion,
  Overlay,
  ToastBar,
} from '..';
import { getPostDetails } from '../../actions';
import deleteImg from '../../assets/svg/delete.svg';
import blockImg from '../../assets/svg/block.svg';
import exportImg from '../../assets/svg/export.svg';
import { dateFormatting } from '../../utils/dateMethods';
import unblockImg from '../../assets/svg/unblock.svg';
import  '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  postdetails: any;
  id: any;
  fetchPostDetails: any;
  intl: any;
}

const PostDetailsComponent: React.FC<Props> = (props: Props) => {
  const {
    postdetails,
    id,
    fetchPostDetails,
    intl,
  } = props;

  React.useEffect(() => {
    fetchPostDetails(id);
  }, [fetchPostDetails, id]);

  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [showPostExports, setShowPostExports] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  const handleModalClose = React.useCallback(() => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
    setShowPostExports(false);
  }, []);

  return (
    <>
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={() => setNotification({
              ...notification,
              show: false,
              message: '',
            })}
          />
        )
      }
      {showOverlay && <Overlay />}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className="modal_container">
          {
            showBlock && (
              <PostCreatedBlocked
                handleModalClose={handleModalClose}
                fetchPostList={() => { fetchPostDetails(id); }}
                id={id}
                status={postdetails.data.status === 'publish' ? 'blocked' : 'unblocked'}
              />
            )
          }
          {showDelete && (
            <PostCreatedDelete
              id={id}
              handleModalClose={handleModalClose}
              fetchPostList={() => { fetchPostDetails(id); }}
            />
          )}
          {showPostExports && (
            <ExportPostInfoForm
              handleFormSubmit={(formData: any) => {
                setShowOverlay(true);
                const exportType: any = [];
                Object.keys(formData).forEach((ele: any) => {
                  if (formData[ele]) {
                    exportType.push(ele);
                  }
                });
                PostDetailReportedUserInfoService
                  .exportPostInfo({
                    postId: id,
                    optionCode: exportType,
                    ...(
                      exportType.includes('detail') && {
                        values: {
                          Like: postdetails.data.likeCount,
                          Reactions: 0,
                          'Reports Count': postdetails.data.reportCount,
                          'Post type': postdetails.data?.postType.substring(0, 1).toUpperCase() + postdetails.data?.postType.substring(1, postdetails.data.postType.length),
                          Dome: postdetails.data.categoryId.name,
                        },
                      }
                    ),
                  })
                  .then(() => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: 'Details Exported Successfully',
                    });
                    handleModalClose();
                  }).catch((err: any) => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: err.response.data.message,
                    });
                  });
              }}
              handleCloseModal={handleModalClose}
            />
          )}
        </div>
      </Modal>
      {
        postdetails.data === null || postdetails.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className="cardBg containerBox p-20">
                <div className="headerActions">
                  <div />
                  <div className="iconWrapperbox">
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className="iconWrapper">
                        <img
                          role="presentation"
                          onClick={() => {
                            setShowModal(true);
                            setShowPostExports(true);
                          }}
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.DELETE}
                    >
                      <span className="iconWrapper">
                        <img
                          src={deleteImg}
                          alt="deleteImg"
                          role="presentation"
                          title="Delete"
                          onClick={() => {
                            setShowModal(true);
                            setShowDelete(true);
                          }}
                        />
                      </span>
                    </AccessControl>

                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      {postdetails.data.status === 'publish' ? (
                        <span className="iconWrapper">
                          <img
                            src={blockImg}
                            alt="blockImg"
                            title="Block"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      ) : (
                        <span className="iconWrapper">
                          <img
                            src={unblockImg}
                            alt="unblockImg"
                            title="unblock"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      )}
                    </AccessControl>
                  </div>
                </div>
                <div className="userInformatinc">
                  <div className="profileImgWrapper">
                    {postdetails.data.media[0].thumbnailImageUrl !== ''
                      && postdetails.data.media[0].thumbnailImageUrl !== undefined
                      ? (
                        <img
                          className="profileImg"
                          src={postdetails.data.media[0].thumbnailImageUrl}
                          alt="profileImg"
                        />
                      )
                      : <img src={postdetails.data.media[0].thumbnailImageUrl} alt="profile" />}
                  </div>
                  <div className="userInfoinner">
                    <div>
                      <h2>Title:</h2>
                      <h2>{postdetails.data.channelName}</h2>
                      <h3>{intl.formatMessage({ id: 'ChannelInfo.label.description' })}</h3>
                      <p>
                        {postdetails.data.description}
                      </p>
                    </div>
                    <div className="right_container">
                      <h3>
                        {intl.formatMessage({ id: 'ChannelInfo.label.createdOn' })}
                        :
                        <span>
                          {dateFormatting(postdetails.data.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="column">
                  <h2>Other Media</h2>
                  <data className="imagewrapper">
                    {postdetails.data.media.map((ele: any) => {
                      if (ele.mediaType === 'image') {
                        return <img width="320" className="aspect_1 img_cover" src={ele.filePath} alt={ele.filePath} />;
                      }
                      if (ele.mediaType === 'video') {
                        return (
                          <video width="320" className="aspect_1" controls>
                            <source src={ele.filePath} type="video/mp4" />
                            <track src="" kind="captions" srcLang="en" label="english_captions" />
                            Your browser does not support the video tag.
                          </video>
                        );
                      }
                      return null;
                    })}
                  </data>
                </div>
                <div className="postDetails">
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Like
                      </p>
                      <p className="count_backround">
                        {postdetails.data.likeCount}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Reactions
                      </p>
                      <p className="count_backround">
                        0
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Reports Count
                      </p>
                      <p className="count_backround">
                        {postdetails.data.reportCount}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Post type
                      </p>
                      <p className="count_backround">
                        {postdetails.data?.postType.substring(0, 1).toUpperCase() + postdetails.data?.postType.substring(1, postdetails.data.postType.length)}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Dome
                      </p>
                      <p className="count_backround">
                        {postdetails.data.categoryId.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="Post Report Detail">
                <PostDetailReportedUserInfo id={id} />
              </Accordion>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { postdetails } = state;
  return {
    postdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPostDetails: (payload: any) => dispatch(getPostDetails(payload)),
});

const PostDetails = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PostDetailsComponent);

export default PostDetails;
