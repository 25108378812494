import React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { UserManagementService } from "../../services";
import { exportToCSV, exportToExcel } from "../../utils/exportToExcel";
import DataTable from "../Datatable";
import  "../LayoutWrapperMain/LayoutWrapperMain.module.scss";
import UserManagementFilter from "../../forms/UserManagementFilter";
import deleteIcon from '../../assets/svg/delete.svg';
import {
  BlockUserInfo,
  DeleteUserInfo,
  hasAccess,
  Modal,
  Overlay,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ShowTable,
  ToastBar,
} from "..";
import { getUserList } from "../../actions";
import avatus from "../../assets/svg/image-placeholder.png";
import block from "../../assets/svg/block.svg";
import unblock from "../../assets/svg/unblock.svg";
import { dateFormatting } from "../../utils/dateMethods";

interface Props {
  userlist: any;
  fetchUserList: any;
}

const Usermanagement: React.FC<Props> = (props: Props) => {
  const { userlist, fetchUserList } = props;
  const { search } = useLocation();
  const { active } = queryString.parse(search);
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    startDate: "",
    endDate: "",
    userType: active ? "active" : "",
    search: "",
    isSortByUserName: "",
    isSortByRegister: "",
  });
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showRows, setShowRows] = React.useState({
    show: false,
    data: [],
  });
  const [showModal, setShowModal] = React.useState(false);
  const [blocked, setBlocked] = React.useState(false);
  const [unBlocked, setUnBlocked] = React.useState(false);
  const [blockedUserId, setBlockedUserId] = React.useState("");
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [notification, setNotification] = React.useState({
    show: false,
    message: "",
  });
  React.useEffect(() => {
    fetchUserList(filter);
  }, [fetchUserList, filter]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = userlist.data ? userlist.data["Pagination-Count"] : 0;
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilter({
      ...filter,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };
  const handleBlockUnblock = (bloc: boolean | 'delete', id: string) => {
    setShowModal(true);
    switch (bloc) {
      case true:
        setUnBlocked(true);
        break;
      case false:
        setBlocked(true);
        break;
      case 'delete':
        // Handle the 'delete' case
        setDeleted({ show: true, id });
        break;
      default:
        // Handle other cases if needed
        break;
    }

    setBlockedUserId(id);
    // if (bloc) {
    //   setUnBlocked(true);
    // } else setBlocked(true);
    // setBlockedUserId(id);
  };

  const handleSubmitUserFilter = (data: any) => {
    if (
      data.endDate !== null ||
      data.search !== "" ||
      data.startDate !== null ||
      data.userType !== ""
    ) {
      setFilter({
        ...filter,
        startDate: data.startDate ? data.startDate.utc() : "",
        endDate: data.endDate ? data.endDate.utc() : "",
        search: data.search.label,
        userType: data.userType,
        page: 0,
      });
    }
  };
  function createData(data: any, index: number): any {
    const id = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id,
      username: data.username,
      avatar: data.avatusImage ? (
        <img src={data.avatusImage} alt="x" />
      ) : (
        <img src={avatus} alt="x" />
      ),
      name: data.fullNameFlag ? "Yes" : "No",
      email: data.emailFlag ? "Yes" : "No",
      phoneFlag: data.phoneFlag ? "Yes" : "No",
      dob: data.ageFlag ? "Yes" : "No",
      gender: data.genderFlag ? "Yes" : "No",
      nationality: data.nationalityFlag ? "Yes" : "No",
      usertype:
        data.status === "deactived"
          ? "Deactivated"
          : data.status === "blocked"
            ? "Blocked"
            : data.registerationStatus === "completed"
              ? "Active"
              : "Pending",
      regon: dateFormatting(data.createdAt),
      ...(hasAccess(PERMISSION_KEYS.USER_MANAGEMENT, PERMISSION_TYPES.EDIT) && {
        action: (
          <>
            {data.status !== "blocked" ? (
              <img
                src={block}
                role="presentation"
                alt="block"
                title="Block"
                width="35"
                height="35"
                onClick={() => handleBlockUnblock(false, id)}
              />
            ) : (
              <img
                src={unblock}
                role="presentation"
                alt="unblock"
                title="Unblock"
                width="35"
                height="35"
                onClick={() => handleBlockUnblock(true, id)}
              />
            )}
            <img
              src={deleteIcon}
              role="presentation"
              alt="delete"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleBlockUnblock('delete', id)}
            />
          </>
        ),
      }),
    };
  }
  const rows: any = [];
  if (userlist.data !== null && userlist.data) {
    userlist.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  const headCells: any = [
    {
      id: "sno",
      sort: false,
      disablePadding: true,
      label: "S.No",
    },
    {
      id: "username",
      sort: true,
      disablePadding: false,
      label: "Username",
    },
    {
      id: "avatar",
      sort: false,
      disablePadding: false,
      label: "Avatar",
    },
    {
      id: "name",
      sort: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email",
      sort: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone",
      sort: false,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "dob",
      sort: false,
      disablePadding: false,
      label: "DOB",
    },
    {
      id: "gender",
      sort: false,
      disablePadding: false,
      label: "Gender",
    },
    {
      id: "nationality",
      sort: false,
      disablePadding: false,
      label: "Nationality",
    },
    {
      id: "usertype",
      sort: false,
      disablePadding: false,
      label: "User Type",
    },
    {
      id: "regon",
      sort: true,
      disablePadding: false,
      label: "Registered On",
    },
    ...(hasAccess(PERMISSION_KEYS.USER_MANAGEMENT, PERMISSION_TYPES.EDIT)
      ? [
        {
          id: "action",
          sort: false,
          disablePadding: false,
          label: "Action",
        },
      ]
      : []),
  ];
  const handleShowInfo = (row: any) => {
    if (userlist.data !== null && userlist.data) {
      setShowModal(true);
      const headerRows: any = [
        row.sno,
        userlist.data.data[row.sno - 1].username,
        "",
        userlist.data.data[row.sno - 1].fullNameFlag ? "Yes" : "No",
        userlist.data.data[row.sno - 1].emailFlag ? "Yes" : "No",
        userlist.data.data[row.sno - 1].phoneFlag ? "Yes" : "No",
        userlist.data.data[row.sno - 1].ageFlag ? "Yes" : "No",
        userlist.data.data[row.sno - 1].genderFlag ? "Yes" : "No",
        userlist.data.data[row.sno - 1].nationalityFlag ? "Yes" : "No",
        userlist.data.data[row.sno - 1].status === "deactived"
          ? "Deactivated"
          : userlist.data.data[row.sno - 1].status === "blocked"
            ? "Blocked"
            : userlist.data.data[row.sno - 1].registerationStatus === "completed"
              ? "Active"
              : "Pending",
        dateFormatting(userlist.data.data[row.sno - 1].createdAt),
      ];
      const data: any = [];
      headCells.forEach((values: any, index: number) => {
        if (values.label !== "Action") {
          data.push({
            key: values.label,
            values: headerRows[index],
          });
        }
      });
      setShowRows({
        show: true,
        data,
      });
    }
  };

  const handleClick = (event: any, name: any) => {

    if (event.target.localName === "img" && event.target.alt === "block") {
      handleBlockUnblock(false, name.id);
    } else if (
      event.target.localName === "img" &&
      event.target.alt === "unblock"
    ) {
      handleBlockUnblock(true, name.id);
    } else if (event.target.localName === "input") {
      handleSelectedClick(event, name);
    }
    else if (event.target.localName === "img" && event.target.alt === "delete") {
      handleBlockUnblock(event, name.id);
    }
    else if (event.target.outerText !== "View")
      navigate(`/usermanagement/userdetails/${name.id}`);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setUnBlocked(false);
    setDeleted(false);
    setShowRows({
      show: false,
      data: [],
    });
    if (deleted.show && deleted.id !== null) {
      fetchUserList({ ...filter, page: filter.page });
    } else {
      fetchUserList(filter);
    }

  };
  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === "username") {
      setFilter({
        ...filter,
        page: 0,
        isSortByUserName: order === "asc" ? "1" : "-1",
      });
    }
    if (orderBy === "regon") {
      setFilter({
        ...filter,
        page: 0,
        isSortByRegister: order === "asc" ? "1" : "-1",
      });
    }
  };
  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === "XLSX") {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Username: ele.username,
            Avatar: ele.avatar,
            Name: ele.name,
            Email: ele.email,
            Phone: ele.phoneFlag,
            DateOfBirth: ele.dob,
            Gender: ele.gender,
            Nationality: ele.nationality,
            UserType: ele.usertype,
            RegisteredOn: ele.regon,
          });
        });
        exportToExcel(selectedRowDataModified, "UserManagementData");
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: "Excel successfully downloaded.",
        });
      } else {
        UserManagementService.exportUserListToMail({ ...filter, type: "xlsx" })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: "Excel has been sent on mail.",
            });
          })
          .catch((err: any) => {
            setLoaderExport(false);
            setNotification({
              show: true,
              message: err.response.data.message,
            });
          });
      }
    } else if (exportType === "CSV") {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            dob: ele.dob,
            email: ele.email,
            gender: ele.gender,
            id: ele.id,
            name: ele.name,
            nationality: ele.nationality,
            phoneFlag: ele.phoneFlag,
            regon: ele.regon,
            sno: ele.sno,
            username: ele.username,
            usertype: ele.usertype,
          });
        });
        exportToCSV(selectedRowDataModified, "UserManagementData");
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: "CSV successfully downloaded.",
        });
      } else {
        UserManagementService.exportUserListToMail({ ...filter, type: "csv" })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: "CSV has been sent on mail.",
            });
          })
          .catch((err: any) => {
            setLoaderExport(false);
            setNotification({
              show: true,
              message: err.response.data.message,
            });
          });
      }
    }
  };
  const handleResetSorts = () => {
    setFilter({
      ...filter,
      startDate: "",
      endDate: "",
      userType: "",
      search: "",
      isSortByUserName: "",
      isSortByRegister: "",
    });
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        showOnClose={false}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          deleted.show && (
            <DeleteUserInfo // navigate('/usermanagement');
              setShowOverlay={setShowOverlay}
              fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
              handleModalClose={handleModalClose}
              id={deleted.id}
            />
          )
        }
        {blocked && (
          <BlockUserInfo
            id={blockedUserId}
            setShowOverlay={setShowOverlay}
            fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
            callId
            handleModalClose={handleModalClose}
            userInfoStatus="unblocked"
          />
        )}
        {unBlocked && (
          <BlockUserInfo
            id={blockedUserId}
            setShowOverlay={setShowOverlay}
            callId
            fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
            handleModalClose={handleModalClose}
            userInfoStatus="blocked"
          />
        )}
        {showRows.show && <ShowTable headerRows={showRows.data} />}
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() =>
            setNotification({
              ...notification,
              show: false,
              message: "",
            })
          }
        />
      )}
      <div>
        <UserManagementFilter
          handleFormSubmit={handleSubmitUserFilter}
          handleExport={handleExport}
          handleResetSorts={handleResetSorts}
          loaderExport={loaderExport}
        />
        {userlist.loadingError ? (
          <ToastBar
            message={userlist.loadingError}
            handleCloseMethod={() => null}
          />
        ) : (
          <div className="dataTableCover">

            <DataTable
              // handleShowRowInfo={(row: any) => handleShowInfo(row)}
              headCells_={headCells}
              rows_={rows}
              rowClick={handleClick}
              selected={selected}
              setSelected={setSelected}
              page={filter.page}
              rowsPerPage={filter.rowsPerPage}
              handleChangePage={handleChangePage}
              setSelectedRows={setSelectedRows}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              loading={userlist.loadingInProgress}
              handleSort={handleSort}
              count={count}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { usermanagement } = state;
  return {
    userlist: usermanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserList: (payload: any) => dispatch(getUserList(payload)),
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(Usermanagement)
);
