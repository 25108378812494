import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Chip } from '@material-ui/core';
import avatcoin from '../../assets/svg/avatcoin.svg';
import silvercoin from '../../assets/images/silvercoin.png';
import { ExportEventInfoForm } from '../../forms';
import { LiveStreamingManagementInfo } from '../../services';
import {
  Button,
  CircularProgressLoader,
  Modal,
  EventDetails,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  LiveStreamingBlocked,
  LiveStreamDelete,
  Accordion,
  Overlay,
  NotificationDetailsLiveStream,
  ParticipantDetails,
  ToastBar,
  LiveStreamSendNotification,
} from '..';
import { livestreaminfo } from '../../actions';
import profileImg from '../../assets/svg/profilepic.svg';
import deleteImg from '../../assets/svg/delete.svg';
import blockImg from '../../assets/svg/block.svg';
import exportImg from '../../assets/svg/export.svg';
import { dateFormatting, getDateAndTime } from '../../utils/dateMethods';
import unblockImg from '../../assets/svg/unblock.svg';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  livestreamInfo: any;
  id: any;
  fetchLiveStreamInfo: any;
  intl: any;
}

const LiveStreamInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    livestreamInfo,
    id,
    fetchLiveStreamInfo,
    intl,
  } = props;

  React.useEffect(() => {
    fetchLiveStreamInfo(id);
  }, [fetchLiveStreamInfo, id]);
  const navigate = useNavigate();

  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [showUnBlock, setShowUnBlock] = React.useState(false);
  const [showExport, setShowExport] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const [sendNotification, setSendNotification] = React.useState(false);

  const handleModalClose = () => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
    setShowUnBlock(false);
    setShowExport(false);
    setSendNotification(false);
  };

  const handleEventString = (evt: string) => {
    if (evt === 'private_event_paid') {
      return 'Private Event Paid';
    }
    if (evt === 'private_event_free') {
      return 'Private Event Free';
    }
    if (evt === 'public_event_free') {
      return 'Public Event Free';
    }
    if (evt === 'public_event_paid') {
      return 'Public Event Paid';
    }
    return '';
  };

  const handleTimeDifference = (date1: any, date2: any) => {
    const diff = new Date(date2).getTime() > new Date(date1).getTime() ? new Date(date2).getTime() - new Date(date1).getTime() : new Date(date1).getTime() - new Date(date2).getTime();
    let msec = diff;
    const hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    const mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    const ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    return (`${hh}h:${mm}m:${ss}s`);
  };

  return (
    <>
      {showOverlay && <Overlay />}
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={() => {
              setNotification({
                message: '',
                show: false,
              });
            }}
          />
        )
      }
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className="modal_container">
          {
            showBlock && (
              <LiveStreamingBlocked
                handleModalClose={handleModalClose}
                id={id}
                fetchLiveStreaming={() => fetchLiveStreamInfo(id)}
                status="blocked"
              />
            )
          }
          {showDelete && (
            <LiveStreamDelete
              handleModalClose={handleModalClose}
              id={id}
              fetchLiveStreaming={() => navigate('/livestreaming')}
            />
          )}
          {
            showUnBlock && (
              <>
                <p>Event cancelled</p>
                <Button onClick={handleModalClose}>Ok</Button>
              </>
            )
          }
          {
            showExport && (
              <ExportEventInfoForm
                handleFormSubmit={(formdata: any) => {
                  setShowOverlay(true);
                  const exportTypes: any = [];
                  Object.keys(formdata).forEach((ele: any) => {
                    if (formdata[ele]) {
                      exportTypes.push(ele);
                    }
                  });
                  LiveStreamingManagementInfo.exportEventInfo({
                    eventId: id,
                    optionCode: exportTypes,
                    ...(
                      exportTypes.includes('detail') && {
                        values: {
                          Username: livestreamInfo.data.user?.username,
                          Likes: 0,
                          Reactions: 0,
                          'Reports Count': livestreamInfo.data.reportCount,
                          Dome: livestreamInfo.data?.categoryId?.name,
                          'Live type': handleEventString(livestreamInfo.data.eventType),
                          'Live date & time': getDateAndTime(livestreamInfo.data.startTime),
                          'Live Status': (livestreamInfo.data.status === 'blocked' ? 'Blocked'
                            : (livestreamInfo.data.status === 'live' ? 'Ongoing' : (livestreamInfo.data.status === 'completed'
                              ? 'Ended' : livestreamInfo.data.status === 'canceled' ? 'Cancelled' : livestreamInfo.data.status[0].toUpperCase() + livestreamInfo.data.status.substring(1, livestreamInfo.data.status.length)))),
                          'Live duration': livestreamInfo.data.status === 'completed' ? (
                            livestreamInfo.data.isAutoEnd
                              ? handleTimeDifference(livestreamInfo.data.startTime, livestreamInfo.data.autoEndTime)
                              : handleTimeDifference(livestreamInfo.data.startTime, livestreamInfo.data.eventCompletedDateTime))
                            : livestreamInfo.data.eventCompletedDateTime ? handleTimeDifference(livestreamInfo.data.startTime, livestreamInfo.data.eventCompletedDateTime) : '--',
                          'Number of participants': livestreamInfo.data.joiningUserCount ? livestreamInfo.data.joiningUserCount : '0',
                          'Avatcoin Earned': !livestreamInfo.data.isPaidEvent ? 0 : (
                            livestreamInfo.data.status === 'completed'
                              ? livestreamInfo.data.earned : (livestreamInfo.data.status === 'live' || livestreamInfo.data.status === 'scheduled' ? `${livestreamInfo.data.escrowed} (Escrowed)` : 0)
                          ),
                          'Accepted User Count': livestreamInfo.data.eventType.includes('public') ? '--' : livestreamInfo.data.acceptedUserCount,
                          'Pricing Plan': livestreamInfo.data.isPaidEvent ? `Paid ${livestreamInfo.data.goldPrice}` : 'Free',
                          'Discounted Pricing Plan': `Gold (${livestreamInfo.data.discountedPrice.gold}) + Silver (${livestreamInfo.data.discountedPrice.gold})`,
                          'Virtual gifts received': 0,
                        },
                      }
                    ),
                  }).then(() => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: 'Details Exported Successfully',
                    });
                    handleModalClose();
                  }).catch((err: any) => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: err.response.data.message,
                    });
                  });
                }}
                handleCloseModal={handleModalClose}
              />
            )
          }
          {
            sendNotification
            && (
              <LiveStreamSendNotification
                handleModalClose={handleModalClose}
                id={id}
                liveStreamInfo={livestreamInfo}
                setShowOverlay={setShowOverlay}
              />
            )
          }
        </div>
      </Modal>
      {
        livestreamInfo.data === null || livestreamInfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className="cardBg containerBox p-20">
                <div className="headerActions">
                  <div>
                    <Button
                      className="button"
                      onClick={() => {
                        setShowModal(true);
                        setSendNotification(true);
                      }}
                    >
                      {intl.formatMessage({ id: 'ChannelInfo.label.SendNotification' })}
                    </Button>
                  </div>
                  <div className="iconWrapperbox">
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className="iconWrapper">
                        <img
                          role="presentation"
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                          onClick={() => {
                            setShowModal(true);
                            setShowExport(true);
                          }}
                        />
                      </span>
                    </AccessControl>
                    {
                      livestreamInfo.data.status !== 'completed' &&
                      livestreamInfo.data.status !== 'canceled' &&
                      <AccessControl
                        permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                        accessType={PERMISSION_TYPES.DELETE}
                      >
                        <span className="iconWrapper">
                          <img
                            src={deleteImg}
                            alt="deleteImg"
                            role="presentation"
                            title="Delete"
                            onClick={() => {
                              setShowModal(true);
                              setShowDelete(true);
                            }}
                          />
                        </span>
                      </AccessControl>
                    }
                    {
                      livestreamInfo.data.status !== 'completed' &&
                      livestreamInfo.data.status !== 'canceled' && <AccessControl
                        permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                        accessType={PERMISSION_TYPES.EDIT}
                      >

                        {
                          livestreamInfo.data.status !== 'blocked' ? (
                            <span className="iconWrapper">
                              <img
                                src={blockImg}
                                alt="blockImg"
                                title="Block"
                                role="presentation"
                                onClick={() => {
                                  setShowModal(true);
                                  setShowBlock(true);
                                }}
                              />
                            </span>
                          ) : (
                            <span className="iconWrapper">
                              <img
                                src={unblockImg}
                                alt="unblockImg"
                                title="unblock"
                                role="presentation"
                                onClick={() => {
                                  setShowModal(true);
                                  setShowUnBlock(true);
                                }}
                              />
                            </span>
                          )
                        }
                      </AccessControl>
                    }
                  </div>
                </div>
                <div className="userInformatinc">
                  <div className="profileImgWrapper">
                    {livestreamInfo.data.imageUrl !== ''
                      && livestreamInfo.data.imageUrl !== undefined
                      ? (
                        <img
                          className="profileImg"
                          src={livestreamInfo.data.imageUrl}
                          alt="profileImg"
                        />
                      )
                      : <img src={profileImg} alt="profile" />}
                  </div>
                  <div className="userInfoinner">
                    <div>
                      <h2>{livestreamInfo.data.title}</h2>
                      <h3>Description</h3>
                      <p>
                        {livestreamInfo.data.description}
                      </p>
                    </div>
                    <div className="right_container">
                      <h3>
                        Created On:
                        {' '}
                        <span>
                          {dateFormatting(livestreamInfo.data.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="postDetails">
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Username
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.user?.username} />
                      </div>
                    </div>
                    {/* <div>
                      <p className="heading_styles">
                        Likes
                      </p>
                      <div className="badge">
                        <Chip label={0} />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Reactions
                      </p>
                      <div className="badge">
                        <Chip label={0} />
                      </div>
                    </div> */}
                    <div>
                      <p className="heading_styles">
                        Reports Count
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.reportCount} />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Dome
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data?.categoryId?.name} />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Live type
                      </p>
                      <div className="badge">
                        <Chip label={handleEventString(livestreamInfo.data.eventType)} />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Live date & time
                      </p>
                      <div className="badge">
                        <Chip label={getDateAndTime(livestreamInfo.data.startTime)} />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Live Status
                      </p>
                      <div className="badge">
                        <Chip label={(livestreamInfo.data.status === 'blocked' ? 'Blocked'
                          : (livestreamInfo.data.status === 'live' ? 'Ongoing' : (livestreamInfo.data.status === 'completed'
                            ? 'Ended' : livestreamInfo.data.status === 'canceled' ? 'Cancelled' : livestreamInfo.data.status[0].toUpperCase() + livestreamInfo.data.status.substring(1, livestreamInfo.data.status.length))))}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Live duration
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.status === 'completed' ? (
                          livestreamInfo.data.isAutoEnd
                            ? handleTimeDifference(livestreamInfo.data.startTime, livestreamInfo.data.autoEndTime)
                            : handleTimeDifference(livestreamInfo.data.startTime, livestreamInfo.data.eventCompletedDateTime))
                          : livestreamInfo.data.status === 'live' ? handleTimeDifference(livestreamInfo.data.startTime, new Date().toUTCString())
                            : livestreamInfo.data.eventCompletedDateTime ? handleTimeDifference(livestreamInfo.data.startTime, livestreamInfo.data.eventCompletedDateTime) : '--'}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Number of participants
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.joiningUserCount ? livestreamInfo.data.joiningUserCount : '0'} />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Avatcoin Earned
                      </p>
                      <div className="badge">
                        <Chip label={(
                          <div className="c_price">
                            <img src={avatcoin} alt="avatcoin" />
                            <span>
                              {
                                !livestreamInfo.data.isPaidEvent ? 0 : (
                                  livestreamInfo.data.status === 'completed'
                                    ? livestreamInfo.data.earned : (livestreamInfo.data.status === 'live' || livestreamInfo.data.status === 'scheduled' ? `${livestreamInfo.data.escrowed} (Escrowed)` : 0)
                                )
                              }

                            </span>
                          </div>
                        )}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Accepted User Count
                      </p>
                      <div className="badge">
                        {livestreamInfo.data.eventType.includes('public') ? <Chip label="--" /> : <Chip label={<span>{livestreamInfo.data.acceptedUserCount}</span>} />}
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Pricing Plan
                      </p>
                      <div className="badge">
                        <Chip label={
                          livestreamInfo.data.isPaidEvent
                            ? (
                              <div className="c_price">
                                Paid &nbsp;
                                <img src={avatcoin} alt="avatcoin" />
                                {' '}
                                {livestreamInfo.data.goldPrice}
                              </div>
                            )
                            : 'Free'
                        }
                        />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Discounted Pricing Plan
                      </p>
                      <div className="badge">
                        <Chip label={(
                          <div style={{ display: 'flex' }}>
                            <div>
                              <div className="c_price">
                                <img src={avatcoin} alt="avatcoin" />
                                <span>{livestreamInfo.data.discountedPrice.gold}</span>
                                <span className="separator">+</span>
                                <img src={silvercoin} alt="avatcoin" />
                                <span>{livestreamInfo.data.discountedPrice.silver}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Virtual gifts received
                      </p>
                      <div className="badge">
                        <Chip label={(
                          <div className="c_price">
                            <img src={avatcoin} alt="avatcoin" />
                            <span>0</span>
                          </div>
                        )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="Participants Detail">
                <ParticipantDetails id={id} />
              </Accordion>
              <Accordion heading="Event Report Detail">
                <EventDetails id={id} />
              </Accordion>
              <Accordion heading="Notification Details">
                <NotificationDetailsLiveStream id={id} />
              </Accordion>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({
  livestreamInfo: state.livestreaminfo,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchLiveStreamInfo: (payload: any) => dispatch(livestreaminfo(payload)),
});

const LiveStreamInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(LiveStreamInfoComponent);

export default LiveStreamInfo;
