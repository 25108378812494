import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dateFormatting } from '../../utils/dateMethods';
import {
  AccessControl,
  Button,
  hasAccess,
  Modal,
  NotificationDelete,
  NotificationManagementShowSave,
  Overlay,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ToastBar,
} from '../../components';
import { Notification } from '../../services';
import { getNotificationManagement } from '../../actions';
import {
  NotificationManagementFilter,
} from '../../forms';
import { exportToCSV, exportToExcel } from '../../utils/exportToExcel';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import avatus from '../../assets/svg/image-placeholder.png';
import DataTable from '../Datatable';
import deleteIcon from '../../assets/svg/delete.svg';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import './notification.module.scss';

interface Props {
  notificationmanagement: any;
  fetchNotificationList: any;
}

const NotificationManagement: React.FC<Props> = (props: Props) => {
  const {
    notificationmanagement,
    fetchNotificationList,
  } = props;
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState<any>({
    platform: '',
    startDate: '',
    endDate: '',
    search: '',
    userType: '',
    page: 0,
    rowsPerPage: 10,
    isSortByCreated: '',
    isSortBySentAt: '',
    sendBy: '',
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [deleted, setDeleted] = React.useState({
    show: false,
    id: '',
  });
  const [showResend, setShowResend] = React.useState<any>({
    show: false,
    id: null,
    status: '',
  });
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  React.useEffect(() => {
    fetchNotificationList(filter);
  }, [fetchNotificationList, filter]);
  const hideActions = () => hasAccess(PERMISSION_KEYS.NOTIFICATION_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.NOTIFICATION_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.NOTIFICATION_MANAGEMENT, PERMISSION_TYPES.DELETE);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const count = notificationmanagement.data.length !== 0 ? notificationmanagement.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitFilter = (data: any) => {
    if (data.search !== ''
      || data.Platform !== ''
      || data.endDate !== null
      || data.startDate !== null
      || data.userType !== ''
      || data.notificationType !== '') {
      setFilter({
        ...filter,
        platform: data.Platform,
        startDate: data.startDate ? data.startDate.utc() : '',
        endDate: data.endDate ? data.endDate.utc() : '',
        search: data.search.label,
        userType: data.userType,
        sendBy: data.notificationType,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowResend({
      show: false,
      id: null,
      status: '',
    });
    setDeleted({
      show: false,
      id: '',
    });
  };

  const handleSendBy = (sendBy: string) => {
    if (sendBy === 'sms') {
      return 'SMS';
    }
    if (sendBy === 'push') {
      return 'Push';
    }
    return 'Email';
  };

  const getPlatformString = (platform: any) => {
    if (platform.length === 1) {
      if (platform[0] === '1') return 'Android';
      if (platform[0] === '2') return 'IOS';
      if (platform[0] === '3') return 'Web';
    } else if (platform.length === 2) {
      if (platform[0] === '1' && platform[1] === '2') return 'Android & IOS';
      if (platform[0] === '2' && platform[1] === '3') return 'IOS & Web';
      if (platform[0] === '3' && platform[1] === '1') return 'Web & Android';
      if (platform[0] === '2' && platform[1] === '1') return 'IOS & Android';
      if (platform[0] === '1' && platform[1] === '3') return 'Android & Web';
      if (platform[0] === '3' && platform[1] === '2') return 'Web & IOS';
    }
    return 'Android, IOS & Web';
  };

  const handleBlockUnblock = (modalValue: any, id: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'delete':
        setDeleted({ ...deleted, show: true, id });
        break;
      case 'edit':
        navigate(`/notification/editNotification/${id}`);
        break;
      default:
        break;
    }
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    const image = data.image === null || data.image === '' || data.sendBy === 'email' || data.sendBy === 'sms' ? <img src={avatus} alt="img" /> : <img src={data.image} alt="img" />;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      title: data.sendBy === 'email' ? (
        <div title={data.subject.replace(/<[^>]+>/g, '')}>
          {data.subject.replace(/<[^>]+>/g, '').substring(0, 20)}
          ...
          {' '}
        </div>
      ) : (data.sendBy === 'sms' ? 'SMS' : (data.title.length > 20 ? (
        <div title={data.title.replace(/<[^>]+>/g, '')}>
          {data.title.replace(/<[^>]+>/g, '').substring(0, 20)}
          ...
          {' '}
        </div>
      ) : data.title.replace(/<[^>]+>/g, ''))),
      desc: data.message.length > 20 ? (
        <div title={data.message.replace(/<[^>]+>/g, '')}>
          {data.message.replace(/<[^>]+>/g, '').substring(0, 20)}
          ...
          {' '}
        </div>
      ) : data.message.replace(/<[^>]+>/g, ''),
      image,
      sentby: handleSendBy(data.sendBy),
      platform: getPlatformString(data.platforms),
      createdAt: dateFormatting(data.createdAt),
      status: data.status,
      senton: dateFormatting(data.updatedAt),
      ...(hideActions() && {
        action: (
          <div className="actionBtnsWrapper">
            <AccessControl
              permissionKey={PERMISSION_KEYS.NOTIFICATION_MANAGEMENT}
              accessType={PERMISSION_TYPES.EDIT}
            >
              <div className="actionBtnCover">
                <p
                  role="presentation"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => navigate(`/notification/editNotification/${idS}`)}
                >
                  {data.status === 'draft' ? 'Send' : 'Resend'}
                </p>
              </div>
            </AccessControl>
            {data.status === 'draft' && (
              <AccessControl
                permissionKey={PERMISSION_KEYS.NOTIFICATION_MANAGEMENT}
                accessType={PERMISSION_TYPES.DELETE}
              >
                <div className="actionBtnCover deleteIcon">
                  <img
                    src={deleteIcon}
                    role="presentation"
                    alt="delete"
                    width="35"
                    title="Delete"
                    height="35"
                    onClick={() => handleBlockUnblock('delete', idS)}
                  />
                </div>
              </AccessControl>
            )}
          </div>),
      }),
    };
  }

  const rows: any = [];

  if (notificationmanagement.data.length !== 0 && notificationmanagement.data) {
    notificationmanagement.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'title', label: 'Title', sort: false,
    },
    {
      id: 'desc', label: 'Description', sort: false,
    },
    {
      id: 'image', label: 'Image', sort: false,
    },
    {
      id: 'sentby', label: 'Notification Type', sort: false,
    },
    {
      id: 'platform', label: 'Platform Type', sort: false,
    },
    {
      id: 'createdAt', label: 'Added On', sort: true,
    },
    {
      id: 'status', label: 'Status', sort: false,
    },
    {
      id: 'senton', label: 'Sent On', sort: true,
    },
    {
      id: 'action', label: 'Action', sort: false,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'senton') {
      setFilter({
        ...filter,
        page: 0,
        isSortBySentAt: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      platform: '',
      startDate: '',
      endDate: '',
      search: '',
      userType: '',
      page: 0,
      rowsPerPage: 10,
      isSortByCreated: '',
      isSortBySentAt: '',
      sendBy: '',
    });
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Title: ele.sentby === 'email'
              ? ele.subject.replace(/<[^>]+>/g, '')
              : (
                ele.sentby === 'sms'
                  ? 'SMS' : ele.title.props ? ele.title.props.title.replace(/<[^>]+>/g, '') : ele.title),
            Description: ele.desc,
            Image: ele.image.props.src,
            SentBy: ele.sentby,
            Platform: ele.platform,
            AddedOn: ele.createdAt,
            Status: ele.status,
            SentOn: ele.senton,
          });
        });
        exportToExcel(selectedRowDataModified, 'NotificationManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        Notification.export({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        }).catch((err: any) => {
          setLoaderExport(false);
          setNotification({
            show: true,
            message: err.response.data.message,
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Title: ele.sentby === 'email'
              ? ele.subject.replace(/<[^>]+>/g, '')
              : (
                ele.sentby === 'sms'
                  ? 'SMS' : ele.title.props ? ele.title.props.title.replace(/<[^>]+>/g, '') : ele.title),
            Description: ele.desc,
            Image: ele.image.props.src,
            SentBy: ele.sentby,
            Platform: ele.platform,
            AddedOn: ele.createdAt,
            Status: ele.status,
            SentOn: ele.senton,
          });
        });
        exportToCSV(selectedRowDataModified, 'NotificationManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        Notification.export({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        }).catch((err: any) => {
          setLoaderExport(false);
          setNotification({
            show: true,
            message: err.response.data.message,
          });
        });
      }
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          showResend.show && (
            <NotificationManagementShowSave
              handleModalClose={handleModalClose}
              id={showResend.id}
              fetchAdminList={() => setFilter({ page: 0 })}
              setShowOverlay={setShowOverlay}
              status={showResend.status}
            />
          )
        }
        {
          deleted.show && (
            <NotificationDelete
              handleModalClose={handleModalClose}
              id={deleted.id}
              fetchList={() => setFilter({ ...filter, page: 0 })}
              setShowOverlay={setShowOverlay}
            />
          )
        }
      </Modal>
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={() => setNotification({
              ...notification,
              show: false,
              message: '',
            })}
          />
        )
      }
      <div>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT}
          accessType={PERMISSION_TYPES.ADD}
        >
          <div className="topButton">
            <Button
              onClick={() => navigate('/notification/addNotification')}
            >
              Add Notification
            </Button>
          </div>
        </AccessControl>
        <NotificationManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className="dataTableCover">
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={notificationmanagement.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  notificationmanagement: state.notificationmanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotificationList: (payload: any) => dispatch(getNotificationManagement(payload)),
});

const NotificationManagementComponent = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(NotificationManagement);

export default NotificationManagementComponent;
