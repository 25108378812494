import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { injectIntl } from '../../utils/reactIntl';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Button,
  FieldDatePicker,
  FieldSelect,
  FieldTextInput,
  CircularProgressLoader,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  AccessControl,
} from '../../components';
import carretDown from '../../assets/svg/caretdown.svg';
import exp from '../../assets/svg/export.svg';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  intl: any;
  handleFormSubmit: any,
  handleExport: any,
  handleResetSorts: any,
  loaderExport: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const AvatcoinFilterForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    handleFormSubmit,
    handleExport,
    loaderExport,
    handleResetSorts,
  } = props;
  const formProps = useForm();
  const [showFilter, setShowFilter] = React.useState(true);
  const [toggle, setToggle] = React.useState(false);
  const handleReset = () => {
    formProps.reset({
      search: '',
      startDate: null,
      endDate: null,
      avatcoinActivity: '',
      transactionStatus: '',
    });
    handleResetSorts();
  };
  return (
    <form className="filterForm" onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <div className="filter_container">
        <div className="filterSearch">
          <div>
            <div className="filter">
              Filter
              <span
                className="closeBtn"
                role="presentation"
                onClick={() => setTimeout(() => {
                  setShowFilter((prevState) => !prevState);
                }, 200)}
              >
                {showFilter
                  ? (
                    <ClearIcon />
                  ) : <img src={carretDown} alt="down" />}
              </span>
            </div>
            <div className="fieldSet">
              <FieldTextInput
                id="search"
                name="search"
                formName="avatCoinFilter"
                className="textInput"
                type="text"
                formProps={formProps}
                label="Search by username or transaction Id"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className="userRightExport">
              {!loaderExport ? (
                <span>
                  <img
                    src={exp}
                    alt="export"
                    role="presentation"
                    onClick={() => setToggle((prevState: boolean) => !prevState)}
                  />
                  {toggle && (
                    <ul className="userrigthToggle">
                      <li
                        role="presentation"
                        onClick={() => handleExport('XLSX')}
                      >
                        {intl.formatMessage({ id: 'ChannelManagement.label.export.xlsx' })}
                      </li>
                      <li
                        role="presentation"
                        onClick={() => handleExport('CSV')}
                      >
                        {intl.formatMessage({ id: 'ChannelManagement.label.export.csv' })}
                      </li>
                    </ul>
                  )}
                </span>
              ) : <div className="exportProgressBar"><CircularProgressLoader /></div>}

            </div>
          </AccessControl>
        </div>
        {showFilter && (
          <div>
            <p>
              {intl.formatMessage({ id: 'ChannelManagenent.label.added' })}
            </p>
            <div className="filterOptions">
              <div className="datepickerField">
                <FieldDatePicker
                  id="startDate"
                  name="startDate"
                  label={intl.formatMessage({ id: 'ChannelManagement.label.startDate' })}
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null ? formProps.watch('endDate') : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className="datepickerField">
                <FieldDatePicker
                  id="endDate"
                  name="endDate"
                  label={intl.formatMessage({ id: 'ChannelManagement.label.endDate' })}
                  formProps={formProps}
                  minDate={formProps.watch('startDate') !== null ? formProps.watch('startDate') : moment().subtract(118, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className="userType">
                <FieldSelect
                  id="transactionStatus"
                  name="transactionStatus"
                  label="Transaction Status"
                  variant={false}
                  formProps={formProps}
                  options={
                    [
                      { key: '', label: '--Select--' },
                      { key: 'pending', label: 'Pending' },
                      { key: 'Success', label: 'Success' },
                      { key: 'canceled', label: 'Failed' },
                    ]
                  }
                />
              </div>
              <div className="userType">
                <FieldSelect
                  id="avatcoinActivity"
                  name="avatcoinActivity"
                  variant={false}
                  label="Avatcoin Activity"
                  formProps={formProps}
                  options={
                    [
                      { key: '', label: '--Select--' },
                      { key: 'credit', label: 'Pay In' },
                      { key: 'debit', label: 'Pay Out' },
                    ]
                  }
                />
              </div>
              <div className="buttonWrapper">
                <Button buttonType={ButtonTypes.submit} className="button">
                  Apply
                </Button>
                <Button onClick={handleReset} className="button">
                  Reset
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default injectIntl(AvatcoinFilterForm);
