import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Box,
  Typography,
} from '@material-ui/core';
import {
  SubscriptionValues,
} from '../../components';
import { injectIntl } from '../../utils/reactIntl';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import './subscription.module.scss';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="content_body"
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span" variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  intl: any;
}

const ContentManagement = (props: Props) => {
  const { intl } = props;
  const { search } = useLocation();
  const { active } = queryString.parse(search);
  const [value, setValue] = React.useState(active === 'FAQ' ? 2 : 0);

  const handleChange = (event: React.SyntheticEvent, currNumber: number) => {
    setValue(currNumber);
  };

  return (
    <div className="cardBg containerBox">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={(e: any, currNumber: number) => handleChange(e, currNumber)}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            className="tabsUI"
          >
            <Tab
              className="tabBox"
              label="Monthly Plan"
              {...a11yProps(0)}
            />
            <Tab
              className="tabBox"
              label="Quaterly Plan"
              {...a11yProps(1)}
            />
            <Tab
              className="tabBox"
              label="Annual Plan"
              {...a11yProps(2)}
            />
            <Tab
              className="tabBox"
              label="Free"
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <div className="cardBody">
          <TabPanel value={value} index={0}>
            <SubscriptionValues value="monthly" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SubscriptionValues value="quarterly" />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SubscriptionValues value="yearly" />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <SubscriptionValues value="free" />
          </TabPanel>
        </div>
      </Box>
    </div>
  );
};

export default injectIntl(ContentManagement);
