import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { CommissionRulesManagement } from '../modal';


export default function reducer(state: CommissionRulesManagement = new CommissionRulesManagement(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.COMMISSION_RULES_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.COMMISSION_RULES_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.COMMISSION_RULES_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    default:
      return state;
  }
}
