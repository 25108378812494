import { Button } from '../../components';
import React from 'react';
import { MasterConditionManagement } from '../../services/masterconditionmanagement';
import warning from '../../assets/svg/warning.svg';
import  '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  fetchPriceList: any;
  editableRow: any;
}
const MasterConditionManagementEdit: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    fetchPriceList,
    editableRow,
  } = props;

  const [save, setSave] = React.useState(false);
  const blockService = () => {
    const obj: any = {};
    if (editableRow?.age) {
      obj.minimumAge = editableRow.age;
    }
    if (editableRow?.existance) {
      obj.minimumAccountAge = editableRow.existance;
    }
    if (editableRow?.condition) {
      obj.havingPremiumSubscription = editableRow.condition;
    }

    MasterConditionManagement.changeMasterCondition(
      obj,
    ).then(() => {
      setSave(true);
    });
  };

  return (
    <>
      {!save ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            Edit
          </h2>
          <p>
            Are you sure you want to edit the entered value?
          </p>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Edited successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchPriceList();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default MasterConditionManagementEdit;
