export default {
    GET_TUTORIALS_MGMT_REQUEST: 'APP/CREATE_TUTORIALS/GET_TUTORIALS_MGMT_REQUEST',
    GET_TUTORIALS_MGMT_SUCCESS: 'APP/CREATE_TUTORIALS/GET_TUTORIALS_MGMT_SUCCESS',
    GET_TUTORIALS_MGMT_ERROR: 'APP/CREATE_TUTORIALS/GET_TUTORIALS_MGMT_ERROR',

    CREATE_TUTORIALS_MGMT_REQUEST: 'APP/CREATE_TUTORIALS/CREATE_TUTORIALS_REQUEST',
    CREATE_TUTORIALS_MGMT_SUCCESS: 'APP/CREATE_TUTORIALS/CREATE_TUTORIALS_SUCCESS',
    CREATE_TUTORIALS_MGMT_ERROR: 'APP/CREATE_TUTORIALS/CREATE_TUTORIALS_ERROR',

    DELETE_TUTORIALS_MGMT_REQUEST: 'APP/DELETE_TUTORIALS/DELETE_TUTORIALS_REQUEST',
    DELETE_TUTORIALS_MGMT_SUCCESS: 'APP/DELETE_TUTORIALS/DELETE_TUTORIALS_SUCCESS',
    DELETE_TUTORIALS_MGMT_ERROR: 'APP/DELETE_TUTORIALS/DELETE_TUTORIALS_ERROR',

  };
  