import React from 'react';
import classNames from 'classnames';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import './FieldDatePicker.module.scss';

interface Props {
  rootClassName?: string;
  className?: string;
  formProps: any;
  name: string;
  id: string;
  label: string;
  placeholder?: string;
  screenReaderInputMessage?: string;
  defaultValue?: any;
  minDate?: any;
  maxDate?: any;
  onChange: (value: any) => void; // Add onChange prop
}

const Datepicker = (props: Props) => {
  const {
    name,
    label,
    id,
    formProps: { formState: { errors }, control },
    rootClassName = null,
    className = null,
    placeholder = null,
    screenReaderInputMessage = null,
    defaultValue = null,
    minDate = null,
    maxDate = Date.now(),
    onChange = () => { }, // Set default onChange prop

  } = props;
  const classes = classNames(rootClassName || 'fieldRoot', className);
  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange: onFieldChange, // Use onChange from Controller
            value,
            ref,
          },
        }) => (
          <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="center">
                <DatePicker
                  format="DD / MM / yyyy"
                  placeholder={placeholder}
                  onChange={(date) => {
                    onFieldChange(date); // Call onFieldChange from Controller
                    onChange(date); // Call the onChange prop
                  }}
                  inputVariant="outlined"
                  error={!!errors[id]}
                  label={label}
                  inputRef={ref}
                  value={value}
                  aria-required
                  aria-label={screenReaderInputMessage}
                  minDate={minDate ? new Date(minDate) : undefined} // Check if minDate is provided
                  maxDate={maxDate ? new Date(maxDate) : undefined} // Check if maxDate is provided
                />
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => {
                    onFieldChange(null); // Clear the field value
                    onChange(null); // Call the onChange prop with null to clear the value
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </MuiPickersUtilsProvider>
          </>
        )}
      />
    </div>
  );
};


export default Datepicker;
