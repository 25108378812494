import React from 'react';
import { EcommerceFilter } from '../../forms';
import {
  DataTable,
} from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { ecommerceAction } from '../../actions';
import { getDateAndTime } from '../../utils/dateMethods';
import { exportToCSV, exportToExcel } from '../../utils/exportToExcel';
import { EcommerceService } from '../../services';
import avatcoin from '../../assets/svg/avatcoin.svg';
import avatcoinsilver from '../../assets/images/silvercoin.png';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  fetchEcommerceData: any;
  ecommerce: any;
}

const EcommercePurchase = (props: Props) => {
  const {
    ecommerce,
    fetchEcommerceData,
  } = props;
  const navigate = useNavigate();
  const { search } = useLocation();
  const { active } = queryString.parse(search);

  const [filter, setFilter] = React.useState<any>({
    startDate: '',
    endDate: '',
    domes: '',
    search: '',
    userType: active ? 'completed' : '',
    page: 0,
    rowsPerPage: 10,
    isSortByCreated: '',
    isSortByScheduledDateTime: '',
    isSortByCategory: '',
  });
  const [domes, setDomes] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  React.useEffect(() => {
    fetchEcommerceData(filter);
  }, [fetchEcommerceData, filter]);

  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  React.useEffect(() => {
    EcommerceService.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
    });
  }, []);

  function handleStatus(status: string) {
    switch (status) {
      case 'requested':
        return 'Requested';
      case 'refundRequested':
        return 'Refund Requested';
      case 'reScheduleRequested':
        return 'Rescehdule Requested';
      case 'refunded':
        return 'Refunded';
      case 'cancelled':
        return 'Cancelled';
      case 'completed':
        return 'Completed';
      case 'delivered':
        return 'Delivered';
      case 'accepted':
        return 'Accepted';
      case 'upcoming':
        return 'Upcoming';
      case 'ongoing':
        return 'Ongoing';
      default:
        return '';
    }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = ecommerce.data ? ecommerce.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitFilter = (data: any) => {
    if (data.domes !== ''
      || data.endDate !== null
      || data.liveType !== ''
      || data.search !== ''
      || data.startDate !== null
      || data.status !== '') {
      setFilter({
        ...filter,
        startDate: data.startDate ? data.startDate : '',
        endDate: data.endDate ? data.endDate : '',
        domes: data.domes,
        liveType: data.liveType,
        search: data.search.label,
        userType: data.status,
        page: 0,
      });
    }
  };

  const headCells: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: false,
    },
    {
      id: 'servicetitle', label: 'Service title', sort: false,
    },
    {
      id: 'servicetype', label: 'Service type', sort: false,
    },
    {
      id: 'serviceprovider', label: 'Service Provider', sort: false,
    },
    {
      id: 'domes', label: 'Domes', sort: true,
    },
    {
      id: 'purchasedateandtime', label: 'Purchase Date & Time', sort: true,
    },
    {
      id: 'totalprice', label: 'Total Price', sort: true,
    },
    {
      id: 'discountedprice', label: 'Discounted Price', sort: false,
    },
    {
      id: 'couponsapplied', label: 'Coupons applied', sort: true,
    },
    {
      id: 'amountpaid', label: 'Amount paid',
    },
    {
      id: 'orderstatus', label: 'Order Status',
    },
    {
      id: 'message', label: 'Message',
    },
  ];

  function createData(data: any, index: number): any {
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: data._id,
      username: data.username,
      servicetitle: data.serviceSessionTitle,
      servicetype: '--',
      serviceprovider: '--',
      domes: data.category.name,
      purchasedateandtime: getDateAndTime(data.scheduleDate),
      totalprice: (
        <div className="c_price">
          <img src={avatcoin} alt="avatcoin" />
          <p className="count_backround">
            {data.serviceSession.goldPrice}
          </p>
        </div>
      ), //
      discountedprice: (
        <div className="c_price">
          <img src={avatcoin} alt="avatcoin" />
          <span>{data.serviceSession.discountedPrice.gold}</span>
          <span className="separator">+</span>
          <img src={avatcoinsilver} alt="avatcoin" />
          <span>{data.serviceSession.discountedPrice.silver}</span>
        </div>),
      couponsapplied: '--', //
      amountpaid: (
        data.serviceSession.isDiscountedPrice ? (
          <div className="c_price">
            <img src={avatcoin} alt="avatcoin" />
            <span>{data.serviceSession.discountedPrice.gold}</span>
            <span className="separator">+</span>
            <img src={avatcoinsilver} alt="avatcoin" />
            <span>{data.serviceSession.discountedPrice.silver}</span>
          </div>
        ) : (
          <div className="c_price">
            <img src={avatcoin} alt="avatcoin" />
            <span>{data.serviceSession.goldPrice}</span>
          </div>
        )
      ),
      orderstatus: handleStatus(data.status),
      message: data.message,
    };
  }

  const rows: any = [];
  if (ecommerce.data !== null && ecommerce.data) {
    ecommerce.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    } else if (event.target.localName !== 'img') {
      navigate(`/ecommerce/ecommerceInfo/${name.id}`);
    }
  };

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'eventtitle') {
      setFilter({
        ...filter,
        page: 0,
        isSortByTitle: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'dateofcreation') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'livedateandtime') {
      setFilter({
        ...filter,
        page: 0,
        isSortByScheduledDateTime: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategory: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'numberofparticipants') {
      setFilter({
        ...filter,
        page: 0,
        isAcceptedUserCount: order === 'asc' ? '1' : '-1',
      });
    }
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            OrderId: ele.orderId,
            Username: ele.username,
            Service_title: ele.servicetitle,
            Service_type: ele.servicetype,
            Service_Provider: ele.serviceprovider,
            Domes: ele.domes,
            'Purchase_Date_&_Time': ele.purchasedateandtime,
            Total_Price: ele.totalprice,
            DiscountPrice: `Gold(${ele.discountedprice.props.children[1].props.children}) + Silver(${ele.discountedprice.props.children[4].props.children})`,
            Coupons_applied: ele.couponsapplied,
            Amount_paid: ele.amountpaid,
            Order_Status: ele.orderstatus,
            Message: ele.message,
          });
        });
        exportToExcel(selectedRowDataModified, 'EcommerceAndPurchaseData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        EcommerceService.export({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            OrderId: ele.orderId,
            Username: ele.username,
            Service_title: ele.servicetitle,
            Service_type: ele.servicetype,
            Service_Provider: ele.serviceprovider,
            Domes: ele.domes,
            'Purchase_Date_&_Time': ele.purchasedateandtime,
            Total_Price: ele.totalprice,
            DiscountPrice: `Gold(${ele.discountedprice.props.children[1].props.children}) + Silver(${ele.discountedprice.props.children[4].props.children})`,
            Coupons_applied: ele.couponsapplied,
            Amount_paid: ele.amountpaid,
            Order_Status: ele.orderstatus,
            Message: ele.message,
          });
        });
        exportToCSV(selectedRowDataModified, 'EcommerceAndPurchaseData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        EcommerceService.export({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      status: '',
      search: '',
      isSortByTitle: '',
      isSortByCreated: '',
      isSortByScheduledDateTime: '',
      isSortByCategory: '',
      isAcceptedUserCount: '',
    });
  };
  return (
    <>
      <EcommerceFilter
        handleFormSubmit={handleSubmitFilter}
        handleExport={handleExport}
        handleResetSorts={handleResetSorts}
        domes={domes}
        loaderExport={loaderExport}
      />
      <div className="dataTableCover">
        <DataTable
          headCells_={headCells}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={ecommerce.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({ ecommerce: state.ecommerce });

const mapDispatchToProps = (dispatch: any) => ({
  fetchEcommerceData: (payload: any) => dispatch(ecommerceAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EcommercePurchase);
