import {
  AccessControl,
  ChannelReportBlocked,
  ChannelReportDelete,
  DataTable,
  hasAccess,
  Modal,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
} from '../../components';
import React from 'react';
import { connect } from 'react-redux';
import { getChannelReport } from '../../actions';
import { getDateAndTime } from '../../utils/dateMethods';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import '../Accordion/accordion.module.scss';

interface Props {
  id: any
  fetchChannelReport: any;
  channelreported: any;
}

const ChannelReportDetail: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { fetchChannelReport, channelreported, id } = props;
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });

  React.useEffect(() => {
    fetchChannelReport(filter);
  }, [fetchChannelReport, filter]);
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'active':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'inactive':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      default:
        break;
    }
  };
  const hideActions = () => hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.DELETE);

  const actionButtons = (action: any, idS: any) => (
    <>
      <div className="actionBtnsWrapper">
        <AccessControl
          permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
          accessType={PERMISSION_TYPES.DELETE}
        >
          <div className="actionBtnCover">
            <img
              src={deleteIcon}
              role="presentation"
              alt="delete"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleBlockUnblock('delete', idS)}
            />
          </div>
        </AccessControl>
        <AccessControl
          permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div className="actionBtnCover">
            {action === 'pending' ? null : (action === 'active' ? (
              <img
                src={blockIcon}
                role="presentation"
                alt="block"
                width="35"
                title="Block"
                height="35"
                onClick={() => handleBlockUnblock('active', idS)}
              />
            ) : (
              <img
                src={unblock}
                role="presentation"
                alt="unblock"
                width="35"
                title="Unblock"
                height="35"
                onClick={() => handleBlockUnblock('inactive', idS)}
              />
            ))}
          </div>
        </AccessControl>
      </div>
    </>
  );

  function createData(data: any, index: number): any {
    const id_ = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      name: data.channelName,
      description: data.description !== null ? (data.description.length > 20
        ? (
          <div title={data.description}>
            {`${data.description.substring(0, 20)}...`}
          </div>
        ) : data.description) : '',
      reportscount: data.reportCount,
      createdOn: getDateAndTime(data.lastReportedDateTime),
      ...(hideActions() && {
        action: actionButtons(data.status, id_),
      }),
    };
  }

  const rows: any = [];
  if (channelreported !== undefined
    && channelreported.data !== null
    && channelreported.data) {
    channelreported.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      name: 'name', label: 'Channel Name',
    },
    {
      id: 'description', label: 'Description',
    },
    {
      id: 'reportscount', label: 'Reports count',
    },
    {
      id: 'createdOn', label: 'Last Reported Date & Time',
    },
    ...(hideActions() ? [{
      id: 'action', label: 'Action',
    }] : []),
  ];

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const count = channelreported !== undefined
    && channelreported.data !== null
    ? channelreported.data['Pagination-Count'] : 0;

  const handleClick = (event: any, name: any) => {
    if (event.target.localName !== 'img') {
      navigate(`/channelmanagement/channeldetails/${name.id}`);
    }
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          deleted.show && (
            <ChannelReportDelete
              id={deleted.id}
              handleModalClose={handleModalClose}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
            />
          )
        }
        {
          blocked.show && (
            <ChannelReportBlocked
              handleModalClose={handleModalClose}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="active"
            />
          )
        }
        {
          unblocked.show && (
            <ChannelReportBlocked
              handleModalClose={handleModalClose}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="inactive"
            />
          )
        }
      </Modal>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={channelreported.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { channelreported } = state;
  return {
    channelreported,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchChannelReport: (payload: any) => dispatch(getChannelReport(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelReportDetail);
