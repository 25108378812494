import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldFileInput,
  FieldTextInput,
  ValidationError,
} from '../../components';
import { FormattedMessage, injectIntl } from '../../utils/reactIntl';
import classNames from 'classnames';
import information from '../../assets/svg/info.svg';
import './addtutorial.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefineds
}

interface Props {
  handleSubmit: any;
  intl: any;
  handleModalClose: any;
  error: string;
}

const AddTutorialsForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    handleModalClose,
    intl,
    error,
  } = props;
  const FILE_SIZE = 50 * 1024 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
  ];

  const addDomesSchema = yup.object().shape({
    title: yup.string()
      .required("Title is required"),
    videoURL: yup.string()
      .required("Video URL is required"),
    thumbnailURL: yup
      .mixed()
      .required("Thumbnail")
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || SUPPORTED_FORMATS.includes(value[0].type)),
      ),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addDomesSchema),
  });

  return (
    <div className="addDetailsPage">
      <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
        <h1
          className="sign_text heading"
        >
          Add New Tutorial
        </h1>
        <FieldTextInput
          id="title"
          name="title"
          formName="tutorial"
          className="textInput"
          type="text"
          formProps={formProps}
          rootClassName="passwordInput"
          label="Title"
        />
        <FieldTextInput
          id="videoURL"
          name="videoURL"
          formName="videoURL"
          className="textInput"
          type="text"
          formProps={formProps}
          rootClassName="passwordInput"
          label="Video URL"
        />
        <p className="h2">Upload thumnail for this video</p>
        <div className="profileWrapperimg marginTopX">
          <div className="selection_box">
            <FieldFileInput
              id="thumbnailURL"
              name="thumbnailURL"
              className="textInput"
              formProps={formProps}
              rootClassName="input_upload"
            />
          </div>
        </div>
        <p className="informationUpload info_text">
          <img src={information} alt="information" />
          <FormattedMessage id="AddDomesComponent.files.size" />
        </p>
        {Object.keys(formProps.formState.errors).length > 0
          && (
            <ValidationError
              className="validation_error"
              error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
            />
          )}
        <div className="buttonWrapper btnsWrap">
          <Button
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            buttonType={ButtonTypes.submit}
          >
            <FormattedMessage id="AddComponent.publish" />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default injectIntl(AddTutorialsForm);
