import React from 'react';
import { connect } from 'react-redux';
import { dateFormatting } from '../../utils/dateMethods';
import { getPostDetailReportedUserInfo } from '../../actions';
import classNames from 'classnames';
import DataTable from '../Datatable';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  id: any;
  postdetailreporteduserinfo: any;
  getPostDetailReportedUser: any;
}

const PostCreated: React.FC<Props> = (props: Props) => {
  const {
    postdetailreporteduserinfo,
    getPostDetailReportedUser,
    id,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    id,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  React.useEffect(() => {
    getPostDetailReportedUser(filter);
  }, [getPostDetailReportedUser, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = postdetailreporteduserinfo.data ? postdetailreporteduserinfo.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      username: data.user?.username,
      reason: data.reason.length > 20 ? <div title={data.reason}>{`${data.reason.substring(0, 20)}...`}</div> : data.reason,
      createdAt: dateFormatting(data.createdAt),
    };
  }

  const rows: any = [];
  if (postdetailreporteduserinfo.data !== null && postdetailreporteduserinfo.data) {
    postdetailreporteduserinfo.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCellsPostsCreated: any = [
    {
      id: 'sno', numeric: true, disablePadding: true, label: 'S.No',
    },
    {
      id: 'username', numeric: false, disablePadding: false, label: 'Reporter Username',
    },
    {
      id: 'reason', numeric: false, disablePadding: false, label: 'Reason',
    },
    {
      id: 'createdAt', numeric: false, disablePadding: false, label: 'Reported Date & Time',
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByParentCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headCellsPostsCreated}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={postdetailreporteduserinfo.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { postdetailreporteduserinfo } = state;
  return {
    postdetailreporteduserinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getPostDetailReportedUser: (payload: any) => dispatch(getPostDetailReportedUserInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCreated);
