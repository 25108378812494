import { Button } from '../../components';
import React from 'react';
import { PriceManagementService } from '../../services';
import warning from '../../assets/svg/warning.svg';
import  '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  fetchPriceList: any;
  volumeFrom: number;
  volumeTo: number;
  percentage: number;
}
const PriceManagementSave: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    fetchPriceList,
    volumeFrom,
    volumeTo,
    percentage,
  } = props;

  const [save, setSave] = React.useState(false);
  const blockService = () => {
    PriceManagementService.addPriceEntry({
      volumeFrom,
      volumeTo,
      percentage,
    }).then(() => {
      setSave(true);
    });
  };

  return (
    <>
      {!save ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            Save
          </h2>
          <p>
            Are you sure you want to save the entered value?
          </p>
          <div className="modalButtonWrapper">
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Saved successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchPriceList();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default PriceManagementSave;
