import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Button, IconClose } from '..';
import { injectIntl } from '../../utils/reactIntl';
import './Modal.module.scss';

const KEY_CODE_ESCAPE = 27;

interface Props {
  children?: React.ReactNode,
  className?: string,
  scrollLayerClassName?: string,
  containerClassName?: string,
  contentClassName?: string,
  lightCloseButton?: boolean,
  id: string,
  intl: any,
  isClosedClassName?: string,
  isOpen?: boolean,
  onClose?: any,
  onManageDisableScrolling: any
  showOnClose?: boolean;
}

const ModalComponent: React.FC<Props> = (props: Props) => {
  const {
    id,
    intl,
    onManageDisableScrolling,
    children = null,
    className = '',
    scrollLayerClassName = '',
    containerClassName = '',
    contentClassName = '',
    lightCloseButton = false,
    isClosedClassName = "isClosed",
    isOpen = false,
    onClose = null,
    showOnClose = true,

  } = props;

  const handleClose = useCallback((event: any) => {
    onManageDisableScrolling(id, false);
    onClose(event);
  }, [id, onClose, onManageDisableScrolling]);

  const handleBodyKeyUp = useCallback(
    (event: any) => {
      if (event.keyCode === KEY_CODE_ESCAPE && isOpen) {
        handleClose(event);
      }
    },
    [handleClose, isOpen],
  );
  useEffect(() => {
    onManageDisableScrolling(id, isOpen);
    document.body.addEventListener('keyup', handleBodyKeyUp);
    return () => {
      document.body.removeEventListener('keyup', handleBodyKeyUp);
    };
  }, [handleBodyKeyUp, id, isOpen, onManageDisableScrolling]);

  useEffect(() => {
    onManageDisableScrolling(id, isOpen);
    return () => {
      onManageDisableScrolling(id, false);
    };
  }, [onManageDisableScrolling, id, isOpen]);

  const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
  const closeButtonClasses = classNames('close', {
    ['closeLight']: lightCloseButton,
  });
  const closeBtn = isOpen ? (
    <Button
      onClick={handleClose}
      rootClassName={closeButtonClasses}
      title={closeModalMessage}
    >
      <IconClose rootClassName="closeIcon" />
    </Button>
  ) : null;

  const modalClass = isOpen ? 'isOpen' : isClosedClassName;
  const classes = classNames(modalClass, className);
  const scrollLayerClasses = scrollLayerClassName || 'scrollLayer';
  const containerClasses = containerClassName || 'container_modal';

  return (
    <div className={classes}>
      <div className={scrollLayerClasses}>
        <div className={containerClasses}>
          {showOnClose && closeBtn}
          <div className={classNames(contentClassName || 'content')}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ModalComponent);
