import { ActionTypes } from '../constants/index';

export const updateVersionManagement = (payload: any) => ({
  type: ActionTypes.MANAGE_VERSION_UPDATE_REQUEST,
  payload: {
    "versionId": "652d1c5476643dafec48bad6",
    "versionNo": "4",
    "platform": "1",
    "launchType": "soft",
    "description": "Test12",
    "dateTime": "2023-10-16T11:19:16.000Z",
    "status": "unblocked"
  },
});
