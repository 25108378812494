import {
  Button,
  FieldTextInput,
  ValidationError,
  CircularProgressLoader,
} from '../../components';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DomesManagementService } from '../../services';
import { injectIntl } from '../../utils/reactIntl';
import './EditRankOfDomes.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any;
  intl: any;
  error: any;
  handleClose: any;
  loading: boolean;
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const EditRankOfDomes: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    error,
    handleClose,
    loading,
  } = props;

  const formProps = useForm();
  const [domesWithRankes, setDomesWithRanks] = React.useState<any>([]);
  const [showLoader, setShowLoader] = React.useState(true);

  useEffect(() => {
    DomesManagementService.getAllDomesList().then((data: any) => {
      data.data.data.map((ele: any) => setDomesWithRanks(
        (prevState: any[]) => [
          ...prevState,
          {
            name: ele.name,
            rank: ele.rank,
            id: ele._id,
          },
        ],
      ));
      setShowLoader(false);
    });
  }, []);

  return (
    <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <h2>
        {intl.formatMessage({ id: 'EditRankOfDomes.label.title' })}
      </h2>
      {showLoader && error.length === 0 ? <div className="loaderWrapper"><CircularProgressLoader /></div> : (
        <div className="container_s">
          <table>
            <tr>
              <th className="centerAlign">
                {intl.formatMessage({ id: 'EditRankOfDomes.label.domename' })}
              </th>
              <th>
                {intl.formatMessage({ id: 'EditRankOfDomes.label.rank' })}
              </th>
            </tr>
            {
              domesWithRankes.map((ele: any) => (
                <>
                  <tr key={ele.id}>
                    <td>{ele.name}</td>
                    <td>
                      <FieldTextInput
                        id="rank"
                        name={ele.id}
                        formName="domesAndRanks"
                        className="textInput"
                        type="number"
                        formProps={formProps}
                        defaultValue={ele.rank}
                        rootClassName="passwordInput"
                        label={intl.formatMessage({ id: 'EditRankOfDomes.label.rank' })}
                      />
                    </td>
                  </tr>
                </>
              ))
            }
          </table>
        </div>
      )}
      <div>
        {error.length > 0 && <ValidationError error={error} />}
        {loading
          ? (
            <div className="savingLoader">
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className="buttonWrapper">
                <Button buttonType={ButtonTypes.submit}>
                  {intl.formatMessage({ id: 'EditRankOfDomes.label.save' })}
                </Button>
                <Button buttonType={ButtonTypes.button} onClick={handleClose}>
                  {intl.formatMessage({ id: 'EditRankOfDomes.label.cancel' })}
                </Button>
              </div>
            </>
          )}
      </div>
    </form>
  );
};

export default injectIntl(EditRankOfDomes);
