import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldTextInput,
  ValidationError,
  FieldRadioButton,
  FieldTimePicker,
  FieldDatePicker,
  FieldSelect,
} from '../../components';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import './addnewversion.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import { updateVersionManagement } from '../../actions';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  error: any;
}

const AddNewVersionForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    error,
    intl,
  } = props;
  const navigate = useNavigate();
  const addSubDomesSchema = yup.object().shape({
    versionNumber: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.versionNumber' })),
    platform: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.platform' })),
    launch: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.launch' })),
    date: yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.date' }))
      .nullable(),
    time: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.time' }))
      .nullable(),
    description: yup.string()
      .max(250, intl.formatMessage({ id: 'AddVersionManagement.error.description.max' }))
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.description.required' })),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addSubDomesSchema),
  });
  // useEffect(() => {
  //   updateVersionManagement({
  //     versionId: "652d1c5476643dafec48bad6",
  //     versionNo: "4",
  //     platform: "1",
  //     launchType: "soft",
  //     description: "Test12",
  //     dateTime: "2023-10-16T11:19:16.000Z",
  //     status: "unblocked",
  //   });
  // }, []);
  return (
    <>
      <div className="cardBg addDetailsPage">
        <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
          <h2 className="sign_text">
            {intl.formatMessage({ id: 'AddVersionManagement.label.details' })}
          </h2>
          <div className="flex_row">
            <div className="flex_col_sm_6">
              <FieldTextInput
                id="versionNumber"
                name="versionNumber"
                formName="subAdmin"
                className="textInput"
                type="text"
                formProps={formProps}
                pattern="^\d+(\.\d+)*$"
                rootClassName="passwordInput"
                label={intl.formatMessage({ id: 'AddVersionManagement.label.versionNumber' })}
                pattern="^\d+(\.\d+)*$"
              />
            </div>
            <div className="flex_col_sm_6">
              <div className="userType">
                <FieldSelect
                  id="platform"
                  name="platform"
                  label={intl.formatMessage({ id: 'AddVersionManagement.label.platform' })}
                  variant={false}
                  formProps={formProps}
                  options={[
                    { key: '1', label: 'Android' },
                    { key: '2', label: 'IOS' },
                  ]}
                />
              </div>
            </div>
            <div className="flex_col_sm_12">
              <FieldRadioButton
                id="launch"
                name="launch"
                formProps={formProps}
                className="radioBtnWrapper"
                options={[
                  { key: 'hard', value: intl.formatMessage({ id: 'AddVersionManagement.label.hardLaunch' }) },
                  { key: 'soft', value: intl.formatMessage({ id: 'AddVersionManagement.label.softLaunch' }) },
                ]}
              />
            </div>
            <div className="flex_col_sm_6">
              <div className="datepickerField">
                <FieldDatePicker
                  id="date"
                  name="date"
                  className="dateOfBirthInput dateOfBirthInputLg"
                  label={intl.formatMessage({ id: 'AddVersionManagement.label.date' })}
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null
                    ? formProps.watch('endDate')
                    : moment().subtract(0, 'years').format('YYYY-MM-DD')} onChange={function (value: any): void {
                      throw new Error('Function not implemented.');
                    } }                />
              </div>
            </div>
            <div className="flex_col_sm_6">
              <div className="datepickerField">
                <FieldTimePicker
                  id="time"
                  name="time"
                  className="dateOfBirthInput dateOfBirthInputLg"
                  label={intl.formatMessage({ id: 'AddVersionManagement.label.time' })}
                  formProps={formProps}
                />
              </div>
            </div>
            <div className="flex_col_sm_12">
              <FieldTextInput
                id="description"
                name="description"
                formName="addDomes"
                className="textInput"
                type="textarea"
                formProps={formProps}
                rootClassName="passwordInput"
                label={intl.formatMessage({ id: 'AddVersionManagement.label.Description' })}
              />
            </div>
          </div>
          {Object.keys(formProps.formState.errors).length > 0
            && (
              <ValidationError
                className="validation_error"
                error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
              />
            )}
          {Object.keys(formProps.formState.errors).length === 0
            && <ValidationError error={error} />}
          <div className="buttonWrapper">
            <Button onClick={() => navigate('/versionmanagement')}>
              {intl.formatMessage({ id: 'AddVersionManagement.label.cancel' })}
            </Button>
            <Button buttonType={ButtonTypes.submit}>
              {intl.formatMessage({ id: 'AddVersionManagement.label.publish' })}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default injectIntl(AddNewVersionForm);
