export const PERMISSION_TYPES = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
};

export const PERMISSION_KEYS = {
  USER_MANAGEMENT: 'user_mangement',
  CHANNEL_MANAGEMENT: 'channel_mangement',
  SUBSCRIPTION_PLAN_MANAGEMENT: 'subscription_plan_management',
  SUBSCRIBED_USERS: 'subscribed_users',
  COMMISSION_MANAGEMENT: 'commission_management',
  SERVICE_PROVIDER_MANAGEMENT: 'service_provider_management',
  PURCHASE_E_COMMERCE: 'purchase_e-commerce',
  CAMPAIGN_MANAGEMENT: 'campaign_management',
  PROMOTIONS_AND_ADVERTISING: 'promotions& _advertising',
  SELLER_BRAND_MANGEMENT: 'seller/Brand_management',
  LIVE_STREAMING: 'live_streamings',
  REPORTED_POST_MANAGEMENT: 'reported_post_management',
  ROLE_MANAGEMENT: 'role_management',
  SUB_ADMIN_MANAGEMENT: 'sub-Admin_management',
  EVENT_MANAGEMENT: 'event_management',
  PAYMENT_MANAGEMENT: 'payment_management',
  COIN_ACTIVITY_MANAGEMENT: 'coin_activity_anagement',
  COMMISION_MANAGEMENT: 'commission_management',
  CONTENT_MANAGEMENT: 'content_management',
  REWARD_POINTS: 'reward_points',
  CATERGORIES: 'categories',
  SUB_CATEGORIES: 'sub_categories',
  ISSUES_REPORTED: 'issues_reported',
  VERSION_MANAGEMENT: 'version_management',
  REMINDER_EMAILS: 'reminder_emails',
  NOTIFICATION_MANAGEMENT: 'notification_management',
  SUPPORT: 'support',
  FEEDBACK: 'feedback',
  REPORT_MANAGEMENT: 'report_management',
  REPORT_USER_MANAGEMENT: 'report_user_management',
};
