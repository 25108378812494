import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import avatcoin from '../../assets/svg/avatcoin.svg';
import { ExportEcommerceInfo } from '../../forms';
import avatcoinsilver from '../../assets/images/silvercoin.png';
import {
  Button,
  CircularProgressLoader,
  Modal,
  ChannelManagementBlocked,
  ChannelManagementDelete,
  Overlay,
  Accordion,
  ToastBar,
  EcommerceSendNotification,
  NotificationEcommerce,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
} from '..';
import { ecommerceInfo } from '../../actions';
import { ChannelInfoService, EcomemrceInfoService } from '../../services';
import exportIcon from '../../assets/svg/export.svg';
import { dateFormatting } from '../../utils/dateMethods';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  ecommerceinfo: any;
  id: any;
  fetchEcommerceInfo: any;
  intl: any;
}

const ChannelInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    ecommerceinfo,
    id,
    fetchEcommerceInfo,
    intl,
  } = props;

  React.useEffect(() => {
    fetchEcommerceInfo(id);
  }, [fetchEcommerceInfo, id]);

  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [showExportModal, setShowExportModal] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  //   if(property=='Username'){
  //     headers.push({
  //         header: 'Username',
  //         key: 'Username'
  //     })
  // }
  // if(property == 'Service Type'){
  //     headers.push({
  //         header: 'Service Type',
  //         key: 'Service Type'
  //     })
  // }
  // if(property=='Service Provider'){
  //     headers.push({
  //         header: 'Service Provider',
  //         key: 'Service Provider'
  //     })
  // }
  // if(property=='Domes'){
  //     headers.push({
  //         header: 'Domes',
  //         key: 'Domes'
  //     })
  // }
  // if(property=='Date & Time of Purchase'){
  //     headers.push({
  //         header: 'Date & Time of Purchase',
  //         key: 'Date & Time of Purchase'
  //     })
  // }
  // if(property=='Total Price'){
  //     headers.push({
  //         header: 'Total Price',
  //         key: 'Total Price'
  //     })
  // }
  // if(property=='Discounted Price'){
  //     headers.push({
  //         header: 'Discounted Price',
  //         key: 'Discounted Price'
  //     })
  // }
  // if(property=='Coupons Applied'){
  //     headers.push({
  //         header: 'Coupons Applied',
  //         key: 'Coupons Applied'
  //     })
  // }
  // if(property=='Amount Paid'){
  //     headers.push({
  //         header: 'Amount Paid',
  //         key: 'Amount Paid'
  //     })
  // }
  // if(property=='Order Status'){
  //     headers.push({
  //         header: 'Order Status',
  //         key: 'Order Status'
  //     })
  // }
  // }
  function handleStatus(status: string) {
    switch (status) {
      case 'requested':
        return 'Requested';
      case 'refundRequested':
        return 'Refund Requested';
      case 'reScheduleRequested':
        return 'Rescehdule Requested';
      case 'refunded':
        return 'Refunded';
      case 'cancelled':
        return 'Cancelled';
      case 'completed':
        return 'Completed';
      case 'delivered':
        return 'Delivered';
      case 'accepted':
        return 'Accepted';
      case 'upcoming':
        return 'Upcoming';
      case 'ongoing':
        return 'Ongoing';
      default:
        return '';
    }
  }

  const handleModalClose = () => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
    setSendNotification(false);
    setShowExportModal(false);
  };

  const deleteChannelService = async () => {
    setShowOverlay(true);
    const data = await ChannelInfoService.deleteChannelApi(id);
    setShowOverlay(false);
    if (data.data.statusCode === 202) {
      navigate('/channelmanagement');
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className="modal_container">
          {
            showBlock && (
              <ChannelManagementBlocked
                handleModalClose={handleModalClose}
                fetchChannelList={() => { fetchEcommerceInfo(id); }}
                id={id}
                status={ecommerceinfo.data.status === 'active' ? 'blocked' : 'unblocked'}
                setOverlay={setShowOverlay}
              />
            )
          }
          {showDelete && (
            <ChannelManagementDelete
              handleModalClose={handleModalClose}
              deleteChannelService={deleteChannelService}
            />
          )}
        </div>
        {
          sendNotification
          && (
            <EcommerceSendNotification
              handleModalClose={handleModalClose}
              id={id}
              serviceInfo={ecommerceinfo}
              setShowOverlay={setShowOverlay}
            />
          )
        }
        {
          showExportModal && (
            <ExportEcommerceInfo
              handleFormSubmit={(formData: any) => {
                setShowOverlay(true);
                const exportType: any = [];
                Object.keys(formData).forEach((ele: any) => {
                  if (formData[ele]) {
                    exportType.push(ele);
                  }
                });
                EcomemrceInfoService
                  .exportInfo({
                    channelId: id,
                    optionCode: exportType,
                    ...(
                      exportType.includes('detail') && {
                        values: {
                          Username: ecommerceinfo.data.userId?.username,
                          'Service Type': ecommerceinfo.data?.serviceType === '' ? '--' : ecommerceinfo.data.serviceType,
                          'Service Provider': '--',
                          Domes: ecommerceinfo.data.category.name,
                          'Date & Time of Purchase': ecommerceinfo.data.createdAt,
                          'Total Price': `Gold (${ecommerceinfo.data.goldPrice})`,
                          'Discounted Price': `Gold(${ecommerceinfo.data.discountedPrice.gold}) Silver(${ecommerceinfo.data.discountedPrice.silver})`,
                          'Coupons Applied': '--',
                          'Amount Paid': '--',
                          'Order Status': ecommerceinfo.data.status,
                        },
                      }
                    ),
                  })
                  .then(() => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: 'Details Exported Successfully',
                    });
                    handleModalClose();
                  }).catch((err: any) => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: err.response.data.message,
                    });
                  });
              }}
              handleCloseModal={handleModalClose}
            />
          )
        }
      </Modal>
      {
        ecommerceinfo.data === null || ecommerceinfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className="cardBg containerBox p-20">
                <div className="headerActions">
                  <div>
                    <Button
                      className="button"
                      onClick={() => {
                        setShowModal(true);
                        setSendNotification(true);
                      }}
                    >
                      {intl.formatMessage({ id: 'ChannelInfo.label.SendNotification' })}
                    </Button>
                  </div>
                  <div className="iconWrapperbox">
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className="iconWrapper">
                        <img
                          role="presentation"
                          onClick={() => {
                            setShowModal(true);
                            setShowExportModal(true);
                          }}
                          src={exportIcon}
                          alt="Export"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                  </div>
                </div>
                <div className="userInformatinc">
                  <div className="userInfoinner">
                    <div>
                      <h3 className="userInformatinc">
                        Service Title:
                        <p>
                          {ecommerceinfo.data[0].serviceSessionTitle}
                        </p>
                      </h3>
                      {/* <h3>{intl.formatMessage({ id: 'ChannelInfo.label.description' })}</h3>
                      <p>
                        {ecommerceinfo.data.description}
                      </p> */}
                    </div>
                    <div className="right_container">
                      <h3>
                        {intl.formatMessage({ id: 'ChannelInfo.label.createdOn' })}
                        :
                        {' '}
                        <span>
                          {dateFormatting(ecommerceinfo.data[0].serviceSession.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="icons">
                  {
                    ecommerceinfo.data.status === 'inactive'
                      ? <div className="simple_text">Deactivated</div>
                      : ecommerceinfo.data.status === 'blocked'
                        ? <div className="simple_text">Blocked</div>
                        : <div className="simple_text">Active</div>
                  }
                </div>
                <div className="postDetails">
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Username
                      </p>
                      <p className="count_backround">
                        {ecommerceinfo.data[0].username}
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Service Type
                      </p>
                      <p className="count_backround">
                        --
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Service Provider
                      </p>
                      <p className="count_backround">
                        --
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Domes
                      </p>
                      <p className="count_backround">
                        {ecommerceinfo.data[0].category.name}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Date & Time of Purchase
                      </p>
                      <div className="c_price">
                        <p className="count_backround">
                          {dateFormatting(ecommerceinfo.data[0].createdAt)}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Total Price
                      </p>
                      <div className="c_price">
                        <img src={avatcoin} alt="avatcoin" />
                        <p className="count_backround">
                          {ecommerceinfo.data[0].serviceSession.goldPrice}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Discounted Price
                      </p>
                      <p className="count_backround">
                        <div className="c_price">
                          <img src={avatcoin} alt="avatcoin" />
                          <span>{ecommerceinfo.data[0].serviceSession.discountedPrice.gold}</span>
                          <span className="separator">+</span>
                          <img src={avatcoinsilver} alt="avatcoin" />
                          <span>{ecommerceinfo.data[0].serviceSession.discountedPrice.silver}</span>
                        </div>
                      </p>
                    </div>
                    <div>
                      <p className="heading_styles">
                        Coupons Applied
                      </p>
                      <p className="count_backround">
                        --
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <p className="heading_styles">
                        Amount Paid
                      </p>
                      {ecommerceinfo.data[0].serviceSession.isDiscountedPrice ? (
                        <div className="c_price">
                          <img src={avatcoin} alt="avatcoin" />
                          <span className="count_backround">{ecommerceinfo.data[0].serviceSession.discountedPrice.gold}</span>
                          <span className="separator">+</span>
                          <img src={avatcoinsilver} alt="avatcoin" />
                          <span className="count_backround">{ecommerceinfo.data[0].serviceSession.discountedPrice.silver}</span>
                        </div>
                      ) : (
                        <div className="c_price">
                          <img src={avatcoin} alt="avatcoin" />
                          <span className="count_backround">{ecommerceinfo.data[0].serviceSession.goldPrice}</span>
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="heading_styles">
                        Order Status
                      </p>
                      <p className="count_backround">
                        {handleStatus(ecommerceinfo.data[0].status)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="Notification Details">
                <NotificationEcommerce id={id} />
              </Accordion>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({ ecommerceinfo: state.ecommerceinfo });

const mapDispatchToProps = (dispatch: any) => ({
  fetchEcommerceInfo: (payload: any) => dispatch(ecommerceInfo(payload)),
});

const ChannelInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ChannelInfoComponent);

export default ChannelInfo;
