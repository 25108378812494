/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import createSagaMiddleware from 'redux-saga';
// import config from '../config';

export const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    ga?: any
  }
}

// ======================================================
// Middleware Configuration
// ======================================================

// const setupAnalyticsHandlers = () => {
//   const handlers = [];

//   // Log analytics page views and events in dev mode
//   // if (config.dev) {
//   //   handlers.push(new LoggingAnalyticsHandler());
//   // }

//   // Add Google Analytics handler if tracker ID is found
//   // if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
//   //   handlers.push(new GoogleAnalyticsHandler(window.ga));
//   // }

//   return handlers;
// };

// const analyticsHandlers = setupAnalyticsHandlers();
const middleware = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  const invariant = require('redux-immutable-state-invariant').default;
  middleware.push(invariant());
  middleware.push(createLogger({ collapsed: true }));
}

export default middleware;
