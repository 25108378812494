import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  BreadCrumbs,
  NamedLink,
  Notification as NotificationComponent,
} from "../../../components";
import { FormattedMessage, injectIntl } from "../../../utils/reactIntl";

import "./TopbarDesktop.module.scss";
import userbig from "../../../assets/svg/userbig.svg";
import setting from "../../../assets/svg/settings.svg";
import logout from "../../../assets/svg/logout.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserUpdatedProfile } from "../../../actions";
import { compose } from "redux";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  className?: string;
  rootClassName?: string;
  isAuthenticated: boolean;
  logoutAction: any;
  userProfile: any;
  getProfileInfo: any;
}

const TopbarDesktop = (props: Props) => {
  const {
    className,
    rootClassName,
    isAuthenticated,
    logoutAction,
    userProfile,
    getProfileInfo,
  } = props;
  const [mounted, setMounted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!userProfile.data && !userProfile.loadingInProgress) {
      getProfileInfo();
    }
  }, [userProfile]);

  const handleLogout = () => {
    logoutAction();
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || 'topbar_root', className);
  const profileMenu = authenticatedOnClientSide ? (
    <p role="presentation" className="logout" onClick={handleLogout}>
      <img src={logout} alt="userprofile" />
      <FormattedMessage id="TopbarDesktop.logout" />
    </p>
  ) : null;

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className="loginLink">
      <span className="login">
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
  const [showProfileImage, setShowProfileImage] = React.useState(true);
  function pageTiTle() {
    if (location.pathname.includes("/myProfile")) {
      return (
        <NamedLink className="logoLink" name="MyProfile">
          My Profile
        </NamedLink>
      );
    }
    if (location.pathname.includes("/usermanagement")) {
      return (
        <NamedLink className="logoLink" name="UserManagement">
          User Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/channelmanagement")) {
      return (
        <NamedLink className="logoLink" name="ChannelManagement">
          Channel Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/domesmanagement")) {
      return (
        <NamedLink className="logoLink" name="DomesManagement">
          Domes Management
        </NamedLink>
      );
    }

    if (location.pathname.includes("/subdomesmanagement")) {
      return (
        <NamedLink className="logoLink" name="SubDomesManagement">
          Sub Domes Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/rolemanagement")) {
      return (
        <NamedLink className="logoLink" name="RoleManagement">
          Role Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/rewardpoints")) {
      return (
        <NamedLink className="logoLink" name="RewardPointManagement">
          Reward Point
        </NamedLink>
      );
    }
    if (location.pathname.includes("/subadminmanagement")) {
      return (
        <NamedLink className="logoLink" name="SubAdminManagement">
          Sub Admin Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/subadminmanagement")) {
      return (
        <NamedLink className="logoLink" name="SubAdminManagement">
          SubAdmin Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/reportusermanagement")) {
      return (
        <NamedLink className="logoLink" name="ReportUserManagement">
          Report User Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/contentmanagement")) {
      return (
        <NamedLink className="logoLink" name="Settings">
          Content Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/notification")) {
      return (
        <NamedLink className="logoLink" name="Settings">
          Notification Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/settings")) {
      return (
        <NamedLink className="logoLink" name="Settings">
          Settings
        </NamedLink>
      );
    }
    if (location.pathname.includes("/masterreason")) {
      return (
        <NamedLink className="logoLink" name="MasterReason">
          Master Reason Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/livestreaming")) {
      return (
        <NamedLink className="logoLink" name="LiveStreaming">
          Event Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/feedback")) {
      return (
        <NamedLink className="logoLink" name="Feedback">
          Rating(Feedback) Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/versionmanagement")) {
      return (
        <NamedLink className="logoLink" name="VersionManagement">
          Version Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/paymentmanagement")) {
      return (
        <NamedLink className="logoLink" name="PaymentManagement">
          Payment Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/support")) {
      return (
        <NamedLink className="logoLink" name="SupportManagement">
          Support Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/coinactivity")) {
      return (
        <NamedLink className="logoLink" name="CoinActivity">
          Avatcoin Activity Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/ecommerce")) {
      return (
        <NamedLink className="logoLink" name="Ecommerce">
          Purchase & Ecommerce Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/sessionmanagement")) {
      return (
        <NamedLink className="logoLink" name="Session">
          Session Management
        </NamedLink>
      );
    }
    if (location.pathname.includes("/subscribedusers")) {
      return (
        <NamedLink className="logoLink" name="SubscribedUsers">
          Subscribed Users
        </NamedLink>
      );
    }
    return "";
  }

  return (
    <nav className={classes}>
      <div>
        {pageTiTle()}
        <BreadCrumbs location={location} />
      </div>
      <div className="rightSide">
        <NotificationComponent />
        <div className="userInfo">
          {showProfileImage && userProfile.data && userProfile.data.profilePicture && !userProfile.loadingInProgress ?
            <img
              src={
                userProfile.data.profilePicture
              }
              className="profile_img"
              alt="userprofile"
              onError={() => setShowProfileImage(false)}
              onLoad={() => setShowProfileImage(true)}
            /> : <img
              // hidden={showProfileImage}
              src={userbig}
              alt="userprofile"
              className="profile_img"
            />}

          <h4>{localStorage.getItem("username")}</h4>
          <div className="userSubmenu">
            <ul>
              <li
                onClick={() => navigate("/myProfile")}
              >
                <p>

                  {showProfileImage && userProfile.data && userProfile.data.profilePicture && !userProfile.loadingInProgress ?
                    <img
                      src={
                        userProfile.data.profilePicture
                      }
                      alt="userprofile"
                      className="userImg"
                      onError={() => setShowProfileImage(false)}
                      onLoad={() => setShowProfileImage(true)}
                    /> : <img
                      // hidden={showProfileImage}
                      src={userbig}
                      alt="userprofile"

                    />}

                  My Profile
                </p>
              </li>
              <li onClick={() => navigate("/settings")}>
                <p>
                  <img src={setting} alt="setting" />
                  Settings
                </p>
              </li>
              <li>{profileMenu}</li>
            </ul>
          </div>
        </div>
        {loginLink}
      </div>
    </nav>
  );
};

const mapStateToProps = (state: any) => ({
  userProfile: state.updateProfile,
});
const mapDispatchToProps = (dispatch: any) => ({
  getProfileInfo: () => dispatch(getUserUpdatedProfile()),
});

const TopbarDesktopComponent = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TopbarDesktop);

export default TopbarDesktopComponent;
