import React from "react";
import { connect } from "react-redux";
import { dateFormatting } from "../../utils/dateMethods";
import {
  LoginDetailsUserInfo,
  Button,
  CircularProgressLoader,
  Modal,
  UserReportDetail,
  DeleteUserInfo,
  BlockUserInfo,
  UserInfoSendNotification,
  SuperAdminAccessControl,
  ToastBar,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  AccessControl,
  Overlay,
  Accordion,
  UserInfoNotification,
  TransactionDetail,
} from "../../components";
import { ExportUserInfo } from "../../forms";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import avatcoin from "../../assets/svg/avatcoin.svg";
import AdminRules from "../AdminRoles/adminRules";

import { getUserInfo } from "../../actions";
import { UserInfoService } from "../../services";
import OtpVerification from "../OtpVerification";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import deleteIcon from "../../assets/svg/delete.svg";
import blockIcon from "../../assets/svg/block.svg";
import exportIcon from "../../assets/svg/export.svg";
import userprofile from "../../assets/svg/image-placeholder.png";
import unblock from "../../assets/svg/unblock.svg";
import "../LayoutWrapperMain/LayoutWrapperMain.module.scss";
import "./userinfo.module.scss";

interface Props {
  fetchUserInfo: any;
  id: any;
  userInfo: any;
}

const UserInfo: React.FC<Props> = (props: Props) => {
  const { fetchUserInfo, id, userInfo } = props;
  console.log(userInfo);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [deleteUser, setDeleteUser] = React.useState(false);
  const [blockUser, setBlockUser] = React.useState(false);
  const [showExportModal, setShowExportModal] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [otpVerif, setOtpVerif] = React.useState(false);
  const [userVerified, setUserVerified] = React.useState(false);
  const [userData, setUserData] = React.useState<any>();
  const [invalidOtp, setInvalidOtp] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  const [otpLoader, setOtpLoader] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: "",
  });
  React.useEffect(() => {
    fetchUserInfo(id);
  }, [fetchUserInfo, id]);

  const handleDeleteUser = () => {
    setDeleteUser(true);
    setShowModal(true);
  };
  const handleModalClose = () => {
    setOtpVerif(false);
    setDeleteUser(false);
    setBlockUser(false);
    setShowModal(false);
    setSendNotification(false);
    setShowExportModal(false);
  };
  const handleBlockUser = () => {
    setBlockUser(true);
    setShowModal(true);
  };

  const handleShowPersonalInfo = async () => {
    try {
      setShowOverlay(true);
      await UserInfoService.sendOTP();
      setShowOverlay(false);
      setOtpVerif(true);
      setShowModal(true);
    } catch (err: any) {
      if (err.response.status === 405) {
        setShowOverlay(false);
        setNotification({
          show: true,
          message: err.response.data.message,
        });
      }
    }
  };

  const handleOtpSubmit = async (otp: any) => {
    setInvalidOtp(false);
    setOtpLoader(true);
    setShowOverlay(true);
    UserInfoService.checkOTP(otp, id)
      .then((res) => {
        if (res.status === 200) {
          setUserVerified(true);
          setUserData(res.data.data);
          setShowModal(false);
          setOtpVerif(false);
          setOtpLoader(false);
          setShowOverlay(false);
          handleModalClose();
        }
      })
      .catch(() => {
        setInvalidOtp(true);
        setShowOverlay(false);
        setOtpLoader(false);
      });
  };

  const handleSendNotification = () => {
    setShowModal(true);
    setSendNotification(true);
  };

  const handleCloseOTPResenError = () => {
    setNotification({
      show: false,
      message: "",
    });
  };

  const navigate = useNavigate();
  return (
    <>
      {showOverlay && <Overlay />}
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={handleCloseOTPResenError}
        />
      )}
      <Modal
        id="userinfo-modal"
        showOnClose={blockUser || deleteUser ? false : true}
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className="modal_container">
          {otpVerif && (
            <OtpVerification
              handleOtpSubmit={handleOtpSubmit}
              invalid={invalidOtp}
              handleResendOTP={() => UserInfoService.sendOTP()}
              showLoader={otpLoader}
              setShowOverlay={setShowOverlay}
            />
          )}
          {deleteUser && (
            <DeleteUserInfo
              fetchUserInfo={() => navigate("/usermanagement")}
              handleModalClose={handleModalClose}
              setShowOverlay={setShowOverlay}
              id={id}
            />
          )}
          {blockUser && (
            <BlockUserInfo
              id={id}
              setShowOverlay={setShowOverlay}
              fetchUserInfo={fetchUserInfo}
              handleModalClose={handleModalClose}
              userInfoStatus={userInfo.data.status}
            />
          )}
          {sendNotification && (
            <UserInfoSendNotification
              id={id}
              handleModalClose={handleModalClose}
              setShowOverlay={setShowOverlay}
              setNotification={setNotification}
            />
          )}
          {showExportModal && (
            <ExportUserInfo
              handleFormSubmit={(formdata: any) => {
                setShowOverlay(true);
                const exportTypes: any = [];
                Object.keys(formdata).forEach((ele: any) => {
                  if (formdata[ele]) {
                    exportTypes.push(ele);
                  }
                });
                if (
                  exportTypes.includes("service-created") ||
                  exportTypes.includes("services-purchased") ||
                  exportTypes.includes("campaigns-created") ||
                  exportTypes.includes("campaigns-invested")
                ) {
                  setShowOverlay(false);
                  setNotification({
                    show: true,
                    message: "Under Development",
                  });
                } else {
                  UserInfoService.userInfoExports({
                    userId: id,
                    optionCode: exportTypes,
                    ...(exportTypes.includes("detail") && {
                      values: {
                        "Post Count": userInfo.data.postCount,
                        "Subscribed Channels":
                          userInfo.data.subscribedChannelCount,
                        "Events Hosted": userInfo.data.eventHosted,
                        "Purchases Made": userInfo.data.purchasesMade,
                        "Services Created": 0,
                        "Earned (Transferrable)": userInfo.data.earning,
                        Payments: userInfo.data.payments,
                        Payouts: userInfo.data.payouts,
                        "Escrow Amount": userInfo.data.escrowEarnings,
                        "Registered On": dateFormatting(
                          userInfo.data.createdAt,
                        ),
                        "Campaigns Created": 0,
                        "Campaigns Invested In": userInfo.data.campaignCount,
                        Followers: userInfo.data.followersCount,
                        Following: userInfo.data.followingCount,
                      },
                    }),
                  })
                    .then(() => {
                      setShowOverlay(false);
                      setNotification({
                        show: true,
                        message: "Details Exported Successfully",
                      });
                      handleModalClose();
                    })
                    .catch((err: any) => {
                      setShowOverlay(false);
                      setNotification({
                        show: true,
                        message: err.response.data.message,
                      });
                    });
                }
              }}
              handleCloseModal={handleModalClose}
            />
          )}
        </div>
      </Modal>
      <div>
        {userInfo.data === null || userInfo.loadingInProgress ? (
          <CircularProgressLoader />
        ) : (
          <>
            <div className="cardBg containerBox p-20">
              <div className="headerActions">
                <div>
                  <SuperAdminAccessControl rules={AdminRules.AdminOTPRules}>
                    <Button className="button" onClick={handleShowPersonalInfo}>
                      Show Personal Information
                    </Button>
                  </SuperAdminAccessControl>
                  <Button onClick={handleSendNotification}>
                    Send Notification
                  </Button>
                </div>
                <div className="iconWrapperbox">
                  <AccessControl
                    permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
                    accessType={PERMISSION_TYPES.EDIT}
                  >
                    <span className="iconWrapper">
                      <img
                        role="presentation"
                        onClick={() => {
                          setShowModal(true);
                          setShowExportModal(true);
                        }}
                        src={exportIcon}
                        alt="Export"
                        title="Export"
                      />
                    </span>
                  </AccessControl>
                  <AccessControl
                    permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
                    accessType={PERMISSION_TYPES.DELETE}
                  >
                    <span className="iconWrapper">
                      <img
                        role="presentation"
                        src={deleteIcon}
                        alt="Delete"
                        title="Delete"
                        onClick={handleDeleteUser}
                      />
                    </span>
                  </AccessControl>
                  <AccessControl
                    permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
                    accessType={PERMISSION_TYPES.EDIT}
                  >
                    {userInfo.data.status === "unblocked" ||
                    userInfo.data.status === "deactived" ? (
                      <span className="iconWrapper">
                        <img
                          role="presentation"
                          src={blockIcon}
                          alt="Block"
                          title="Block"
                          onClick={handleBlockUser}
                        />
                      </span>
                    ) : (
                      <span className="iconWrapper">
                        <img
                          role="presentation"
                          src={unblock}
                          alt="unblock"
                          title="Unblock"
                          onClick={handleBlockUser}
                        />
                      </span>
                    )}
                  </AccessControl>
                </div>
              </div>
              <div className="userInformatinc">
                <div className="column_left">
                  <img
                    src={
                      userInfo.data.avatusImage
                        ? userInfo.data.avatusImage
                        : userprofile
                    }
                    alt="userprofile"
                  />
                </div>
                <div className="column_right">
                  <div className="userInfoinner userInfo">
                    <h2>{userInfo.data.username}</h2>
                    {
                      // data.status === 'deactived'
                      userInfo.data.status === "deactived" ? (
                        <div className="simple_text">Deactivated</div>
                      ) : userInfo.data.status === "blocked" ? (
                        <div className="simple_text">Blocked</div>
                      ) : userInfo.data.registerationStatus === "pending" ? (
                        <div className="simple_text">Pending</div>
                      ) : (
                        <div className="simple_text">Active</div>
                      )
                    }
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Nationality</th>
                        <th>Gender</th>
                        <th>Date of Birth</th>
                        <th>Phone no.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {userVerified
                            ? userData?.fullName
                            : userInfo.data.fullNameFlag
                              ? "Yes"
                              : "No"}
                        </td>
                        <td>
                          {userVerified
                            ? userData?.email
                            : userInfo.data.emailFlag
                              ? "Yes"
                              : "No"}
                        </td>
                        <td>
                          {userVerified
                            ? userData?.nationality
                            : userInfo.data.nationalityFlag
                              ? "Yes"
                              : "No"}
                        </td>
                        <td>
                          {userVerified
                            ? userData?.gender === "other"
                              ? "Gender Variant / Non Binary"
                              : userData?.gender
                            : userInfo.data.genderFlag
                              ? "Yes"
                              : "No"}
                        </td>
                        <td>
                          {userVerified
                            ? dateFormatting(userData?.dob)
                            : userInfo.data.ageFlag
                              ? "Yes"
                              : "No"}
                        </td>
                        <td>
                          {userVerified
                            ? userData?.phoneNo
                            : userInfo.data.phoneFlag
                              ? "Yes"
                              : "No"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="postDetails">
                <div className="column">
                  <div>
                    <p className="heading_styles">Post Count</p>
                    <p className="count_backround">{userInfo.data.postCount}</p>
                  </div>
                  <div>
                    <p className="heading_styles">Subscribed Channels</p>
                    <div className="domFlex">
                      <p className="count_backround">
                        {userInfo.data.subscribedChannelCount}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Events Hosted</p>
                    <div className="domFlex">
                      <Link
                        style={{ color: "black", textDecoration: "underline" }}
                        className="count_backround"
                        to={`/livestreaming?active=${id}`}
                      >
                        {userInfo.data.eventHosted}
                      </Link>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Purchases Made</p>
                    <div className="domFlex">
                      <Link
                        style={{ color: "black", textDecoration: "underline" }}
                        className="count_backround"
                        to={`/paymentmanagement?active=${id}`}
                      >
                        {userInfo.data.purchasesMade}
                      </Link>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Services Created</p>
                    <div className="domFlex">
                      <p className="count_backround">0</p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <p className="heading_styles">Earned (Transferrable)</p>
                    <div className="c_price">
                      <img src={avatcoin} alt="avatcoin" />
                      <p className="count_backround">{userInfo.data.earning}</p>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Payments</p>
                    <div className="domFlex">
                      <div className="c_price">
                        <img src={avatcoin} alt="avatcoin" />
                        <p className="count_backround">
                          {userInfo.data.payments}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Payouts</p>
                    <div className="domFlex">
                      <div className="c_price">
                        <img src={avatcoin} alt="avatcoin" />
                        <p className="count_backround">
                          {userInfo.data.payouts}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Escrow Amount</p>
                    <div className="domFlex">
                      <div className="c_price">
                        <img src={avatcoin} alt="avatcoin" />
                        <p className="count_backround">
                          {userInfo.data.escrowEarnings}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Registered On </p>
                    <div className="domFlex">
                      <p className="count_backround">
                        {dateFormatting(userInfo.data.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div>
                    <p className="heading_styles">Campaigns Created</p>
                    <div className="domFlex">
                      <p className="count_backround">0</p>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Campaigns Invested In</p>
                    <div className="domFlex">
                      <p className="count_backround">
                        {userInfo.data.campaignCount}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="heading_styles">Followers</p>
                    <p className="count_backround">
                      {userInfo.data.followersCount}
                    </p>
                  </div>
                  <div>
                    <p className="heading_styles">Following</p>
                    <p className="count_backround">
                      {userInfo.data.followingCount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="domBox">
                <p className="heading_styles">
                  Domes ({userInfo.data.categories.length})
                </p>
                <div className="domFlex">
                  {userInfo.data.categories.map((ele: any, index: any) => (
                    <p key={index} className="count_backround">
                      {ele.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="domBox">
                <p className="heading_styles">
                  Channel Created ({userInfo.data.channels.length})
                </p>
                <div className="domFlex">
                  {userInfo.data.channels.map((ele: any, index: any) => (
                    <p key={index} className="count_backround">
                      {ele.channelName}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <Accordion heading="Notification Details">
              <UserInfoNotification id={id} />
            </Accordion>
            <Accordion heading="Transaction Details">
              <TransactionDetail id={id} username={userInfo.data.username} />
            </Accordion>
            <Accordion heading="Login Details">
              <LoginDetailsUserInfo id={id} />
            </Accordion>
            <Accordion heading="User Report Detail">
              <UserReportDetail id={id} />
            </Accordion>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { userinfo } = state;
  return {
    userInfo: userinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserInfo: (payload: any) => dispatch(getUserInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);

