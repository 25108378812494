import React from 'react';
import { SessionFilter } from '../../forms';
import {
  AccessControl,
  DataTable,
  PERMISSION_KEYS,
  hasAccess,
  PERMISSION_TYPES,
  Modal,
  Overlay,
  SessionManagementBlocked,
  SessionManagementDelete,
} from '../../components';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSession } from '../../actions';
import { dateFormatting } from '../../utils/dateMethods';
import { exportToCSV, exportToExcel } from '../../utils/exportToExcel';
import { EcommerceService, SessionService } from '../../services';
import avatcoin from '../../assets/svg/avatcoin.svg';
import avatcoinsilver from '../../assets/images/silvercoin.png';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  fetchSession: any;
  session: any;
}

const EcommercePurchase = (props: Props) => {
  const {
    session,
    fetchSession,
  } = props;
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState<any>({
    startDate: '',
    endDate: '',
    search: '',
    status: '',
    page: 0,
    rowsPerPage: 10,
  });
  const [showModal, setShowModal] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleteSession, setDelete] = React.useState<any>({
    show: false,
    id: null,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showOverlay, setShowOverlay] = React.useState(false);

  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  React.useEffect(() => {
    fetchSession(filter);
  }, [fetchSession, filter]);

  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = session.data ? session.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitFilter = (data: any) => {
    if (data.endDate !== null
      || data.search !== ''
      || data.startDate !== null
      || data.Status !== '') {
      setFilter({
        ...filter,
        startDate: data.startDate === null ? '' : data.startDate,
        endDate: data.endDate === null ? '' : data.endDate,
        search: data.search.label,
        status: data.Status,
        page: 0,
      });
    }
  };

  const headCells: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'sessionname', label: 'Session Name/Title', sort: false,
    },
    {
      id: 'sessionprice', label: 'Session Price', sort: false,
    },
    {
      id: 'discountedprice', label: 'Discounted Price', sort: false,
    },
    {
      id: 'totalbookings', label: 'Total bookings', sort: false,
    },
    {
      id: 'serviceprovider', label: 'Service Provider Name', sort: true,
    },
    {
      id: 'status', label: 'Status', sort: true,
    },
    {
      id: 'addedon', label: 'Added on', sort: true,
    },
    {
      id: 'action', label: 'Action', sort: false,
    },
  ];
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDelete({ ...deleteSession, show: true, id: blockedid });
        break;
      default:
        break;
    }
  };
  const actionButtons = (action: any, idS: any) => (
    <>
      <div className="actionBtnsWrapper">
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
          accessType={PERMISSION_TYPES.DELETE}
        >
          <div className="actionBtnCover">
            <img
              src={deleteIcon}
              role="presentation"
              alt="delete"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleBlockUnblock('delete', idS)}
            />
          </div>
        </AccessControl>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div className="actionBtnCover">
            {action === 'active' ? (
              <img
                src={blockIcon}
                role="presentation"
                alt="block"
                width="35"
                title="Block"
                height="35"
                onClick={() => handleBlockUnblock('blocked', idS)}
              />
            ) : (
              <img
                src={unblock}
                role="presentation"
                alt="unblock"
                width="35"
                title="Unblock"
                height="35"
                onClick={() => handleBlockUnblock('unblocked', idS)}
              />
            )}
          </div>
        </AccessControl>
      </div>
    </>
  );

  function getStatus(status: string) {
    switch (status) {
      case 'active':
        return 'Active';
      case 'pending':
        return 'Draft';
      default:
        return 'Blocked';
    }
  }

  function createData(data: any, index: number): any {
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: data._id,
      sessionname: data.title.length > 20 ? (
        <div title={data.title}>
          {data.title.substring(0, 20)}
          ...
        </div>
      ) : data.title.length,
      sessionprice: (
        <div className="c_price">
          <img src={avatcoin} alt="avatcoin" />
          <p className="count_backround">
            {data.goldPrice}
          </p>
        </div>
      ),
      discountedprice: data.discountedPrice
        ? (
          <div className="c_price">
            <img src={avatcoin} alt="avatcoin" />
            <span>{data.discountedPrice.gold}</span>
            <span className="separator">+</span>
            <img src={avatcoinsilver} alt="avatcoin" />
            <span>{data.discountedPrice.silver}</span>
          </div>
        ) : '',
      totalbookings: data.bookingCount,
      serviceprovider: data.user[0].username,
      status: getStatus(data.status),
      addedon: dateFormatting(data.createdAt),
      ...(hasAccess(PERMISSION_KEYS.CHANNEL_MANAGEMENT, PERMISSION_TYPES.EDIT) && { action: actionButtons(data.status, data._id) }),
    };
  }

  const rows: any = [];
  if (session.data !== null && session.data) {
    session.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    } else if (event.target.localName !== 'img') {
      navigate(`/sessionmanagement/sessioninfo/${name.id}`);
    }
  };

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'eventtitle') {
      setFilter({
        ...filter,
        page: 0,
        isSortByTitle: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'dateofcreation') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'livedateandtime') {
      setFilter({
        ...filter,
        page: 0,
        isSortByScheduledDateTime: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategory: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'numberofparticipants') {
      setFilter({
        ...filter,
        page: 0,
        isAcceptedUserCount: order === 'asc' ? '1' : '-1',
      });
    }
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            OrderId: ele.orderId,
            Username: ele.username,
            Service_title: ele.servicetitle,
            Service_type: ele.servicetype,
            Service_Provider: ele.serviceprovider,
            Domes: ele.domes,
            'Purchase_Date_&_Time': ele.purchasedateandtime,
            Total_Price: ele.totalprice,
            DiscountPrice: `Gold(${ele.discountedprice.props.children[1].props.children}) + Silver(${ele.discountedprice.props.children[4].props.children})`,
            Coupons_applied: ele.couponsapplied,
            Amount_paid: ele.amountpaid,
            Order_Status: ele.orderstatus,
            Message: ele.message,
          });
        });
        exportToExcel(selectedRowDataModified, 'EcommerceAndPurchaseData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        EcommerceService.export({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            OrderId: ele.orderId,
            Username: ele.username,
            Service_title: ele.servicetitle,
            Service_type: ele.servicetype,
            Service_Provider: ele.serviceprovider,
            Domes: ele.domes,
            'Purchase_Date_&_Time': ele.purchasedateandtime,
            Total_Price: ele.totalprice,
            DiscountPrice: `Gold(${ele.discountedprice.props.children[1].props.children}) + Silver(${ele.discountedprice.props.children[4].props.children})`,
            Coupons_applied: ele.couponsapplied,
            Amount_paid: ele.amountpaid,
            Order_Status: ele.orderstatus,
            Message: ele.message,
          });
        });
        exportToCSV(selectedRowDataModified, 'EcommerceAndPurchaseData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        EcommerceService.export({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      status: '',
      search: '',
      isSortByTitle: '',
      isSortByCreated: '',
      isSortByScheduledDateTime: '',
      isSortByCategory: '',
      isAcceptedUserCount: '',
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked({ ...blocked, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
    setDelete({ ...deleteSession, show: false, id: null });
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          blocked.show && (
            <SessionManagementBlocked
              handleModalClose={handleModalClose}
              setOverlay={setShowOverlay}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <SessionManagementBlocked
              setOverlay={setShowOverlay}
              handleModalClose={handleModalClose}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
        {
          deleteSession.show && (
            <SessionManagementDelete
              handleModalClose={handleModalClose}
              setShowOverlay={setShowOverlay}
              deleteService={() => {
                SessionService
                  .deleteApi(deleteSession.id).then(() => {
                    setFilter({ ...filter, page: 0 });
                    handleModalClose();
                    setShowOverlay(false);
                  });
              }}
            />
          )
        }
      </Modal>
      <SessionFilter
        handleFormSubmit={handleSubmitFilter}
        handleExport={handleExport}
        handleResetSorts={handleResetSorts}
        loaderExport={loaderExport}
      />
      <div className="dataTableCover">
        <DataTable
          headCells_={headCells}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={session.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({ session: state.session });

const mapDispatchToProps = (dispatch: any) => ({
  fetchSession: (payload: any) => dispatch(getSession(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EcommercePurchase);
