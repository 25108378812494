import {
  DataTable, hasAccess, PERMISSION_KEYS, PERMISSION_TYPES,
} from '../../components';
import classNames from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

const CampaignReportDetailComponent = () => {
  const [selected, setSelected] = React.useState([]);
  const hideActions = () => hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.DELETE);

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'reporter', label: 'Campaign Title',
    },
    {
      id: 'reason', label: 'Description',
    },
    {
      id: 'reportsCount', label: 'Reports count',
    },
    {
      id: 'createdOn', label: 'Last Reported Date & Time',
    },
    ...(hideActions() ? [{
      id: 'action', label: 'Action',
    }] : []),
  ];
  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={[]}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={0}
          rowsPerPage={10}
          loading={false}
          handleChangePage={() => null}
          handleSort={() => null}
          handleChangeRowsPerPage={() => null}
          count={0}
        />
      </div>
    </>
  );
};

const mapStateToProps = () => ({
});
const mapDispatchToProps = () => ({
});

const CampaignReportDetail = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(CampaignReportDetailComponent);

export default CampaignReportDetail;
