import { DataTable } from '../../components';
import React from 'react';
import { connect } from 'react-redux';
import { getTransactionInfo } from '../../actions';
import { getDateAndTime } from '../../utils/dateMethods';
import avatcoin from '../../assets/svg/avatcoin.svg';
import avatcoinsilver from '../../assets/images/silvercoin.png';
import deducted from '../../assets/svg/money-deducted.svg';
import sent from '../../assets/svg/money-sent.svg';
import classNames from 'classnames';
import added from '../../assets/svg/money-added.svg';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  fetchTransactionInfo: any;
  transactionDetails: any;
  id: any;
  username: any;
}

const TransactionReportDetail: React.FC<Props> = (props: Props) => {
  const {
    fetchTransactionInfo, transactionDetails, id, username,
  } = props;
  const [selected, setSelected] = React.useState([]);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });

  React.useEffect(() => {
    fetchTransactionInfo(filter);
  }, [fetchTransactionInfo, filter]);
  const handleStatus = (status: any) => {
    switch (status) {
      case 'Success':
        return 'Successful';
      case 'pending':
        return 'Pending';
      case 'refund':
        return 'Refunded';
      case 'processing':
        return 'Processing';
      default:
        return '';
    }
  };

  function createData(data: any, index: number): any {
    const id_ = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      transactionId: data.transactionId,
      transactionMade: data.receiverUser?.username,
      rewardPointsEarned: (
        <div className="c_price">
          <img src={avatcoinsilver} alt="avatcoin" />
          <span>{data.silverCoin}</span>
        </div>),
      avatcoins: data.isViaDiscountPrice ? (
        <div className="c_price">
          {data?.transactionType === 'credit'
            ? <img src={added} alt="added" /> : data?.transactionType === 'debit'
              ? <img src={deducted} alt="deducted" /> : data?.transactionType === 'payout'
                ? <img src={sent} alt="sent" /> : (data?.transactionType === 'purchase' || data?.transactionType === 'purchase_gift') && data?.receiverUserId === id
                  ? <img src={added} alt="added" /> : <img src={deducted} alt="deducted" />}
          <img src={avatcoin} alt="avatcoin" />
          <span>{data.discountedPrice.gold}</span>
          <span className="separator">+</span>
          <img src={avatcoinsilver} alt="avatcoin" />
          <span>{data.discountedPrice.silver}</span>
        </div>
      ) : data.purchaseIn !== 'USD' ? (
        <div className="c_price">
          {data?.transactionType === 'credit'
            ? <img src={added} alt="added" /> : data?.transactionType === 'debit'
              ? <img src={deducted} alt="deducted" /> : data?.transactionType === 'payout'
                ? <img src={sent} alt="sent" /> : (data?.transactionType === 'purchase' || data?.transactionType === 'purchase_gift') && data?.receiverUserId === id
                  ? <img src={added} alt="added" /> : <img src={deducted} alt="deducted" />}
          <img src={avatcoin} alt="avatcoin" />
          <span>{data.goldCoin}</span>
        </div>
      ) : `$${data.amount}`,
      dateAndTime: getDateAndTime(data.createdAt),
      type: data.sourceUser?.username === username ? 'Debit' : 'Credit',
      status: data.sourceUser?.username === username ? handleStatus(data.sourceUserPaymentStatus) : handleStatus(data.receiverUserPaymentStatus),
    };
  }

  const rows: any = [];
  if (transactionDetails.data !== null && transactionDetails.data) {
    transactionDetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'transactionId', label: 'Transaction ID',
    },
    {
      id: 'transactionMade', label: 'Transaction made against',
    },
    {
      id: 'rewardPointsEarned', label: 'Reward Points Earned/Spent',
    },
    {
      id: 'avatcoins', label: 'Avatcoins Purchased/Spent',
    },
    {
      id: 'dateAndTime', label: ' Date & time',
    },
    {
      id: 'type', label: 'Type',
    },
    {
      id: 'status', label: 'Status',
    },
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const count = transactionDetails.data ? transactionDetails.data['Pagination-Count'] : 0;
  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={transactionDetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { transactionDetails } = state;
  return {
    transactionDetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchTransactionInfo: (payload: any) => dispatch(getTransactionInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReportDetail);
