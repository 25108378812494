import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import deleteImg from '../../assets/svg/delete.svg';
import Typography from '@mui/material/Typography';
import './tutorial.scss';
import { IconButton } from '@material-ui/core';

interface Props {
  title: string;
  thumbnail: string;
  link: string;
  dispatch: any;
  id: string;
  handleDelete: (id: string) => void;
}

export default function MediaCard(props: Props) {
  const { title, thumbnail, link, handleDelete, id } = props;
  return (
    <Card className="tutorial_card" onClick={(e: any) => {
      window.open(link);
    }}>
      <CardMedia
        sx={{ height: 140 }}
        image={thumbnail}
        title="green iguana"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <IconButton
          title="Delete"
          onClick={(e: any) => {
            e.stopPropagation();
            handleDelete(id);
          }}
        >
          <img
            src={deleteImg}
            alt=""
          />
        </IconButton>
      </CardContent>
    </Card>
  );
}