import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import {
  FieldTextInput,
  Button,
  FieldDatePicker,
  ValidationError,
  FieldSelect,
} from '../../components';
import { injectIntl } from '../../utils/reactIntl';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import carretDown from '../../assets/svg/caretdown.svg';
import filters from './FAQFilters.json';

interface Props {
  handleFormSubmit: any,
  handleResetSorts: any,
  intl: any,
  error: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const FAQSFiltersForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    handleResetSorts,
    intl,
    error,
  } = props;
  const formProps = useForm();
  const [showFilter, setShowFilter] = React.useState(true);
  const handleReset = () => {
    const {
      search, startDate, endDate, status,
    } = formProps.getValues();
    if (search !== ''
      || startDate !== null
      || endDate !== null
      || status !== '') {
      formProps.setValue('search', '');
      formProps.setValue('startDate', null);
      formProps.setValue('endDate', null);
      formProps.setValue('status', '');
      handleResetSorts();
    }
  };
  return (
    <form
      className="filterForm"
      onSubmit={formProps.handleSubmit(handleFormSubmit)}
    >
      <div className="filter_container">
        <div className="filterSearch">
          <div>
            <div className="filter">
              {intl.formatMessage({ id: 'FAQFilter.label.Title' })}
              <span
                className="closeBtn"
                role="presentation"
                onClick={() => setTimeout(() => {
                  setShowFilter((prevState) => !prevState);
                }, 200)}
              >
                {showFilter
                  ? (
                    <ClearIcon />
                  )
                  : <img src={carretDown} alt="down" />}
              </span>
            </div>
            <div className="fieldSet">
              <FieldTextInput
                id="search"
                name="search"
                formName="FAQsFilter"
                type="text"
                formProps={formProps}
                label={intl.formatMessage({ id: 'FAQFilter.label.Search' })}
              />
            </div>
          </div>
        </div>
        {showFilter && (
          <div>
            <p>
              {intl.formatMessage({ id: 'FAQFilter.label.AddedOn' })}
            </p>
            <div className="filterOptions">
              <div className="datepickerField">
                <FieldDatePicker
                  id="startDate"
                  name="startDate"
                  label={intl.formatMessage({ id: 'FAQFilter.label.StartDate' })}
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null
                    ? formProps.watch('endDate')
                    : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className="datepickerField">
                <FieldDatePicker
                  id="endDate"
                  name="endDate"
                  label={intl.formatMessage({ id: 'FAQFilter.label.EndDate' })}
                  formProps={formProps}
                  minDate={formProps.watch('startDate') !== null
                    ? formProps.watch('startDate')
                    : moment().subtract(118, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className="userType">
                <FieldSelect
                  id="status"
                  name="status"
                  variant={false}
                  label={intl.formatMessage({ id: 'FAQFilter.label.Status' })}
                  defaultValue=""
                  formProps={formProps}
                  options={filters}
                />
              </div>
              <div className="buttonWrapper">
                <Button
                  buttonType={ButtonTypes.submit}
                  className="button"
                >
                  {intl.formatMessage({ id: 'FAQFilter.label.Apply' })}
                </Button>
                <Button
                  buttonType={ButtonTypes.reset}
                  onClick={handleReset}
                  className="button"
                >
                  {intl.formatMessage({ id: 'FAQFilter.label.Reset' })}
                </Button>
              </div>
            </div>
            <ValidationError error={error} />
          </div>
        )}
      </div>
    </form>
  );
};

export default injectIntl(FAQSFiltersForm);
