import {
    put, call, takeEvery,
  } from 'redux-saga/effects';
  import { GetTutorials } from '../services';
  import { ActionTypes } from '../constants';
  
  async function fetchAsync(func: any, arg: any) {
    try {
      const response = arg !== null ? await func(arg) : await func();
      return response;
    } catch (error: any) {
      return {
        error: true,
        message: error.response.data.message,
        status: error.response.data.statusCode,
      };
    }
  }
  
  function* fetchTutorials(action: any): Generator {
    try {
      const data: any = yield call(fetchAsync, GetTutorials.getTutorials, null);
      if (data.error) {
        if (data.status === 403) {
          yield put({ type: ActionTypes.LOGOUT_WITH_ERROR_REQUEST });
        } else {
          yield put({ type: ActionTypes.GET_TUTORIALS_MGMT_ERROR, payload: data.message });
        }
      } else {
        yield put({ type: ActionTypes.GET_TUTORIALS_MGMT_SUCCESS, payload: data.data.data });
      }
    } catch (e: any) {
      yield put({ type: ActionTypes.GET_TUTORIALS_MGMT_ERROR, error: e.data });
    }
  }

  function* addTutorials(action: any): Generator {
    try {
      const data: any = yield call(fetchAsync, GetTutorials.addTutorials, action.payload);
      if (data.error) {
        if (data.status === 403) {
          yield put({ type: ActionTypes.LOGOUT_WITH_ERROR_REQUEST });
        } else {
          yield put({ type: ActionTypes.CREATE_TUTORIALS_MGMT_ERROR, payload: data.message });
        }
      } else {
        yield put({ type: ActionTypes.CREATE_TUTORIALS_MGMT_SUCCESS, payload: data.data.data });
      }
    } catch (e: any) {
      yield put({ type: ActionTypes.CREATE_TUTORIALS_MGMT_ERROR, error: e.data });
    }
  }
  
  export function* watchTutorialsSaga() {
    yield takeEvery(ActionTypes.GET_TUTORIALS_MGMT_REQUEST, fetchTutorials);
    yield takeEvery(ActionTypes.CREATE_TUTORIALS_MGMT_REQUEST, addTutorials);
  }
  export default watchTutorialsSaga;
  