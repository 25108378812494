import React from 'react';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ForgotPassword } from '../../forms';
import {
  Button,
} from '..';

import './forgotpass.module.scss';

interface Props {
  handleSubmit: any;
  intl: any;
  forgotpassSucess: any;
  forgotpassError: any;
}

const ForgotPassComponent: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit, forgotpassSucess, forgotpassError, intl,
  } = props;
  const navigate = useNavigate();
  const handleFormSubmit = (data: any) => {
    handleSubmit(data);
  };
  return (
    <section>
      {forgotpassSucess ? (
        <>
          <h2 className="sign_text">
            {intl.formatMessage({ id: 'ForgotPassword.back.reset' })}
          </h2>
          <p className="details_text">
            {intl.formatMessage({ id: 'ForgotPassword.labekl.detail' })}
          </p>
          <Button onClick={() => navigate('/')}>
            {intl.formatMessage({ id: 'ForgotPassword.back.login' })}
          </Button>
        </>
      ) : (
        <ForgotPassword
          handleFormSubmit={handleFormSubmit}
          forgotpassError={forgotpassError}
        />
      )}
    </section>
  );
};
const mapStateToProps = () => ({
});
const mapDispatchToProps = () => ({
});

const ForgotPass = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ForgotPassComponent);

export default ForgotPass;
