import {
  AccessControl,
  Button,
  DataTable,
  hasAccess,
  LiveStreamDelete,
  LiveStreamingBlocked,
  Modal,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
} from '../../components';
import React from 'react';
import { connect } from 'react-redux';
import { eventReportDetails } from '../../actions';
import { getDateAndTime } from '../../utils/dateMethods';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import '../Accordion/accordion.module.scss';

interface Props {
  id: any
  fetcheventInfo: any;
  eventreportdetail: any;
}

const EventReportDetail: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { fetcheventInfo, eventreportdetail, id } = props;
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });

  const hideActions = () => hasAccess(PERMISSION_KEYS.EVENT_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.EVENT_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.EVENT_MANAGEMENT, PERMISSION_TYPES.DELETE);

  React.useEffect(() => {
    fetcheventInfo(filter);
  }, [fetcheventInfo, filter]);
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      default:
        break;
    }
  };

  const actionButtons = (action: any, idS: any) => (
    <div className="actionBtnsWrapper">
      <AccessControl
        permissionKey={PERMISSION_KEYS.CATERGORIES}
        accessType={PERMISSION_TYPES.DELETE}
      >
        <div>
          <img
            src={deleteIcon}
            role="presentation"
            alt="delete"
            width="35"
            title="Delete"
            height="35"
            onClick={() => handleBlockUnblock('delete', idS)}
          />
        </div>
      </AccessControl>
      <AccessControl
        permissionKey={PERMISSION_KEYS.CATERGORIES}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          {action === 'pending' ? null : (action !== 'blocked' ? (
            <img
              src={blockIcon}
              role="presentation"
              alt="block"
              width="35"
              title="Block"
              height="35"
              onClick={() => handleBlockUnblock('blocked', idS)}
            />
          ) : (
            <img
              src={unblock}
              role="presentation"
              alt="unblock"
              width="35"
              title="Unblock"
              height="35"
              onClick={() => handleBlockUnblock('unblocked', idS)}
            />
          ))}
        </div>
      </AccessControl>
    </div>
  );

  function createData(data: any, index: number): any {
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: data._id,
      title: data.title.length > 20 ? (
        <div title={data.title}>
          {`${data.title.substring(0, 20)}...`}
        </div>
      ) : data.title,
      description: data.description !== null && data.description !== undefined ? (data.description.length > 20
        ? (
          <div title={data.description}>
            {`${data.description.substring(0, 20)}...`}
          </div>
        ) : data.description) : '',
      reportscount: data.reportCount,
      createdOn: data.lastReportedTime ? getDateAndTime(data.lastReportedTime) : '',
      ...(hideActions() && {
        action: actionButtons(data.status, data._id),
      }),
    };
  }

  const rows: any = [];
  if (eventreportdetail !== undefined
    && eventreportdetail.data !== null
    && eventreportdetail.data) {
    eventreportdetail.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'title', label: 'Event Title',
    },
    {
      id: 'description', label: 'Description',
    },
    {
      id: 'reportCount', label: 'Reports Count',
    },
    {
      id: 'createdOn', label: 'Last Reported Date & Time',
    },
    ...(hideActions() ? [{
      id: 'action', label: 'Action',
    }] : []),
  ];
  const handleModalClose = () => {
    setShowModal(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const count = eventreportdetail !== undefined
    && eventreportdetail.data !== null
    ? eventreportdetail.data['Pagination-Count'] : 0;

  const handleClick = (event: any, name: any) => {
    if (event.target.localName !== 'img') {
      navigate(`/livestreaming/livestreaminfo/${name.id}`);
    }
  };
  return (
    <>
      {showModal && (
        <Modal
          id="block-user"
          isOpen={showModal}
          onClose={handleModalClose}
          onManageDisableScrolling={() => null}
          lightCloseButton={false}
        >
          {deleted.show && (
            <LiveStreamDelete
              handleModalClose={handleModalClose}
              id={deleted.id}
              fetchLiveStreaming={() => setFilter({ ...filter, page: 0 })}
            />
          )}
          {blocked.show && (
            <LiveStreamingBlocked
              handleModalClose={handleModalClose}
              id={blocked.id}
              fetchLiveStreaming={() => setFilter({ ...filter, page: 0 })}
              status="blocked"
            />
          )}
          {unblocked.show && (
            <>
              <p>Event cancelled</p>
              <Button onClick={handleModalClose}>Ok</Button>
            </>
          )}
        </Modal>
      )}
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={eventreportdetail.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  eventreportdetail: state.eventreportdetail,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetcheventInfo: (payload: any) => dispatch(eventReportDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventReportDetail);
