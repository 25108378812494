import { Button } from '../../components';
import React, { useState } from 'react';
import { MasterReasonManagement } from '../../services';
import warning from '../../assets/svg/warning.svg';
import  '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  fetchReasonList: any;
  masterReasonId: any;
}
const MasterReasonManagementDelete: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    fetchReasonList,
    masterReasonId,
  } = props;

  const [del, setDel] = useState(false);

  const deleteService = () => {
    MasterReasonManagement.deleteMasterReason({
      masterReasonId,
    }).then(() => {
      setDel(true);
    });
  };

  return (
    <>
      {!del
        ? (
          <>
            <img src={warning} alt="warning" />
            <h2>
              Delete
            </h2>
            <p>
              Are you sure you want to delete the entered value?
            </p>
            <div className="modalButtonWrapper">
              <Button onClick={handleModalClose}>No</Button>
              <Button onClick={deleteService}>Yes</Button>
            </div>
          </>
        ) : (
          <>
            <h3>
              Deleted successfully !
            </h3>
            <Button onClick={() => {
              handleModalClose();
              fetchReasonList();
            }}
            >
              Ok
            </Button>
          </>
        )}
    </>
  );
};

export default MasterReasonManagementDelete;
