import api from '../api';

export class Notification {
  static async getNotificationService(payload: any) {
    const {
      platform,
      startDate,
      endDate,
      search,
      userType,
      page,
      sendBy,
      rowsPerPage,
      isSortByCreated,
      isSortBySentAt,
    } = payload;

    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${platform !== '' && platform !== undefined ? `&platforms=${platform}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${userType !== '' && userType !== undefined ? `&status=${userType}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortBySentAt !== '' && isSortBySentAt !== undefined ? `&isSortBySentAt=${isSortBySentAt}` : ''}${sendBy !== undefined && sendBy !== '' ? `&sendBy=${sendBy}` : ''}`;
    return api.getApiCall(`/api/v1/admins/notifications/save-notifications?${apiEndPoint}`);
  }

  static async addNotification(payload: any) {
    return api.postApiCall('/api/v1/admins/notifications', payload);
  }

  static async sendResend(payload: string) {
    return api.postApiCall('/api/v1/admins/notifications/resend', {
      notificationId: payload,
    });
  }

  static async uploadpicture(payload: any) {
    return api.postApiCall('/api/v1/admins/upload-files', payload);
  }

  static async getUserNotification(payload: any) {
    return api.getApiCall(`api/v1/admins/notifications?limit=${payload.data.rowsPerPage}&pageNo=${payload.data.page + 1}&userId=${payload.data.id}`);
  }

  static async getChannelNotification(payload: any) {
    return api.getApiCall(`api/v1/admins/notifications?limit=${payload.rowsPerPage}&pageNo=${payload.page + 1}&type=channel&channelId=${payload.id}`);
  }

  static async getAppNotification(payload: any) {
    return api.getApiCall(`api/v1/admins/admin-notifications?limit=${payload.limit}&pageNo=${payload.page + 1}`);
  }

  static async markReadNotiifcation(payload: any) {
    const {
      isReaded,
      isCleared,
      noitficationIds,
    } = payload;
    return api.putApiCall('api/v1/admins/admin-notifications', {
      isReaded,
      isCleared,
      noitficationIds,
    });
  }

  static async deleteNotification(payload: any) {
    return api.deleteApiCall(`/api/v1/admins/notifications?notificationId=${payload}`);
  }

  static async getNotification(id: any) {
    return api.getApiCall(`/api/v1/admins/admin-notifications/${id}`);
  }

  static async export(payload: any) {
    const {
      platform,
      startDate,
      endDate,
      search,
      userType,
      page,
      sendBy,
      rowsPerPage,
      isSortByCreated,
      isSortBySentAt,
      type,
    } = payload;

    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${platform !== '' && platform !== undefined ? `&platforms=${platform}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${userType !== '' && userType !== undefined ? `&status=${userType}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortBySentAt !== '' && isSortBySentAt !== undefined ? `&isSortBySentAt=${isSortBySentAt}` : ''}${sendBy !== undefined && sendBy !== '' ? `&sendBy=${sendBy}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/notifications/exports?${apiEndPoint}`);
  }
}
