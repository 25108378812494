import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  FieldTextInput,
  Button,
  ValidationError,
} from '../../components';
import { injectIntl } from '../../utils/reactIntl';
import './forgotpassword.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any;
  intl: any;
  forgotpassError: any;
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const ForgotPassForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    forgotpassError,
    intl,
  } = props;
  const forgotPassSchema = yup.object().shape({
    email: yup.string()
      .required(intl.formatMessage({ id: 'ForgotPass.error.email.required' }))
      .matches(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/,
        intl.formatMessage({ id: 'ForgotPass.error.email.matches' })),
  }, []);
  const formProps = useForm({
    resolver: yupResolver(forgotPassSchema),
  });
  const navigate = useNavigate();
  return (
    <form className="form" onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <p className="backBUtton" role="presentation" onClick={() => navigate('/login')}>
        <span>{'<'}</span>
        {' '}
        {intl.formatMessage({ id: 'ForgotPasswordFilter.reset.back' })}
        {' '}
      </p>
      <h2 className="sign_text">
        {intl.formatMessage({ id: 'ForgotPasswordFilter.reset.fogotpassword' })}
      </h2>
      <p className="details_text">
        {intl.formatMessage({ id: 'ForgotPasswordFilter.reset.resettext' })}
      </p>
      <FieldTextInput
        id="email"
        name="email"
        formName="ForgotPassword"
        type="text"
        formProps={formProps}
        label={intl.formatMessage({ id: 'ForgotPasswordFilter.reset.emailaddress' })}
      />
      {Object.keys(formProps.formState.errors).length > 0
        && (
          <ValidationError
            className="validation_error"
            error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
          />
        )}
      {forgotpassError && Object.keys(formProps.formState.errors).length === 0 && (
        <ValidationError
          className="validation_error"
          error={forgotpassError}
        />
      )}
      <Button buttonType={ButtonTypes.submit}>
        {intl.formatMessage({ id: 'ForgotPasswordFilter.reset.text' })}
      </Button>
    </form>
  );
};

export default injectIntl(ForgotPassForm);
