import React from 'react';
import { connect } from 'react-redux';
import { FeedbackManagementService } from '../../services';
import FeedbackManagementForm from '../../forms/FeedbackManagementForm';
import { range } from 'lodash';
import star from '../../assets/svg/ratingStar.svg';
import { getDateAndTime } from '../../utils/dateMethods';
import DataTable from '../Datatable';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import {
  CircularProgressLoader,
  ToastBar,
} from '..';
import { feedbackmanagement } from '../../actions';

interface Props {
  feedback: any;
  fetchFeedbackList: any;
}

const Feedbackmanagement: React.FC<Props> = (props: Props) => {
  const {
    feedback,
    fetchFeedbackList,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    ratings: '',
    search: '',
    isSortByCreated: '',
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showOverlay, setShowOverlay] = React.useState(true);
  const [averageRating, setAverageRating] = React.useState(0);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  React.useEffect(() => {
    FeedbackManagementService
      .getAverge()
      .then((ele: any) => {
        setShowOverlay(false);
        setAverageRating(ele.data.data[0].averageRating);
      });
    fetchFeedbackList(filter);
  }, [fetchFeedbackList, filter]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = feedback.data ? feedback.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitFilter = (data: any) => {
    if (data.rating !== '' || data.search !== '') {
      setFilter({
        ...filter,
        ratings: data.rating,
        search: data.search.label,
        page: 0,
      });
    }
  };
  const handleGetStart = (start: number) => (
    <>
      {
        range(start).map(() => <img src={star} alt="star" className="ratingImg" />)
      }
    </>
  );
  function createData(data: any, index: number): any {
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      username: data.username,
      rating: handleGetStart(data.rating),
      review: data.review,
      createdAt: getDateAndTime(data.createdAt),
    };
  }
  const rows: any = [];
  if (feedback.data !== null && feedback.data !== undefined && feedback) {
    feedback.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [{
    id: 'sno', sort: false, disablePadding: true, label: 'S.No',
  },
  {
    id: 'username', sort: false, disablePadding: false, label: 'Username',
  },
  {
    id: 'rating', sort: false, disablePadding: false, label: 'Ratings',
  },
  {
    id: 'review', sort: false, disablePadding: false, label: 'Reviews',
  },
  {
    id: 'createdAt', sort: true, disablePadding: false, label: 'Submit on',
  }];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'createdAt') {
      setFilter({ ...filter, page: 0, isSortByCreated: order === 'asc' ? '1' : '-1' });
    }
  };
  const handleResetSorts = () => {
    setFilter({
      ...filter,
      page: 0,
      rowsPerPage: 10,
      ratings: '',
      search: '',
    });
  };

  return (
    <>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <FeedbackManagementForm
          handleFormSubmit={handleSubmitFilter}
          handleResetSorts={handleResetSorts}
          error={undefined}
        />
        {showOverlay ? <CircularProgressLoader /> : (
          <>
            <h2 style={{ marginTop: '20px' }}>
              Average Rating :
              {averageRating}
              {' '}
              (
              {count}
              {' '}
              Users)
            </h2>
            <div className="dataTableCover">
              <DataTable
                headCells_={headCells}
                rows_={rows}
                rowClick={() => null}
                selected={selected}
                setSelected={setSelected}
                page={filter.page}
                rowsPerPage={filter.rowsPerPage}
                handleChangePage={handleChangePage}
                setSelectedRows={null}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                loading={feedback.loadingInProgress}
                handleSort={handleSort}
                count={count}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  feedback: state.feedbackmanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFeedbackList: (payload: any) => dispatch(feedbackmanagement(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedbackmanagement);
