import api from '../api';

export class GetTutorials {
  static async getTutorials() {
    return api.getApiCall(`/api/v1/admins/video-tutorial`);
  }
  static async addTutorials(payload: any) {
    return api.postApiCall(`/api/v1/admins/video-tutorial`, payload);
  }
  static async deleteTutorials(payload: any) {
    return api.deleteApiCall(`/api/v1/admins/video-tutorial`, {videoTutorialId: payload});
  }
}
