import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  Button,
  FieldDatePicker,
  FieldSelect,
  FieldTextInput,
  CircularProgressLoader,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
} from '../../components';
import ClearIcon from '@material-ui/icons/Clear';
import status from './status.json';
import { FormattedMessage, injectIntl } from '../../utils/reactIntl';
import carretDown from '../../assets/svg/caretdown.svg';
import exp from '../../assets/svg/export.svg';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any,
  handleExport: any,
  handleResetSorts: any,
  loaderExport: any,
  intl: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const PaymentManagementFilter: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    handleExport,
    handleResetSorts,
    loaderExport,
  } = props;
  const formProps = useForm();
  const [showFilter, setShowFilter] = React.useState(true);
  const [toggle, setToggle] = React.useState(false);
  const handleReset = () => {
    formProps.reset({
      search: '',
      startDate: null,
      endDate: null,
      status: '',
      Platform: '',
      notificationType: '',
    });
    handleResetSorts();
  };

  return (
    <form
      className="filterForm"
      onSubmit={formProps.handleSubmit(handleFormSubmit)}
    >
      <div className="filter_container">
        <div className="filterSearch">
          <div>
            <div className="filter">
              {intl.formatMessage({ id: 'NotificationManagement.label.filter' })}
              <span
                className="closeBtn"
                role="presentation"
                onClick={() => setTimeout(() => {
                  setShowFilter((prevState) => !prevState);
                }, 200)}
              >
                {showFilter
                  ? <ClearIcon />
                  : <img src={carretDown} alt="down" />}
              </span>
            </div>
            <div className="fieldSet">
              <FieldTextInput
                id="search"
                type="text"
                name="search"
                formProps={formProps}
                placeholder="Search "
                formName="Version"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className="userRightExport">
              {!loaderExport
                ? (
                  <>
                    <span>
                      <img
                        src={exp}
                        alt="export"
                        role="presentation"
                        onClick={() => setToggle((prevState: boolean) => !prevState)}
                      />
                    </span>
                    {toggle && (
                      <ul className="userrigthToggle">
                        <li
                          role="presentation"
                          onClick={() => handleExport('XLSX')}
                        >
                          {intl.formatMessage({ id: 'NotificationManagement.label.expot.xlsx' })}
                        </li>
                        <li
                          role="presentation"
                          onClick={() => handleExport('CSV')}
                        >
                          {intl.formatMessage({ id: 'NotificationManagement.label.export.csv' })}
                        </li>
                      </ul>
                    )}
                  </>
                ) : <div className="exportProgressBar"><CircularProgressLoader /></div>}
            </div>
          </AccessControl>
        </div>
        {showFilter && (
          <div>
            <p>
              Added on
            </p>
            <div className="filterOptions">
              <div className="datepickerField">
                <FieldDatePicker
                  id="startDate"
                  name="startDate"
                  label={intl.formatMessage({ id: 'NotificationManagement.startDate.label' })}
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null ? formProps.watch('endDate') : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className="datepickerField">
                <FieldDatePicker
                  id="endDate"
                  name="endDate"
                  label={intl.formatMessage({ id: 'NotificationManagement.endDate.label' })}
                  formProps={formProps}
                  minDate={formProps.watch('startDate') !== null ? formProps.watch('startDate') : moment().subtract(118, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className="userType">
                <FieldSelect
                  id="status"
                  name="status"
                  variant={false}
                  label="Transaction status"
                  formProps={formProps}
                  options={status}
                />
              </div>
              <div className="buttonWrapper">
                <Button buttonType={ButtonTypes.submit} className="button">
                  <FormattedMessage id="NotificationManagement.apply.label" />
                </Button>
                <Button onClick={handleReset} className="button">
                  <FormattedMessage id="NotificationManagement.reset.label" />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default injectIntl(PaymentManagementFilter);
