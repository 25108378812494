import api from '../api';

export class PaymentinfoManagementService {
  static async getParticipantDetails(payload: any) {
    return api.getApiCall(`api/v1/admins/transactions/${payload}`);
  }

  static async exportPaymentInfo(payload: any) {
    return api.postApiCall('api/v1/admins/transactions/exports/details', {
      type: 'xlsx',
      transactionId: payload.transactionId,
      optionCode: payload.optionCode,
      ...(payload.values && { detailKeys: payload.values }),
    });
  }
}
