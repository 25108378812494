import { combineReducers } from 'redux';
import userreportdetail from './usereporteduser';
import rolesmanagement from './rolesmanagement';
import routing from './routing';
import auth from './auth';
import usermanagement from './usermanagement';
import channelmanagement from './channelmanagement';
import userinfo from './userInfo';
import channelinfo from './channelInfo';
import updateProfile from './updateProfile';
import domesmanagement from './domesmanagement';
import subdomesmanagement from './subdomesmanagement';
import contentmanagement from './contentmanagement';
import faqs from './faqs';
import postcreated from './postcreated';
import logindetails from './logindetails';
import subadminmanagement from './subadminmanagement';
import creditactivity from './creditactivity';
import debitactivity from './debitactivity';
import reportedusermanagement from './reportedusermanagement';
import notesdetails from './notesdetails';
import postreportdetail from './postreportdetail';
import channelreported from './channelreported';
import notificationdetails from './notificationdetail';
import pricemanagement from './pricemanagement';
import permission from './permission';
import notification from './notification';
import notificationmanagement from './notificationmanagement';
import masterreasonmanagement from './masterreasonmanagement';
import masterconditionmanagement from './masterconditionmanagement';
import feedbackmanagement from './feedback';
import manageversion from './manageversion';
import livestreaming from './livestreaming';
import livestreaminfo from './livestreaminfo';
import participantdetails from './participantdetails';
import eventdetails from './eventdetails';
import postdetails from './postdetail';
import postdetailreporteduserinfo from './postdetailreporteduserinfo';
import support from './support';
import supportInfo from './supportinfo';
import payementmanagement from './paymentmanagement';
import paymentinfo from './paymentinfo';
import usernotification from './usernotification';
import channelnotificationdetail from './channelnotificationdetail';
import coinactivity from './coinactivity';
import eventreportdetail from './eventreportdetail';
import adminnotification from './adminnotification';
import transactionDetails from './transactiodetails';
import dashboard from './dashboard';
import notificationedit from './notificationedit';
import ecommerce from './ecommerce';
import ecommerceinfo from './ecommerceinfo';
import ecommercenotification from './notificationecommerce';
import session from './sessionmanagement';
import sessionInfo from './sessioninfo';
import subscriptionplan from './subscriptionplan';
import tutorials from './tutorials';
import subscribedusers from './subscribedusers';
import subscriptiondetails from './subscriptiondetails';
import commissionmanagement from './commissionmanagement';
import commissiondetails from './commissiondetails';
import commissionrules from './commissionrules';
import commissionrulesupdate from './commissionrulesupdate';

const rootReducer = combineReducers({
  routing,
  auth,
  usermanagement,
  userinfo,
  channelmanagement,
  channelinfo,
  updateProfile,
  domesmanagement,
  subdomesmanagement,
  contentmanagement,
  faqs,
  rolesmanagement,
  postcreated,
  logindetails,
  subadminmanagement,
  creditactivity,
  debitactivity,
  reportedusermanagement,
  notesdetails,
  userreportdetail,
  postreportdetail,
  channelreported,
  notificationdetails,
  pricemanagement,
  permission,
  notification,
  notificationmanagement,
  masterreasonmanagement,
  masterconditionmanagement,
  feedbackmanagement,
  manageversion,
  livestreaming,
  livestreaminfo,
  participantdetails,
  eventdetails,
  postdetails,
  postdetailreporteduserinfo,
  support,
  supportInfo,
  payementmanagement,
  paymentinfo,
  usernotification,
  channelnotificationdetail,
  coinactivity,
  eventreportdetail,
  adminnotification,
  transactionDetails,
  dashboard,
  notificationedit,
  ecommerce,
  ecommerceinfo,
  ecommercenotification,
  session,
  sessionInfo,
  subscriptionplan,
  tutorials,
  subscribedusers,
  subscriptiondetails,
  commissionmanagement,
  commissiondetails,
  commissionrules,
  commissionrulesupdate
});

const appReducer = (state: any, action: any) => rootReducer(state, action);

export default appReducer;
