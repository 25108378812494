import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dateFormatting } from '../../utils/dateMethods';
import {
  ChannelManagementBlocked,
  hasAccess,
  Modal,
  Overlay,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ToastBar,
} from '../../components';
import { ChannelManagementService } from '../../services';
import { exportToCSV, exportToExcel } from '../../utils/exportToExcel';
import DataTable from '../Datatable';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import { getChannelList } from '../../actions';
import ChannelManagementFilter from '../../forms/ChannelManagementFilter';
import blockIcon from '../../assets/svg/block.svg';
import unblockIcon from '../../assets/svg/unblock.svg';

interface Props {
  channellist: any;
  fetchChannelList: any;
}

const ChannelManagement: React.FC<Props> = (props: Props) => {
  const {
    channellist,
    fetchChannelList,
  } = props;
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    startDate: '',
    endDate: '',
    userType: '',
    search: '',
    domes: '',
    isSortByCreated: '',
    isSortByCategoryName: '',
    isSortByChannelName: '',
    isSortByUserName: '',
  });
  const [domes, setDomes] = React.useState<any>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [loaderExport, setLoaderExport] = React.useState(false);
  React.useEffect(() => {
    fetchChannelList(filter);
  }, [fetchChannelList, filter]);
  React.useEffect(() => {
    ChannelManagementService.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
    });
  }, []);
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      default:
        break;
    }
  };
  function capitalize(str: string) {
    return str === 'inactive' ? 'Deactivated' : str === 'active' ? 'Active' : 'Blocked';
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = channellist.data ? channellist.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitChannelFilter = (data: any) => {
    if (data.endDate !== null
      || data.search !== ''
      || data.startDate !== null
      || data.userType !== ''
      || data.domes !== '') {
      setFilter({
        ...filter,
        startDate: data.startDate ? data.startDate.utc() : '',
        endDate: data.endDate ? data.endDate.utc() : '',
        search: data.search.label,
        domes: data.domes,
        userType: data.userType,
        page: 0,
      });
    }
  };
  const handleModalClose = () => {
    setShowModal(false);
    setBlocked({ ...blocked, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };
  const actionButtons = (action: any, idS: any) => (
    <>
      {action === 'active' || action === 'inactive' ? (
        <img
          src={blockIcon}
          role="presentation"
          alt="block"
          width="35"
          title="Block"
          height="35"
          onClick={() => handleBlockUnblock('blocked', idS)}
        />
      ) : (
        <img
          src={unblockIcon}
          role="presentation"
          alt="unblock"
          width="35"
          title="Unblock"
          height="35"
          onClick={() => handleBlockUnblock('unblocked', idS)}
        />
      )}
    </>
  );

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      channelName: data.channelName,
      avatar: <img src={data.channelIconImage} alt={data.channelName} width={50} height={50} />,
      username: data?.user?.username || '',
      linktowebsite: data.link,
      domes: data.category.name,
      pricingplan: data.subscriptionType === 'free' ? 'Free' : 'Paid',
      subscribercount: data.subscriberCount,
      channelType: capitalize(data.status),
      postCount: data.postCount,
      createdAt: dateFormatting(data.createdAt),
      ...(hasAccess(PERMISSION_KEYS.CHANNEL_MANAGEMENT, PERMISSION_TYPES.EDIT) && { action: actionButtons(data.status, idS) }),
    };
  }

  const rows: any = [];
  if (channellist.data !== null && channellist.data) {
    channellist.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCells: any = hasAccess(PERMISSION_KEYS.CHANNEL_MANAGEMENT, PERMISSION_TYPES.EDIT) ? [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'channelName', label: 'Channel Name', sort: true,
    },
    {
      id: 'avatar', label: 'Channel Pic', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'linktowebsite', label: 'Link to Website', sort: false,
    },
    {
      id: 'domes', label: 'Domes', sort: true,
    },
    {
      id: 'pricingplan', label: 'Pricing Plan', sort: false,
    },
    {
      id: 'subscribercount', label: 'No. of Subscribers', sort: false,
    },
    {
      id: 'channelType', label: 'Status', sort: false,
    },
    {
      id: 'postCount', label: 'No. of Post', sort: false,
    },
    {
      id: 'createdAt', label: 'Created On', sort: true,
    },
    {
      id: 'action', label: 'Action', sort: false,
    },
  ] : [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'channelName', label: 'Channel Name', sort: true,
    },
    {
      id: 'avatar', label: 'Avatar', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'linktowebsite', label: 'Link to Website', sort: false,
    },
    {
      id: 'domes', label: 'Domes', sort: true,
    },
    {
      id: 'pricingplan', label: 'Pricing Plan', sort: false,
    },
    {
      id: 'subscribercount', label: 'No. of Subscribers', sort: false,
    },
    {
      id: 'channelType', label: 'Type', sort: false,
    },
    {
      id: 'postCount', label: 'No. of Post', sort: false,
    },
    {
      id: 'createdAt', label: 'Created On', sort: true,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'channelName') {
      setFilter({
        ...filter,
        page: 0,
        isSortByChannelName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'username') {
      setFilter({
        ...filter,
        page: 0,
        isSortByUserName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Channel_Name: ele.channelName,
            Channel_pic: ele.avatar.props.src,
            Username: ele.username,
            Link_To_Website: ele.linktowebsite,
            Domes: ele.domes,
            Price_Plan: ele.pricingplan,
            Subscriber_Count: ele.subscribercount,
            Channel_Type: ele.channelType,
            Post_Count: ele.postCount,
            Created_On: dateFormatting(ele.createdAt),
          });
        });
        exportToExcel(selectedRowDataModified, 'ChannelManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        ChannelManagementService.exportUserListToMail({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        }).catch((err: any) => {
          setShowOverlay(false);
          setNotification({
            show: true,
            message: err.response.data.message,
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            S_No: ele.sno,
            Channel_Name: ele.channelName,
            Channel_pic: ele.avatar.props.src,
            Username: ele.username,
            Link_To_Website: ele.linktowebsite,
            Domes: ele.domes,
            Price_Plan: ele.pricingplan,
            Subscriber_Count: ele.subscribercount,
            Channel_Type: ele.channelType,
            Post_Count: ele.postCount,
            Created_On: dateFormatting(ele.createdAt),
          });
        });
        exportToCSV(selectedRowDataModified, 'ChannelManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        ChannelManagementService.exportUserListToMail({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        }).catch((err: any) => {
          setShowOverlay(false);
          setNotification({
            show: true,
            message: err.response.data.message,
          });
        });
      }
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      startDate: '',
      endDate: '',
      userType: '',
      search: '',
      domes: '',
      isSortByUserName: '',
      isSortByRegister: '',
      isSortByCreated: '',
      isSortByCategoryName: '',
      isSortByChannelName: '',
    });
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'img' && event.target.alt === 'block') {
      handleBlockUnblock('blocked', name.id);
    } else if (event.target.localName === 'img' && event.target.alt === 'unblock') {
      handleBlockUnblock('unblocked', name.id);
    } else if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    } else navigate(`/channelmanagement/channeldetails/${name.id}`);
  };
  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        showOnClose={false}
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          blocked.show && (
            <ChannelManagementBlocked
              handleModalClose={handleModalClose}
              setOverlay={setShowOverlay}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <ChannelManagementBlocked
              setOverlay={setShowOverlay}
              handleModalClose={handleModalClose}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <ChannelManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitChannelFilter}
          domes={domes}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className="dataTableCover">
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={channellist.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { channelmanagement } = state;
  return {
    channellist: channelmanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchChannelList: (payload: any) => dispatch(getChannelList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelManagement);
