import {
  Table, TableBody, TableCell, TableRow,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import React from 'react';
import './showTable.module.scss';

interface Props {
  headerRows: any;
}

const ShowTable = (props: Props) => {
  const { headerRows } = props;
  const useStyles = makeStyles(() => createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      borderRadius: '0',
    },
    table: {
      minWidth: 750,
      background: '#fffff',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },

  }));
  const classes = useStyles();
  return (
    <Table
      className={classes.table}
      aria-labelledby="tableTitle"
      size="medium"
      aria-label="enhanced table"
    >
      {/* <TableBody>
        <TableRow>
          <TableCell>
            <CircularProgressLoader />
          </TableCell>
        </TableRow>
      </TableBody>
      )
      : ( */}
      <TableBody>
        {headerRows.length === 0
          ? (
            <>
              <TableRow>
                <TableCell>
                  <div className="headingWrapper">
                    <h3> No Record Found </h3>
                  </div>
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              {headerRows.map((row: any, index: number) => (
                <>
                  <TableRow key={index}>
                    <TableCell className="leftcolumn">
                      {row.key}
                    </TableCell>
                    <TableCell>
                      {row.values}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </>
          )}
      </TableBody>
    </Table>
  );
};

export default ShowTable;
