import classNames from 'classnames';
import { DataTable } from '../../components';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserNotificationDetail } from '../../actions';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { getDateAndTime } from '../../utils/dateMethods';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  fetchUserNotification: any;
  usernotification: any;
  id: any;
}

const UserInfoNotificationDetail = (props: Props) => {
  const { fetchUserNotification, usernotification, id } = props;
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({
    rowsPerPage: 10,
    page: 0,
    id,
  });
  useEffect(() => {
    fetchUserNotification(filter);
  }, [fetchUserNotification, filter]);

  const handleFormatSendBy = (sendBy: string) => {
    switch (sendBy) {
      case 'email':
        return 'Email';
        break;
      case 'push':
        return 'Push';
        break;
      case 'sms':
        return 'SMS';
      default:
        return sendBy;
    }
  };

  function createData(data: any, index: number): any {
    const id_ = data._id;
    const blocksFromHTML = convertFromHTML(data.message);
    const htmlData = EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    ));
    const desc = (
      <div title={htmlData.getCurrentContent().getPlainText()}>
        {htmlData.getCurrentContent().getPlainText().length > 20 ? `${htmlData.getCurrentContent().getPlainText().substring(0, 20)}...` : htmlData.getCurrentContent().getPlainText()}
      </div>
    );
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      title: data.title ? data.title.length > 20
        ? (
          <div title={data.title}>
            {data.title.substring(0, 20)}
            ...
          </div>
        ) : data.title : 'SMS',
      desc: data.message ? data.message.replace(/<[^>]+>/g, '').length > 20
        ? (
          <div title={data.message.replace(/<[^>]+>/g, '')}>
            {data.message.replace(/<[^>]+>/g, '').substring(0, 20)}
            ...
          </div>
        ) : data.message.replace(/<[^>]+>/g, '') : '',
      sentby: data.admin ? data.admin.name : '',
      type: handleFormatSendBy(data.sendBy),
      senton: getDateAndTime(data.createdAt),
    };
  }

  const rows: any = [];
  if (usernotification.data !== null && usernotification) {
    usernotification.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'title', label: 'Title',
    },
    {
      id: 'desc', numeric: false, disablePadding: false, label: 'Description',
    },
    {
      id: 'sentby', label: 'Sent by',
    },
    {
      id: 'type', label: 'Type',
    },
    {
      id: 'senton', label: 'Sent on',
    },
  ];
  const count = usernotification.data ? usernotification.data['Pagination-Count'] : 0;
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headCells}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={usernotification.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({ usernotification: state.usernotification });

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserNotification: (payload: any) => dispatch(getUserNotificationDetail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoNotificationDetail);
