import React from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import {
  Button,
  ValidationError,
  CircularProgressLoader,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  FieldTextInput,
} from '../../components';
import exp from '../../assets/svg/export.svg';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any,
  handleResetSorts: any,
  handleExport: any,
  intl: any,
  error: any,
  loaderExport: boolean,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const VersionManagementFilter: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    error,
    handleExport,
    loaderExport,
    handleResetSorts,
  } = props;
  const [toggle, setToggle] = React.useState(false);
  const formProps = useForm();
  const handleReset = () => {
    formProps.reset({
      search: '',
    });
    handleResetSorts();
  };
  return (
    <form
      className="filterForm"
      onSubmit={formProps.handleSubmit(handleFormSubmit)}
    >
      <div className="filter_container">
        <div className="filterSearch">
          <div>
            <div className="searchField">
              <FieldTextInput
                id="search"
                type="text"
                name="search"
                formProps={formProps}
                placeholder={intl.formatMessage({ id: 'VersionManagement.label.search' })}
                formName="Version"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.VERSION_MANAGEMENT}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className="userRightExport">
              {!loaderExport
                ? (
                  <>
                    <span>
                      <img
                        src={exp}
                        alt="export"
                        role="presentation"
                        onClick={() => setToggle((prevState: boolean) => !prevState)}
                      />
                    </span>
                    {toggle && (
                      <ul className="userrigthToggle">
                        <li
                          role="presentation"
                          onClick={() => handleExport('XLSX')}
                        >
                          {intl.formatMessage({ id: 'VersionManagement.label.expot.xlsx' })}
                        </li>
                        <li
                          role="presentation"
                          onClick={() => handleExport('CSV')}
                        >
                          {intl.formatMessage({ id: 'VersionManagement.label.export.csv' })}
                        </li>
                      </ul>
                    )}
                  </>
                ) : <div className="exportProgressBar"><CircularProgressLoader /></div>}
            </div>
          </AccessControl>
        </div>
        <div className="filterOptions">
          <div className="buttonWrapper">
            <Button buttonType={ButtonTypes.submit} className="button">
              {intl.formatMessage({ id: 'VersionManagement.label.apply' })}
            </Button>
            <Button onClick={handleReset} className="button">
              {intl.formatMessage({ id: 'VersionManagement.label.reset' })}
            </Button>
          </div>
        </div>
        {error && (
          <ValidationError
            className="validation_error"
            error={error}
          />
        )}
      </div>
    </form>
  );
};

export default injectIntl(VersionManagementFilter);
