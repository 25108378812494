import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { fetchMasterCondition } from '../../actions';
import {
  CircularProgressLoader,
  Modal,
  MasterConditionManagementEdit,
  // ValidationError,
  MasterConditionManagementBlock,
} from '../../components';

import './mastercondition.module.scss';
// import saveIcon from '../../assets/svg/save.svg';
// import editIcon from '../../assets/svg/edit.svg';
// import blockIcon from '../../assets/svg/block.svg';
// import unblockIcon from '../../assets/svg/unblock.svg';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  masterconditionmanagement: any;
  fetchMasterConditionList: any;
}

const MasterConditionManagementComponent: React.FC<Props> = (props: Props) => {
  const { masterconditionmanagement, fetchMasterConditionList } = props;
  const [editableRow, setEditableRow] = React.useState<any>({
    id: null,
    age: '',
    existance: '',
    condition: '',
  });
  const [showModal, setShowModal] = React.useState(false);
  const [idx, setIdx] = React.useState<any>();
  // const [validationexiting, setValidationExisting] = React.useState<any>([{}]);
  const [edit, setEdit] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [block, setBlock] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblock, setUnBlock] = React.useState<any>({
    show: false,
    id: null,
  });

  const handleModalClose = () => {
    setShowModal(false);
    setEdit({ ...edit, show: false, id: null });
    setBlock({ ...block, show: false, id: null });
    setDeleted({ ...deleted, show: false, id: null });
    setUnBlock({ ...unblock, show: false, id: null });
  };
  React.useEffect(() => {
    fetchMasterConditionList();
  }, [fetchMasterConditionList]);
  return (
    <>
      <Modal
        id="mastercondition"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          edit.show && (
            <MasterConditionManagementEdit
              handleModalClose={handleModalClose}
              fetchPriceList={() => {
                fetchMasterConditionList();
                setEdit({
                  show: false,
                  id: null,
                });
                setIdx(0);
              }}
              editableRow={editableRow}
            />
          )
        }
        {
          block.show && (
            <MasterConditionManagementBlock
              handleModalClose={handleModalClose}
              id={block.id}
              fetchPriceList={() => {
                fetchMasterConditionList();
              }}
              status="blocked"
            />
          )
        }
        {
          unblock.show && (
            <MasterConditionManagementBlock
              handleModalClose={handleModalClose}
              id={unblock.id}
              fetchPriceList={() => {
                fetchMasterConditionList();
              }}
              status="unblocked"
            />
          )
        }
      </Modal>
      <div className="page_header">
        <div className="buttonWrapper buttonWrapper_s">
          <h2>Master Condition Management</h2>
        </div>
      </div>
      <hr />
      <div>
        <p>Rules for Campaigns/Events/Channels</p>
      </div>
      <div className="dataTableCover">
        <Table
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                S.No
              </TableCell>
              <TableCell>
                Description
              </TableCell>
              <TableCell>
                Conditions
              </TableCell>
              {/* <TableCell>
                Action
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {masterconditionmanagement.loadingInProgress || masterconditionmanagement.data === null
              ? (
                <TableRow>
                  <TableCell>
                    <CircularProgressLoader />
                  </TableCell>
                </TableRow>
              )
              : (
                <>
                  <TableRow>
                    <TableCell>
                      1
                    </TableCell>
                    <TableCell>
                      Minimum Age
                    </TableCell>
                    <TableCell>
                      {idx === 1 ? (
                        <>
                          <TextField
                            name="age"
                            type="number"
                            value={editableRow.age}
                            onChange={(e: any) => {
                              setEditableRow({ ...editableRow, age: e.target.value });
                            }}
                          />
                          {/* {validationexiting.age !== undefined
                            && <ValidationError error={validationexiting.age} />} */}
                        </>
                      ) : (
                        <div className="imagewrapper">
                          <p>18</p>
                          {/* <p>{masterconditionmanagement.data.minimumAge}</p> */}
                        </div>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      <div className="actionBtnsWrapper">
                        {edit.show && edit.id === 1 ? (
                          <div>
                            <img
                              src={saveIcon}
                              role="presentation"
                              alt="save"
                              width="35"
                              title="Save"
                              height="35"
                              onClick={() => {
                                let flag = true;
                                const valida: any = {};
                                if (editableRow?.age === '') {
                                  flag = false;
                                  valida.age = 'Please enter the required field';
                                }

                                if (flag) {
                                  setShowModal(true);
                                  setEdit({
                                    show: true,
                                    id: 1,
                                  });
                                  setIdx(1);
                                } else {
                                  setValidationExisting(valida);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={editIcon}
                              role="presentation"
                              alt="edit"
                              width="35"
                              title="Edit"
                              height="35"
                              onClick={() => {
                                setEdit({
                                  ...edit,
                                  id: 1,
                                  show: true,
                                });
                                setIdx(1);
                                setEditableRow({
                                  id: masterconditionmanagement.data._id,
                                  age: masterconditionmanagement.data.minimumAge,
                                });
                              }}
                            />
                          </div>
                        )}

                        <div>
                          {masterconditionmanagement.data.isMinimumAgeEnable ? (
                            <img
                              src={blockIcon}
                              role="presentation"
                              alt="block"
                              width="35"
                              title="block"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setBlock({
                                  ...block,
                                  show: true,
                                  id: 'age',
                                });
                              }}
                            />
                          ) : (
                            <img
                              src={unblockIcon}
                              role="presentation"
                              alt="unblock"
                              width="35"
                              title="Unblock"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setUnBlock({
                                  ...block,
                                  show: true,
                                  id: 'age',
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      2
                    </TableCell>
                    <TableCell>
                      User’s existence in the platform
                    </TableCell>
                    <TableCell>
                      {idx === 2 ? (
                        <>
                          <TextField
                            name="description"
                            type="number"
                            value={editableRow.existance}
                            onChange={(e: any) => {
                              setEditableRow({ ...editableRow, existance: e.target.value });
                            }}
                          />
                          {/* {validationexiting.existance !== undefined
                            && <ValidationError error={validationexiting.existance} />} */}
                        </>
                      ) : (
                        <div className="imagewrapper">
                          <p>60</p>
                          {/* <p>{masterconditionmanagement.data.minimumAccountAge}</p> */}
                        </div>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      <div className="actionBtnsWrapper">
                        {edit.show && edit.id === 2 ? (
                          <div>
                            <img
                              src={saveIcon}
                              role="presentation"
                              alt="save"
                              width="35"
                              title="Save"
                              height="35"
                              onClick={() => {
                                let flag = true;
                                const valida: any = {};
                                if (editableRow.existance === undefined
                                  || editableRow.existance === '') {
                                  flag = false;
                                  valida.existance = 'Please enter the required field';
                                }

                                if (flag) {
                                  setShowModal(true);
                                  setEdit({
                                    show: true,
                                    id: 2,
                                  });
                                  setIdx(2);
                                } else {
                                  setValidationExisting(valida);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={editIcon}
                              role="presentation"
                              alt="edit"
                              width="35"
                              title="Edit"
                              height="35"
                              onClick={() => {
                                setEdit({
                                  ...edit,
                                  id: 2,
                                  show: true,
                                });
                                setIdx(2);
                                setEditableRow({
                                  id: masterconditionmanagement.data._id,
                                  existance: masterconditionmanagement.data.minimumAccountAge,
                                });
                              }}
                            />
                          </div>
                        )}
                        <div>
                          {masterconditionmanagement.data.isMinimumAccountAgeEnable ? (
                            <img
                              src={blockIcon}
                              role="presentation"
                              alt="block"
                              width="35"
                              title="block"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setBlock({
                                  ...block,
                                  show: true,
                                  id: 'existance',
                                });
                              }}
                            />
                          ) : (
                            <img
                              src={unblockIcon}
                              role="presentation"
                              alt="unblock"
                              width="35"
                              title="Unblock"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setUnBlock({
                                  ...block,
                                  show: true,
                                  id: 'existance',
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      3
                    </TableCell>
                    <TableCell>
                      Having a premium subscription
                    </TableCell>
                    <TableCell>
                      {idx === 3 ? (
                        <>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={editableRow.condition}
                              label="Age"
                              onChange={(e: any) => setEditableRow({ ...editableRow, condition: e.target.value })}
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </FormControl>
                          {/* {validationexiting[idx] !== undefined
                            && validationexiting[idx].description !== undefined
                            && validationexiting[idx].description.length > 0
                            && <ValidationError error={validationexiting[idx].description} />} */}
                        </>
                      ) : (
                        <div className="imagewrapper">
                          <p>Yes</p>
                          {/* <p>{masterconditionmanagement.data.havingPremiumSubscription ? 'Yes' : 'No'}</p> */}
                          {/* <p>{item.description}</p> */}
                        </div>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      <div className="actionBtnsWrapper">
                        {edit.show && edit.id === 3 ? (
                          <div>
                            <img
                              src={saveIcon}
                              role="presentation"
                              alt="save"
                              width="35"
                              title="Save"
                              height="35"
                              onClick={() => {
                                let flag = true;
                                const valida: any = {};
                                if (editableRow.condition === '') {
                                  flag = false;
                                  valida.valuefrom = 'Please enter the required field';
                                }

                                if (flag) {
                                  setShowModal(true);
                                  setEdit({
                                    show: true,
                                    id: 3,
                                  });
                                  setIdx(3);
                                } else {
                                  setValidationExisting(valida);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={editIcon}
                              role="presentation"
                              alt="edit"
                              width="35"
                              title="Edit"
                              height="35"
                              onClick={() => {
                                setEdit({
                                  ...edit,
                                  id: 3,
                                  show: true,
                                });
                                setIdx(3);
                                setEditableRow({
                                  id: masterconditionmanagement.data._id,
                                  condition: masterconditionmanagement.data.havingPremiumSubscription ? 'true' : 'false',
                                });
                              }}
                            />
                          </div>
                        )}
                        <div>
                          {masterconditionmanagement.data.isHavingPremiumSubscriptionEnable ? (
                            <img
                              src={blockIcon}
                              role="presentation"
                              alt="block"
                              width="35"
                              title="block"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setBlock({
                                  ...block,
                                  show: true,
                                  id: 'havingPremiumSubscription', // item._id,
                                });
                              }}
                            />
                          ) : (
                            <img
                              src={unblockIcon}
                              role="presentation"
                              alt="unblock"
                              width="35"
                              title="Unblock"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setUnBlock({
                                  ...block,
                                  show: true,
                                  id: 'havingPremiumSubscription',
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell> */}
                  </TableRow>
                </>
              )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  masterconditionmanagement: state.masterconditionmanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchMasterConditionList: () => dispatch(fetchMasterCondition()),
});

const MasterConditionManagement = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(MasterConditionManagementComponent);

export default MasterConditionManagement;
