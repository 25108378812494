import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  ValidationError,
  CircularProgressLoader,
} from '../../components';
import { RolesManagementService } from '../../services';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import './addsubadmin.module.scss';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  error: any;
}

const AddSubDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    error,
    intl,
  } = props;
  const navigate = useNavigate();
  const [roles, setRoles] = React.useState([]);
  const addSubDomesSchema = yup.object().shape({
    name: yup.string()
      .required(intl.formatMessage({ id: 'AddSubAdmin.error.name.required' })),
    email: yup.string()
      .email(intl.formatMessage({ id: 'AddSubAdmin.error.email.email' }))
      .required(intl.formatMessage({ id: 'AddSubAdmin.error.email.required' })),
    phoneNo: yup.string()
      .required(intl.formatMessage({ id: 'AddSubAdmin.error.phoneNo.required' }))
      .matches(
        /^[a-zA-Z0-9\-().\s]{6,12}$/,
        intl.formatMessage({ id: 'AddSubAdmin.error.phoneNo.matches' }),
      ),
    roleId: yup.string()
      .required(intl.formatMessage({ id: 'AddSubAdmin.error.roleId.required' })),
  }, []);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    RolesManagementService
      .getAllRoles()
      .then((res: any) => {
        const data: any = [];
        res.data.data.forEach((ele: any) => {
          data.push({ key: ele._id, label: ele.role });
        });
        setRoles(data);
        setLoading(false);
      });
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addSubDomesSchema),
  });

  return (
    <>
      {loading ? (
        <div>
          <CircularProgressLoader />
        </div>
      ) : (
        <div className="cardBg addDetailsPage">
          <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
            <h2 className="sign_text">
              {intl.formatMessage({ id: 'AddSubAdmin.label.details' })}
            </h2>
            <div className="flex_row">
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="name"
                  name="name"
                  formName="subAdmin"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName="passwordInput"
                  label={intl.formatMessage({ id: 'AddSubAdmin.label.name' })}
                />
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="email"
                  name="email"
                  formName="subAdmin"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName="passwordInput"
                  label={intl.formatMessage({ id: 'AddSubAdmin.label.EmailId' })}
                />
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  className="phoneNumber2"
                  id="phoneNo"
                  name="phoneNo"
                  formName="UpdateProfile"
                  type="text"
                  formProps={formProps}
                  label={intl.formatMessage({ id: 'AddSubAdmin.label.mobileNum' })}
                />
              </div>
              <div className="flex_col_sm_6">
                <div className="userType">
                  <FieldSelect
                    id="roleId"
                    name="roleId"
                    label={intl.formatMessage({ id: 'AddSubAdmin.label.role' })}
                    variant={false}
                    formProps={formProps}
                    options={roles}
                    placeholder={intl.formatMessage({ id: 'AddSubAdmin.label.Assignrole' })}
                  />
                </div>
              </div>
            </div>

            {Object.keys(formProps.formState.errors).length > 0
              && (
                <ValidationError
                  className="validation_error"
                  error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
                />
              )}
            {Object.keys(formProps.formState.errors).length === 0
              && <ValidationError error={error} />}
            <div className="buttonWrapper">
              <Button onClick={() => navigate('/subadminmanagement')}>
                {intl.formatMessage({ id: 'AddSubAdmin.label.Cancel' })}
              </Button>
              <Button buttonType={ButtonTypes.submit}>
                {intl.formatMessage({ id: 'AddSubAdmin.label.Create' })}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(AddSubDomesForm);
