import React from 'react';
import {
  CreditActivity,
  // DebitActivity,
} from '../../components';

const RewardPointDetails: React.FC = () => (
  <div>
    <CreditActivity />
    {/* <DebitActivity /> */}
  </div>
);

export default RewardPointDetails;
