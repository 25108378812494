import { addTutorial, getTutorials } from '../../actions/tutorials';
import { Button, Modal, Overlay } from '../../components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddTutorialForm } from '../../forms';
import { Notification } from '../../services';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import classNames from 'classnames';
import './tutorial.scss';
import TutorialCard from './card';
import DeleteTutorialConfirmation from './deleteTutorialConfirmation';


const Tutorials = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const tutorialsState = useSelector((state: any) => state.tutorials);
  const [showTutorial, setShowTutorial] = useState(false);
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    id: '',
  });
  const handleModalClose = () => {
    setShowModal(false);
    setShowTutorial(false);
    setShowDeleteConfirmation({
      show: false,
      id: '',
    });
  };

  const handleAddTutorial = (values: any) => {
    const form1 = new FormData();
    form1.append('file', values.thumbnailURL[0]);
    setLoader(true);
    Notification.uploadpicture(form1).then((res: any) => {
      setLoader(false);
      dispatch(addTutorial({
        "title": values.title,
        "thumbnailUrl": res.data.data.url,
        "videoUrl": values.videoURL,
      }));
    });
  }

  React.useEffect(() => {
    dispatch(getTutorials());
  }, [getTutorials]);

  React.useEffect(() => {
    if (tutorialsState.loadingSuccessTutorial) {
      dispatch(getTutorials());
      handleModalClose();
    }
    if (tutorialsState.loadingError) {
      setError(tutorialsState.data);
    }
  }, [tutorialsState.loadingSuccessTutorial]);

  const handleDelete = (id: string) => {
    setShowModal(true);
    setShowDeleteConfirmation({
      show: true,
      id,
    });
  }

  return (
    <>
      {(tutorialsState.loadingInProgress || loader || tutorialsState.loadingInProgressTutorial) && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        showOnClose={false}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={true}
      >
        {showTutorial && <AddTutorialForm
          error={error}
          handleSubmit={handleAddTutorial}
          handleModalClose={handleModalClose}
        />}
        {showDeleteConfirmation.show && <DeleteTutorialConfirmation
          handleModalClose={handleModalClose}
          id={showDeleteConfirmation.id}
          fetchPriceList={() => {
            dispatch(getTutorials())
          }}
        />}
      </Modal>
      <div className="textRight">
        <Button
          onClick={() => {
            setShowTutorial(true);
            setShowModal(true);
          }}
          className="button">
          Add New Tutorial
        </Button>
        <ul className="tutorial_list">
          {!tutorialsState.loadingInProgress &&
            !tutorialsState.loadingInProgressTutorial &&
            tutorialsState.data &&
            Object.keys(tutorialsState.data).length > 0
            && tutorialsState.data.map((tut: any) =>
              <li>
                <TutorialCard
                  title={tut.title}
                  thumbnail={tut.thumbnailUrl}
                  link={tut.videoUrl}
                  dispatch={dispatch}
                  id={tut._id}
                  handleDelete={handleDelete}
                />
              </li>
            )}
        </ul>
      </div>
    </>


  )
}

export default Tutorials;