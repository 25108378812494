import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  ArgumentAxis,
  ValueAxis,
  BarSeries,
} from "@devexpress/dx-react-chart-material-ui";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { CommissionRulesUpdate, FieldDatePicker, Modal } from "../../components";
import { Animation } from "@devexpress/dx-react-chart";
import classNames from "classnames";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  fetchCommissionRules,
  fetchCommssionDetails,
  fetchDashboardData,
  fetchTopCardsDetail,
  fetchUpdateCommissionRules,
} from "../../actions";
import Overlay from "../../components/Overlay";
import { Link } from "react-router-dom";
import eventDashboard from "../../assets/svg/eventDashboard.svg";
import { SuperAdminAccessControl } from "../../components/AccessControl";
import roles from "../../components/AdminRoles/roles.enum";
import ToastBar from "../../components/ToastBar";
import "./dashboard.scss";
import exp from "../../assets/svg/export.svg";
import "../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss";
import { Button, FieldTextInput } from "../../components";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "../../utils/reactIntl";
import CommissionRulesReset from "../../components/CommissionRulesReset";
import Chart from "react-apexcharts";
import moment from "moment";
ChartJS.register(ArcElement, Tooltip);

interface Props {
  fetchDashboardDetails: any;
  dashboardData: any;
  commissionrules: any;
  commissionmanagement: any;
  fetchTopCardsDetail: any;
  commissiondetails: any;
  fetchCommissionDetail: any;
  fetchCommissionRules: any;
  fetchCommissionRulesUpdate: any;
  commissionrulesupdate: any;
  // commissionType: string;
  // updateFormData: (fieldId: string, value: any) => void; // Function to update form data
}
interface CommissionItem {
  commissionDuration: {
    year: number;
    month: number;
  };
  adminCommissions: number;
}
type CommissionRule = {
  _id: string;
  status: string;
  createdAt: string;
  type: string;
  percentage: number;
  commissionType: string;
};
const CommissionManage = (props: Props) => {
  const {
    fetchTopCardsDetail,
    fetchDashboardDetails,
    dashboardData,
    commissionmanagement,
    commissiondetails,
    fetchCommissionDetail,
    fetchCommissionRules,
    commissionrules,
    fetchCommissionRulesUpdate,
  } = props;
  const [trendingChannelType, setTrendingChannelType] = React.useState("free");

  const formProps = useForm();
  console.log(commissiondetails);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (trendingChannelType === 'free') {
      fetchTopCardsDetail({ startDate: "", endDate: "" });
    } else if (startDate && endDate && startDate !== "" && endDate !== "") {
      fetchTopCardsDetail({ startDate: startDate, endDate: endDate });
    }
  }, [trendingChannelType, startDate, endDate]);



  useEffect(() => {
    fetchCommissionDetail();
  }, []);
  useEffect(() => {
    fetchCommissionRules();
  }, []);

  const commissionData = commissionmanagement?.data || [];

  const eventCommission = commissionData.find(
    (item: any) => item.commissionType === "Event"
  );
  const adminCommissionsForEvent = eventCommission
    ? eventCommission.adminComissions
    : null;

  const channelCommission = commissionData.find(
    (item: any) => item.commissionType === "channel"
  );
  const adminCommissionsForChannel = channelCommission
    ? channelCommission.adminComissions
    : null;

  const SubscriptionPlan = commissionData.find(
    (item: any) => item.commissionType === "Subscription plan"
  );
  const adminCommissionsForSubscriptionPlan = SubscriptionPlan
    ? SubscriptionPlan.adminComissions
    : null;

  const AvatcoinExchange = commissionData.find(
    (item: any) => item.commissionType === "Gold coin"
  );
  const adminCommissionsForAvatcoinExchange = AvatcoinExchange
    ? AvatcoinExchange.adminComissions
    : null;

  let trendingUserData: any = [];
  let CommissionDetailsData: any = [];
  let trendingChannelData: any = [];
  let liveEvents: any = [];
  let upcomingEvents: any = [];
  let ageStructure: any = [];
  let memberShipData: any = {};

  if (
    !commissiondetails.loadingInProgress &&
    commissiondetails.loadingError === null &&
    commissiondetails.data !== null
  ) {
    CommissionDetailsData = commissiondetails.data.length !== 0
      ? commissiondetails.data
        .map((item: any) => {
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const monthIndex = item.commissionDuration.month - 1; // Because array is zero-indexed and months are 1-indexed
          const yearShort = item.commissionDuration.year.toString().slice(-2); // Get last two digits of year
          const formattedDate = `${monthNames[monthIndex]} ${yearShort}`; // Combine for final format

          return {
            argument: formattedDate,
            value: item.adminCommissions,
          };
        })
        .reverse()
      : [];
  }


  // Prepare the chart data
  const categories = CommissionDetailsData.map((data: any) => data.argument);
  const seriesData = CommissionDetailsData.map((data: any) => data.value);

  const [showModal, setShowModal] = React.useState(false);
  const [showModalReset, setShowModalReset] = React.useState(false);
  const handleModalCloseUpdate = () => {
    setShowModalReset(false);
  };

  const TotalCommissions =
    adminCommissionsForEvent +
    adminCommissionsForChannel +
    adminCommissionsForAvatcoinExchange +
    adminCommissionsForSubscriptionPlan;

  const [commissionTypeToReset, setCommissionTypeToReset] = useState("");
  const [formData, setFormData] = useState({});
  // Define your findDefaultValue function here
  const [subscriptionFeeDefault, setSubscriptionFeeDefault] = useState("");
  const [eventPurchaseDefault, setEventPurchaseDefault] = useState("");
  const [channelSubscriptionDefault, setChannelSubscriptionDefault] =
    useState("");
  const [avatcoinsExchangeDefault, setAvatcoinsExchangeDefault] = useState("");

  const findDefaultValue = useCallback((rules, commissionType) => {
    if (!Array.isArray(rules)) {
      return "";
    }
    const rule = rules.find((rule) => rule.commissionType === commissionType);
    return rule ? rule.percentage.toString() : "";
  }, []);


  const [commissionTypeToUpdate, setCommissionTypeToUpdate] = useState("");

  const handleReset = useCallback(
    (commissionType) => () => {
      setShowModalReset(true);
      setCommissionTypeToUpdate(commissionType);
    },
    [setShowModalReset, setCommissionTypeToUpdate]
  );

  const handleSubscriptionFeeChange = (event: any) => {
    setSubscriptionFeeDefault(event.target.value);
  };

  const handleEventPurchaseChange = (event: any) => {
    setEventPurchaseDefault(event.target.value);
  };

  const handleChannelSubscriptionChange = (event: any) => {
    setChannelSubscriptionDefault(event.target.value);
  };

  const handleAvatcoinsExchangeChange = (event: any) => {
    setAvatcoinsExchangeDefault(event.target.value);
  };

  useEffect(() => {
    const defaultValueSubscription = findDefaultValue(
      commissionrules?.data,
      "subscription-fee"
    );
    const defaultValueEventPurchase = findDefaultValue(
      commissionrules?.data,
      "event-purchase"
    );
    const defaultValueChannelSubscription = findDefaultValue(
      commissionrules?.data,
      "channel-subscription"
    );
    const defaultValueAvatcoinExchange = findDefaultValue(
      commissionrules?.data,
      "avatcoins-exchange"
    );
    setSubscriptionFeeDefault(defaultValueSubscription);
    setEventPurchaseDefault(defaultValueEventPurchase);
    setChannelSubscriptionDefault(defaultValueChannelSubscription);
    setAvatcoinsExchangeDefault(defaultValueAvatcoinExchange);
  }, [commissionrules, findDefaultValue]);

  const [updatedPercentage, setUpdatedPercentage] = useState("");
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleUpdate = useCallback(
    (commissionType) => () => {
      setShowModal(true);
      setCommissionTypeToUpdate(commissionType);

      // Set the appropriate updated percentage state
      switch (commissionType) {
        case "subscription-fee":
          setUpdatedPercentage(subscriptionFeeDefault);
          break;
        case "event-purchase":
          setUpdatedPercentage(eventPurchaseDefault);
          break;
        case "channel-subscription":
          setUpdatedPercentage(channelSubscriptionDefault);
          break;
        case "avatcoins-exchange":
          setUpdatedPercentage(avatcoinsExchangeDefault);
          break;
        default:
          break;
      }
    },
    [
      setShowModal,
      setCommissionTypeToUpdate,
      subscriptionFeeDefault,
      channelSubscriptionDefault,
      avatcoinsExchangeDefault,
      eventPurchaseDefault,
    ]
  );
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: categories
    },
    dataLabels: {
      enabled: false,
    },
  }


  const series = [
    {
      name: "Commissions",
      data: seriesData
    }
  ]

  return (
    <>
      <Modal
        id="userinfo-modal"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <CommissionRulesUpdate
          commissionTypeToUpdate={commissionTypeToUpdate}
          handleModalClose={() => {
            setShowModal(false);
            // Call the API to update the value in the textbox
            const updateData = {
              commissionId: commissionrules?.data.find(
                (rule: any) => rule.commissionType === commissionTypeToUpdate
              )?._id,
              percentage: Number(updatedPercentage),
            };
            fetchCommissionRulesUpdate(updateData);
          }}
          handleModalCloseonNo={() => {
            setShowModal(false);
            switch (commissionTypeToUpdate) {
              case "subscription-fee":
                setSubscriptionFeeDefault(
                  findDefaultValue(commissionrules?.data, "subscription-fee")
                );
                break;
              case "event-purchase":
                setEventPurchaseDefault(
                  findDefaultValue(commissionrules?.data, "event-purchase")
                );
                break;
              case "channel-subscription":
                setChannelSubscriptionDefault(
                  findDefaultValue(
                    commissionrules?.data,
                    "channel-subscription"
                  )
                );
                break;
              case "avatcoins-exchange":
                setAvatcoinsExchangeDefault(
                  findDefaultValue(commissionrules?.data, "avatcoins-exchange")
                );
                break;
              default:
                break;
            }
          }}
          updatedPercentage={updatedPercentage} // Pass updatedPercentage to the modal
        />
      </Modal>
      <Modal
        id="userinfo-modal"
        isOpen={showModalReset}
        onClose={handleModalCloseUpdate}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <CommissionRulesReset
          handleModalUpdate={() => {
            const updateData = {
              commissionId: commissionrules?.data.find(
                (rule: any) => rule.commissionType === commissionTypeToUpdate
              )?._id,
              percentage: 8, // Setting the percentage to 8
            };

            // Call API to update the commission rules
            fetchCommissionRulesUpdate(updateData);

            // Update the state variables
            switch (commissionTypeToUpdate) {
              case "subscription-fee":
                setSubscriptionFeeDefault("8");
                break;
              case "event-purchase":
                setEventPurchaseDefault("8");
                break;
              case "channel-subscription":
                setChannelSubscriptionDefault("8");
                break;
              case "avatcoins-exchange":
                setAvatcoinsExchangeDefault("8");
                break;
              default:
                break;
            }

            // Close the modal
            setShowModalReset(false);
          }}
          commissionTypeToUpdate={commissionTypeToUpdate}
          handleModalCloseReset={() => {
            setShowModalReset(false);
          }}
          updatedPercentage={updatedPercentage}
        />
      </Modal>

      <div className="dashboard_page_inner">
        <SuperAdminAccessControl rules={[roles.superadmin]}>
          <div className="heading_wrapper">
            <h2 className="heading">Commission Dashboard</h2>
            {/* <button className="export_btn">
              <img src={exp} alt="export" />
            </button> */}
          </div>
          <div className="commission_card">
            <div className="heading_wrapper">
              <p className="commission">
                Total Commissions:{" "}
                <span className="value">${TotalCommissions.toFixed(2)}</span>
              </p>
              <div>
                <div className="channelPaidSelect">

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={trendingChannelType}
                    label="Category"
                    className="selectPadding"
                    onChange={(val: any) => {
                      setTrendingChannelType(val.target.value);
                      // Reset start date and end date to null when switching to Custom
                      if (val.target.value === 'paid') {
                        setStartDate(null);
                        setEndDate(null);
                      }
                    }}
                  >
                    <MenuItem value="free">Monthly</MenuItem>
                    <MenuItem value="paid">Custom</MenuItem>
                  </Select>

                  {trendingChannelType === "paid" && (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ marginRight: "20px" }}>
                          <DatePicker
                            label="Start Date"
                            onChange={(date: any) => setStartDate(date)}
                            sx={{ width: 200 }}
                          />
                        </div>
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ marginRight: "20px" }}>
                          <DatePicker
                            label="End Date"
                            onChange={(date: any) => setEndDate(date)}
                            sx={{ width: 200 }}
                          />
                        </div>
                      </LocalizationProvider>


                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="card_row cardRow1">
              <Box className="card_box">
                <Card className="card_content" variant="outlined">
                  <CardContent>
                    <div>
                      <img src={eventDashboard} alt="" />
                      <h3>Subscription</h3>
                    </div>
                    <div style={{ fontSize: "24px", color: "#db0716" }}>
                      {commissionmanagement?.loadingInProgress ? (
                        <CircularProgress />
                      ) : adminCommissionsForSubscriptionPlan !== null ? (
                        adminCommissionsForSubscriptionPlan
                      ) : (
                        0
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Box>
              <Box className="card_box">
                <Card className="card_content" variant="outlined">
                  <CardContent>
                    <div>
                      <img src={eventDashboard} alt="" />
                      <h3>Event Purchase</h3>
                    </div>
                    <div style={{ fontSize: "24px", color: "#db0716" }}>
                      {/* {adminCommissionsForEvent !== null
                        ? adminCommissionsForEvent
                        : 0} */}
                      {commissionmanagement?.loadingInProgress ? (
                        <CircularProgress />
                      ) : adminCommissionsForEvent !== null ? (
                        adminCommissionsForEvent
                      ) : (
                        0
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Box>
              <Box className="card_box">
                <Card className="card_content" variant="outlined">
                  <CardContent>
                    <div>
                      <img src={eventDashboard} alt="" />
                      <h3>Channel Subscription</h3>
                    </div>
                    <div style={{ fontSize: "24px", color: "#db0716" }}>
                      {/* {dashboardData.data[2].data.totalUsers
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                      {/* {adminCommissionsForChannel !== null
                        ? adminCommissionsForChannel
                        : 0} */}
                      {commissionmanagement?.loadingInProgress ? (
                        <CircularProgress />
                      ) : adminCommissionsForChannel !== null ? (
                        adminCommissionsForChannel
                      ) : (
                        0
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Box>
              <Box className="card_box">
                <Card className="card_content" variant="outlined">
                  <CardContent>
                    <div>
                      <img src={eventDashboard} alt="" />
                      <h3>Avatcoins Exchange</h3>
                    </div>
                    <div style={{ fontSize: "24px", color: "#db0716" }}>
                      {/* {adminCommissionsForAvatcoinExchange !== null
                        ? adminCommissionsForAvatcoinExchange
                        : 0} */}
                      {commissionmanagement?.loadingInProgress ? (
                        <CircularProgress />
                      ) : adminCommissionsForAvatcoinExchange !== null ? (
                        adminCommissionsForAvatcoinExchange
                      ) : (
                        0
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Box>
            </div>
          </div>
        </SuperAdminAccessControl>
        <div className="lg_card_row">
          <div className="col_6 col lg_col">
            <div className="f_col_box f_col_box1">
              <div className="heading_wrapper">
                <h2 className="heading">Total Commissions</h2>
                <span className="badge">Yearly</span>
              </div>
              <div
                className="trending_user_chart axis_box"
              >
                <Paper>
                  {/* <Chart data={CommissionDetailsData}>
                    <ArgumentAxis />
                    <ValueAxis />
                    <Animation />
                    <BarSeries valueField="value" argumentField="argument" />
                  </Chart> */}
                  <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                  />
                </Paper>
                <div className="x_axis">Months</div>
                <div className="y_axis">Commissions</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="heading_wrapper">
        <h2 style={{ fontSize: "16px", marginTop: "20px" }}>
          Commission Rules
        </h2>
      </div>
      <div className="filterFormCommission">
        <div style={{ display: "flex" }}>
          <TextField
            type="number"
            className="textInput"
            id="subscription-fee"
            label="Subscription fees(%)"
            variant="outlined"
            value={subscriptionFeeDefault}
            onChange={handleSubscriptionFeeChange}
          />

          <div>
            <Button
              className="button"
              onClick={handleUpdate("subscription-fee")}
            >
              <FormattedMessage id="Commissionmanagement.update.label" />
            </Button>
            <Button
              className="button"
              onClick={handleReset("subscription-fee")}
            >
              <FormattedMessage id="Commissionmanagementrules.reset.label" />
            </Button>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "15px" }}>
          <TextField
            type="number"
            className="textInput"
            id="event-purchase"
            label="Events Purchase(%)"
            variant="outlined"
            value={eventPurchaseDefault}
            onChange={handleEventPurchaseChange}
          />
          <div>
            <Button
              className="button"
              onClick={handleUpdate("event-purchase")}
            >
              <FormattedMessage id="Commissionmanagement.update.label" />
            </Button>
            <Button
              className="button"
              onClick={handleReset("event-purchase")}
            >
              <FormattedMessage id="Commissionmanagementrules.reset.label" />
            </Button>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "15px" }}>
          <TextField
            type="number"
            id="channel-subscription"
            label="Channel Subscription(%)"
            variant="outlined"
            className="textInput"
            value={channelSubscriptionDefault}
            onChange={handleChannelSubscriptionChange}
          />
          <div>
            <Button
              className="button"
              onClick={handleUpdate("channel-subscription")}
            >
              <FormattedMessage id="Commissionmanagement.update.label" />
            </Button>
            <Button
              className="button"
              onClick={handleReset("channel-subscription")}
            >
              <FormattedMessage id="Commissionmanagementrules.reset.label" />
            </Button>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "15px" }}>
          <TextField
            type="number"
            className="textInput"
            id="avatcoins-exchange"
            label="Avatcoins Exchange"
            variant="outlined"
            value={avatcoinsExchangeDefault}
            onChange={handleAvatcoinsExchangeChange}
          />
          <div>
            <Button
              className="button"
              onClick={handleUpdate("avatcoins-exchange")}
            >
              <FormattedMessage id="Commissionmanagement.update.label" />
            </Button>
            <Button
              className="button"
              onClick={handleReset("avatcoins-exchange")}
            >
              <FormattedMessage id="Commissionmanagementrules.reset.label" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  dashboardData: state.dashboard,
  commissionmanagement: state.commissionmanagement,
  commissiondetails: state.commissiondetails,
  commissionrules: state.commissionrules,
  commissionrulesupdate: state.commissionrulesupdate,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchDashboardDetails: (payload: any) =>
    dispatch(fetchDashboardData(payload)),
  fetchTopCardsDetail: (payload: any) => dispatch(fetchTopCardsDetail(payload)),
  fetchCommissionDetail: (payload: any) =>
    dispatch(fetchCommssionDetails(payload)),
  fetchCommissionRules: (payload: any) =>
    dispatch(fetchCommissionRules(payload)),
  fetchCommissionRulesUpdate: (payload: any) =>
    dispatch(fetchUpdateCommissionRules(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommissionManage);

