import classNames from "classnames";
import { Button, DataTable, DataTableWithoutCheckbox } from "../../components";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSubscriptionDetail } from "../../actions";
import { dateFormatting } from "../../utils/dateMethods";
import "../LayoutWrapperMain/LayoutWrapperMain.module.scss";
import "../Accordion/accordion.module.scss";

interface Props {
  fetchSubscriptionDetails: any;
  subscriptiondetails: any;
  id: any;
}

const SubscriptionDetail = (props: Props) => {
  const { fetchSubscriptionDetails, subscriptiondetails, id } = props;
  console.log(fetchSubscriptionDetails, "what is this");
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({
    rowsPerPage: 10,
    page: 0,
    id,
  });
  useEffect(() => {
    fetchSubscriptionDetails(filter);
  }, [fetchSubscriptionDetails, filter]);

  const handleFormatSendBy = (sendBy: string) => {
    switch (sendBy) {
      case "email":
        return "Email";
        break;
      case "push":
        return "Push";
        break;
      case "sms":
        return "SMS";
      default:
        return sendBy;
    }
  };

  function createData(data: any, index: number): any {
    console.log(data, "what is data");
    const id_ = data._id;
    // const blocksFromHTML = convertFromHTML(data.message);
    // const htmlData = EditorState.createWithContent(ContentState.createFromBlockArray(
    //   blocksFromHTML.contentBlocks,
    //   blocksFromHTML.entityMap,
    // ));
    // const desc = (
    //   <div title={htmlData.getCurrentContent().getPlainText()}>
    //     {htmlData.getCurrentContent().getPlainText().length > 20 ? `${htmlData.getCurrentContent().getPlainText().substring(0, 20)}...` : htmlData.getCurrentContent().getPlainText()}
    //   </div>
    // );
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      title: data.title,
      purchaseDate: dateFormatting(data.purchaseDate),
      expiryDate: dateFormatting(data.expiryDate),
      planPrice: data.planPrice,
      paymentMedium: data.paymentMedium,

      // title: data.title ? data.title.length > 20
      //   ? (
      //     <div title={data.title}>
      //       {data.title.substring(0, 20)}
      //       ...
      //     </div>
      //   ) : data.title : 'SMS',
      // desc: data.message ? data.message.replace(/<[^>]+>/g, '').length > 20
      //   ? (
      //     <div title={data.message.replace(/<[^>]+>/g, '')}>
      //       {data.message.replace(/<[^>]+>/g, '').substring(0, 20)}
      //       ...
      //     </div>
      //   ) : data.message.replace(/<[^>]+>/g, '') : '',
      // sentby: data.admin ? data.admin.name : '',
      // type: handleFormatSendBy(data.sendBy),
      // senton: getDateAndTime(data.createdAt),
    };
  }

  const rows: any = [];
  if (subscriptiondetails.data !== null && subscriptiondetails) {
    subscriptiondetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  console.log(
    subscriptiondetails?.data?.data[0]?.expiryDate.split("T")[0],
    "wht is this date",
  );
  const expiryDate =
    subscriptiondetails?.data?.data[0]?.expiryDate.split("T")[0];
  const todayDate = new Date().toISOString().split("T")[0];
  const headCells: any = [
    {
      id: "sno",
      label: "S.No",
    },
    {
      id: "title",
      label: "Plan Type",
    },
    {
      id: "purchaseDate",
      label: "Purchased Date",
    },
    {
      id: "expiryDate",
      label: "Expiry Date",
    },

    {
      id: "planPrice",
      label: "Plan Price",
    },
    {
      id: "paymentMedium",
      label: "Payment Medium",
    },
  ];
  const count = subscriptiondetails.data
    ? subscriptiondetails.data["Pagination-Count"]
    : 0;
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilter({
      ...filter,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };
  return (
    <>
      {subscriptiondetails?.data?.data[0]?.planStatus && (
        <div className="subscriptionContainer container">
          <div className="domBox">
            <div className="heading_wrap">
              <h3 className="heading_styles">Activated Plan Details</h3>
              <Button>Deactivate</Button>
            </div>
            <div className={`postDetails $"postDetails"`}>
              <div className="col">
                <div className="col_title">
                  {subscriptiondetails?.data?.data[0]?.planStatus === "active"
                    ? "Current Plan"
                    : "Previous Plan"}
                </div>
                <p className="heading_styles">{rows[0]?.title}</p>
              </div>
              <div className="col">
                <div className="col_title">Expiry Date</div>
                <p className="heading_styles">{rows[0]?.expiryDate}</p>
              </div>
              <div className="col">
                <div className="col_title">Purchased Date</div>
                <p className="heading_styles">{rows[0]?.purchaseDate}</p>
              </div>
              <div className="col">
                <div className="col_title">Plan Price</div>
                <p className="heading_styles">{rows[0]?.planPrice}</p>
              </div>
              <div className="col">
                <div className="col_title">Payment Medium</div>
                <p className="heading_styles">{rows[0]?.paymentMedium}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="dataTableCover accordionTable">
        <DataTableWithoutCheckbox
          headCells_={headCells}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={subscriptiondetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  subscriptiondetails: state.subscriptiondetails,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchSubscriptionDetails: (payload: any) =>
    dispatch(getSubscriptionDetail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetail);

