import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldTextInput,
  Button,
  ValidationError,
  CircularProgressLoader,
} from '../../components';
import AdminRules from '../../components/AdminRoles/adminRules';
import { injectIntl } from '../../utils/reactIntl';
import './updateprofile.module.scss';

interface Props {
  handleFormSubmit: any
  intl: any,
  userProfile: any,
  error: any,
  handleModalClose: any,
  updateProfileLoader: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const UpdateProfileForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    userProfile,
    error,
    handleModalClose,
    updateProfileLoader,
  } = props;

  const UpdateProfileSchema = yup.object().shape({
    name: yup.string()
      .required(intl.formatMessage({ id: 'UpdateProfile.error.name' })),
    email: yup.string()
      .required(intl.formatMessage({ id: 'UpdateProfile.error.email.required' }))
      .email(intl.formatMessage({ id: 'UpdateProfile.error.email.email' })),
    phoneNumber: yup.string()
      .required(intl.formatMessage({ id: 'UpdateProfile.error.phoneNumber' })),
    title: yup.string()
      .required(intl.formatMessage({ id: 'UpdateProfile.error.title' })),
  }, []);
  const formProps = useForm({
    resolver: yupResolver(UpdateProfileSchema),
    defaultValues: {
      name: userProfile.name,
      email: userProfile.email,
      phoneNumber: userProfile.phoneNo,
      title: userProfile.adminType,
    },
  });
  return (
    <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <h2 className="sign_text">
        {intl.formatMessage({ id: 'UpdateProfile.label.title' })}
      </h2>
      <FieldTextInput
        id="name"
        name="name"
        formName="UpdateProfile"
        type="text"
        formProps={formProps}
        label={intl.formatMessage({ id: 'UpdateProfile.label.name' })}
      />
      <FieldTextInput
        id="email"
        name="email"
        formName="UpdateProfile"
        type="text"
        formProps={formProps}
        disabled
        label={intl.formatMessage({ id: 'UpdateProfile.label.email' })}
      />
      <FieldTextInput
        className="phoneNumber2"
        id="phoneNo"
        name="phoneNumber"
        formName="UpdateProfile"
        type="text"
        formProps={formProps}
        defaultValueCc={userProfile.countryCode}
        label={intl.formatMessage({ id: 'UpdateProfile.label.phonenumber' })}
        disabled={!AdminRules.UpdatePhoneNumver.includes(localStorage.getItem('adminType') || '')}
      />
      <FieldTextInput
        id="title"
        name="title"
        type="text"
        formName="UpdateProfile"
        formProps={formProps}
        label={intl.formatMessage({ id: 'UpdateProfile.label.title_role' })}
        disabled
      />
      {Object.keys(formProps.formState.errors).length > 0
        && (
          <ValidationError
            className="validation_error"
            error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
          />
        )}
      {error.error && Object.keys(formProps.formState.errors).length === 0 && (
        <ValidationError
          className="validation_error"
          error={error.message}
        />
      )}
      {updateProfileLoader ? (
        <div className="loader_s">
          <CircularProgressLoader />
        </div>
      )
        : (
          <>
            <Button
              buttonType={ButtonTypes.button}
              onClick={handleModalClose}
              className="secondaryButton"
            >
              {intl.formatMessage({ id: 'UpdateProfile.label.cancel' })}
            </Button>
            <Button
              buttonType={ButtonTypes.submit}
            >
              {intl.formatMessage({ id: 'UpdateProfile.label.update' })}
            </Button>
          </>
        )}
    </form>
  );
};

export default injectIntl(UpdateProfileForm);
