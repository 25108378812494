import React, { useEffect } from 'react';
import { Modal, Overlay } from '../../components';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { SubscriptionPlanForm } from '../../forms';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getSubscriptionPlan } from '../../actions';
import '../ContentManagement/contentmanagement.module.scss';
import edit from '../../assets/svg/edit.svg';

interface Props {
  fetchSubscription: any
  value: string
  subscriptionplan: any
}

const SubscriptionPlan = (props: Props) => {
  const { value, subscriptionplan, fetchSubscription } = props;
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>

      {subscriptionplan.loadingInProgress ? <Overlay /> : (
        <>
          <Modal
            id="block-user"
            isOpen={showModal}
            onClose={handleModalClose}
            onManageDisableScrolling={() => null}
            lightCloseButton={false}
          >
            <SubscriptionPlanForm
              values={{
                title: subscriptionplan.data.data[0].title,
                type: subscriptionplan.data.data[0].title,
                membership: subscriptionplan.data.data[0].price,
                discount: 25,
                discountedprice: 25,
              }}
              loader={undefined}
              handleSubmit={undefined}
              handleSaveAsDraft={undefined}
              error=""
            />
          </Modal>
          <h2 className="contentHeading">
            {value === 'monthly' && 'Monthly Plan'}
            {value === 'yearly' && 'Yearly Plan'}
            {value === 'quarterly' && 'Quarterly Plan'}
            {value === 'free' && 'Free Plan'}

            <button type="button" className="headingEdit">
              <img
                src={edit}
                alt="edit"
                role="presentation"
                onClick={() => setShowModal(true)}
              />
            </button>
          </h2>
          <table className="simple_table">
            <thead>
              <tr>
                <th>
                  Title
                </th>
                <th>
                  Membership Type
                </th>
                <th>
                  Membership Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {subscriptionplan.data.data[0].title}
                </td>
                <td>
                  {value === 'monthly' && 'Monthly'}
                  {value === 'quaterly' && 'Quaterly'}
                  {value === 'yearly' && 'Yearly'}
                  {value === 'free' && 'Free'}
                </td>
                <td>
                  {subscriptionplan.data.data[0].price}
                </td>
              </tr>
            </tbody>
          </table>
          <Table
            size="medium"
            aria-label="enhanced table"
            className="simple_table plan_details"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  This Plan Include
                </TableCell>
                <TableCell>
                  Benefits
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionplan?.data?.data.map(
                (ele: any) => ele.title.toLowerCase() === value && (ele.features.map((feature: any, index: number) => (
                  <TableRow>
                    <TableCell>
                      {feature.featureName}
                    </TableCell>
                    <TableCell>
                      {index === 0 ? `${feature.coinValue} Coin` : index === 9 ? `${feature.noOfChannel} Channel` : feature.enable ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                ))),
              )}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  subscriptionplan: state.subscriptionplan,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchSubscription: () => dispatch(getSubscriptionPlan()),
});

const SubscriptionPlanComponent = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SubscriptionPlan);

export default SubscriptionPlanComponent;
