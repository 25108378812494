import { DataTable } from '../../components';
import React from 'react';
import { connect } from 'react-redux';
import { getNotificationDetails } from '../../actions';
import { getDateAndTime } from '../../utils/dateMethods';
import classNames from 'classnames';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  id: any
  fetchNotificationReport: any;
  notificationdetails: any;
}

const NotificationDetail: React.FC<Props> = (props: Props) => {
  const { fetchNotificationReport, notificationdetails, id } = props;
  const [selected, setSelected] = React.useState([]);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });

  React.useEffect(() => {
    fetchNotificationReport(filter);
  }, [fetchNotificationReport, filter]);

  const handleSendBy = (status: string) => {
    if (status === 'push') {
      return 'Push';
    }
    if (status === 'email') {
      return 'Email';
    }
    if (status === 'sms') {
      return 'SMS';
    }
    return '';
  };

  function createData(data: any, index: number): any {
    const id_ = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      title: data.title ? data.title.length > 20 ? <div title={data.title}>{`${data.title.substring(0, 20)}...`}</div> : data.title : 'SMS',
      desc: data.message ? data.message.replace(/<[^>]+>/g, '').length > 20
        ? (
          <div title={data.message.replace(/<[^>]+>/g, '')}>
            {data.message.replace(/<[^>]+>/g, '').substring(0, 20)}
            ...
          </div>
        ) : data.message.replace(/<[^>]+>/g, '') : '',
      username: data.admin.name,
      sentBy: handleSendBy(data.sendBy),
      createdOn: getDateAndTime(data.createdAt),
    };
  }

  const rows: any = [];
  if (notificationdetails !== undefined
    && notificationdetails.data !== null
    && notificationdetails.data) {
    notificationdetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'title', label: 'Title',
    },
    {
      id: 'desc', label: 'Description',
    },
    {
      id: 'username', label: 'Sent By',
    },
    {
      id: 'sentBy', label: 'Type',
    },
    {
      id: 'createdOn', label: 'Sent on',
    },
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const count = notificationdetails !== undefined
    && notificationdetails.data !== null
    ? notificationdetails.data['Pagination-Count'] : 0;

  return (
    <>
      {/* <h2>Notification Details</h2> */}
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={notificationdetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { notificationdetails } = state;
  return {
    notificationdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotificationReport: (payload: any) => dispatch(getNotificationDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetail);
