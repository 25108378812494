import api from '../api';

export class SubscribedUserManagementService {
  static async getSubscribedUserList(payload: any) {
    const {
      page,
      rowsPerPage,
      startDate,
      endDate,
      planStatus,
      search,
      planType,
      isSortByPlanActivationDate,
      isSortByUserName,
      isSortByPlanUpgradationDate
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}${startDate !== '' ? `&startDate=${startDate}` : ''}${endDate !== '' ? `&endDate=${endDate}` : ''}${planStatus !== 'Select' && planStatus !== '' && planStatus !== undefined ? `&planStatus=${planStatus}` : ''}${planType !== 'Select' && planType !== '' && planType !== undefined ? `&planType=${planType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByUserName !== undefined && isSortByUserName !== '' ? `&isSortByUserName=${isSortByUserName}` : ''}${isSortByPlanActivationDate !== undefined && isSortByPlanActivationDate !== '' ? `&isSortByPlanActivationDate=${isSortByPlanActivationDate}` : ''}${isSortByPlanUpgradationDate !== undefined && isSortByPlanUpgradationDate !== '' ? `&isSortByPlanUpgradationDate=${isSortByPlanUpgradationDate}` : ''}`;
    return api.getApiCall(`/api/v1/admins/subscribed-users?${apiEndPoint}`);
  }
  static async exportSubscribedUserListToMail(payload: any) {
    const {
      startDate,
      endDate,
      userType,
      search,
      planType,
      isSortByPlanActivationDate,
      isSortByUserName,
      isSortByPlanUpgradationDate,
      type,
    } = payload;
    const apiEndPoint = `${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${userType !== 'Select' && userType !== '' && userType !== undefined ? `&userType=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByUserName !== undefined && isSortByUserName !== '' ? `&isSortByUserName=${isSortByUserName}` : ''}${planType !== undefined && planType !== '' ? `&planType=${planType}` : ''}${isSortByPlanUpgradationDate !== undefined && isSortByPlanUpgradationDate !== '' ? `&isSortByPlanUpgradationDate=${isSortByPlanUpgradationDate}` : ''}${isSortByPlanActivationDate !== undefined && isSortByPlanActivationDate !== '' ? `&isSortByPlanActivationDate=${isSortByPlanActivationDate}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/subscribed-users/exports?${apiEndPoint}`);
  }


}
