import { Suspense, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { bool, object, func } from "prop-types";
import {
  CircularProgressLoader,
  NamedRedirect,
  LoadableComponentErrorBoundary,
  Sidebar,
} from "../components";
import { NotFoundPage } from "../modules";
import { locationChanged } from "../actions/routing";
import { canonicalRoutePath } from "../utils/routes";
import { propTypes } from "../utils/types.js";
import routeConfiguration from "./routeConfiguration.js";

const canShowComponent = (props) => {
  const { isAuthenticated, route } = props;
  const { auth } = route;
  return !auth || isAuthenticated;
};

const callLoadData = (props) => {
  const { params, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;
  const shouldLoadData =
    typeof loadData === "function" &&
    canShowComponent(props) &&
    !logoutInProgress;

  if (shouldLoadData) {
    dispatch(loadData(params, location.search))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`loadData success for ${name} route`);
      })
      .catch(() => {
        // console.log(e);
      });
  }
};

const setPageScrollPosition = (location) => {
  if (!location.hash) {
    // No hash, scroll to top
    window.scroll({
      top: 0,
      left: 0,
    });
  } else {
    const el = document.querySelector(location.hash);
    if (el) {
      el.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }
};

const handleLocationChanged = (dispatch, location) => {
  setPageScrollPosition(location);
  const url = canonicalRoutePath(routeConfiguration(), location);
  dispatch(locationChanged(location, url));
};

/**
 * RouteComponentRenderer handles loadData calls on client-side.
 * It also checks authentication and redirects unauthenticated users
 * away from routes that are for authenticated users only
 * (aka "auth: true" is set in routeConfiguration.js)
 *
 * This component is a container: it needs to be connected to Redux.
 */
const RouteComponentRenderer = (propsWithKey) => {
  const { key, ...rest } = propsWithKey;
  const props = rest;
  const { route, staticContext = {}, dispatch } = props;
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    callLoadData({ ...props, location, params });
    // handleLocationChanged(dispatch, location);
  }, [location]);

  const {
    component: RouteComponent,
    authPage = "LoginPage",
    extraProps,
  } = route;
  const canShow = canShowComponent(props);

  if (!canShow) {
    staticContext.unauthorized = true;
  }
  delete extraProps.key;
  return canShow ? (
    <LoadableComponentErrorBoundary>
      <RouteComponent
        params={params}
        key={key}
        location={location}
        {...extraProps}
      />
    </LoadableComponentErrorBoundary>
  ) : (
    <NamedRedirect
      name={authPage}
      state={{
        from: `${location.pathname}${location.search}${location.hash}`,
      }}
    />
  );
};

RouteComponentRenderer.propTypes = {
  isAuthenticated: bool.isRequired,
  logoutInProgress: bool.isRequired,
  route: propTypes.route.isRequired,
  dispatch: func.isRequired,
  staticContext: object,
};

const mapStateToProps = (state) => {
  const { isAuthenticated, logoutInProgress } = state.auth;
  return { isAuthenticated, logoutInProgress };
};

const RouteComponentContainer = compose(connect(mapStateToProps))(
  RouteComponentRenderer,
);
/**
 * Routes component creates React Router rendering setup.
 * It needs routeConfiguration (named as "routes") through props.
 * Using that configuration it creates navigation on top of page-level
 * components. Essentially, it's something like:
 * <Switch>
 *   <Route render={pageA} />
 *   <Route render={pageB} />
 * </Switch>
 */
const RoutesComponent = (props) => {
  const { isAuthenticated, logoutInProgress, routes, permission } = props;
  const toRouteComponent = (route) => {
    const renderProps = {
      isAuthenticated,
      logoutInProgress,
      route,
    };

    return (
      <Route
        key={route.path}
        path={route.path}
        element={<RouteComponentContainer {...renderProps} />}
      />
    );
  };

  return (
    <>
      {isAuthenticated && <Sidebar />}
      <Suspense fallback={<CircularProgressLoader />}>
        <Routes>
          {routes.map((ele) => {
            if (
              !ele.extraProps.key ||
              !permission.data ||
              permission.data.length === 0
            ) {
              return toRouteComponent(ele);
            }

            const perm = permission.data.filter(
              (per) => ele.extraProps.key === per.key,
            );
            const access =
              perm[0].view &&
              (ele.extraProps.per_type != null
                ? perm[0][ele.extraProps.per_type]
                : true);

            if (perm.length !== 0 && perm[0] !== undefined && access) {
              return toRouteComponent(ele);
            }
            return null;
          })}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </>
  );
};

const mapStateToPropsForRoutes = (state) => {
  const { isAuthenticated } = state.auth;
  return {
    isAuthenticated,
    permission: state.permission,
  };
};

export default compose(connect(mapStateToPropsForRoutes))(RoutesComponent);

