import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgressLoader,
  FieldTextInput,
  ValidationError,
} from '../../components';
import {
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import classNames from 'classnames';
import { Editor } from 'react-draft-wysiwyg';
import { Notification } from '../../services';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { stateToHTML } from 'draft-js-export-html';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  loader: any;
  error: string;
  dataForm?: any;
  setError: any;
  setOverlay: any;
}

const AddNotificationEmailForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    loader,
    error,
    dataForm,
    setError,
    setOverlay,
  } = props;
  const navigate = useNavigate();

  const [editorState, setEditorState] = React.useState(dataForm ? EditorState.createWithContent(ContentState.createFromBlockArray(
    convertFromHTML(dataForm.message).contentBlocks,
    convertFromHTML(dataForm.message).entityMap,
  )) : EditorState.createEmpty());
  const [editorError, setEditorError] = React.useState('');
  const addNotificationSchema = yup.object().shape({
    title: yup.string()
      .max(250, 'Please enter characters less than 250 characters for description')
      .required('Please fill all required fields'),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addNotificationSchema),
  });
  React.useEffect(() => {
    if (dataForm) {
      formProps.reset({
        title: dataForm.title,
      });
    }
  }, [dataForm, formProps]);

  const handleSaveAsDraft = (e: any, data: any) => {
    e.preventDefault();
    if (editorState.getCurrentContent().getPlainText().length === 0) {
      setEditorError('Please fill description');
    } else {
      setEditorError('');
      setOverlay(true);
      Notification.addNotification({
        subject: data.title,
        message: stateToHTML(editorState.getCurrentContent()),
        sendBy: 'email',
        status: 'draft',
      }).then(() => {
        navigate('/notification');
        setOverlay(false);
      }).catch((err: any) => {
        setError(err.response.data.message);
        setOverlay(false);
      });
    }
  };

  const handleSubmit = (data: any) => {
    const content = `<!doctype html>
    <html lang="en-US">
    
    <head>
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
        <title>AVATUS</title>
        <meta name="description" content="email">
        <style type="text/css">
            a:hover {
                text-decoration: underline !important;
            }
            .editor *{
              color: #FFFFFF !important;
            }
        </style>
    </head>
    
    <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #000000;" leftmargin="0">
        <!--100% body table-->
        <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#000000" style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
            <tr>
                <td>
                    <table style="background-color: #000000; max-width:670px;  margin:0 auto;" width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
                        <tr>
                            <td style="height:80px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">
                                <a href="https://www.avatus.com" title="logo" target="_blank">
                                    <img width="60" src="https://media.avatus.com/avatus-logo-dark.png" title="logo" alt="logo">
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:20px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align: center;">
                                <h1 style="color:#FFFFFF; font-weight:500; margin:0 0 30px;font-size:32px;font-family:'Rubik',sans-serif;">AVATUS – {$title}</h1>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0" style="max-width:670px;background: #101719; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="padding:0 35px; text-align: center;">
                                            <p class="editor" style="color:#FFFFFF !important; font-size:15px;line-height:24px; margin:0;">
                                                {$description}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                            <tr>
                                <td style="height:20px;">&nbsp;</td>
                            </tr>
                            <tr>
                                <td style="text-align: center; padding-top: 60px;">
                                    <a href="https://play.google.com/store/apps/details?id=com.avatus" aria-label="Get the app on google play"
                                        style="margin-right: 5px; display: inline-block;">
                                        <img src="https://media.avatus.com/admins/google-play-dark-1684355369162.png"
                                            alt="Google Play Store" style="width: 90px; height: auto; border-radius: 4px;">
                                    </a>
                                    <a href="https://apps.apple.com/us/app/avatus/id1585376629" aria-label="Get the app on app store" style="display: inline-block;">
                                        <img src="https://media.avatus.com/admins/app-store-dark-1684355309036.png"
                                            alt="App Store" style="width: 90px; height: auto; border-radius: 4px;">
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align: center;">
                                    <a href="https://www.avatus.com" aria-label="Click to go to Avatus website"
                                        style="font-size: 14px; color: #F2F2F2; letter-spacing: 0; margin: 15px 0 20px; display: inline-block; line-height: 16.8px; text-decoration: none;">https://www.avatus.com</a>
                                </td>
                            </tr>
                            <tr>
                                <td style="height:80px;">&nbsp;</td>
                            </tr>
                    </table>
                </td>
                </tr>
        </table>
        <!--/100% body table-->
    </body>
    
    </html>`;
    if (editorState.getCurrentContent().getPlainText().length === 0) {
      setEditorError('Please fill description');
    } else {
      setEditorError('');
      setOverlay(true);
      var regex = new RegExp('<p', "g");
      Notification.addNotification({
        subject: data.title,
        message: stateToHTML(editorState.getCurrentContent()),
        //message: content.replace('{$title}', data.title).replace('{$description}',stateToHTML(editorState.getCurrentContent()).replace(regex,'<p style="color:#FFFFFF;"')),
        sendBy: 'email',
        status: 'sent',
      }).then(() => {
        navigate('/notification');
        setOverlay(false);
      }).catch((err) => {
        setOverlay(false);
        setError(err.response.data.message);
      });
    }
  };

  return (
    <div className="cardBg addDetailsPage">
      <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
        <FieldTextInput
          id="title"
          name="title"
          formName="addNotification"
          className="textInput"
          type="text"
          formProps={formProps}
          rootClassName="passwordInput"
          label={intl.formatMessage({ id: 'AddNotification.label.subject' })}
        />
        <div>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => setEditorState(e)}
            placeholder={intl.formatMessage({ id: 'AddNotification.label.description' })}
            toolbar={{
              options: ['inline', 'textAlign'],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline'],
              },
              textAlign: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['left', 'center', 'right', 'justify'],
              },
            }}
          />
        </div>
        {Object.keys(formProps.formState.errors).length > 0
          && (
            <ValidationError
              className="validation_error"
              error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
            />
          )}
        {Object.keys(formProps.formState.errors).length === 0
          && <ValidationError error={error} />}
        {Object.keys(formProps.formState.errors).length === 0
          && editorError.length > 0
          && <ValidationError error="Please fill the description" />}
        {loader
          ? (
            <div className="savingLoader">
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className="buttonWrapper">
                <Button
                  buttonType={ButtonTypes.button}
                  onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                >
                  {intl.formatMessage({ id: 'AddNotification.label.Save.as.draft' })}
                </Button>
                <Button
                  buttonType={ButtonTypes.submit}
                >
                  {intl.formatMessage({ id: 'AddNotification.label.Submit' })}
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
};


export default injectIntl(AddNotificationEmailForm);
