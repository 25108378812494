import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {


} from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutWrapperMain,
  Page,
  NotificationManagement,
} from '../../components';
import config from '../../config';

interface Props {
  scrollingDisabled: boolean,
  // from  
  // from injectIntl
  intl: any,
}

const NotificationManagementComponent: React.FC<Props> = (props: Props) => {
  const {
    intl,
    scrollingDisabled,
  } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'NotificationManagement.page.title' },
    { siteTitle },
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain>
        <div>
          <NotificationManagement />
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const NotificationManagementPage = compose(

  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(NotificationManagementComponent);

export default NotificationManagementPage;
