import React from 'react';
import { connect } from 'react-redux';
import { dateFormatting } from '../../utils/dateMethods';
import { fetchLoginDetails } from '../../actions';
import classNames from 'classnames';
import DataTable from '../Datatable';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  id: string;
  getLoginDetails: any;
  logindetails: any;
}

const LoginDetailsUserInfo: React.FC<Props> = (props: Props) => {
  const {
    getLoginDetails,
    id,
    logindetails,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    id,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  React.useEffect(() => {
    getLoginDetails(filter);
  }, [getLoginDetails, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = logindetails.data ? logindetails.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const getPlatForm = (platformcode: any) => {
    switch (platformcode) {
      case '1':
        return 'Android';
      case '2':
        return 'IOS';
      case '3':
        return 'Web';
      case '4':
        return 'All';
      default:
        return null;
    }
  };
  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      username: data.userId.username,
      platform: getPlatForm(data.platform),
      ipaddress: data.remoteAddress,
      lastlogindate: dateFormatting(data.createdAt), // lastLoginActivity
      lastloginactivity: dateFormatting(data.lastLoginActivity), // ele.lastLoginActivity !== null && ele.lastLoginActivity !== '' ? dateFormatting(ele.lastLoginActivity) : '',
    };
  }

  const rows: any = [];
  if (logindetails.data !== null && logindetails.data) {
    logindetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCellslogindetails: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'username', label: 'Username',
    },
    {
      id: 'platform', label: 'Platforms',
    },
    {
      id: 'name', label: 'IP Address/Device Id',
    },
    {
      id: 'phone', label: 'Last Login Date',
    },
    {
      id: 'lastactivity', label: 'Last Activity Date',
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByParentCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headCellslogindetails}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={logindetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { logindetails } = state;
  return {
    logindetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getLoginDetails: (payload: any) => dispatch(fetchLoginDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDetailsUserInfo);
