import classNames from 'classnames';
import { DataTable } from '../../components';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { channelNotificationDetail } from '../../actions';
import { getDateAndTime } from '../../utils/dateMethods';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  fetchChannelNotification: any;
  channelnotificationdetail: any;
  id: any;
}

const ChannelInfoNotificationDetail = (props: Props) => {
  const { fetchChannelNotification, channelnotificationdetail, id } = props;
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({
    rowsPerPage: 10,
    page: 0,
    id,
  });
  useEffect(() => {
    fetchChannelNotification(filter);
  }, [fetchChannelNotification, filter]);

  const handleSendBy = (status: string) => {
    if (status === 'push') {
      return 'Push';
    }
    if (status === 'email') {
      return 'Email';
    }
    if (status === 'sms') {
      return 'SMS';
    }
    return '';
  };

  function createData(data: any, index: number): any {
    const id_ = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      title: data.title ? (data.title.length > 20 ? (
        <div title={data.title}>
          {data.title.substring(0, 20)}
          ...
        </div>
      ) : data.title) : '--',
      desc: data.message ? data.message.replace(/<[^>]+>/g, '').length > 20
        ? (
          <div title={data.message.replace(/<[^>]+>/g, '')}>
            {data.message.replace(/<[^>]+>/g, '').substring(0, 20)}
            ...
          </div>
        ) : data.message.replace(/<[^>]+>/g, '') : '',
      sentby: data.admin.name,
      type: handleSendBy(data.sendBy),
      senton: getDateAndTime(data.createdAt),
    };
  }

  const rows: any = [];
  if (channelnotificationdetail.data !== null && channelnotificationdetail) {
    channelnotificationdetail.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'title', label: 'Title',
    },
    {
      id: 'desc', label: 'Description',
    },
    {
      id: 'sentby', label: 'Sent by',
    },
    {
      id: 'type', label: 'Type',
    },
    {
      id: 'senton', label: 'Sent on',
    },
  ];
  const count = channelnotificationdetail.data ? channelnotificationdetail.data['Pagination-Count'] : 0;
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headCells}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={channelnotificationdetail.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({ channelnotificationdetail: state.channelnotificationdetail });

const mapDispatchToProps = (dispatch: any) => ({
  fetchChannelNotification: (payload: any) => dispatch(channelNotificationDetail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelInfoNotificationDetail);
