import React, { useState } from 'react';
import _ from 'lodash/fp';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { SearchBarApiService } from '../../services';
import { debounce } from 'lodash';

interface SearchValues {
  label: string;
}

interface Props {
  searchURL: string;
  formProps: any;
  name: string;
  label: string;
  id: string;
}

const FieldSeachBar = (props: Props) => {
  const {
    id,
    name,
    formProps: { control },
    searchURL,
    label,
  } = props;
  const [searchData, setSearchData] = useState<any>([]);
  const handleChange = debounce((e: any) => {
    if (e.target.value !== '') {
      SearchBarApiService.getSearchInfo(searchURL + e.target.value).then((res) => {
        const data: any = [];
        res.data.data.search.forEach((ele: any) => {
          if (ele !== '') {
            data.push({ label: ele.replace(/<[^>]+>/g, '') });
          }
        });
        setSearchData(data);
      });
    }
  });
  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          open={false}
          id={id}
          {...field}
          options={searchData}
          getOptionLabel={(option: any) => (typeof (option) === 'string' ? option : option.label)}
          popupIcon=""
          renderOption={(option: any) => (
            <span>
              {option.label}
            </span>
          )}
          getOptionSelected={(option: SearchValues, value: SearchValues) => _.isEqual(option, value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              value={field.value}
              onChange={(e: any) => {
                field.onChange({ label: e.target.value });
                //handleChange(e);
              }}
            />
          )}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          onChange={(_, data: any) => (data !== null ? field.onChange(data) : field.onChange(''))}
          disableListWrap
          value={field.value}
          ref={field.ref}
        />
      )}
      defaultValue=""
      name={name}
      control={control}
    />
  );
};

export default FieldSeachBar;
