import api from '../api';

export class SupportManagementService {
  static async getSupportList(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      startDate,
      endDate,
      isSortByCreatedAt,
      isSortByUpdatedAt,
    } = payload.data;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${startDate !== '' && startDate !== undefined && startDate !== null ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined && endDate !== null ? `&endDate=${endDate}` : ''}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreatedAt !== undefined && isSortByCreatedAt !== '' ? `&isSortByCreatedAt=${isSortByCreatedAt}` : ''}${isSortByUpdatedAt !== undefined && isSortByUpdatedAt !== '' ? `&isSortByUpdatedAt=${isSortByUpdatedAt}` : ''}`;
    return api.getApiCall(`/api/v1/admins/support-managements?${apiEndPoint}`);
  }

  static async getTicketDetail(payload: any) {
    return api.getApiCall(`/api/v1/admins/support-managements/${payload.data}`);
  }

  static async changeStatus(payload: any) {
    return api.putApiCall('/api/v1/admins/support-managements/change-status', payload);
  }

  static async export(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      startDate,
      endDate,
      isSortByCreatedAt,
      isSortByUpdatedAt,
      type
    } = payload;
    const apiEndPoint = `type=${type}&limit=${rowsPerPage}&pageNo=${page + 1}${startDate !== '' && startDate !== undefined && startDate !== null ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined && endDate !== null ? `&endDate=${endDate}` : ''}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreatedAt !== undefined && isSortByCreatedAt !== '' ? `&isSortByCreatedAt=${isSortByCreatedAt}` : ''}${isSortByUpdatedAt !== undefined && isSortByUpdatedAt !== '' ? `&isSortByUpdatedAt=${isSortByUpdatedAt}` : ''}`;
    return api.getApiCall(`/api/v1/admins/support-managements/exports?${apiEndPoint}`);
  }
}
