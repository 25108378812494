import React from "react";
import { connect } from "react-redux";
import {
  Button,
  CircularProgressLoader,
  Modal,
  UserReportDetail,
  DeleteUserInfo,
  BlockUserInfo,
  UserInfoSendNotification,
  PostReportDetail,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  AccessControl,
  ChannelReportDetailUserManagement,
  CampaignReportDetailUserManagement,
  NotificationDetail,
  NotesDetail,
  EventReportDetail,
  AddNote,
  Overlay,
  Accordion,
  ToastBar,
} from "../../components";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { ExportReportForm } from "../../forms";
import { ReportedUserManagementService } from "../../services";
import "./reportuserinfo.module.scss";
import { getUserInfo } from "../../actions";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import deleteIcon from "../../assets/svg/delete.svg";
import blockIcon from "../../assets/svg/block.svg";
import exportIcon from "../../assets/svg/export.svg";
import userprofile from "../../assets/svg/image-placeholder.png";
import blocked from "../../assets/svg/blocked.svg";
import unblock from "../../assets/svg/unblock.svg";
import "../LayoutWrapperMain/LayoutWrapperMain.module.scss";

interface Props {
  fetchUserInfo: any;
  id: any;
  intl: any;
  userInfo: any;
}

const ReportUserInfoComponent: React.FC<Props> = (props: Props) => {
  const { fetchUserInfo, id, userInfo, intl } = props;
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: "",
  });
  const [deleteUser, setDeleteUser] = React.useState(false);
  const [blockUser, setBlockUser] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [addNote, setAddNote] = React.useState(false);
  const [exports, setExports] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  React.useEffect(() => {
    fetchUserInfo(id);
  }, [fetchUserInfo, id]);

  const handleDeleteUser = () => {
    setDeleteUser(true);
    setShowModal(true);
  };
  const handleModalClose = () => {
    setDeleteUser(false);
    setBlockUser(false);
    setShowModal(false);
    setSendNotification(false);
    setAddNote(false);
    setExports(false);
  };
  const handleBlockUser = () => {
    setBlockUser(true);
    setShowModal(true);
  };

  const handleSendNotification = () => {
    setShowModal(true);
    setSendNotification(true);
  };
  const navigate = useNavigate();

  return (
    <>
      {showOverlay && <Overlay />}
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() =>
            setNotification({
              show: false,
              message: "",
            })
          }
        />
      )}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className="modal_container">
          {deleteUser && (
            <DeleteUserInfo
              setShowOverlay={setShowOverlay}
              fetchUserInfo={() => navigate("/reportusermanagement")}
              handleModalClose={handleModalClose}
              id={id}
            />
          )}
          {blockUser && (
            <BlockUserInfo
              id={id}
              fetchUserInfo={fetchUserInfo}
              handleModalClose={handleModalClose}
              userInfoStatus={userInfo.data.status}
              setShowOverlay={setShowOverlay}
            />
          )}
          {sendNotification && (
            <UserInfoSendNotification
              setNotification={setNotification}
              setShowOverlay={setShowOverlay}
              reportedUser
              id={id}
              handleModalClose={handleModalClose}
            />
          )}
          {addNote && (
            <AddNote
              setShowOverlay={setShowOverlay}
              setNotification={setNotification}
              handleModalClose={() => {
                fetchUserInfo();
                handleModalClose();
              }}
              id={id}
            />
          )}
          {exports && (
            <ExportReportForm
              handleFormSubmit={(formdata: any) => {
                setShowOverlay(true);
                const exportTypes: any = [];
                Object.keys(formdata).forEach((ele: any) => {
                  if (formdata[ele]) {
                    exportTypes.push(ele);
                  }
                });
                ReportedUserManagementService.exports({
                  userId: id,
                  optionCode: exportTypes,
                })
                  .then(() => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: "Details Exported Successfully",
                    });
                    handleModalClose();
                  })
                  .catch((err: any) => {
                    setShowOverlay(false);
                    setNotification({
                      show: true,
                      message: err.response.data.message,
                    });
                  });
              }}
              handleCloseModal={handleModalClose}
            />
          )}
        </div>
      </Modal>
      <div>
        {userInfo.data === null || userInfo.loadingInProgress ? (
          <CircularProgressLoader />
        ) : (
          <>
            <div
              className="cardBg containerBox p-20">
              <div className="headerActions">
                <div>
                  <Button
                    onClick={() => {
                      setShowModal(true);
                      setAddNote(true);
                    }}
                  >
                    Add Note
                  </Button>
                  <Button onClick={handleSendNotification}>
                    Send Notification
                  </Button>
                </div>
                <div className="iconWrapperbox">
                  <AccessControl
                    permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
                    accessType={PERMISSION_TYPES.EDIT}
                  >
                    <span className="iconWrapper">
                      <img
                        role="presentation"
                        onClick={() => {
                          setShowModal(true);
                          setExports(true);
                        }}
                        src={exportIcon}
                        alt="Export"
                        title="Export"
                      />
                    </span>
                  </AccessControl>
                  <AccessControl
                    permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
                    accessType={PERMISSION_TYPES.DELETE}
                  >
                    <span className="iconWrapper">
                      <img
                        role="presentation"
                        src={deleteIcon}
                        alt="Delete"
                        title="Delete"
                        onClick={handleDeleteUser}
                      />
                    </span>
                  </AccessControl>
                  <AccessControl
                    permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
                    accessType={PERMISSION_TYPES.EDIT}
                  >
                    <span className="iconWrapper">
                      <img
                        role="presentation"
                        src={
                          userInfo.data.status === "blocked"
                            ? unblock
                            : blockIcon
                        }
                        alt="Block"
                        title="Block"
                        onClick={handleBlockUser}
                      />
                    </span>
                  </AccessControl>
                </div>
              </div>
              <div className="userInformatinc">
                <div className="column_left">
                  <img
                    src={
                      userInfo.data.avatusImage
                        ? userInfo.data.avatusImage
                        : userprofile
                    }
                    alt="userprofile"
                    className="imgCover"
                  />
                </div>
                <div className="column_right">
                  <div
                    className="userInfoinner userInfo">
                    <div>
                      <h2>{userInfo.data.username}</h2>
                      <h3>Bio</h3>
                      <p>{userInfo.data.bio}</p>
                    </div>
                    {userInfo.data.status === "blocked" ? (
                      <img src={blocked} alt="blocked" />
                    ) : userInfo.data.registerationStatus === "pending" ? (
                      <div className="simple_text">Pending</div>
                    ) : (
                      <div className="simple_text">Active</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Accordion heading="User Report Detail">
              <UserReportDetail id={id} />
            </Accordion>
            <Accordion heading="Post Report Detail">
              <PostReportDetail id={id} />
            </Accordion>
            <Accordion heading="Channel Report Detail">
              <ChannelReportDetailUserManagement id={id} />
            </Accordion>
            <Accordion heading="Event Report Detail">
              <EventReportDetail id={id} />
            </Accordion>
            <Accordion
              heading={intl.formatMessage({ id: "CampaignReport.label.title" })}
            >
              <CampaignReportDetailUserManagement />
            </Accordion>
            <Accordion heading="Notification Details">
              <NotificationDetail id={id} />
            </Accordion>
            <Accordion heading="Notes Detail">
              <NotesDetail id={id} />
            </Accordion>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { userinfo } = state;
  return {
    userInfo: userinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserInfo: (payload: any) => dispatch(getUserInfo(payload)),
});

const ReportUserInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ReportUserInfoComponent);

export default ReportUserInfo;
