import api from '../api';

export class TransactionDetailService {
  static async getTransactionDetails(payload: any) {
    const {
      page,
      rowsPerPage,
      id,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}&userId=${id}`;
    return api.getApiCall(`/api/v1/admins/transactions/user?${apiEndPoint}`);
  }
}
