import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import {
  Chart as ChartJS, ArcElement, Tooltip,
} from 'chart.js';

import { Pie, Doughnut } from 'react-chartjs-2';

import {
  Animation, ArgumentScale, Stack,
} from '@devexpress/dx-react-chart';
import classNames from 'classnames';
import userImg from '../../assets/svg/userDashboard.svg';

import {
  Box, Card, CardContent, MenuItem, Select,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchDashboardData } from '../../actions';
import Overlay from '../../components/Overlay';
import { scaleBand } from '@devexpress/dx-chart-core';
import { Link } from 'react-router-dom';
import eventDashboard from '../../assets/svg/eventDashboard.svg';
import totalActiveUsers from '../../assets/svg/totalActiveUsers.svg';
import totalUcons from '../../assets/svg/totalIcons.svg';
import { SuperAdminAccessControl } from '../../components/AccessControl';
import roles from '../../components/AdminRoles/roles.enum';
import ToastBar from '../../components/ToastBar';
import './dashboard.scss';

ChartJS.register(ArcElement, Tooltip);

interface Props {
  fetchDashboardDetails: any;
  dashboardData: any;
}

const Dashboard = (props: Props) => {
  const { fetchDashboardDetails, dashboardData } = props;
  const [trendingChannelType, setTrendingChannelType] = React.useState('free');
  useEffect(() => {
    fetchDashboardDetails(trendingChannelType);
  }, [fetchDashboardDetails, trendingChannelType]);
  let trendingUserData: any = [];
  let trendingChannelData: any = [];
  let liveEvents: any = [];
  let upcomingEvents: any = [];
  let ageStructure: any = [];
  let memberShipData: any = {};
  if (!dashboardData.loadingInProgress && dashboardData.loadingError === null && dashboardData.data !== null) {
    trendingUserData = dashboardData?.data[0]?.data.length !== 0 ? [
      { argument: `${dashboardData.data[0]?.data[0]?.username} (${dashboardData.data[0].data[0].followersCount})`, value: dashboardData.data[0].data[0].followersCount },
      { argument: `${dashboardData.data[0]?.data[1]?.username} (${dashboardData.data[0].data[1].followersCount})`, value: dashboardData.data[0].data[1].followersCount },
      { argument: `${dashboardData.data[0]?.data[2]?.username} (${dashboardData.data[0].data[2].followersCount})`, value: dashboardData.data[0].data[2].followersCount },
      { argument: `${dashboardData.data[0]?.data[3]?.username} (${dashboardData.data[0].data[3].followersCount})`, value: dashboardData.data[0].data[3].followersCount },
      { argument: `${dashboardData.data[0]?.data[4]?.username} (${dashboardData.data[0].data[4].followersCount})`, value: dashboardData.data[0].data[4].followersCount },
    ] : [];

    trendingChannelData = dashboardData.data[1].data.length !== 0 ? [
      { argument: `${dashboardData.data[1]?.data[0]?.channelName} (${dashboardData.data[1].data[0]?.subscriberCount})`, value: dashboardData.data[1].data[0]?.subscriberCount }, // followersCount
      { argument: `${dashboardData.data[1]?.data[1]?.channelName} (${dashboardData.data[1].data[1]?.subscriberCount})`, value: dashboardData.data[1].data[1]?.subscriberCount },
      { argument: `${dashboardData.data[1].data[2]?.channelName} (${dashboardData.data[1].data[2]?.subscriberCount})`, value: dashboardData.data[1].data[2]?.subscriberCount },
      { argument: `${dashboardData.data[1].data[3]?.channelName} (${dashboardData.data[1].data[3]?.subscriberCount})`, value: dashboardData.data[1].data[3]?.subscriberCount },
      { argument: `${dashboardData.data[1].data[4]?.channelName} (${dashboardData.data[1].data[4]?.subscriberCount})`, value: dashboardData.data[1].data[4]?.subscriberCount },
    ] : [];

    liveEvents = {
      labels: [
        `Paid (${((dashboardData.data[3].data[0].eventsLivePaid / (dashboardData.data[3].data[0].eventsLivePaid + dashboardData.data[3].data[0].eventsLiveFree)) * 100).toFixed(2)}%)`,
        `Free (${((dashboardData.data[3].data[0].eventsLiveFree / (dashboardData.data[3].data[0].eventsLivePaid + dashboardData.data[3].data[0].eventsLiveFree)) * 100).toFixed(2)}%)`],
      datasets: [
        {
          label: '# of Events',
          data: [
            dashboardData.data[3].data[0].eventsLivePaid,
            dashboardData.data[3].data[0].eventsLiveFree,

          ],
          backgroundColor: [
            '#db0716',
            'rgba(54, 162, 235)',
          ],
          borderWidth: 1,
        },
      ],
    };

    upcomingEvents = {
      labels: [
        `Paid (${((dashboardData.data[3].data[0].eventsScheduledFree / (dashboardData.data[3].data[0].eventsScheduledFree + dashboardData.data[3].data[0].eventsUpcomingPaid)) * 100).toFixed(2)}%)`,
        `Free (${((dashboardData.data[3].data[0].eventsUpcomingPaid / (dashboardData.data[3].data[0].eventsScheduledFree + dashboardData.data[3].data[0].eventsUpcomingPaid)) * 100).toFixed(2)}%)`],
      datasets: [
        {
          label: '# of Votes',
          data: [
            dashboardData.data[3].data[0].eventsScheduledFree,
            dashboardData.data[3].data[0].eventsUpcomingPaid,

          ],
          backgroundColor: [
            '#db0716',
            'rgba(54, 162, 235)',
          ],
          borderWidth: 1,
        },
      ],
    };

    ageStructure = [{
      membership: `Monthly Plan ( P: ${dashboardData?.data[4].data[0].subscribersMonthly}, R: ${dashboardData?.data[4].data[0].subscribersMonthlyRenewal})`,
      purchased: dashboardData?.data[4].data[0].subscribersMonthly,
      renewed: dashboardData?.data[4].data[0].subscribersMonthlyRenewal,
    }, {
      membership: `Quaterly Plan ( P: ${dashboardData?.data[4].data[0].subscribersQuarterly}, R: ${dashboardData?.data[4].data[0].subscribersQuarterlyRenewal})`,
      purchased: dashboardData?.data[4].data[0].subscribersQuarterly,
      renewed: dashboardData.data[4].data[0].subscribersQuarterlyRenewal,
    }, {
      membership: `Yearly Plan ( P: ${dashboardData?.data[4].data[0].subscribersYearly}, R: ${dashboardData?.data[4].data[0].subscribersYearlyRenewal})`,
      purchased: dashboardData.data[4].data[0].subscribersYearly,
      renewed: dashboardData.data[4].data[0].subscribersYearlyRenewal,
    }];
    memberShipData = {
      labels: [
        `Free (${((dashboardData.data[5].data.free / (dashboardData.data[5].data.totalUsers)) * 100).toFixed(2)}%)`,
        `Subscription Monthly (${((dashboardData.data[5].data.subscriptioCountMonthly / (dashboardData.data[5].data.totalUsers)) * 100).toFixed(2)}%)`,
        `Subscription Quaterly (${((dashboardData.data[5].data.subscriptioCountQuarterly / (dashboardData.data[5].data.totalUsers)) * 100).toFixed(2)}%)`,
        `Subscription Yearly (${((dashboardData.data[5].data.subscriptioCountYearly / (dashboardData.data[5].data.totalUsers)) * 100).toFixed(2)}%)`,
      ],
      datasets: [
        {
          label: '# of Memberships',
          data: [
            dashboardData.data[5].data.free,
            dashboardData.data[5].data.subscriptioCountMonthly,
            dashboardData.data[5].data.subscriptioCountQuarterly,
            dashboardData.data[5].data.subscriptioCountYearly,
          ],
          backgroundColor: [
            '#db0716',
            'rgba(255, 99, 132)',
            'rgba(54, 162, 235)',
            'rgba(255, 206, 86)',
          ],
          borderWidth: 1,
        },
      ],
    };
  }

  return (
    <>
      {dashboardData.loadingError
        ? (
          <ToastBar
            message={dashboardData.loadingError}
            handleCloseMethod={() => null}
          />
        ) : (
          <>
            {
              dashboardData.loadingInProgress ? <Overlay /> : (
                <div className="dashboard_page_inner">
                  <SuperAdminAccessControl rules={[roles.superadmin]}>
                    <h2 className="heading">All Transactions Overview</h2>
                    <div className="card_row">
                      <Box className="card_box">
                        <Card className="card" variant="outlined">
                          <CardContent className="card_content">
                            <h3>
                              Total Payment Transactions
                              of Gold AVC
                              <span>(in numbers)</span>
                            </h3>
                            <p className="data">
                              {dashboardData?.data[6]?.data.totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box className="card_box">
                        <Card className="card" variant="outlined">
                          <CardContent className="card_content">
                            <h3>
                              Total Amount of Payment
                              Transactions
                            </h3>
                            <p className="data">
                              {dashboardData?.data[6]?.data.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              {' '}
                              AVC
                            </p>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box className="card_box">
                        <Card className="card" variant="outlined">
                          <CardContent className="card_content">
                            <h3>
                              Total Amount of Payment
                              Transactions
                              <span>(Current Month)</span>
                            </h3>
                            <p className="data">
                              {dashboardData?.data[6]?.data.monthly.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              {' '}
                              AVC
                            </p>
                          </CardContent>
                        </Card>
                      </Box>
                    </div>
                  </SuperAdminAccessControl>
                  <div className="lg_card_row">
                    <div className="col_6 col sm_col">
                      <div className="f_col_box">
                        <h3 className="heading">Important Statistics</h3>
                        <div className="card_row column_card">
                          <Box className="card_box">
                            <Card className="card_content" variant="outlined">
                              <CardContent>
                                <div>
                                  <img src={userImg} alt="" />
                                  <h3>
                                    Total Users
                                  </h3>
                                </div>
                                <Link to="/usermanagement" className="data">
                                  {dashboardData.data[2].data.totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </Link>
                              </CardContent>
                            </Card>
                          </Box>
                          <Box className="card_box">
                            <Card className="card_content" variant="outlined">
                              <CardContent>
                                <div>
                                  <img src={totalActiveUsers} alt="" />
                                  <h3>
                                    Total Active Users
                                  </h3>
                                </div>
                                <Link to="/usermanagement?active=true" className="data">
                                  {dashboardData.data[2].data.totalActiveUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </Link>
                              </CardContent>
                            </Card>
                          </Box>
                          <Box className="card_box">
                            <Card className="card_content" variant="outlined">
                              <CardContent>
                                <div>
                                  <img src={eventDashboard} alt="" />
                                  <h3>
                                    Total Events
                                  </h3>
                                </div>
                                <Link to="/livestreaming" className="data">
                                  {dashboardData.data[2].data.totalEvents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </Link>
                              </CardContent>
                            </Card>
                          </Box>
                          <Box className="card_box">
                            <Card className="card_content" variant="outlined">
                              <CardContent>
                                <div>
                                  <img src={totalUcons} alt="" />
                                  <h3>
                                    Total Channels
                                  </h3>
                                </div>
                                <Link to="/channelmanagement" className="data">
                                  {dashboardData.data[2].data.totalChannels.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </Link>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div className="col_6 col md_col">
                      <div className="f_col_box">
                        <h3 className="heading">Events Overview</h3>
                        <div className="card_row event_row">
                          <div className="col_6 col">
                            <Paper className="trending_user_chart">
                              <Pie data={liveEvents} />
                            </Paper>
                            <p>Live Events</p>
                          </div>
                          <div className="col_6 col">
                            <Paper className="trending_user_chart">
                              <Pie data={upcomingEvents} />
                            </Paper>
                            <p>Upcoming Events</p>
                          </div>
                          <div className="chart_legend_wrap jus_center">
                            <div className="chart_legend">
                              <span className="legend_icon paid" />
                              <p className="legend_text">Paid</p>
                            </div>
                            <div className="chart_legend">
                              <span className="legend_icon free" />
                              <p className="legend_text">Free</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col_6 col lg_col">
                      <div className="f_col_box">
                        <h3 className="heading">Top 5 Trending Users </h3>
                        <div className="trending_user_chart axis_box">
                          <Paper>
                            <Chart
                              data={trendingUserData}
                            >
                              <ArgumentAxis />
                              <ValueAxis />
                              <Animation />
                              <BarSeries valueField="value" argumentField="argument" />
                            </Chart>
                          </Paper>
                          <div className="x_axis">User Profiles</div>
                          <div className="y_axis">Followers</div>
                        </div>
                      </div>
                    </div>
                    <div className="col_6 col lg_col">
                      <div className="f_col_box">
                        <h3 className="heading">Top 5 Channels </h3>
                        <div className="trending_user_chart axis_box">
                          <Paper>
                            <div className="channelPaidSelect">
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={trendingChannelType}
                                label="Category"
                                className="selectPadding"
                                onChange={(val: any) => {
                                  setTrendingChannelType(val.target.value);
                                }}
                              >
                                <MenuItem value="free">Free</MenuItem>
                                <MenuItem value="paid">Paid</MenuItem>
                              </Select>
                            </div>
                            <Chart
                              data={trendingChannelData}
                            >
                              <ArgumentAxis />
                              <ValueAxis />
                              <Animation />
                              <BarSeries valueField="value" argumentField="argument" />
                            </Chart>
                          </Paper>
                          <div className="x_axis">Channels</div>
                          <div className="y_axis subscribers">Subscribers</div>
                          <Link className="link_view_all" to="/channelmanagement">View All</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col_4 col">
                      <div className="f_col_box">
                        <h3 className="heading">Membership Overview</h3>
                        <div className="trending_user_chart">
                          <Paper>
                            <Doughnut title="Membership" data={memberShipData} />
                          </Paper>
                          <div className="chart_legend_wrap jus_center">
                            <div className="chart_legend">
                              <span className="legend_icon free"/>
                              <p className="legend_text">Free</p>
                            </div>
                            <div className="chart_legend">
                              <span className="legend_icon monthly"/>
                              <p className="legend_text">Subscription Monthly</p>
                            </div>
                            <div className="chart_legend">
                              <span className="legend_icon quarterly"/>
                              <p className="legend_text">Subscription Quarterly</p>
                            </div>
                            <div className="chart_legend">
                              <span className="legend_icon yearly"/>
                              <p className="legend_text">Subscription Yearly</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col_8 col">
                      <div className="f_col_box">
                        <h3 className="heading">Subscription Plan Purchase</h3>
                        <div className="trending_user_chart axis_box">
                          <Paper>
                            <Chart
                              data={ageStructure}
                            >
                              <ArgumentScale factory={scaleBand} />
                              <ArgumentAxis />
                              <ValueAxis />

                              <BarSeries
                                valueField="purchased"
                                argumentField="membership"
                                name="Purchased"
                              />
                              <BarSeries
                                valueField="renewed"
                                argumentField="membership"
                                name="Renewed"
                              />
                              <Stack />
                            </Chart>
                          </Paper>
                          <div style={{ justifyContent: 'center' }} className="chart_legend_wrap jus_center">
                            <div className="chart_legend">
                              <span style={{ backgroundColor: '#42A5F5' }} className="legend_icon paid" />
                              <p className="legend_text">Purchased</p>
                            </div>
                            <div className="chart_legend">
                              <span style={{ backgroundColor: '#FF7043' }} className="legend_icon free"/>
                              <p className="legend_text">Auto-Renewal set ON</p>
                            </div>
                          </div>
                          <div className="x_axis">Plans</div>
                          <div className="y_axis subscribers">Subscribers</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </>
        )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  dashboardData: state.dashboard,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchDashboardDetails: (payload: any) => dispatch(fetchDashboardData(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
