import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl } from '../../utils/reactIntl';
import routeConfiguration from '../../routes/routeConfiguration.js';
import config from '../../config';
import { metaTagProps } from '../../utils/seo';
import { canonicalRoutePath } from '../../utils/routes';
import scss from './Page.module.scss';

const preventDefault = (e: any) => {
  e.preventDefault();
};

interface Props {
  className?: string,
  rootClassName?: string,
  children: React.ReactNode,
  intl: any,
  referrer?: any,
  schema?: any,
  title: string,
  scrollingDisabled?: boolean,
  description?: string,
  author?: string,
  contentType?: string,
  published?: string,
  tags?: string,
  updated?: string,
}

const PageComponent: React.FC<Props> = (props) => {
  const {
    className,
    rootClassName,
    children,
    intl,
    referrer,
    author,
    contentType,
    schema,
    title,
    scrollingDisabled,
    description,
    published,
    tags,
    updated,
  } = props;

  const location = useLocation();
  const contentDivRef = useRef<HTMLDivElement | null>(null);
  let scrollPosition = 0;
  let previousScrollingDisabled = useRef<boolean | undefined>(undefined);

  useEffect(() => {
    const handleDragOver = (e: any) => preventDefault(e);
    const handleDrop = (e: any) => preventDefault(e);

    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);

    const pageSchema = document.getElementById('page-schema');
    if (pageSchema) {
      pageSchema.remove();
    }

    return () => {
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  useEffect(() => {
    if (scrollingDisabled && scrollingDisabled !== previousScrollingDisabled.current) {
      scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      previousScrollingDisabled.current = scrollingDisabled;
    } else if (scrollingDisabled !== previousScrollingDisabled.current) {
      previousScrollingDisabled.current = scrollingDisabled;
    }
  }, [scrollingDisabled]);

  const classes = classNames(rootClassName || scss.root , className);
  const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

  const { canonicalRootURL } = config;
  const shouldReturnPathOnly = referrer && referrer !== 'unsafe-url';
  const canonicalPath = canonicalRoutePath(routeConfiguration(), location, shouldReturnPathOnly);
  const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'Page.schemaDescription' });
  const metaTitle = title || schemaTitle;
  const metaDescription = description || schemaDescription;

  const metaToHead = metaTagProps({
    author,
    contentType,
    description: metaDescription,
    published,
    tags,
    title: metaTitle,
    updated,
    url: canonicalUrl,
    locale: intl.locale,
  });

  const metaTags = metaToHead.map((metaProps, i) => <meta key={i} {...metaProps} />);

  const schemaFromProps = Array.isArray(schema) ? schema : [schema];
  const schemaArrayJSONString = JSON.stringify([
    ...schemaFromProps,
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': `${canonicalRootURL}#organization`,
      url: canonicalRootURL,
      name: siteTitle,
      logo: `${canonicalRootURL}/static/webapp-icon-192x192.png`,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: canonicalRootURL,
      description: schemaDescription,
      name: schemaTitle,
      publisher: {
        '@id': `${canonicalRootURL}#organization`,
      },
    },
  ]);

  const scrollPositionStyles = {};

  return (
    <div className={classes}>
      <Helmet
        htmlAttributes={{
          lang: intl.locale,
        }}
      >
        <title>{title}</title>
        {referrerMeta}
        <link rel="canonical" href={canonicalUrl} />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="Content-Language" content={intl.locale} />
        {metaTags}
        <script id="page-schema" type="application/ld+json">
          {schemaArrayJSONString.replace(/</g, '\\u003c')}
        </script>
      </Helmet>
      {/* to add Cookie Consent Component */}
      <div
        className={scss.content_s}
        style={scrollPositionStyles}
        ref={contentDivRef}
      >
        {children}
      </div>
    </div>
  );
};

const Page = injectIntl(PageComponent);
Page.displayName = 'Page';

export default Page;
