import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDateAndTime } from "../../utils/dateMethods";
import {
  AccessControl,
  Button,
  Modal,
  Overlay,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ToastBar,
  VersionManagementDelete,
  hasAccess,
} from "../../components";
import { ManageVersionManagement } from "../../services";
import { fetchManageVersion, updateVersionManagement } from "../../actions";
import { VersionManagementFilter } from "../../forms";
import { exportToCSV, exportToExcel } from "../../utils/exportToExcel";
import DataTable from "../Datatable";
import "../LayoutWrapperMain/LayoutWrapperMain.module.scss";
import editIcon from "../../assets/svg/edit.svg";
import deleteIcon from "../../assets/svg/delete.svg";

interface Props {
  manageversion: any;
  fetchManageVersions: any;
  updateVersionManagement: any;
}

const SubAdminManagement: React.FC<Props> = (props: Props) => {
  const { manageversion, fetchManageVersions, updateVersionManagement } = props;
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    // search: "",
    // isSortByName: "",
    // isSortByCreated: "",
  });
  const [error, setError] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: "",
  });
  const [showOverlay, setShowOverlay] = React.useState(false);
  React.useEffect(() => {
    fetchManageVersions(filter);
  }, [fetchManageVersions, filter]);

  // React.useEffect(() => {
  //   updateVersionManagement({
  //     versionId: "652d1c5476643dafec48bad6",
  //     versionNo: "4",
  //     platform: "1",
  //     launchType: "soft",
  //     description: "Test12",
  //     dateTime: "2023-10-16T11:19:16.000Z",
  //     status: "unblocked",
  //   });
  // }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const count = manageversion.data ? manageversion.data["Pagination-Count"] : 0;
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilter({
      ...filter,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };
  const handlePlatform = (platform: any) => {
    if (platform === "1") {
      return "Android";
    }
    if (platform === "2") {
      return "IOS";
    }
    return "Web";
  };
  const handleSubmitFilter = (data: any) => {
    setError(null);
    if (data.search !== "") {
      setFilter({
        ...filter,
        search: data.search,
        page: 0,
      });
    }
  };
  const handleBlockUnblock = (modalValue: any, versionId: string) => {
    setShowModal(true);
    switch (modalValue) {
      case "edit":
        navigate(`/versionmanagement/editVersion/${versionId}`);
        break;
      case "delete":
        setDeleted({ ...deleted, show: true, id: versionId });
        break;
      default:
        break;
    }
  };
  const handleModalClose = () => {
    setShowModal(false);
    setDeleted({ ...deleted, show: false, id: null });
  };
  const actionButtons = (action: any, idS: any) => (
    <>
      <div className="actionBtnsWrapper">
        <AccessControl
          permissionKey={PERMISSION_KEYS.VERSION_MANAGEMENT}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div>
            <img
              src={editIcon}
              role="presentation"
              alt="edit"
              width="35"
              title="Edit"
              height="35"
              onClick={() => handleBlockUnblock("edit", idS)}
            />
          </div>
        </AccessControl>
        <AccessControl
          permissionKey={PERMISSION_KEYS.VERSION_MANAGEMENT}
          accessType={PERMISSION_TYPES.DELETE}
        >
          <div>
            <img
              src={deleteIcon}
              role="presentation"
              alt="delete"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleBlockUnblock("delete", idS)}
            />
          </div>
        </AccessControl>
      </div>
    </>
  );
  const hideActions = () =>
    hasAccess(PERMISSION_KEYS.VERSION_MANAGEMENT, PERMISSION_TYPES.ADD) ||
    hasAccess(PERMISSION_KEYS.VERSION_MANAGEMENT, PERMISSION_TYPES.EDIT) ||
    hasAccess(PERMISSION_KEYS.VERSION_MANAGEMENT, PERMISSION_TYPES.DELETE);
  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      versionNo: data.versionNo,
      platform: handlePlatform(data.platform),
      launchType: data.launchType,
      description:
        data.description.length > 20 ? (
          <div title={data.description}>
            {data.description.substring(0, 20)}
            ...{" "}
          </div>
        ) : (
          data.description
        ),
      createdAt: getDateAndTime(data.dateTime),
      ...(hideActions() && { action: actionButtons(data.launchType, idS) }),
    };
  }

  const rows: any = [];
  if (manageversion.data !== null && manageversion.data) {
    manageversion.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: "sno",
      label: "S.No",
      sort: false,
    },
    {
      id: "versionNo",
      label: "Version Number",
      sort: false,
    },
    {
      id: "platform",
      label: "Platform",
      sort: false,
    },
    {
      id: "launchType",
      label: "Launch Type",
      sort: false,
    },
    {
      id: "description",
      label: "Version Description",
      sort: false,
    },
    {
      id: "createdAt",
      label: "Hosted On",
      sort: true,
    },
    ...(hideActions()
      ? [
        {
          id: "action",
          label: "Action",
        },
      ]
      : []),
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === "name") {
      setFilter({
        ...filter,
        page: 0,
        // isSortByName: order === "asc" ? "1" : "-1",
      });
    }
    if (orderBy === "createdAt") {
      setFilter({
        ...filter,
        page: 0,
        // isSortByCreated: order === "asc" ? "1" : "-1",
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === "input") {
      handleSelectedClick(event, name);
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      search: "",
    });
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === "XLSX") {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Version_Number: ele.versionNo,
            PlatForm: ele.platform,
            Description: ele.description,
            Launch_Type: ele.launchType,
            AddedOn: ele.createdAt,
          });
        });
        exportToExcel(selectedRowDataModified, "VersionManagement");
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: "Excel successfully downloaded.",
        });
      } else {
        ManageVersionManagement.exportAPI({ ...filter, type: "xlsx" })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: "Excel has been sent on mail.",
            });
          })
          .catch((err: any) => {
            setLoaderExport(false);
            setNotification({
              show: true,
              message: err.response.data.message,
            });
          });
      }
    } else if (exportType === "CSV") {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Version_Number: ele.versionNo,
            PlatForm: handlePlatform(ele.platform),
            Description: ele.description,
            Launch_Type: ele.launchType,
            AddedOn: ele.createdAt,
          });
        });
        exportToCSV(selectedRowDataModified, "VersionManagement");
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: "CSV successfully downloaded.",
        });
      } else {
        ManageVersionManagement.exportAPI({ ...filter, type: "csv" })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: "CSV has been sent on mail.",
            });
          })
          .catch((err: any) => {
            setLoaderExport(false);
            setNotification({
              show: true,
              message: err.response.data.message,
            });
          });
      }
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {deleted.show && (
          <VersionManagementDelete
            setShowOverlay={setShowOverlay}
            handleModalClose={handleModalClose}
            fetchAdminList={() => { setFilter({ ...filter, page: 0 }) }}
            id={deleted.id}
          />
        )}
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() =>
            setNotification({
              ...notification,
              show: false,
              message: "",
            })
          }
        />
      )}

      <div>
        <AccessControl
          permissionKey={PERMISSION_KEYS.VERSION_MANAGEMENT}
          accessType={PERMISSION_TYPES.ADD}
        >
          <div className="topButton">
            <Button
              onClick={() => navigate("/versionmanagement/addVersion")}
            >
              Add New Version
            </Button>
          </div>
        </AccessControl>
        <VersionManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className="dataTableCover">
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={manageversion.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { manageversion } = state;
  return {
    manageversion,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchManageVersions: (payload: any) => dispatch(fetchManageVersion(payload)),
  updateVersionManagement: (payload: any) =>
    dispatch(updateVersionManagement(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminManagement);

