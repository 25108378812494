import React from 'react';
import { connect } from 'react-redux';
import { getNotificationLiveStreaming } from '../../actions';
import classNames from 'classnames';
import { getDateAndTime } from '../../utils/dateMethods';
import DataTable from '../Datatable';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import '../Accordion/accordion.module.scss';

interface Props {
  id: any;
  notification: any;
  fetchNotificationLiveStreaming: any;
}

const NotificationDetailsCreated: React.FC<Props> = (props: Props) => {
  const {
    notification,
    fetchNotificationLiveStreaming,
    id,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    id,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const handleFormatSendBy = (sendBy: string) => {
    switch (sendBy) {
      case 'email':
        return 'Email';
        break;
      case 'push':
        return 'Push';
        break;
      case 'sms':
        return 'SMS';
      default:
        return sendBy;
    }
  };
  React.useEffect(() => {
    fetchNotificationLiveStreaming(filter);
  }, [fetchNotificationLiveStreaming, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = notification.data ? notification.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      title: data.title ? data.title.length > 20
        ? (
          <div title={data.title}>
            {data.title.substring(0, 20)}
            ...
          </div>
        ) : data.title : 'SMS',
      desc: data.message ? data.message.replace(/<[^>]+>/g, '').length > 20
        ? (
          <div title={data.message.replace(/<[^>]+>/g, '')}>
            {data.message.replace(/<[^>]+>/g, '').substring(0, 20)}
            ...
          </div>
        ) : data.message.replace(/<[^>]+>/g, '') : '',// ,
      sentby: data.admin ? data.admin.name : '',
      type: handleFormatSendBy(data.sendBy),
      senton: getDateAndTime(data.createdAt),
    };
  }

  const rows: any = [];
  if (notification.data !== null && notification.data.length !== 0) {
    notification.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCellsPostsCreated: any = [
    {
      id: 'sno', numeric: true, disablePadding: true, label: 'S.No',
    },
    {
      id: 'title', numeric: false, disablePadding: false, label: 'Title',
    },
    {
      id: 'desc', numeric: false, disablePadding: false, label: 'Description',
    },
    {
      id: 'sentby', numeric: false, disablePadding: false, label: 'Sent By',
    },
    {
      id: 'type', numeric: false, disablePadding: false, label: 'Type',
    },
    {
      id: 'senton', numeric: false, disablePadding: false, label: 'Sent On',
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByParentCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <div className="dataTableCover accordionTable">
        <DataTable
          headCells_={headCellsPostsCreated}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={notification.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { notification } = state;
  return {
    notification,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotificationLiveStreaming: (payload: any) => dispatch(getNotificationLiveStreaming(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetailsCreated);
