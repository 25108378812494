import { AddNewVersionForm, EditNewVersionForm } from "../../forms";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Overlay } from "../../components";
import { ManageVersionManagement, VerionManagementDetail } from "../../services";


const ManageVersion = () => {
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const { id } = useParams();
  const location = useLocation();
  const addVersion: boolean = location.pathname.includes("addVersion");
  const handleSubmitFormEdit = async (data: any) => {
    setError("");
    setLoader(true);
    const formDataWithVersionId = {
      ...data,
      versionId: id,
    };

    VerionManagementDetail.getVersionMangementDetail(formDataWithVersionId).then(() => {
      setLoader(false);
      navigate('/versionmanagement');
    }).catch((err) => {
      setLoader(false);
      setError(err.response.data.message);
    });
  };

  return (
    <>
      {showOverlay && <Overlay />}
      {addVersion ? (
        <AddNewVersionForm
          handleSubmit={(data: any) => {
            if (data.launch === "false") {
              setShowOverlay(false);
              setError("Please select launch type");
            } else {
              setError("");
              setShowOverlay(true);
              ManageVersionManagement.manageVersionManagement(data).then(() => {
                navigate("/versionmanagement");
                setShowOverlay(false);
              });
            }
          }}
          error={error}
        />
      ) : (
        <EditNewVersionForm
          handleSubmit={handleSubmitFormEdit}
          loader={loader}
          id={id}
          error={error}
        />
      )}
    </>
  );
};

export default ManageVersion;

