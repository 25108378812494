import React from 'react';
import { connect } from 'react-redux';
import { getDateAndTime } from '../../utils/dateMethods';
import { useNavigate } from 'react-router-dom';
import {
  ToastBar,
} from '../../components';
import { SubDomesManagementService, SupportManagementService } from '../../services';
import { getSupport } from '../../actions';
import {
  SupportManagementFilter,
} from '../../forms';
import { exportToCSV, exportToExcel } from '../../utils/exportToExcel';
import DataTable from '../Datatable';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  support: any;
  fetchSupport: any;
}

const SupportManagement: React.FC<Props> = (props: Props) => {
  const {
    support,
    fetchSupport,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    status: '',
    search: '',
    startDate: '',
    endDate: '',
    isSortByUpdatedAt: '',
    isSortByCreatedAt: '',
  });
  const [error, setError] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    fetchSupport(filter);
  }, [fetchSupport, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = support.data ? support.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const handleSubmitFilter = (data: any) => {
    setError(null);
    if (data.search !== '' || data.userType !== '' || data.endDate !== null || data.startDate !== null) {
      setFilter({
        ...filter,
        search: data.search,
        status: data.userType,
        startDate: data.startDate,
        endDate: data.endDate,
        page: 0,
        isSortByCreatedAt: '',
      });
    }
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      ticketId: data.ticketId,
      username: data.username,
      title: data.title,
      description: data.description !== undefined ? (data.description.length > 20 ? (
        <div title={data.description}>
          {data.description.substring(0, 20)}
          ...
        </div>
      ) : <div title={data.description}>
        {data.description}
      </div>) : '',
      createdAt: getDateAndTime(data.createdAt),
      status: data.status,
      updatedOn: data.status !== 'open' ? getDateAndTime(data.updatedAt) : '',
    };
  }

  const rows: any = [];
  if (support.data !== null && support.data) {
    support.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'ticketId', label: 'Ticket ID', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: false,
    },
    {
      id: 'title', label: 'Title', sort: false,
    },
    {
      id: 'description', label: 'Description', sort: false,
    },
    {
      id: 'createdAt', label: 'Raised At', sort: true,
    },
    {
      id: 'status', label: 'Status', sort: false,
    },
    {
      id: 'updatedOn', label: 'Updated At', sort: true,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'updatedOn') {
      setFilter({
        ...filter,
        page: 0,
        isSortByUpdatedAt: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreatedAt: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    } else {
      navigate(`/support/supportinfo/${name.id}`);
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      status: '',
      search: '',
      startDate: '',
      endDate: '',
      isSortByCreatedAt: '',
    });
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            "Ticket ID": ele.ticketId,
            Username: ele.username,
            Title: ele.title,
            Description: ele.description?.props?.title,
            "Raised At": ele.createdAt,
            Status: ele.status,
            "Updated At": ele.updatedOn,
          });
        });
        exportToExcel(selectedRowDataModified, 'SubDomesManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        SupportManagementService
          .export({ ...filter, type: 'xlsx' }).then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: 'Excel has been sent on mail.',
            });
          }).catch((err: any) => {
            setLoaderExport(false);
            setNotification({
              show: true,
              message: err.response.data.message,
            });
          });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            "Ticket ID": ele.ticketId,
            Username: ele.username,
            Title: ele.title,
            Description: ele.description.props.title,
            "Raised At": ele.createdAt,
            Status: ele.status,
            "Updated At": ele.updatedOn,
          });
        });
        exportToCSV(selectedRowDataModified, 'SubDomesManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        SupportManagementService.export({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        }).catch((err: any) => {
          setLoaderExport(false);
          setNotification({
            show: true,
            message: err.response.data.message,
          });
        });
      }
    }
  };

  return (
    <>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <SupportManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className="dataTableCover">
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={support.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { support } = state;
  return {
    support,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSupport: (payload: any) => dispatch(getSupport(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportManagement);
