import moment from 'moment';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { IntlProvider } from './utils/reactIntl';
import { persistor } from './store';
import routeConfiguration from './routes/routeConfiguration.js';
import Routes from './routes/Routes.jsx';
import config from './config';
import './assets/styles/_avatusDefault.scss';
import './assets/styles/propertySets.scss';
import './assets/styles/customMediaQueries.scss';


// Flex template application uses English translations as default.
import defaultMessages from './translations/en.json';

import messagesInLocale from './translations/fr.json';
import { CircularProgressLoader } from './components';

// If translation key is missing from `messagesInLocale`,
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (
  sourceLangTranslations: any,
  targetLangTranslations: any,
) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations: any, missingKey: any) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

const isDefaultLanguageInUse = config.locale === 'en';

const messages = isDefaultLanguageInUse
  ? defaultMessages
  : addMissingTranslations(defaultMessages, messagesInLocale);

const isTestEnv = process.env.NODE_ENV === 'test';

// Locale should not affect the tests. We ensure this by providing
// messages with the key as the value of each message.
const testMessages = mapValues(messages, (val, key) => key);
const localeMessages = isTestEnv ? testMessages : messages;

const setupLocale = () => {
  if (isTestEnv) {
    // Use english as a default locale in tests
    config.locale = 'en';
    return;
  }

  moment.locale(config.locale);
};

interface Props {
  appStore: any
}

export const AdminApp = (props: Props) => {
  const { appStore } = props;
  setupLocale();
  // getNotifications();
  return (
    <IntlProvider
      locale={config.locale}
      messages={localeMessages}
      textComponent="span"
    >
      <Provider store={appStore}>
        <PersistGate loading={<CircularProgressLoader />} persistor={persistor}>
          <HelmetProvider>
            <BrowserRouter>
              <Routes routes={routeConfiguration()} />
            </BrowserRouter>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
};

export default AdminApp;
