import React from 'react';
import classNames from 'classnames';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import './FieldRadioButton.module.scss';

interface Props {
  rootClassName?: string;
  className?: string;
  children?: any;
  id: string;
  name: string;
  formProps: any;
  options?: any;
  defaultValue?: string; // Change the type to string
}

const FieldRadioButton = (props: Props) => {
  const {
    rootClassName,
    className,
    children,
    id,
    name,
    formProps: { control },
    options = [],
    defaultValue = '', // Set default value to empty string
  } = props;

  const classes = classNames(rootClassName || 'root', className);

  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue} // Set the default value
        render={({ field: { onChange, value } }) => (
          <RadioGroup value={value} onChange={onChange}> {/* Set the value and onChange */}
            <div className='flex'>
              {options.map((option: any) => (
                <FormControlLabel
                  key={option.key}
                  value={option.key}
                  control={<Radio />}
                  label={option.value}
                  id={`${id}-${option.key}`}
                />
              ))}
            </div>
          </RadioGroup>
        )}
      />
      {children}
    </div>
  );
};

export default FieldRadioButton;
