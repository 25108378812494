import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import routeConfiguration from '../../routes/routeConfiguration.js';
import { pathByRouteName } from '../../utils/routes';

const NamedRedirect = (props: any) => {
  const {
    name, search = '', state = {}, push = false, params = {},
  } = props;
  const navigate = useNavigate();
  const pathname = pathByRouteName(name, routeConfiguration(), params);

  useEffect(() => {
    navigate(`${pathname}${search}`, { state, replace: !push });
  }, [navigate, pathname, search, state, push]);

  return null;
};

const { bool, object, string } = PropTypes;

NamedRedirect.propTypes = {
  name: string.isRequired,
  search: string,
  state: object,
  push: bool,
  params: object,
};

export default NamedRedirect;
