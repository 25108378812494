import api from '../api';

export class NotificationEcommerceService {
  static async getNotification(payload: any) {
    const {
      id,
      page,
      rowsPerPage,
      isSortByUsername,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}&sessionBookingId=${id}${isSortByUsername !== '' && isSortByUsername !== 0 && isSortByUsername !== undefined ? `&isSortByUsername=${isSortByUsername}` : ''}&type=service-session-booking`;
    return api.getApiCall(`/api/v1/admins/notifications?${apiEndPoint}`);
  }
}
