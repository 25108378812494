import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dateFormatting } from '../../utils/dateMethods';
import {
  DataTable,
  Modal,
  ToastBar,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  hasAccess,
  DeleteUserInfo,
  BlockUserInfo,
  Overlay,
} from '../../components';
import { SubscribedUserManagementService } from '../../services';
import { exportToCSV, exportToExcel } from '../../utils/exportToExcel';
import { connect } from 'react-redux';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import { fetchSubscribedUserList } from '../../actions';
import '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import SubscribedUserManagementFilter from '../../forms/SubscribedUserManagementFilter';
import avatus from "../../assets/svg/image-placeholder.png";

interface Props {
  subscribeduserList: any;
  fetchSubscribedUsers: any;
}

const SubscribedUserManagement: React.FC<Props> = (props: Props) => {
  const {
    subscribeduserList,
    fetchSubscribedUsers,
  } = props;

  const navigate = useNavigate();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    startDate: '',
    endDate: '',
    search: '',
    isSortByUserName: '',
    isSortByPlanActivationDate: '',
    isSortByPlanUpgradationDate: ''
  });
  const [error, setError] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edited, setEdited] = React.useState<any>({
    show: false,
    id: null,
  });
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  const hideActions = () => hasAccess(PERMISSION_KEYS.SUBSCRIBED_USERS, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.SUBSCRIBED_USERS, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.SUBSCRIBED_USERS, PERMISSION_TYPES.DELETE);

  React.useEffect(() => {
    fetchSubscribedUsers(filter);
  }, [fetchSubscribedUsers, filter]);
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      case 'edit':
        navigate(`/rolemanagement/editRoles/${blockedid}`);
        break;
      default:
        break;
    }
  };
  const actionButtons = (action: any, idS: any) => (
    <div className="actionBtnsWrapper">
      <AccessControl
        permissionKey={PERMISSION_KEYS.SUBSCRIBED_USERS}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          <img
            src={deleteIcon}
            role="presentation"
            alt="delete"
            width="35"
            title="Delete"
            height="35"
            onClick={() => handleBlockUnblock('delete', idS)}
          />
        </div>
      </AccessControl>
      <AccessControl
        permissionKey={PERMISSION_KEYS.SUBSCRIBED_USERS}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          {action === 'unblocked' ? (
            <img
              src={blockIcon}
              role="presentation"
              alt="block"
              width="35"
              title="Block"
              height="35"
              onClick={() => handleBlockUnblock('blocked', idS)}
            />
          ) : (
            <img
              src={unblock}
              role="presentation"
              alt="unblock"
              width="35"
              title="Unblock"
              height="35"
              onClick={() => handleBlockUnblock('unblocked', idS)}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = subscribeduserList.data ? subscribeduserList.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitUserFilter = (data: any) => {
    if (
      data.endDate !== null ||
      data.search !== "" ||
      data.startDate !== null ||
      data.planStatus !== "" ||
      data.planType !== ""
    ) {
      setFilter({
        ...filter,
        startDate: data.startDate ? data.startDate.utc() : "",
        endDate: data.endDate ? data.endDate.utc() : "",
        search: data.search.label,
        planStatus: data.planStatus,
        planType: data.planType,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setEdited({ ...edited, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      username: data.username,
      avatusImage: data.avatusImage ? (
        <img src={data.avatusImage} alt="x" />
      ) : (
        <img src={avatus} alt="x" />
      ),
      planActivated: data.planActivated,
      periodStart: dateFormatting(data.periodStart),
      periodEnd: dateFormatting(data.periodEnd),
      status: data.status === "active"
        ? "Active"
        : data.status === "deactived"
          ? "Deactived"
          : data.status,
      ...(hideActions() && {
        action: actionButtons(data.status, idS),
      }),
    };
  }

  const rows: any = [];
  if (subscribeduserList.data !== null && subscribeduserList.data) {
    subscribeduserList.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'avatusImage', label: 'Avatar', sort: false,
    },
    {
      id: 'planActivation', label: 'Plan Activated', sort: false,
    },
    {
      id: 'periodStart', label: 'Date of Plan Activation', sort: true,
    },
    {
      id: 'periodEnd', label: 'Plan Expiration Date', sort: true,
    },
    {
      id: 'status', label: 'Plan Status', sort: false,
    },
    ...(hideActions() ? [{
      id: 'action', label: 'Action',
    }] : []),
  ];
  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    } else if (event.target.localName !== 'img') {
      navigate(`/subscribedusers/subscribeduserdetails/${name.id}`);
    }
  };
  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'username') {
      setFilter({
        ...filter,
        page: 0,
        isSortByUserName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'periodStart') {
      setFilter({
        ...filter,
        page: 0,
        isSortByPlanActivationDate: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'periodEnd') {
      setFilter({
        ...filter,
        page: 0,
        isSortByPlanUpgradationDate: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleResetSorts = () => {
    setFilter({
      ...filter,
      startDate: '',
      endDate: '',
      search: '',
      planType: '',
      planStatus: '',
      isSortByUserName: '',
      isSortByPlanActivationDate: '',
      isSortByPlanUpgradationDate: ''
    });
  };
  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            username: ele.username,
            status: ele.status,
            planActivated: ele.planActivated,
            periodStart: ele.periodStart,
            periodEnd: ele.periodEnd,
          });
        });
        exportToExcel(selectedRowDataModified, 'SubscribedUserManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        SubscribedUserManagementService.exportSubscribedUserListToMail({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        }).catch((err: any) => {
          setLoaderExport(false);
          setNotification({
            show: true,
            message: err.response.data.message,
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            username: ele.username,
            status: ele.status,
            planActivated: ele.planActivated,
            periodStart: ele.periodStart,
            periodEnd: ele.periodEnd,
          });
        });
        console.log(selectedRowDataModified, "value of modified rows")
        exportToCSV(selectedRowDataModified, 'SubscribedUserManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        SubscribedUserManagementService.exportSubscribedUserListToMail({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        }).catch((err: any) => {
          setLoaderExport(false);
          setNotification({
            show: true,
            message: err.response.data.message,
          });
        });
      }
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          deleted.show && (
            <DeleteUserInfo // navigate('/usermanagement');
              setShowOverlay={setShowOverlay}
              fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
              handleModalClose={handleModalClose}
              id={deleted.id}
            />
          )
        }
        {
          blocked.show && (
            <BlockUserInfo
              id={blocked.id}
              fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
              handleModalClose={handleModalClose}
              userInfoStatus="unblocked"
              setShowOverlay={setShowOverlay}
            />
          )
        }
        {
          unblocked.show && (
            <BlockUserInfo
              id={unblocked.id}
              fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
              handleModalClose={handleModalClose}
              userInfoStatus="blocked"
              setShowOverlay={setShowOverlay}
            />
          )
        }
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <SubscribedUserManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitUserFilter}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className="dataTableCover">
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={subscribeduserList.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { subscribedusers } = state;
  return {
    subscribeduserList: subscribedusers,
  };
};

const mapDispatchToProps = (dispatch: any) => ({

  fetchSubscribedUsers: (payload: any) => dispatch(
    fetchSubscribedUserList(payload),
  ),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscribedUserManagement);
