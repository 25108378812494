import React from 'react';
import classNames from 'classnames';

import './dotsLoader.module.scss';

const Loader = () => (
  <div className="containerBox loaderContainer">
    <h3 className="loader_main">
      Loading
      <span className="loader threeDots"/>
    </h3>
  </div>
);

export default Loader;
