import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgressLoader,
  FieldFileInput,
  FieldTextInput,
  ValidationError,
} from '../../components';
import { FormattedMessage, injectIntl } from '../../utils/reactIntl';
import classNames from 'classnames';
import information from '../../assets/svg/info.svg';
import '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefineds
}

interface Props {
  loader: any;
  handleSubmit: any;
  intl: any;
  handleSaveAsDraft: any;
  error: string;
}

const AddDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    loader,
    handleSaveAsDraft,
    intl,
    error,
  } = props;
  const FILE_SIZE = 50 * 1024 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
  ];

  const addDomesSchema = yup.object().shape({
    domeName: yup.string()
      .required(intl.formatMessage({ id: 'EditDomeForm.error.domeName' })),
    description: yup.string()
      .max(250, intl.formatMessage({ id: 'EditDomeForm.error.description' })),
    selectedIcon: yup
      .mixed()
      .required(intl.formatMessage({ id: 'AddDomes.error.selectedIcon.required' }))
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || SUPPORTED_FORMATS.includes(value[0].type)),
      ),
    unselectedIcon: yup.mixed()
      .required('Please fill all required fields')
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIcon.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || SUPPORTED_FORMATS.includes(value[0].type)),
      ),
    selectedSVG: yup
      .mixed()
      .required(intl.formatMessage({ id: 'AddDomes.error.selectedSVG.required' }))
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.selectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.selectedIconSVG.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || value[0].type === 'image/svg+xml'),
      ),
    unselectedsvg: yup
      .mixed()
      .required(intl.formatMessage({ id: 'AddDomes.error.unselectedsvg.required' }))
      .test(
        'fileSize',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIcon.fileSize' }),
        (value) => value && (typeof (value) === 'string' || value[0].size <= FILE_SIZE),
      )
      .test(
        'fileFormat',
        intl.formatMessage({ id: 'AddDomes.error.unselectedIconSVG.fileFormat' }),
        (value) => value && (typeof (value) === 'string' || value[0].type === 'image/svg+xml'),
      ),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addDomesSchema),
  });

  return (
    <div className="cardBg addDetailsPage">
      <form className="form" onSubmit={formProps.handleSubmit(handleSubmit)}>
        <h2 className="sign_text">{intl.formatMessage({ id: 'AddDomes.label.AddDetails' })}</h2>
        <FieldTextInput
          id="domeName"
          name="domeName"
          formName="addDomes"
          className="textInput"
          type="text"
          formProps={formProps}
          rootClassName="passwordInput"
          label={intl.formatMessage({ id: 'AddDomes.label.domeName' })}
        />
        <div>
          <FieldTextInput
            id="description"
            name="description"
            formName="addDomes"
            className="textInput"
            type="textarea"
            formProps={formProps}
            rootClassName="passwordInput"
            label={intl.formatMessage({ id: 'AddDomes.label.description' })}
          />
        </div>
        <h2 className="sign_text">Upload icon for this dome</h2>
        <div className="profileWrapperimg">
          <div className="selection_box">
            <h4>
              <FormattedMessage id="AddDomesComponent.selected.icon" />
            </h4>
            <FieldFileInput
              id="selectedIcon"
              name="selectedIcon"
              className="textInput"
              formProps={formProps}
              rootClassName="input_upload"
            />
          </div>
          <div className="selection_box">
            <h4>
              <FormattedMessage id="AddDomesComponent.unselected.icon" />
            </h4>
            <FieldFileInput
              id="unselectedIcon"
              name="unselectedIcon"
              className="textInput"
              formProps={formProps}
              rootClassName="input_upload"
            />
          </div>
          <div className="selection_box">
            <h4>
              Selected SVG
            </h4>
            <FieldFileInput
              id="selectedSVG"
              name="selectedSVG"
              className="textInput"
              formProps={formProps}
              rootClassName="input_upload"
            />
          </div>
          <div className="selection_box">
            <h4>
              Unselected SVG
            </h4>
            <FieldFileInput
              id="unselectedsvg"
              name="unselectedsvg"
              className="textInput"
              formProps={formProps}
              rootClassName="input_upload"
            />
          </div>
        </div>
        <p className="informationUpload">
          <img src={information} alt="information" />
          <FormattedMessage id="AddDomesComponent.files.size" />
        </p>
        {Object.keys(formProps.formState.errors).length > 0
          && (
            <ValidationError
              className="validation_error"
              error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]]?.message?.toString()}
            />
          )}
        {Object.keys(formProps.formState.errors).length === 0
          && <ValidationError error={error} />}
        {loader
          ? (
            <div className="savingLoader">
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className="buttonWrapper">
                <Button
                  buttonType={ButtonTypes.button}
                  onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                >
                  <FormattedMessage id="AddComponent.save.as.draft" />
                </Button>
                <Button
                  buttonType={ButtonTypes.submit}
                >
                  <FormattedMessage id="AddComponent.publish" />
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
};

export default injectIntl(AddDomesForm);
